import { RiFileCopyLine } from "react-icons/ri";
import {
  BoxProps,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { SectionHeading } from "@/common/components/SectionHeading";

function FlagKeyCopyDisplay({
  flagKey = "",
  ...boxProps
}: {
  flagKey?: string;
} & BoxProps) {
  const { hasCopied, onCopy } = useClipboard(flagKey);

  return (
    <Flex align="center" gap={1} justify="space-between" {...boxProps}>
      <Text fontFamily="mono" fontSize="sm">
        {flagKey}
      </Text>
      <Tooltip
        closeOnClick={false}
        label={hasCopied ? "Copied!" : "Copy to clipboard"}
      >
        <IconButton
          aria-label="Copy to clipboard"
          color="dimmed"
          icon={<RiFileCopyLine size={14} />}
          size={"xs"}
          variant="ghost"
          isRound
          onClick={onCopy}
        />
      </Tooltip>
    </Flex>
  );
}

export function FeatureKeyDisplay({
  featureKey,
  ...boxProps
}: {
  featureKey?: string;
} & BoxProps) {
  return (
    <Flex direction="column" gap={1}>
      <HStack>
        <SectionHeading variant="subtle">Feature key</SectionHeading>
        <InfoIconTooltip
          _content={{ lineHeight: "base", whiteSpace: "normal" }}
          docsURL="https://docs.bucket.co/product-handbook/create-your-first-feature#get-your-feature-key"
          text="Use the feature key in the Bucket client SDKs to check if the feature is enabled and to track its usage."
        />
      </HStack>
      <FlagKeyCopyDisplay flagKey={featureKey} {...boxProps} />
    </Flex>
  );
}
