const qk = {
  list: (appId?: string) => ["apps", appId, "flags"] as const,

  single: (appId?: string, flagId?: string | null) =>
    [...qk.list(appId), flagId] as const,

  status: (appId?: string, flagId?: string) =>
    [...qk.single(appId, flagId), "status"] as const,

  versions: (appId?: string, flagId?: string, params?: Record<string, any>) =>
    params
      ? ([...qk.single(appId, flagId), "versions", params] as const)
      : ([...qk.single(appId, flagId), "versions"] as const),

  attributeNames: (appId?: string, prefix?: string) =>
    [...qk.list(appId), "context", "keys", prefix] as const,

  attributeKeys: (appId?: string, prefix?: string, value?: string) =>
    [...qk.attributeNames(appId, prefix), "values", value] as const,
};

export { qk as flagQueryKeys };
