import React from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Button, Flex } from "@chakra-ui/react";

import { CompanyAutocompleteSelect } from "@/common/components/CompanyAutocompleteSelect";
import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import { SatisfactionFilter } from "@/common/components/SatisfactionFilter";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { StarsFunnelFilter } from "@/feature/components/StarsFunnelFilter";
import { useFeedbackTableState } from "@/feedback/hooks/useFeedbackTableState";

export const FeedbackFilters = ({
  canCreate,
  canFilterByFeature = true,
  canFilterByCompany = true,
}: {
  canCreate?: boolean;
  canFilterByFeature?: boolean;
  canFilterByCompany?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const {
    funnelStepsFilter,
    onFunnelStepsFilterChange,
    satisfactionFilter,
    onSatisfactionFilterChange,
    featureFilter,
    onFeatureFilterChange,
    companyFilter,
    onCompanyFilterChange,
  } = useFeedbackTableState();

  return (
    <Flex align="center" gap={2}>
      {canCreate && (
        <Button
          as={RouterLink}
          size="sm"
          to={{
            pathname: "new",
            search: searchParams.toString(),
          }}
          variant="primary"
        >
          New feedback
        </Button>
      )}
      <StarsFunnelFilter
        disabledSteps={["segment"]}
        value={funnelStepsFilter}
        onChange={(newSteps) => {
          onFunnelStepsFilterChange(newSteps);
          segmentAnalytics.track("Feedbacks Filter Updated", {
            type: "funnelSteps",
            value: funnelStepsFilter,
          });
        }}
      />
      <SatisfactionFilter
        size="sm"
        value={satisfactionFilter}
        onChange={(value) => {
          onSatisfactionFilterChange(value);
          segmentAnalytics.track("Feedbacks Filter Updated", {
            type: "satisfaction",
            value: satisfactionFilter,
          });
        }}
      />
      {canFilterByFeature && (
        <FeatureAutocompleteSelect
          placeholder="Filter by feature"
          value={featureFilter}
          onChange={(value) => {
            onFeatureFilterChange(value?.id);
            segmentAnalytics.track("Feedbacks Filter Updated", {
              type: "featureFilter",
              value: featureFilter,
            });
          }}
        />
      )}
      {canFilterByCompany && (
        <CompanyAutocompleteSelect
          placeholder="Filter by company"
          value={companyFilter ? { id: companyFilter } : undefined}
          onChange={(company) => {
            onCompanyFilterChange(company?.id);
            segmentAnalytics.track("Feedbacks Filter Updated", {
              type: "companyFilter",
              value: company?.id,
            });
          }}
        />
      )}
    </Flex>
  );
};
