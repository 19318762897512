import { FunnelStep, FunnelStepList } from "@bucketco/shared/featureAPI";
import {
  FeedbackListColumn,
  FeedbackListSortBy,
} from "@bucketco/shared/feedbackAPI";
import { SatisfactionSpan } from "@bucketco/shared/schemas/satisfactionScore";

import { useDataTableParamState } from "@/common/hooks/useDataTableParamState";
import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";

const defaultFunnelSteps = [...FunnelStepList];

export const useFeedbackTableState = () => {
  // Filters
  const [funnelStepsFilter, onFunnelStepsFilterChange] =
    useSearchArrayParam<FunnelStep>("stars", {
      fallback: defaultFunnelSteps,
    });
  const [satisfactionFilter, onSatisfactionFilterChange] =
    useSearchParam<SatisfactionSpan>("satisfaction");
  const [featureFilter, onFeatureFilterChange] = useSearchParam("feature");
  const [companyFilter, onCompanyFilterChange] = useSearchParam("company");

  // Create table state from feature view data and url params.
  const tableState = useDataTableParamState<
    FeedbackListSortBy,
    FeedbackListColumn
  >({
    sorting: [{ id: "timestamp", desc: true }],
  });

  return {
    ...tableState,
    funnelStepsFilter,
    onFunnelStepsFilterChange,
    satisfactionFilter,
    onSatisfactionFilterChange,
    featureFilter,
    onFeatureFilterChange,
    companyFilter,
    onCompanyFilterChange,
    onReset: () => {
      onFunnelStepsFilterChange(defaultFunnelSteps);
      onSatisfactionFilterChange(undefined);
      onCompanyFilterChange(undefined);
      onFeatureFilterChange(undefined);
      tableState.onReset();
    },
  };
};
