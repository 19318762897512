import { WidgetsFilterQuery } from "@bucketco/shared/widgetAPI";

const qk = {
  list: (appId?: string, params?: WidgetsFilterQuery) =>
    ["apps", appId, "widgets", ...(params ? [params] : [])] as const,

  single: (appId?: string, widgetId?: string) =>
    [...qk.list(appId), widgetId] as const,
};

export { qk as widgetQueryKeys };
