import { useColorModeValue } from "@chakra-ui/react";

export function useStarsFunnelColors() {
  const [
    segmentColor,
    triedColor,
    adoptedColor,
    retainedColor,
    satisfiedColor,
    disabledColor,
    dropOffColor,
  ] = useColorModeValue(
    [
      "brand.300",
      "brand.300",
      "brand.300",
      "brand.300",
      "brand.300",
      "gray.150",
      "gray.100",
    ],
    [
      "brand.500",
      "brand.500",
      "brand.500",
      "brand.500",
      "brand.500",
      "gray.750",
      "gray.700",
    ],
  );
  return {
    segmentColor,
    triedColor,
    adoptedColor,
    retainedColor,
    satisfiedColor,
    disabledColor,
    dropOffColor,
  };
}
