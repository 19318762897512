import {
  Avatar,
  Badge,
  Box,
  Flex,
  Grid,
  GridItem,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { Org } from "@bucketco/shared/organizationAPI";

import { AdminMemberActions } from "@/admin/components/AdminMemberActions";
import { useAdminMembers } from "@/admin/data/useAdminMembers";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import TimestampCell from "@/common/components/TimestampCell";

export default function AdminOrgMembers({ org }: { org: Org }) {
  const { data = [], isLoading } = useAdminMembers(org.id);

  if (isLoading) {
    return <AnimatedSpinner show />;
  }

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Signed up</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((member) => (
          <Tr key={member.id}>
            <Td>
              <Grid
                columnGap={3}
                rowGap={0.5}
                templateColumns="min-content 1fr"
              >
                <GridItem rowSpan={2}>
                  <Avatar
                    key={`${member.id}-${member.avatar}`}
                    display="flex"
                    name={member.name}
                    size="md"
                    src={member.avatar ?? undefined}
                  />
                </GridItem>
                <GridItem as={Flex}>
                  <Text>{member.name}</Text>
                  <Box>
                    {member.isAdmin ? <Badge mx="2">Admin</Badge> : null}
                  </Box>
                </GridItem>
                <GridItem>
                  <Link color="dimmed" href={`mailto:${member.email}`}>
                    {member.email}
                  </Link>
                </GridItem>
              </Grid>
            </Td>
            <Td>
              <TimestampCell value={member.createdAt} />
            </Td>
            <Td textAlign="right">
              <AdminMemberActions member={member} org={org} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
