import React, { useCallback, useEffect, useRef, useState } from "react";

// used together with AutocompleteInput, AutocompleteSelect or an Input
// component to make a list of suggestions selectable by keyboard
export default function useInputWithSelectableListItems({
  initial = -1,
  maxItems,
  hasExactMatch,
  onPickSelected,
  isOpen,
}: {
  initial?: number;
  maxItems: number;
  hasExactMatch: boolean;
  onPickSelected: (selected: number) => void;
  isOpen: boolean;
}) {
  const [selected, setSelected] = useState(initial);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isOpen) {
        return;
      }

      if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelected((current) => {
          if (selected === -1) return 0;
          if (selected < maxItems - 1) return current + 1;
          return current;
        });
      }

      if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelected((current) => {
          if (selected === -1) return maxItems - 1;
          if (selected > 0) return current - 1;
          return current;
        });
      }

      if (e.key === "Enter") {
        e.preventDefault();
        if (selected !== -1 && !hasExactMatch) {
          onPickSelected(selected);
        }
      }
    },
    [hasExactMatch, maxItems, onPickSelected, selected, isOpen],
  );

  // reset selected whenever  suggestions list max count change,
  // but not on initial render to avoid overriding "initial" prop
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setSelected(maxItems > 0 ? 0 : -1);
  }, [maxItems]);

  return {
    selected,
    onKeyDown,
  };
}
