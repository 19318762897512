import { useEffect, useRef, useState } from "react";
import { Box, Tooltip, TooltipProps } from "@chakra-ui/react";

export function OverflowTooltip({
  children,
  maxWidth,
  ...props
}: TooltipProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    setIsOverflown(ref.current.scrollWidth > ref.current.clientWidth);
  }, []);

  return (
    <Tooltip isDisabled={!isOverflown} {...props}>
      <Box ref={ref} maxWidth={maxWidth} isTruncated>
        {children}
      </Box>
    </Tooltip>
  );
}
