import { keepPreviousData, useQuery } from "@tanstack/react-query";

import {
  CompanyFeatureUsersQuery,
  CompanyUsersQuery,
} from "@bucketco/shared/companyAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";

export function useCompanyUsersData(
  companyId?: string,
  params?: Omit<CompanyUsersQuery, "envId">,
  enabled?: boolean,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: companyQueryKeys.listUsers(appId, envId, companyId, params),
    queryFn: () =>
      api
        .get<"/apps/:appId/companies/:companyId/users">(
          `/apps/${appId}/companies/${encodeURIComponent(companyId!)}/users`,
          {
            params: { ...params, envId: envId! },
          },
        )
        .then((res) => res.data),
    enabled: !!appId && !!envId && !!companyId && enabled,
    placeholderData: keepPreviousData,
  });
}

export function useCompanyFeatureUsersData(
  companyId?: string,
  featureId?: string,
  params?: Omit<CompanyFeatureUsersQuery, "envId">,
  enabled?: boolean,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: companyQueryKeys.listFeatureUsers(
      appId,
      envId,
      companyId,
      featureId,
      params,
    ),
    queryFn: () =>
      api
        .get<"/apps/:appId/companies/:companyId/users/by-feature/:featureId">(
          `/apps/${appId}/companies/${encodeURIComponent(
            companyId!,
          )}/users/by-feature/${featureId}`,
          {
            params: { ...params, envId: envId! },
          },
        )
        .then((res) => res.data),
    enabled: !!appId && !!envId && !!companyId && !!featureId && enabled,
    placeholderData: keepPreviousData,
  });
}
