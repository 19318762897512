import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useSearchParam } from "@/common/hooks/useSearchParam";

export function useFeatureViewParam() {
  const { appId } = useCurrentEnv();

  return useSearchParam("view", {
    storage: localStorage,
    storageKey: `${appId}:view`,
  });
}
