import { Outlet, useParams } from "react-router-dom";

import { spanToScore } from "@/common/components/SatisfactionFilter";
import { FeedbackTable } from "@/feedback/components/FeedbackTable";
import { useFeedbackData } from "@/feedback/data/useFeedbackData";
import { useFeedbackTableState } from "@/feedback/hooks/useFeedbackTableState";

export default function CompanyFeedback() {
  const { companyId } = useParams();

  const {
    sorting,
    pageIndex,
    funnelStepsFilter,
    satisfactionFilter,
    featureFilter,
  } = useFeedbackTableState();

  // We only handle sorting one column for now
  const { id: sortBy, desc: sortDesc } = sorting[0] ?? {};
  const { data, isLoading, isFetching } = useFeedbackData({
    sortBy,
    sortOrder:
      sortDesc === true ? "desc" : sortDesc === false ? "asc" : undefined,
    pageIndex,
    // Filters
    funnelSteps: funnelStepsFilter,
    satisfaction: spanToScore(satisfactionFilter),
    featureId: featureFilter,
    companyId,
  });

  return (
    <>
      <FeedbackTable
        context="company"
        data={data}
        isFetching={isFetching}
        isLoading={isLoading}
      />
      <Outlet />
    </>
  );
}
