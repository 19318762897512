import React from "react";
import { Portal } from "@chakra-ui/react";

type Props = {
  usePortal?: boolean;
  children?: React.ReactNode;
};

export const PortalWrapper = ({ usePortal, children }: Props) =>
  usePortal ? <Portal>{children}</Portal> : <>{children}</>;
