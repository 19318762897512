import {
  Box,
  HTMLChakraProps,
  SystemProps,
  useMenuContext,
  useMultiStyleConfig,
} from "@chakra-ui/react";

export interface MenuArrowProps extends HTMLChakraProps<"div"> {
  /**
   * The color of the arrow's shadow
   */
  shadowColor?: SystemProps["color"];
}

const resolveVar = (scale: string, value: unknown) =>
  value ? `${scale}.${value}, ${value}` : undefined;

export const MenuArrow: React.FC<MenuArrowProps> = (props) => {
  const { bg, bgColor, backgroundColor, shadow, boxShadow, shadowColor } =
    props;
  const { popper } = useMenuContext();
  const styles = useMultiStyleConfig("Menu", props);
  const { getArrowProps, getArrowInnerProps } = popper;
  const arrowBg = bg ?? bgColor ?? backgroundColor;
  const arrowShadow = shadow ?? boxShadow;

  return (
    <Box
      {...getArrowProps()}
      className="chakra-popover__arrow-positioner"
      zIndex="inherit !important" // overridden because menu doesn't have a z-index like popover does
    >
      <Box
        className={`chakra-popover__arrow ${props.className}`}
        {...getArrowInnerProps(props)}
        __css={{
          "--popper-arrow-shadow-color": resolveVar("colors", shadowColor),
          "--popper-arrow-bg": resolveVar("colors", arrowBg),
          "--popper-arrow-shadow": resolveVar("shadows", arrowShadow),
          ...styles.arrow,
        }}
      />
    </Box>
  );
};
