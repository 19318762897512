import { Box } from "@chakra-ui/react";

export function UserOnlineStatus({ userStatus }: { userStatus: string }) {
  if (userStatus != "online" && userStatus != "offline") {
    return null;
  }

  const color = userStatus === "online" ? "green.500" : "red.500";

  return (
    <Box color="gray.500">
      User is currently
      <Box color={color} display="inline">
        &nbsp;&#9679;&nbsp;{userStatus}
      </Box>
    </Box>
  );
}
