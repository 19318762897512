import { useQuery } from "@tanstack/react-query";

import { WidgetsFilterQuery } from "@bucketco/shared/widgetAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { widgetQueryKeys } from "@/widget/data/widgetQueryKeys";

export function useWidgetsData(params?: WidgetsFilterQuery) {
  const { appId } = useCurrentEnv();
  return useQuery({
    queryKey: widgetQueryKeys.list(appId, params),
    queryFn: () =>
      api
        .get<"/apps/:appId/widgets">(`/apps/${appId}/widgets`, { params })
        .then((res) => res.data.widgets),
    enabled: !!appId,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
