import { ReactNode } from "react";
import { Box, chakra, CircularProgress, Flex, HStack } from "@chakra-ui/react";

import CheckIcon from "@/common/assets/check.svg?react";
import { formatPercentage } from "@/common/utils/numberFormatting";
import { GoalStatusPercentage } from "@/feature/components/Goals/GoalStatusPercentage";
import {
  GoalStatus,
  useGoalStatusColors,
} from "@/feature/hooks/useGoalStatusColors";

const Svg = chakra("svg");
const SvgCircle = chakra("circle");
const SvgRect = chakra("rect");

const Icon = ({
  size,
  label,
  progress,
  progressIndeterminate = false,
  dashed,
  slashed,
  showPercentage = true,
  children,
  status,
}: {
  size: number;
  label: string;
  progress?: number;
  progressIndeterminate?: boolean;
  dashed?: boolean;
  slashed?: boolean;
  showPercentage?: boolean;
  children?: ReactNode;
  status: GoalStatus;
}) => {
  const { fg, bg } = useGoalStatusColors(status);

  return (
    <HStack aria-label={label} flexShrink={0} spacing={`${size / 4}px`}>
      <Box position="relative">
        {typeof progress === "number" ? (
          <CircularProgress
            bg={bg}
            borderRadius="full"
            color={fg}
            display="block"
            size={`${size}px`}
            trackColor="transparent"
            value={Math.max(progress, 0) * 100}
          />
        ) : (
          <Svg boxSize={`${size}px`} viewBox="0 0 100 100">
            <SvgCircle
              cx="50"
              cy="50"
              fill={bg}
              r="42"
              stroke={fg}
              strokeDasharray={dashed ? "12 16" : undefined}
              strokeLinecap="round"
              strokeWidth="10px"
            />
          </Svg>
        )}

        <Svg
          boxSize={`${size}px`}
          color={fg}
          left={0}
          position="absolute"
          top={0}
          viewBox="0 0 100 100"
        >
          {slashed && (
            <SvgRect
              fill={fg}
              height="50"
              transform={`rotate(-45deg)`}
              transformOrigin="center"
              width="10px"
              x="45"
              y="25"
            />
          )}
        </Svg>

        {children && (
          <Flex
            align="center"
            boxSize={`${size}px`}
            color={fg}
            justify="center"
            left={0}
            position="absolute"
            top={0}
          >
            {children}
          </Flex>
        )}
      </Box>
      {typeof progress === "number" && showPercentage && (
        <GoalStatusPercentage
          progress={progress}
          progressIndeterminate={progressIndeterminate}
          size={size}
          status={status}
        />
      )}
    </HStack>
  );
};

export function GoalStatusIcon({
  status,
  progress,
  size = 24,
  showPercentage,
  progressIndeterminate = false,
}: {
  status: GoalStatus;
  progress?: number;
  size?: number;
  showPercentage?: boolean;
  progressIndeterminate?: boolean;
}) {
  // ensure that a tiny part of the circular progress indicator is shown
  // when in "evaluating" so that the circle icon is never entirely empty
  const minProgress = Math.max(0.03, progress ?? 0);
  switch (status) {
    case "evaluating":
      return (
        <Icon
          dashed={progressIndeterminate}
          label={
            "Goal is evaluating" +
            (!progressIndeterminate && progress
              ? `with ${formatPercentage(progress)} progress`
              : "")
          }
          progress={progressIndeterminate ? undefined : minProgress}
          progressIndeterminate={progressIndeterminate}
          showPercentage={showPercentage ?? true}
          size={size}
          status={status}
        />
      );
    case "achieved":
      return (
        <Icon
          label={
            "Goal was achieved" +
            (!progressIndeterminate && progress
              ? `with ${formatPercentage(progress)} progress`
              : "")
          }
          progress={1}
          showPercentage={showPercentage ?? false}
          size={size}
          status={status}
        >
          <CheckIcon height={size * 0.55} width={size * 0.55} />
        </Icon>
      );
  }
}
