import { forwardRef, ReactNode, useCallback, useMemo } from "react";
import { RiArrowDownSLine, RiCloseLine } from "react-icons/ri";
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Text,
  useFormControl,
} from "@chakra-ui/react";
import partition from "lodash/partition";

import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { SimplePieChart } from "@/common/charts/components/SimplePieChart";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import MenuDescription from "@/common/components/MenuDescription";
import { useSegments } from "@/company/data/useSegments";

type SegmentWithPieChartProps = {
  segment: SegmentDTOWithTotalCompanies;
  color?: string;
  showPieChart?: boolean;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

function SegmentWithPieChart({
  segment,
  color,
  showPieChart,
  Icon,
}: SegmentWithPieChartProps) {
  return (
    <HStack color={color}>
      {Icon && <Icon height={16} width={16} />}
      {showPieChart && (
        <SimplePieChart
          color={color}
          value={getFraction(segment.segmentCount, segment.allCount) * 100}
        />
      )}
      <Text color={color}>{segment.name}</Text>
      {segment.deletedAt !== null && <Text color="dimmed">(archived)</Text>}
    </HStack>
  );
}

export type SegmentPickerProps = Omit<ButtonProps, "value" | "onChange"> & {
  value?: string | string[] | null;
  placement?: MenuProps["placement"];
  size?: ButtonProps["size"];
  placeholder?: string;
  menuDescription?: string | React.ReactNode;
  multiselect?: boolean;
  multiselectLimit?: number;
  includeAll?: boolean;
  showClearButton?: boolean;
  selectedColor?: string;
  showPieChart?: boolean;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  segmentItemFilter?: (segment: SegmentDTOWithTotalCompanies) => boolean;
  segmentItemFilterReason?: () => ReactNode;
  onChange?: (value: string | string[] | null) => void;
  onClear?: () => void;
};

export const SegmentPicker = forwardRef(
  (
    {
      id,
      value,
      size = "sm",
      placeholder = "No segment selected",
      menuDescription = "Select segment:",
      multiselect = false,
      multiselectLimit = 5,
      includeAll = true,
      showClearButton = false,
      selectedColor,
      showPieChart = false,
      Icon,
      segmentItemFilter,
      segmentItemFilterReason,
      onChange,
      onClear,
      ...rest
    }: SegmentPickerProps,
    ref,
  ) => {
    const formControlProps = useFormControl<HTMLButtonElement>(rest);

    const { data: segments = [], isLoading: isLoadingSegments } = useSegments({
      includeDeleted: true,
    });

    const valueArr = useMemo(
      () => (Array.isArray(value) ? value : value ? [value] : []),
      [value],
    );

    const selectedSegments = useMemo(
      () => segments.filter((s) => valueArr.includes(s.id)),
      [segments, valueArr],
    );

    const filteredSegments = useMemo(
      () =>
        segments
          .filter((s) => {
            // Remove all-segment if it's not allowed
            if (s.isAllSegment && !includeAll) {
              return false;
            }

            // We still show deleted segments if they are returned from backend
            // or if they are selected
            return s.deletedAt === null || valueArr.includes(s.id);
          })
          .sort((a, b) => {
            // First, prioritize 'isAllSegment'
            if (a.isAllSegment) return -1;
            if (b.isAllSegment) return 1;

            // Then, prioritize 'system' segments
            if (a.system && !b.system) return -1;
            if (!a.system && b.system) return 1;

            // Finally, compare by name
            return a.name.localeCompare(b.name);
          }),
      [segments, includeAll, valueArr],
    );

    const [supportedSegments, unsupportedSegments] = useMemo(
      () =>
        // Remove stateful filter segments if not allowed
        partition(
          filteredSegments,
          (s) => !segmentItemFilter || segmentItemFilter(s),
        ),
      [filteredSegments, segmentItemFilter],
    );

    const clearButtonCallback = useCallback(() => {
      onChange?.(null);
      onClear?.();
    }, [onClear, onChange]);

    const segmentListCallback = useCallback(
      (newValue: string | string[]) => {
        onChange?.(newValue);
      },
      [onChange],
    );

    return (
      <Menu autoSelect={false} closeOnSelect={!multiselect} id={id}>
        <ButtonGroup isAttached>
          <MenuButton
            {...formControlProps}
            ref={ref}
            as={Button}
            color={valueArr.length === 0 ? "dimmed" : undefined}
            data-testid="rule-field"
            isDisabled={formControlProps.disabled}
            isLoading={isLoadingSegments}
            loadingText="Loading..."
            placeholder={placeholder}
            rightIcon={
              <Box fontSize="xl" mr={-2}>
                <RiArrowDownSLine />
              </Box>
            }
            size={size}
            variant="input"
            {...rest}
          >
            {selectedSegments.length > 1 && multiselect ? (
              // Multiple segments selected
              <HStack color={selectedColor}>
                {Icon && <Icon height={16} width={16} />}
                <Text>{valueArr.length} segments</Text>
              </HStack>
            ) : selectedSegments.length >= 1 ? (
              // Single segment selected or/and no multiselect
              <SegmentWithPieChart
                segment={selectedSegments[0]}
                showPieChart={showPieChart}
              />
            ) : (
              // No segment selected
              <Text color="dimmed">{placeholder}</Text>
            )}
          </MenuButton>
          {selectedSegments.length > 0 && showClearButton && (
            <IconButton
              aria-label="Clear selection"
              icon={<RiCloseLine />}
              isDisabled={rest.isDisabled}
              marginInlineStart={"-1px"}
              size={size}
              variant="outline"
              onClick={clearButtonCallback}
            />
          )}
        </ButtonGroup>
        <MenuList
          data-testid="rule-field-attributes-list"
          maxH="sm"
          maxW={280}
          overflow="auto"
          // workaround for to avoid horizontal scrollbar when positioned to the right of the screen
          rootProps={{ style: { transform: "scale(0)" } }}
        >
          <MenuDescription>{menuDescription}</MenuDescription>
          <MenuDivider my={0} />
          <MenuOptionGroup
            type={multiselect ? "checkbox" : "radio"}
            value={multiselect ? valueArr : valueArr[0] ?? ""}
            onChange={segmentListCallback}
          >
            {supportedSegments.map((segment) => (
              <MenuItemOption
                key={segment.id}
                isDisabled={
                  multiselect &&
                  !!valueArr &&
                  valueArr.length >= multiselectLimit &&
                  !valueArr?.includes(segment.id)
                }
                value={segment.id}
              >
                <SegmentWithPieChart
                  segment={segment}
                  showPieChart={showPieChart}
                />
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          {unsupportedSegments.length > 0 && (
            <>
              <MenuDivider />
              <MenuGroup>
                <HStack
                  color="dimmed"
                  fontSize="sm"
                  fontWeight="medium"
                  mx={4}
                  my={2}
                >
                  <Text>Unsupported</Text>
                  {segmentItemFilterReason && (
                    <InfoIconTooltip text={segmentItemFilterReason()} />
                  )}
                </HStack>
                {unsupportedSegments.map((segment) => (
                  <MenuItemOption
                    key={segment.id}
                    value={segment.id}
                    isDisabled
                  >
                    <SegmentWithPieChart
                      segment={segment}
                      showPieChart={showPieChart}
                    />
                  </MenuItemOption>
                ))}
              </MenuGroup>
            </>
          )}
        </MenuList>
      </Menu>
    );
  },
);
SegmentPicker.displayName = "SegmentPicker";
