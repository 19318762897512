import { ReactNode } from "react";
import {
  Box,
  ButtonGroup,
  Card,
  Flex,
  HStack,
  Spinner,
} from "@chakra-ui/react";

import CardContainer from "@/common/components/CardContainer";
import {
  CloseIconButton,
  DeleteConfirmIconButton,
  EditIconButton,
} from "@/common/components/CommonIconButtons";
import { SectionHeading } from "@/common/components/SectionHeading";
import { useWidgetDeleteMutation } from "@/widget/data/useWidgetMutations";

const wrapTitle = (title: ReactNode) =>
  typeof title === "string" ? <SectionHeading>{title}</SectionHeading> : title;

type Props = {
  widgetId: string;
  title?: ReactNode;
  icon?: ReactNode;
  children: ReactNode;
  form?: ReactNode;
  isEditing: boolean;
  isLoading: boolean;
  toolbar?: ReactNode;
  canEdit?: boolean;
  canDelete?: boolean;
  formWidth?: number;
  setIsEditing: (isEditing: boolean) => void;
};

export function WidgetLayout({
  widgetId,
  title,
  icon,
  children,
  form,
  isEditing,
  isLoading,
  toolbar,
  canEdit = !!form,
  canDelete = true,
  formWidth = 260,
  setIsEditing,
}: Props) {
  const deleteMutation = useWidgetDeleteMutation(widgetId);

  return (
    <CardContainer mb={4} minH="2xs">
      <Flex dir="column" justify="space-between" px={3} py={0.5}>
        <HStack dir="row">
          {icon && icon}
          {wrapTitle(title)}
          {isLoading && <Spinner color="dimmed" size="xs" />}
        </HStack>
        <HStack spacing={3}>
          {toolbar && toolbar}
          <ButtonGroup spacing={1}>
            {canEdit && !isEditing && (
              <EditIconButton
                borderRadius="full"
                color="dimmed"
                label="Edit widget"
                variant="ghost"
                onClick={() => setIsEditing(true)}
              />
            )}
            {isEditing && (
              <CloseIconButton
                borderRadius="full"
                color="dimmed"
                label="Stop editing widget"
                variant="ghost"
                onClick={() => setIsEditing(false)}
              />
            )}
            {canDelete && (
              <DeleteConfirmIconButton
                borderRadius="full"
                color="dimmed"
                confirmLabel="Click again to delete widget"
                label="Delete widget"
                variant="ghost"
                onClick={() => deleteMutation.mutate()}
              />
            )}
          </ButtonGroup>
        </HStack>
      </Flex>
      <Card align="stretch" direction="row" flexGrow={1}>
        <Box
          px={5}
          py={4}
          transition="width 200ms"
          width={isEditing ? `calc(100% - ${formWidth}px)` : "100%"}
        >
          {children}
        </Box>
        {isEditing && (
          <Box
            borderLeftColor="appBorder"
            borderLeftWidth={1}
            flexGrow={1}
            h="auto"
            px={5}
            py={4}
            w={`${formWidth}px`}
          >
            {form}
          </Box>
        )}
      </Card>
    </CardContainer>
  );
}
