import { ReactNode, useMemo, useState } from "react";
import {
  Box,
  HStack,
  InputGroup,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useUpdateEffect,
} from "@chakra-ui/react";

import { highlightStringMatch } from "@/common/components/AutocompleteSelect";
import useInputWithSelectableListItems from "@/common/hooks/useInputWithSelectableListItems";

type Suggestion<T extends string> = {
  key: T;
  label: string;
  icon?: ReactNode;
};

export default function AutocompleteInput<T extends string>({
  children,
  search,
  suggestions,
  onValuePick,
  maxSuggestions = 50,
  enableClientSidefilter = true,
}: {
  children: ReactNode;
  search: string;
  suggestions: Suggestion<T>[];
  onValuePick: (selected: Suggestion<T>) => void;
  maxSuggestions?: number;
  enableClientSidefilter?: boolean;
}) {
  const activeColor = useColorModeValue("brand.500", "brand.300");
  const bgHoverColor = useColorModeValue("gray.50", "gray.700");

  const filteredSuggestions = useMemo(() => {
    if (!enableClientSidefilter) {
      return suggestions;
    }

    const lowerCaseSearch = search.toLowerCase();
    return suggestions
      .filter(({ label }) => label.toLowerCase().includes(lowerCaseSearch))
      .slice(0, maxSuggestions);
  }, [enableClientSidefilter, search, suggestions, maxSuggestions]);
  const hasSuggestions = filteredSuggestions.length > 0;

  const [isOpen, setIsOpen] = useState(false);
  const open = () => {
    if (hasSuggestions) {
      setIsOpen(true);
    }
  };
  const close = () => setIsOpen(false);

  const hasExactMatch =
    filteredSuggestions.length === 1 && search === filteredSuggestions[0].label;

  const { selected, onKeyDown } = useInputWithSelectableListItems({
    isOpen: isOpen,
    maxItems: filteredSuggestions.length,
    hasExactMatch,
    onPickSelected: (selected) => {
      handleValuePick(filteredSuggestions[selected]);
    },
  });

  const [lastPick, setLastPick] = useState<Suggestion<T> | undefined>(
    undefined,
  );
  const handleValuePick = (pick: Suggestion<T>) => {
    if (isOpen) {
      setLastPick(pick);
      onValuePick(pick);
      close();
    }
  };

  // If search value starts changing, open suggestions again
  useUpdateEffect(() => {
    if (search.length > 0 && lastPick?.label !== search) {
      open();
    }
  }, [search, lastPick]);

  const listItems = filteredSuggestions.map((item, index) => (
    <ListItem
      key={item.key}
      _hover={{ bg: bgHoverColor }}
      bg={selected === index ? bgHoverColor : undefined}
      color={search === item.key ? activeColor : undefined}
      cursor="pointer"
      data-testid="autocomplete-list-item"
      fontSize="sm"
      px={3}
      py={1}
      onPointerDown={() => {
        handleValuePick(item);
      }}
    >
      <HStack>
        {item.icon && <Box>{item.icon}</Box>}
        <Text>{highlightStringMatch(item.label, search)}</Text>
      </HStack>
    </ListItem>
  ));

  return (
    <Popover
      autoFocus={false}
      isOpen={isOpen && hasSuggestions}
      lazyBehavior="unmount"
      placement="bottom-start"
      trigger="hover"
      isLazy
      matchWidth
    >
      <Box
        w="full"
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            close();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape" && isOpen && hasSuggestions) {
            e.stopPropagation();
            close();
          }

          if (["ArrowDown", "ArrowUp"].includes(e.key)) {
            open();
          }
        }}
      >
        <PopoverTrigger>
          <InputGroup
            display="block"
            onBlur={close}
            onFocus={open}
            onKeyDown={onKeyDown}
          >
            {children}
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent w="auto">
          <PopoverBody data-testid="autocomplete-list" p={0}>
            {
              <List maxH="sm" overflowY="auto">
                {listItems}
              </List>
            }
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  );
}
