import { FeatureMetric } from "@bucketco/shared/featureAPI";
import { WidgetFeatureMetrics } from "@bucketco/shared/widgetAPI";

import { Period } from "@/common/components/PeriodPicker";
import dayjs from "@/common/utils/dayjs";

/** Round a number to the nearest value keeping at most 2 decimal places. */
const roundUpToNearest = (value: number, nearest: number) => {
  return Math.round(Math.ceil(value / nearest) * nearest * 100) / 100;
};

/** Suggest a new widget threshold based on the current value, by default 20% higher. */
export const getSuggestedThreshold = (
  metric: FeatureMetric,
  currentValue: number,
  scaleFactor = 1.2,
) => {
  const isPercentage = WidgetFeatureMetrics[metric].type === "percentage";
  const scaledValue = currentValue * scaleFactor;
  const roundedValue = roundUpToNearest(scaledValue, isPercentage ? 0.1 : 5);
  const maxThreshold = WidgetFeatureMetrics[metric].maxThreshold;
  return Math.min(
    Math.max(roundedValue, WidgetFeatureMetrics[metric].defaultThreshold),
    maxThreshold,
  );
};

export function getCurrentValue(
  timeseries: {
    epoch: number;
    value: number | null;
  }[],
) {
  return timeseries.findLast((point) => point.value != null)?.value ?? null;
}

export function periodToDates(period: Period) {
  const now = dayjs.utc();
  switch (period) {
    case "past30days":
      return {
        startDate: now.subtract(30, "days").startOf("day"),
        endDate: now.endOf("day"),
      };
    case "past3months":
      return {
        startDate: now.subtract(3, "months").startOf("day"),
        endDate: now.endOf("day"),
      };
    case "past6months":
      return {
        startDate: now.subtract(6, "months").startOf("day"),
        endDate: now.endOf("day"),
      };
  }
}
