import { SwitchButtonGroup } from "@/common/components/SwitchButtonGroup";
import {
  TableDisplayMode,
  useTableDisplayModeParam,
} from "@/common/hooks/useTableDisplayMode";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export function TableDisplayModePicker() {
  const [displayMode, setDisplayMode] = useTableDisplayModeParam();

  return (
    <SwitchButtonGroup<TableDisplayMode>
      aria-label="Feature display mode"
      buttons={[
        { id: "tree", label: "Tree" },
        { id: "list", label: "List" },
      ]}
      size="sm"
      value={displayMode}
      onChange={(mode) => {
        setDisplayMode(mode);
        segmentAnalytics.track("Feature Tree Sort Type Toggled", {
          sort_type: displayMode,
        });
      }}
    />
  );
}
