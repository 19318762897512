import { useNavigate } from "react-router-dom";
import { Button, ButtonProps, useColorModeValue } from "@chakra-ui/react";

type Props = ButtonProps & {
  shouldNavigate?: boolean;
  shouldGoBack?: boolean;
};

export default function ModalCancelButton({
  shouldNavigate = true,
  shouldGoBack = true,
  ...rest
}: Props) {
  const navigate = useNavigate();

  return (
    <Button
      _hover={{ color: useColorModeValue("gray.700", "gray.300") }}
      color="gray.500"
      type="button"
      variant="ghost"
      onClick={
        shouldNavigate
          ? () => (shouldGoBack ? navigate(-1) : navigate(".."))
          : undefined
      }
      {...rest}
    >
      Cancel
    </Button>
  );
}
