import {
  FieldValues,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { z } from "zod";

import { ErrorResponse } from "@bucketco/shared/api";

import { convertZodErrorsToHookFormErrors } from "@/common/utils/convertZodErrorToHookFormError";

import { useErrorToast } from "./useErrorToast";

export default function useApiForm<TArgs extends FieldValues, TResult>(
  apiHandler: (data: TArgs) => Promise<TResult>,
  argsSchema: z.AnyZodObject | z.ZodTypeAny,
  mutationOptions?: Omit<
    UseMutationOptions<TResult, AxiosError, TArgs>,
    "mutationFn"
  >,
  formOptions?: UseFormProps<TArgs>,
) {
  // form
  const form = useForm<TArgs>({
    resolver: zodResolver(argsSchema),
    mode: "onChange",
    ...formOptions,
  });

  // mutation
  const mutation = useMutation<TResult, AxiosError<ErrorResponse>, TArgs>({
    mutationFn: apiHandler,
    retry: 0,
    ...mutationOptions,
  });

  const { mutateAsync } = mutation;

  const handleSubmit = form.handleSubmit((data) =>
    mutateAsync(data)
      .then(() => {
        form.clearErrors();
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        form.clearErrors();
        const errorResponse = err.response?.data;
        const validationErrors = errorResponse?.validationErrors || [];

        if (validationErrors.length > 0) {
          const hookFormErrors =
            convertZodErrorsToHookFormErrors(validationErrors);

          for (const [fieldName, fieldError] of Object.entries(
            hookFormErrors,
          )) {
            form.setError(
              fieldName as Parameters<typeof form.setError>[0],
              fieldError,
            );
          }
        }

        if (typeof errorResponse?.error?.message === "string") {
          form.setError("root", { message: errorResponse.error.message });
        }
      }),
  );

  return {
    form,
    handleSubmit,
    mutation,
  };
}

export function useFormMutationSubmitHandler<
  TFormFields extends FieldValues,
  TValue,
  TVariables = TFormFields,
>(
  form: UseFormReturn<TFormFields>,
  mutation: UseMutationResult<TValue, AxiosError<ErrorResponse>, TVariables>,
  onSuccess?: (result: TValue, variables: TFormFields) => void,
  options?: {
    prepareVariables?: (data: TFormFields) => TVariables;
    successToast?: string | ((result: TValue) => string);
    errorToast?: string | ((error: AxiosError<ErrorResponse>) => string);
  },
) {
  const toast = useToast();
  const errorToast = useErrorToast();

  return form.handleSubmit(async (data) => {
    form.clearErrors();

    return mutation.mutateAsync(
      options?.prepareVariables
        ? options.prepareVariables(data)
        : (data as unknown as TVariables),
      {
        onSuccess(result) {
          const title =
            typeof options?.successToast === "function"
              ? options?.successToast(result)
              : options?.successToast;

          if (title) {
            toast({
              title,
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
          onSuccess?.(result, data);
        },
        onError(error) {
          form.reset(undefined, {
            keepDirty: true,
            keepValues: true,
          });

          if (error.response?.status === 400) {
            const errorResponse = error.response?.data;
            const validationErrors = errorResponse?.validationErrors || [];

            if (validationErrors.length > 0) {
              const hookFormErrors =
                convertZodErrorsToHookFormErrors(validationErrors);

              for (const [fieldName, fieldError] of Object.entries(
                hookFormErrors,
              )) {
                form.setError(
                  fieldName as Parameters<typeof form.setError>[0],
                  fieldError,
                );
              }
            }

            if (typeof errorResponse?.error?.message === "string") {
              form.setError("root", { message: errorResponse.error.message });
            }
          } else {
            let prefix = "Error";
            switch (error.response?.status) {
              case 401:
                prefix = "Unauthorized";
                break;
              case 403:
                prefix = "Forbidden";
                break;
              case 404:
                prefix = "Not Found";
                break;
              case 409:
                prefix = "Conflict detected";
                break;
              default:
                break;
            }

            const description = `${prefix}: ${
              error.response?.data?.error?.message || error.message
            }`;

            const title =
              (typeof options?.errorToast === "function"
                ? options?.errorToast(error)
                : options?.errorToast) || "An error has occurred";

            errorToast({
              title,
              description,
              duration: 5000,
              isClosable: true,
            });
          }
        },
      },
    );
  });
}
