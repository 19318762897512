import { useQuery } from "@tanstack/react-query";

import api from "@/common/utils/api";

export function useOrgMembers(orgId: string) {
  return useQuery({
    queryKey: ["teamMembers", orgId],

    queryFn: () =>
      api
        .get<"/orgs/:orgId/members">(`/orgs/${orgId}/members`)
        .then((res) => res.data),
  });
}
