export type IndentType = "." | "│" | "├" | "└" | "▽";

export type TreeNode = {
  parent: TreeNode | null;
  children: Array<TreeNode>;
  indents: Array<IndentType>;
  depth: number;
};

export function assignIndents(tree: Array<TreeNode>) {
  for (const node of tree) {
    if (node.parent) {
      if (node.depth > 1) {
        const indentBase = node.parent.indents.slice(0, node.depth - 1);
        const indentEnd = indentBase.pop();

        node.indents.push(...indentBase);

        if (indentEnd === "└") {
          node.indents.push(".");
        }

        if (indentEnd === "├") {
          node.indents.push("│");
        }
      }

      const hasNextSibling =
        node.parent.children.indexOf(node) < node.parent.children.length - 1;

      if (hasNextSibling) {
        node.indents.push("├");
      } else {
        node.indents.push("└");
      }
    }

    if (node.children.length > 0) {
      node.indents.push("▽");
    }

    assignIndents(node.children);
  }
}

export function flattenTree<TItem extends { children: TItem[] }>(
  tree: TItem[],
): TItem[] {
  return tree.flatMap((i) => [i, ...flattenTree(i.children)]);
}
