import { ReactNode } from "react";
import {
  ErrorBoundary as ErrorBoundaryModule,
  ErrorBoundaryProps,
  FallbackProps,
} from "react-error-boundary";
import { RiEmotionSadLine } from "react-icons/ri";
import { Alert, Flex } from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";

function Fallback({ error }: FallbackProps) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <Alert alignItems={"flex-start"} gap="4" status="error">
      <Flex direction={"column"} gap="4">
        <Flex alignItems={"center"} gap="2">
          <RiEmotionSadLine size="24" /> Sorry, an error has occurred. We’re
          looking into it!
        </Flex>

        {import.meta.env.DEV && <pre>{error.stack}</pre>}
      </Flex>
    </Alert>
  );
}

const logError: ErrorBoundaryProps["onError"] = function logError(error, info) {
  datadogRum.addError(error, info);
};

export default function ErrorBoundary(props: { children: ReactNode }) {
  return (
    <ErrorBoundaryModule FallbackComponent={Fallback} onError={logError}>
      {props.children}
    </ErrorBoundaryModule>
  );
}
