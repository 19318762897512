import { useFormContext } from "react-hook-form";
import { Button, ButtonProps } from "@chakra-ui/react";

type FormSubmitProps = ButtonProps;

export default function FormReset({
  variant = "ghost",
  size = "sm",
  children = "Reset",
  onClick,
  ...props
}: FormSubmitProps) {
  const {
    formState: { isSubmitting, isDirty },
    reset,
  } = useFormContext();

  return (
    isDirty && (
      <Button
        color="dimmed"
        isDisabled={props.isDisabled || isSubmitting}
        size={size}
        type="button"
        variant={variant}
        onClick={(e) => {
          reset();
          onClick?.(e);
        }}
        {...props}
      >
        {children}
      </Button>
    )
  );
}
