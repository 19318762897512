import { useCallback } from "react";
import { useToast, UseToastOptions } from "@chakra-ui/react";

export function useErrorToast() {
  const toast = useToast();
  return useCallback(
    (props: UseToastOptions = {}) => {
      toast({
        description: "Something went wrong",
        status: "error",
        duration: 2000,
        isClosable: false,
        ...props,
      });
    },
    [toast],
  );
}
