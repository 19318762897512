import { Plugin } from "@segment/analytics-next";

interface LinkedInTracker {
  (action: "track", options: { conversion_id: number }): void;
  q: Array<[string, object]>;
}

declare global {
  interface Window {
    lintrk?: LinkedInTracker;
    _linkedin_data_partner_ids?: string[];
  }
}

export const LinkedInConversionTracking: (
  LINKEDIN_PARTNER_ID: string,
) => Plugin = (LINKEDIN_PARTNER_ID) => {
  return {
    name: "LinkedIn conversion tracking",
    version: "1.0.0",
    type: "destination",

    isLoaded: () => typeof window.lintrk !== "undefined",
    async load() {
      if (LINKEDIN_PARTNER_ID) {
        window._linkedin_data_partner_ids =
          window._linkedin_data_partner_ids || [];

        window._linkedin_data_partner_ids.push(LINKEDIN_PARTNER_ID);

        (function (l) {
          if (!l) {
            window.lintrk = (() => {
              const q: Array<[string, object]> = [];
              function linktrk(a: "track", b: { conversion_id: number }) {
                q.push([a, b]);
              }
              linktrk.q = q;

              return linktrk;
            })();
          }

          const s = document.getElementsByTagName("script")[0];

          const b = document.createElement("script");
          b.type = "text/javascript";
          b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";

          s.parentNode!.insertBefore(b, s);
        })(window.lintrk);
      }
    },

    track(ctx) {
      if (ctx.event.name === "Signed Up") {
        window.lintrk?.("track", { conversion_id: 17731617 });
      }

      if (ctx.event.name === "Account Created") {
        window.lintrk?.("track", { conversion_id: 17242217 });
      }

      return ctx;
    },
  };
};
