import { WidgetConfiguration, WidgetDTO } from "@bucketco/shared/widgetAPI";

import { WidgetFeatureMetric } from "@/widget/components/WidgetFeatureMetric/WidgetFeatureMetric";
import { WidgetFeedback } from "@/widget/components/WidgetFeedback/WidgetFeedback";
import { WidgetStarsFunnel } from "@/widget/components/WidgetStarsFunnel/WidgetStarsFunnel";
import { WidgetTypeInvalid } from "@/widget/components/WidgetTypeInvalid";

const widgets = {
  featureMetric: WidgetFeatureMetric,
  starsFunnel: WidgetStarsFunnel,
  feedback: WidgetFeedback,
} satisfies Record<WidgetConfiguration["type"], unknown>;

type Props = {
  widget: WidgetDTO;
  implicitConfiguration?: Partial<WidgetDTO["configuration"]>;
  variant?: "full" | "compact";
};

export const Widget = ({
  widget,
  implicitConfiguration,
  variant = "full",
}: Props) => {
  const WidgetComponent = widgets[widget.configuration.type];
  if (!WidgetComponent) {
    return (
      <WidgetTypeInvalid
        configuration={widget.configuration as never}
        implicitConfiguration={implicitConfiguration as any}
        variant={variant}
        widgetId={widget.id}
      />
    );
  }
  return (
    <WidgetComponent
      configuration={widget.configuration as never}
      implicitConfiguration={implicitConfiguration as any}
      variant={variant}
      widgetId={widget.id}
    />
  );
};
