import {
  forwardRef,
  FunctionComponent,
  PropsWithoutRef,
  ReactNode,
  SVGProps,
  useEffect,
} from "react";
import { RiCloseLine } from "react-icons/ri";
import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  RadioGroupProps,
  useColorMode,
  useColorModeValue,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";

import { SATISFACTION_LABELS } from "@bucketco/shared/constants";
import { SatisfactionScore } from "@bucketco/shared/schemas/satisfactionScore";

import SatisfactionIcon1 from "@/common/assets/satisfaction-1-icon.svg?react";
import SatisfactionIcon1Dark from "@/common/assets/satisfaction-1-icon-dark.svg?react";
import SatisfactionIcon2 from "@/common/assets/satisfaction-2-icon.svg?react";
import SatisfactionIcon2Dark from "@/common/assets/satisfaction-2-icon-dark.svg?react";
import SatisfactionIcon3 from "@/common/assets/satisfaction-3-icon.svg?react";
import SatisfactionIcon3Dark from "@/common/assets/satisfaction-3-icon-dark.svg?react";
import SatisfactionIcon4 from "@/common/assets/satisfaction-4-icon.svg?react";
import SatisfactionIcon4Dark from "@/common/assets/satisfaction-4-icon-dark.svg?react";
import SatisfactionIcon5 from "@/common/assets/satisfaction-5-icon.svg?react";
import SatisfactionIcon5Dark from "@/common/assets/satisfaction-5-icon-dark.svg?react";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { focusStyle } from "@/theme/helpers";

type SatisfactionOption = {
  value: SatisfactionScore;
  visual: ReactNode;
  a11yLabel: string;
  activeColor: [string, string];
};

type FeedbackSatisfactionIconProps = {
  value: SatisfactionScore;
  size?: number;
  colored?: boolean;
} & BoxProps;

export const FeedbackSatisfactionIcon = forwardRef<
  HTMLDivElement,
  FeedbackSatisfactionIconProps
>(({ value, size = 20, colored = false, ...boxProps }, ref) => {
  const color = useSatisfactionColors(value);
  const { colorMode } = useColorMode();
  let Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;

  switch (value) {
    case 1:
      Icon = colorMode === "light" ? SatisfactionIcon1 : SatisfactionIcon1Dark;
      break;
    case 2:
      Icon = colorMode === "light" ? SatisfactionIcon2 : SatisfactionIcon2Dark;
      break;
    case 3:
      Icon = colorMode === "light" ? SatisfactionIcon3 : SatisfactionIcon3Dark;
      break;
    case 4:
      Icon = colorMode === "light" ? SatisfactionIcon4 : SatisfactionIcon4Dark;
      break;
    case 5:
      Icon = colorMode === "light" ? SatisfactionIcon5 : SatisfactionIcon5Dark;
      break;
    default:
      return <NotAvailableCell />;
  }
  return (
    <Box ref={ref} color={colored ? color : "inherit"} {...boxProps}>
      <Icon height={size} width={size} />
    </Box>
  );
});

FeedbackSatisfactionIcon.displayName = "FeedbackSatisfactionIcon";

export const satisfactionOptions: SatisfactionOption[] = [
  ["orange.200", "orange.800"],
  ["orange.100", "orange.900"],
  ["gray.100", "gray.650"],
  ["green.100", "green.900"],
  ["green.200", "green.800"],
].map(([light, dark], i) => ({
  value: (i + 1) as SatisfactionScore,
  visual: (
    <FeedbackSatisfactionIcon value={(i + 1) as SatisfactionScore} colored />
  ),
  a11yLabel: SATISFACTION_LABELS[(i + 1) as SatisfactionScore],
  activeColor: [light, dark],
}));

export function useSatisfactionColors(): Record<SatisfactionScore, string>;
export function useSatisfactionColors(value: SatisfactionScore): string;
export function useSatisfactionColors(value?: SatisfactionScore) {
  const colors: Record<SatisfactionScore, string> = {
    0: "gray.500",
    1: useColorModeValue("orange.500", "orange.400"),
    2: useColorModeValue("orange.400", "orange.500"),
    3: useColorModeValue("gray.500", "gray.500"),
    4: useColorModeValue("green.400", "green.500"),
    5: useColorModeValue("green.500", "green.400"),
  };
  if (typeof value !== "undefined") return colors[value];
  return colors;
}

type FormSatisfactionInputProps = {
  options?: SatisfactionOption[];
  autoFocus?: boolean;
} & Omit<RadioGroupProps, "children">;

export const FormSatisfactionInput = forwardRef<
  HTMLDivElement,
  PropsWithoutRef<FormSatisfactionInputProps>
>(function FormSatisfactionInput(
  {
    options = satisfactionOptions,
    autoFocus = false,
    ...groupProps
  }: FormSatisfactionInputProps,
  ref,
) {
  const {
    value: rootValue,
    getRootProps,
    getRadioProps,
    focus,
  } = useRadioGroup(groupProps);
  const group = getRootProps();

  useEffect(() => {
    if (autoFocus) {
      focus();
    }
  }, [autoFocus, focus]);

  return (
    <Box display={"inline"}>
      <Flex gap={1}>
        <Flex flexDirection={"column"} gap={1}>
          <ButtonGroup ref={ref} isAttached {...group}>
            {options.map(({ value, visual, a11yLabel, activeColor }) => {
              const radio = getRadioProps({ value });
              const isChecked = value.toString() === rootValue.toString();

              return (
                <SatisfactionRadioButton
                  {...radio}
                  key={value}
                  activeColor={activeColor}
                  aria-label={a11yLabel}
                  isChecked={isChecked}
                  title={a11yLabel}
                >
                  {visual}
                </SatisfactionRadioButton>
              );
            })}
          </ButtonGroup>
          <Flex
            aria-hidden="true"
            color="gray.500"
            fontSize="xs"
            justifyContent={"space-between"}
          >
            <Box>{satisfactionOptions[0].a11yLabel}</Box>
            <Box>{satisfactionOptions[4].a11yLabel}</Box>
          </Flex>
        </Flex>
        <IconButton
          aria-label={"Remove satisfaction score"}
          icon={<RiCloseLine size={18} />}
          isDisabled={groupProps.isDisabled || !groupProps.value}
          variant="ghost"
          isRound
          onClick={() => {
            groupProps.onChange?.("");
          }}
        />
      </Flex>
    </Box>
  );
});

function SatisfactionRadioButton({
  children,
  activeColor,
  ...props
}: UseRadioProps & {
  children: React.ReactNode;
  activeColor: [string, string];
  title?: string;
}) {
  const { getInputProps, getRadioProps, htmlProps } = useRadio(props);

  const input = getInputProps();
  const { "aria-hidden": _ariaHidden, ...checkbox } = getRadioProps();

  const activeColorValue = useColorModeValue(...activeColor);
  const activeScale = "scale(1.15)";

  return (
    <Button
      {...checkbox}
      {...htmlProps}
      _focusWithin={{ ...focusStyle }}
      _hover={{ backgroundColor: activeColorValue }}
      as="label"
      backgroundColor={props.isChecked ? activeColorValue : undefined}
      cursor="pointer"
      fontSize="lg"
      fontWeight="medium"
      transition="background-color 0.2s ease-in-out"
      variant="outline"
      willChange="background-color"
    >
      <input {...input} />
      <Box
        _hover={{ transform: activeScale }}
        transform={props.isChecked ? activeScale : undefined}
        transition="0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)"
      >
        {children}
      </Box>
    </Button>
  );
}
