import { RiFileCopyLine } from "react-icons/ri";
import {
  HStack,
  IconButton,
  Input,
  InputProps,
  StackProps,
  Tooltip,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";

type Props = StackProps & {
  value?: string;
  _input?: InputProps;
};

export const IntegrationKey = ({ value = "", _input, ...rest }: Props) => {
  const { hasCopied, onCopy } = useClipboard(value);
  const keyInputBg = useColorModeValue("gray.50", "gray.800");

  return (
    <HStack {...rest}>
      <Input
        bg={keyInputBg}
        borderRadius={"md"}
        overflow="hidden"
        size="sm"
        textOverflow="ellipsis"
        value={value}
        variant="outline"
        whiteSpace="nowrap"
        isReadOnly
        onClick={(e) => {
          e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
        }}
        {..._input}
      />
      <Tooltip
        closeOnClick={false}
        label={hasCopied ? "Copied!" : "Copy to clipboard"}
      >
        <IconButton
          aria-label="Copy to clipboard"
          borderRadius="md"
          icon={<RiFileCopyLine />}
          p={0}
          size="sm"
          variant="outline"
          onClick={onCopy}
        />
      </Tooltip>
    </HStack>
  );
};
