import { useEffect } from "react";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  get,
  UseFormReturn,
} from "react-hook-form";

/**
 * Get a callback each time a form field value changes
 *
 * The callback gets updated field value as its first argument.
 *
 * @example
 * ```js
 * useFormFieldUpdate(
 *  form,
 *  "my-field-name",
 *  (value) => {
 *    console.log('triggers only on value updates of "my-field-name"')
 *  }
 * )
 * ```
 */
export function useFormFieldUpdate<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  form: UseFormReturn<TFieldValues>,
  fieldName: TFieldName,
  fn: (value: FieldPathValue<TFieldValues, TFieldName>) => void,
) {
  useEffect(() => {
    const subscription = form.watch((formValues, fieldEvent) => {
      const updatedFieldName = fieldEvent.name;

      if (updatedFieldName === fieldName) {
        const value = get(formValues, fieldName);

        fn(value);
      }
    });

    return subscription.unsubscribe;
  }, [fn, form, fieldName]);
}
