import { useQuery } from "@tanstack/react-query";

import api from "@/common/utils/api";

import { useCurrentEnv } from "./useCurrentEnv";

export type UseFeedbackPromptStatisticsType = ReturnType<
  typeof useFeedbackPromptStatistics
>;

export const useFeedbackPromptStatistics = (
  featureId?: string,
  startDate?: Date,
  endDate?: Date,
) => {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: [
      "apps",
      appId,
      "environment",
      envId,
      "feedback-prompt-statistics",
      featureId,
      startDate?.toString(),
      endDate?.toString(),
    ],

    queryFn: () =>
      api
        .get<"/apps/:appId/feedback-prompting-statistics">(
          `/apps/${appId}/feedback-prompting-statistics`,
          {
            params: {
              featureId,
              startDate: startDate!,
              endDate: endDate!,
              envId: envId!,
            },
          },
        )
        .then((res) => res.data),

    enabled: !!appId && !!envId && featureId !== "" && !!startDate && !!endDate,
  });
};
