import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { CompanyDetail } from "@bucketco/shared/companyAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { companyQueryKeys } from "./companyQueryKeys";

export function useCompanyData<TData = CompanyDetail>(
  companyId?: string,
  options?: Omit<
    UseQueryOptions<CompanyDetail, AxiosError<ErrorResponse>, TData>,
    "enabled" | "queryKey" | "queryFn"
  >,
) {
  const { appId, envId } = useCurrentEnv();

  return useQuery<CompanyDetail, AxiosError<ErrorResponse>, TData>({
    queryKey: companyQueryKeys.single(appId, envId, companyId),

    queryFn: () =>
      api
        .get<"/apps/:appId/companies/:companyId">(
          `/apps/${appId}/companies/${encodeURIComponent(companyId!)}`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data.company),
    ...options,
    enabled: !!appId && !!envId && !!companyId,
  });
}
