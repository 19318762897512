import { RiExternalLinkLine, RiFileCodeLine } from "react-icons/ri";
import { Box, Button, ButtonProps, Flex, Text } from "@chakra-ui/react";

export function DocumentationButton(props: ButtonProps) {
  return (
    <Button
      as="a"
      display="inline-flex"
      href="https://docs.bucket.co"
      justifyContent="space-between"
      leftIcon={
        <Flex justify="center" w={5}>
          <RiFileCodeLine size={18} />
        </Flex>
      }
      px={2.5}
      rightIcon={
        <Box color="gray.500">
          <RiExternalLinkLine size={12} />
        </Box>
      }
      target="_blank"
      variant="outline"
      {...props}
    >
      <Text flexGrow={1}>Documentation</Text>
    </Button>
  );
}

export function AutoFeedbackSurveysButton(props: ButtonProps) {
  return (
    <Button
      as="a"
      display="inline-flex"
      href="https://docs.bucket.co/product-handbook/automated-feedback-surveys"
      justifyContent="space-between"
      leftIcon={
        <Flex justify="center" w={5}>
          <RiFileCodeLine size={18} />
        </Flex>
      }
      px={2.5}
      rightIcon={
        <Box color="gray.500">
          <RiExternalLinkLine size={12} />
        </Box>
      }
      target="_blank"
      variant="outline"
      {...props}
    >
      <Text flexGrow={1}>Automated surveys setup</Text>
    </Button>
  );
}
