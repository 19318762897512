import {
  CompanyFeaturesColumn,
  CompanyFeaturesSortBy,
  CompanyFeatureUsersColumn,
  CompanyFeatureUsersSortBy,
  CompanyUsersColumn,
  CompanyUsersSortBy,
} from "@bucketco/shared/companyAPI";

import { useDataTableParamState } from "@/common/hooks/useDataTableParamState";
import { useSearchParam } from "@/common/hooks/useSearchParam";

export const useCompanyFeaturesTableState = () =>
  useDataTableParamState<CompanyFeaturesSortBy, CompanyFeaturesColumn>({
    sorting: [{ id: "createdAt", desc: true }],
  });

export const useCompanyUsersTableState = () =>
  useDataTableParamState<CompanyUsersSortBy, CompanyUsersColumn>({
    sorting: [{ id: "lastSeen", desc: true }],
  });

export const useCompanyFeatureUsersTableState = () => {
  const [featureFilter, onFeatureFilterChange] = useSearchParam("feature");

  const tableState = useDataTableParamState<
    CompanyFeatureUsersSortBy,
    CompanyFeatureUsersColumn
  >({
    sorting: [{ id: "lastUsed", desc: true }],
  });

  return {
    ...tableState,
    featureFilter,
    onFeatureFilterChange,
    onReset: () => {
      onFeatureFilterChange(undefined);
      tableState.onReset();
    },
  };
};
