import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";

export function FormRootError({
  errors,
}: {
  errors?: {
    [x: string]: any;
  };
}) {
  return (
    <ErrorMessage
      errors={errors}
      name={"root"}
      render={({ message }) => (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{message}</AlertDescription>
        </Alert>
      )}
    />
  );
}
