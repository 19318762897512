import {
  FeatureDetail as FeatureAPIType,
  FunnelStep,
} from "@bucketco/shared/featureAPI";

export type RelevantMetrics = FunnelStep | "satisfied" | "frequency";

export function relevantMetricsForFeature(
  feature?: FeatureAPIType,
): RelevantMetrics[] {
  if (!feature) return [];

  if (feature.source === "attribute") {
    return ["company", "segment", "adopted", "retained", "satisfied"];
  }

  // feature.source is "event"
  return [
    "company",
    "segment",
    ...(feature.adoptionStrategyEventCountMinEventCount > 1
      ? ["tried" as const]
      : []),
    "adopted",
    "retained",
    "satisfied",
    "frequency",
  ];
}
