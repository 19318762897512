import { ReactNode, useRef } from "react";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  ButtonGroup,
  ModalContentProps,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  title?: ReactNode;
  description?: ReactNode;
  warning?: ReactNode;
  isLoading?: boolean;
  loadingText?: string;
  confirmLabel?: string;
  confirmColor?: string;
  cancelLabel?: string;
  onClose?: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  _contentProps?: ModalContentProps;
};

export function ConfirmationDialog({
  isOpen,
  title = "Are you sure?",
  description,
  warning,
  isLoading,
  loadingText,
  confirmLabel = "Confirm",
  confirmColor = "brand",
  cancelLabel = "Cancel",
  onConfirm,
  onCancel,
  onClose,
  _contentProps = {},
}: Props) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => onClose?.()}
    >
      <AlertDialogOverlay>
        <AlertDialogContent {..._contentProps}>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogBody align="flex-start" as={VStack} spacing={4}>
            {typeof description === "string" ? (
              <Text as="p">{description}</Text>
            ) : (
              description
            )}

            {typeof warning === "string" ? (
              <Alert alignItems="flex-start" status="warning">
                <AlertIcon />
                <VStack alignItems="flex-start" spacing={4}>
                  <Text as="p">{warning}</Text>
                </VStack>
              </Alert>
            ) : (
              warning
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                ref={cancelRef}
                _hover={{
                  color: useColorModeValue("gray.700", "gray.300"),
                }}
                color="gray.500"
                disabled={isLoading}
                variant="ghost"
                onClick={onCancel}
              >
                {cancelLabel}
              </Button>
              <Button
                colorScheme={confirmColor}
                isLoading={isLoading}
                loadingText={loadingText}
                type="submit"
                onClick={onConfirm}
              >
                {confirmLabel}
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
