import { useColorModeValue } from "@chakra-ui/color-mode";

const colorScales = [
  "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "blue",
  "cyan",
  "purple",
  "pink",
];

export const useStagesColor = () => {
  return useColorModeValue(
    colorScales.map((s) => `${s}.400`),
    colorScales.map((s) => `${s}.400`),
  );
};

export const useStageColor = (index: number) => {
  const colorScale = useStagesColor();
  return colorScale[index % colorScale.length];
};
