import clone from "lodash/clone";

/**
 * Split an object into two objects, one containing the specified keys and the other containing the rest.
 * Inspired by lodash's `partition` function that exists for arrays.
 *
 * @param object the object to partition
 * @param keys the keys to include in the first object
 */
export function partitionObject<
  T extends Record<string, unknown>,
  K extends keyof T,
>(object: T, keys: K[] | readonly K[]): [Pick<T, K>, Omit<T, K>] {
  const picked: Pick<T, K> = {} as Pick<T, K>;
  const omitted = clone(object);
  for (const key of keys) {
    if (key in object) {
      picked[key as K] = object[key] as any;
      delete omitted[key];
    }
  }
  return [picked, omitted];
}
