import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";

// strict thresholds.
// this is important because otherwise, 22 hours will say "a day ago" which is incorrect
// example: filtering for "less than a day ago" and then having rows show up that say "a day ago"

const conf = {
  thresholds: [
    { l: "s", r: 44, d: "second" },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: 29, d: "day" },
    { l: "M", r: 1 },
    { l: "MM", r: 11, d: "month" },
    { l: "y", r: 1 },
    { l: "yy", d: "year" },
  ],
  rounding: Math.floor,
};

dayjs.extend(relativeTime, conf);
dayjs.extend(isToday);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(duration);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
dayjs.extend(utc);

export const JUST_NOW = "just now";

dayjs.updateLocale("en", {
  weekStart: 1,
  formats: {
    // abbreviated format options allowing localization
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "YYYY-MM-DD",
    LL: "MMMM D, YYYY",
    LLL: "MMMM D, YYYY, HH:mm",
    LLLL: "dddd, MMMM D, YYYY, HH:mm",
    // lowercase/short, optional formats for localization
    l: "YYYY-M-D",
    ll: "MMM D, YYYY",
    lll: "MMM D, YYYY, HH:mm",
    llll: "ddd, MMM D, YYYY, HH:mm",
  },
  relativeTime: {
    future: "in %s",
    past: (s: string) => (s === JUST_NOW ? s : `${s} ago`),
    s: JUST_NOW, // was "a few seconds"
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

export default dayjs;
