import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import http from "react-syntax-highlighter/dist/esm/languages/prism/http";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import darkStyle from "react-syntax-highlighter/dist/esm/styles/prism/one-dark";
import lightStyle from "react-syntax-highlighter/dist/esm/styles/prism/one-light";
import { useColorModeValue } from "@chakra-ui/color-mode";

SyntaxHighlighter.registerLanguage("jsx", jsx);
SyntaxHighlighter.registerLanguage("http", http);

export type SupportedHighlightLanguage =
  | "javascript"
  | "http"
  | "markdown"
  | "jsx"
  | "html"
  | "json"
  | "json5"
  | "http";

export default function CodeBlock({
  children,
  language,
}: {
  children: string;
  language?: SupportedHighlightLanguage;
}) {
  const style = useColorModeValue(lightStyle, darkStyle);

  return (
    <SyntaxHighlighter
      codeTagProps={{ style: { background: "none" } }}
      customStyle={{
        background: "none",
        padding: 0,
        margin: 0,
        overflow: "unset",
      }}
      language={language}
      lineNumberStyle={{
        paddingRight: "1.5rem",
        minWidth: "2.5rem",
      }}
      style={style}
      showLineNumbers
    >
      {children}
    </SyntaxHighlighter>
  );
}
