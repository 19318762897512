import MotionBox from "@/common/components/MotionBox";

function MotionListItem({
  children,
  direction = "vertical",
}: {
  children: React.ReactNode;
  direction?: "vertical" | "horizontal";
}) {
  const dirAttribute = direction === "vertical" ? "height" : "width";

  return (
    <MotionBox
      animate={{ opacity: 1, [dirAttribute]: "auto" }}
      exit={{ opacity: 0, [dirAttribute]: 0 }}
      initial={{ opacity: 0, [dirAttribute]: 0 }}
      transition={{ duration: "0.15", ease: "easeInOut" }}
    >
      {children}
    </MotionBox>
  );
}

export default MotionListItem;
