import { Fragment, useMemo } from "react";
import { RiFlag2Line, RiProhibitedLine } from "react-icons/ri";
import { chakra, HStack, Text, TextProps } from "@chakra-ui/react";

import { MAX_ROLLOUT_THRESHOLD, reduce } from "@bucketco/shared/filter";
import { FlagRule } from "@bucketco/shared/flagAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { FilterItem } from "@/common/components/filters/FilterItem";
import { SegmentFilterItem } from "@/common/components/filters/SegmentFilterItem";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { formatPercentage } from "@/common/utils/numberFormatting";
import pluralize from "@/common/utils/pluralize";

const FlagIcon = chakra(RiFlag2Line);

export const RulesSummary = ({
  rules,
  ...rest
}: TextProps & { rules?: FlagRule[] | null }) => {
  const { isEnabled: expandRule } = useFeature(
    "expanded-flags-in-features-table",
  );

  const singleSimpleRule = useMemo(() => {
    const rule =
      rules?.length === 1 && rules[0].filter.type !== "group" ? rules[0] : null;
    if (rule) {
      return { ...rule, filter: reduce(rule.filter) };
    }
  }, [rules]);

  if (!rules?.length) {
    return (
      <HStack color="dimmed" spacing={1.5}>
        <RiProhibitedLine />
        <Text as="span" {...rest}>
          No one
        </Text>
      </HStack>
    );
  }

  if (!expandRule && singleSimpleRule?.filter.type === "segment") {
    return (
      <Text as="span" {...rest}>
        {singleSimpleRule.partialRolloutThreshold !== MAX_ROLLOUT_THRESHOLD && (
          <>
            <Text as="span">
              {formatPercentage(
                getFraction(
                  singleSimpleRule.partialRolloutThreshold,
                  MAX_ROLLOUT_THRESHOLD,
                ),
              )}
            </Text>{" "}
            <Text as="span" color="dimmed">
              of
            </Text>{" "}
          </>
        )}
        <SegmentFilterItem filter={singleSimpleRule.filter} compact showIcon />
      </Text>
    );
  } else if (expandRule && singleSimpleRule) {
    return (
      <HStack as="span" spacing={1.5} {...rest}>
        {singleSimpleRule.partialRolloutThreshold !== MAX_ROLLOUT_THRESHOLD && (
          <HStack spacing={1}>
            <Text as="span">
              {formatPercentage(
                getFraction(
                  singleSimpleRule.partialRolloutThreshold,
                  MAX_ROLLOUT_THRESHOLD,
                ),
              )}
            </Text>
            <Text as="span" color="dimmed">
              of
            </Text>
          </HStack>
        )}
        <FilterItem filter={singleSimpleRule.filter} compact showIcon />
      </HStack>
    );
  }

  return (
    <Text
      alignItems="center"
      as="span"
      display="inline-flex"
      gap={1.5}
      {...rest}
    >
      <FlagIcon color="dimmed" />
      <Text as="span">
        {rules.length} {pluralize("rule", rules.length)}
      </Text>
    </Text>
  );
};
