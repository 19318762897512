import { useQuery } from "@tanstack/react-query";

import api from "@/common/utils/api";

export const adminMembersQueryKey = (orgId: string) =>
  ["admin", "org", orgId, "members"] as const;

export function useAdminMembers(orgId: string) {
  return useQuery({
    queryKey: adminMembersQueryKey(orgId),
    queryFn: () =>
      api
        .get<"/admin/:orgId/members">(`/admin/${orgId}/members`)
        .then((res) => res.data),
  });
}
