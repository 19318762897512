import { ConfigType, ManipulateType } from "dayjs";

import dayjs from "@/common/utils/dayjs";
import pluralize from "@/common/utils/pluralize";

export const fullFormattedDateTime = (date: ConfigType) =>
  dayjs(date).format("LLLL");

export const compactFormattedDate = (date: ConfigType) =>
  dayjs(date).format("ddd, ll");

export const epochToShortDate = (epoch?: number | string) => {
  if (!epoch || typeof epoch === "string") return "";
  return dayjs.unix(epoch).format("MMM D");
};

export const epochToDate = (epoch?: number | string) => {
  if (!epoch || typeof epoch === "string") return "";
  return dayjs.unix(epoch).format("MMM D, LT");
};

export const epochIsSame = (
  epoch: number,
  unit?: ManipulateType | undefined,
) => {
  const shift = dayjs().utcOffset() >= 0 ? 1 : 0;
  return dayjs.unix(epoch).isSame(dayjs().add(shift, unit), unit);
};

export const humanizeWeeks = (value: number) => {
  const weeks = Math.trunc(value);
  const days = Math.floor((value % 1) * 7);
  const parts = [];
  if (weeks > 0) {
    parts.push(`${weeks} ${pluralize("week", weeks)}`);
  }
  if (days > 0) {
    parts.push(`${days} ${pluralize("day", days)}`);
  }
  return parts.join(", ");
};
