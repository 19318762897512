import { Link as RouterLink } from "react-router-dom";
import { HStack, Link, StackProps, Text } from "@chakra-ui/react";

import { StageDTO } from "@bucketco/shared/stageAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import { StageFillIcon } from "@/stage/components/StagesIcon";
import { useStageColor } from "@/stage/hooks/useStagesColor";

type Props = {
  stage: Pick<StageDTO, "name" | "colorIndex">;
  link?: boolean;
} & StackProps;

export const StageDisplayName = ({
  stage,
  link = false,
  ...stackProps
}: Props) => {
  const { currentEnv } = useAuthContext();
  const color = useStageColor(stage.colorIndex);

  const component = (
    <HStack spacing={1} {...stackProps}>
      <StageFillIcon boxSize={3} color={color} flexShrink={0} />
      <Text as="span" fontWeight="medium" isTruncated>
        {stage.name}
      </Text>
    </HStack>
  );

  if (link && currentEnv) {
    return (
      <Link
        as={RouterLink}
        style={{ textDecoration: "none" }}
        to={GlobalSettingsUrl(currentEnv, "app-stages")}
      >
        {component}
      </Link>
    );
  } else {
    return component;
  }
};
