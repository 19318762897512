import { forwardRef } from "react";

import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import {
  SegmentPicker,
  SegmentPickerProps,
} from "@/common/components/SegmentPicker";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";

export type FormSegmentPickerProps = FormElementBaseProps &
  Omit<SegmentPickerProps, "value">;

export const FormSegmentPicker = forwardRef<
  HTMLInputElement,
  FormSegmentPickerProps
>(({ _control, onChange, onBlur, ...rest }, ref) => {
  const [managedProps, inputProps] = useManagedFormControlProps(rest);
  return (
    <ManagedFormControl
      {..._control}
      {...managedProps}
      render={({ field }) => (
        <SegmentPicker
          {...inputProps}
          {...field}
          ref={mergeRefs(field.ref, ref)}
          onBlur={mergeFunctions(field.onBlur, onBlur)}
          onChange={mergeFunctions(field.onChange, onChange)}
        />
      )}
    />
  );
});
FormSegmentPicker.displayName = "FormSegmentPicker";
