import { popoverAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  popoverAnatomy.keys,
);

export const PopoverTheme = defineMultiStyleConfig({
  baseStyle: {
    content: {
      bg: "popoverBackground",
      boxShadow: "lg",
      borderColor: "popoverBorder",
      _focus: {
        boxShadow: "lg",
      },

      // these should be semantic tokens but cant use them here for CSS vars unfortuantely
      "--popper-bg": "white",
      "--popper-arrow-shadow-color": "var(--chakra-colors-gray-100)",

      _dark: {
        "--popper-bg": "var(--chakra-colors-gray-800)",
        "--popper-arrow-shadow-color": "var(--chakra-colors-gray-700)",
      },
    },
  },
  variants: {
    tooltip: {
      body: {
        borderRadius: "md",
        px: 4,
        pt: 3,
        pb: 4,
      },
    },
  },
});
