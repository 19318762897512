import { ReactNode } from "react";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";
import {
  Flex,
  FlexProps,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormLabel,
  FormLabelProps,
  useId,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";

import { OptionalKeys } from "@bucketco/shared/utils/types";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";

import {
  FormElementDescription,
  FormElementDescriptionProps,
} from "./FormElementDescription";

export type ManagedFormControlProps = Omit<
  FormControlProps,
  "label" | "onChange" | "onBlur"
> &
  OptionalKeys<Omit<ControllerProps, "control" | "disabled">, "render"> & {
    /**
     * Optional override for automated htmlFor generation.
     * Set to match the id of the input.
     *
     * This should only be needed when the input is not a Chakra Input component,
     * such as MenuButton etc.
     */
    id?: string;
    label?: ReactNode;
    description?: ReactNode;
    descriptionLink?: string;
    descriptionAbove?: boolean;
    /** This is only applicable for the horizontal layout. */
    descriptionInPortal?: boolean;
    showErrors?: boolean;
    horizontal?: boolean;
    _label?: FormLabelProps;
    _inputGroup?: FlexProps;
    _description?: FormElementDescriptionProps;
    _error?: FormErrorMessageProps;
  };

// These keys must match deconstructed props in FormControl
export const ManagedFormControlKeysList = [
  // Controller Props
  "name",
  "render",
  "rules",
  "shouldUnregister",
  "defaultValue",
  // Custom Props
  "label",
  "description",
  "descriptionAbove",
  "descriptionLink",
  "descriptionInPortal",
  "showErrors",
  "horizontal",
  "children",
  // Nested Props
  "_label",
  "_inputGroup",
  "_description",
  "_error",
  // Form Control Props
  "id",
  "isDisabled",
  "isInvalid",
] as const;
export type ManagedFormControlKeys =
  (typeof ManagedFormControlKeysList)[number];

// Deconstructed props must match FormControlKeysList
export function ManagedFormControl({
  // Controller Props
  name,
  render,
  rules,
  shouldUnregister,
  defaultValue,
  // Custom Props
  label,
  description,
  descriptionAbove,
  descriptionLink,
  descriptionInPortal,
  showErrors = true,
  horizontal,
  children,
  // Nested Props
  _label,
  _inputGroup,
  _description,
  _error,
  // Form Control Props
  id,
  isDisabled,
  isInvalid,
  ...rest
}: ManagedFormControlProps) {
  const autoId = useId();
  const { control, formState, getFieldState } = useFormContext();
  const fieldState = getFieldState(name);

  return (
    <FormControl
      alignItems="flex-start"
      display="flex"
      flexDirection={!horizontal ? "column" : undefined}
      gap={2}
      id={id || autoId}
      isDisabled={isDisabled || formState.isSubmitting}
      isInvalid={isInvalid || fieldState.invalid}
      {...rest}
    >
      {label && (
        <FormLabel
          alignItems="center"
          display="flex"
          flex="0 0 auto"
          fontSize="md"
          gap={1}
          lineHeight={horizontal ? 8 : undefined}
          marginInlineEnd={0}
          pb={0}
          whiteSpace={horizontal ? "nowrap" : undefined}
          {..._label}
        >
          {label}
          {horizontal && description && (
            <InfoIconTooltip
              _content={{ lineHeight: "base", whiteSpace: "normal" }}
              docsURL={descriptionLink}
              renderInPortal={descriptionInPortal}
              text={description}
            />
          )}
        </FormLabel>
      )}

      <Flex
        direction="column"
        flex="1 1 auto"
        gap={2}
        justifyContent="flex-start"
        minW={0}
        w="full"
        {..._inputGroup}
      >
        {!horizontal && description && descriptionAbove && (
          <FormElementDescription
            docsURL={descriptionLink}
            isDisabled={isDisabled}
            text={description}
            {..._description}
          />
        )}

        {render && (
          <Controller
            control={control}
            defaultValue={defaultValue}
            name={name}
            render={render}
            rules={rules}
            shouldUnregister={shouldUnregister}
          />
        )}

        {children}

        {showErrors && (
          <FormErrorMessage mt={0} {..._error}>
            <ErrorMessage errors={formState.errors} name={name} />
          </FormErrorMessage>
        )}

        {!horizontal && description && !descriptionAbove && (
          <FormElementDescription
            docsURL={descriptionLink}
            isDisabled={isDisabled}
            mt={0}
            text={description}
            {..._description}
          />
        )}
      </Flex>
    </FormControl>
  );
}
