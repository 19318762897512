import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from "react";
import { RiArrowDownSLine, RiCloseLine, RiSearch2Line } from "react-icons/ri";
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Divider,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
  useFormControl,
  useOutsideClick,
} from "@chakra-ui/react";

import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import Mark from "@/common/components/Mark";
import MenuDescription from "@/common/components/MenuDescription";
import { PortalWrapper } from "@/common/components/PortalWrapper";
import useInputWithSelectableListItems from "@/common/hooks/useInputWithSelectableListItems";

export type EmphasizedSuggestion = {
  key: string;
  badge?: BadgeProps;
};

export function getStringRenderFunction<
  TSuggestion extends Record<string, string | number>,
>(key: keyof TSuggestion) {
  return (item: TSuggestion, search?: string): ReactNode => {
    const displayValue = item[key];

    if (search) {
      return highlightStringMatch(displayValue, search);
    }

    return displayValue;
  };
}

export function getStringFilterFunction<TSuggestion extends object>(
  key: keyof TSuggestion,
) {
  return (item: TSuggestion, search: string): boolean => {
    return String(item[key])
      .toLocaleLowerCase()
      .includes(search.toLocaleLowerCase());
  };
}

export function highlightStringMatch(
  value: string | number,
  search: string,
): ReactNode {
  const stringValue = String(value);
  const parts = stringValue
    .toLocaleLowerCase()
    .split(search.toLocaleLowerCase());

  if (parts.length <= 1) {
    return value;
  }

  return [
    stringValue.slice(0, parts[0].length),
    <Mark key="mark">
      {stringValue.slice(parts[0].length, parts[0].length + search.length)}
    </Mark>,
    stringValue.slice(parts[0].length + search.length),
  ];
}

type AutocompleteSelectBaseProps<TSuggestion extends Record<string, any>> =
  Omit<ButtonProps, "value" | "onChange" | "onBlur"> & {
    suggestions: TSuggestion[];
    isLoadingSuggestions?: boolean;
    itemRenderfn: (suggestion: TSuggestion, search?: string) => ReactNode;
    /** Define this function for client side filtering */
    itemFilterFn?: (
      suggestion: TSuggestion,
      search: string,
      suggestions: TSuggestion[],
    ) => boolean;
    itemKeyProperty: keyof TSuggestion;
    onSearchInput?: (search: string) => void;
    onBlur?: () => void;
    maxSuggestions?: number;
    value?: TSuggestion | null;
    placeholder?: ReactNode;
    description?: ReactNode;
    onClear?: () => void;
    closeOnSelect?: boolean;
    emphasized?: EmphasizedSuggestion[];
    usePortal?: boolean;
  };

export type AutocompleteSelectProps<TSuggestion extends Record<string, any>> =
  AutocompleteSelectBaseProps<TSuggestion> &
    (
      | { showClearButton: true; onChange: (value: TSuggestion | null) => void }
      | { showClearButton?: false; onChange: (value: TSuggestion) => void }
    );

function AutocompleteSelectInner<TSuggestion extends Record<string, any>>(
  {
    suggestions,
    isLoadingSuggestions = false,
    itemRenderfn,
    itemFilterFn = () => true,
    itemKeyProperty,
    onSearchInput,
    onChange,
    onBlur,
    maxSuggestions = Infinity,
    value,
    placeholder = "",
    size = "sm",
    showClearButton,
    description,
    onClear,
    closeOnSelect = true,
    emphasized = [],
    usePortal,
    ...rest
  }: AutocompleteSelectProps<TSuggestion>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();
  const formControlProps = useFormControl<HTMLButtonElement>(rest);
  const initialFocusRef = useRef(null);

  const bgHoverColor = useColorModeValue("gray.50", "gray.700");

  const [searchValue, setSearchValue] = useState("");

  const cappedSuggestions = useMemo(() => {
    const filteredSuggestions = suggestions.filter((s) =>
      itemFilterFn(s, searchValue, suggestions),
    );
    return filteredSuggestions
      .sort(
        (a, b) =>
          emphasized.findIndex(({ key }) => key === b[itemKeyProperty]) -
          emphasized.findIndex(({ key }) => key === a[itemKeyProperty]),
      )
      .slice(0, maxSuggestions);
  }, [
    suggestions,
    maxSuggestions,
    itemFilterFn,
    searchValue,
    emphasized,
    itemKeyProperty,
  ]);

  const { selected, onKeyDown } = useInputWithSelectableListItems({
    isOpen: isOpen,
    initial: value
      ? suggestions.findIndex((s) => s === value) ?? undefined
      : undefined,
    maxItems: cappedSuggestions.length,
    hasExactMatch: false, // always false since we want to be able to pick exact matches
    onPickSelected: (selected) => {
      onChange?.(cappedSuggestions[selected]);
      onClose();
    },
  });

  const outsideRef = useRef(null);
  useOutsideClick({
    ref: outsideRef,
    handler: () => onClose(),
  });

  const placeholderColor = useColorModeValue("gray.500", "gray.500");

  const {
    w,
    width,
    maxW,
    maxWidth,
    flex,
    flexGrow,
    flexShrink,
    ...buttonProps
  } = rest;
  const sizingProps = {
    w,
    width,
    maxW,
    maxWidth,
    flex,
    flexGrow,
    flexShrink,
  };

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      placement="bottom-start"
      returnFocusOnClose={true}
      trigger="click"
      onClose={() => onBlur?.()}
      onOpen={() => setSearchValue("")}
    >
      <Box ref={outsideRef} {...sizingProps} position="relative">
        <PopoverTrigger>
          <ButtonGroup w="full" isAttached>
            <Button
              {...formControlProps}
              ref={ref}
              bg="appBackground"
              color={!value ? placeholderColor : undefined}
              flex="1 1 auto"
              isDisabled={formControlProps.disabled}
              overflow="hidden"
              rightIcon={
                <Box fontSize="xl" mr={-2}>
                  <RiArrowDownSLine />
                </Box>
              }
              size={size}
              textOverflow="ellipsis"
              variant="input"
              onClick={() => onToggle()}
              onKeyDown={(e) => {
                if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                  e.preventDefault();
                  onOpen();
                }
              }}
              {...buttonProps}
            >
              <Box maxWidth="100%" overflow="hidden" width="100%">
                {value ? (
                  itemRenderfn(value)
                ) : (
                  <Text fontSize={size}>{placeholder}</Text>
                )}
              </Box>
            </Button>
            {!!value && showClearButton && (
              <IconButton
                aria-label="Clear selection"
                bg="appBackground"
                icon={<RiCloseLine />}
                isDisabled={formControlProps.disabled}
                marginInlineStart={"-1px"}
                size={size}
                variant="outline"
                onClick={() => {
                  onChange?.(null);
                  onClear?.();
                }}
              />
            )}
          </ButtonGroup>
        </PopoverTrigger>

        <PortalWrapper usePortal={usePortal}>
          <PopoverContent
            // workaround for to avoid horizontal scrollbar when positioned to the right of the screen
            rootProps={
              !usePortal
                ? { style: { transform: "scale(0)", width: "100%" } }
                : undefined
            }
            width="100%"
          >
            <PopoverBody p={0}>
              {description && (
                <>
                  <MenuDescription>{description}</MenuDescription>
                  <Divider borderColor="inherit" my={0} />
                </>
              )}
              <InputGroup size="sm" variant="flushed">
                <InputLeftElement mx={1} pointerEvents="none">
                  <Box color="gray.500">
                    <RiSearch2Line />
                  </Box>
                </InputLeftElement>
                <InputRightElement mx={1} pointerEvents="none">
                  <AnimatedSpinner
                    color="dimmed"
                    show={isLoadingSuggestions}
                    size="xs"
                  />
                </InputRightElement>
                <Input
                  ref={initialFocusRef}
                  borderTopRadius={!description ? "md" : undefined}
                  pl={10}
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => {
                    const searchValue = e.target.value;
                    setSearchValue(searchValue);
                    onSearchInput?.(searchValue);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      onClose();
                    }
                    onKeyDown(e);
                  }}
                />
              </InputGroup>
              <List maxH="sm" overflowY="auto">
                {cappedSuggestions.length ? (
                  cappedSuggestions.map((item, index) => {
                    const badge = emphasized.find(
                      ({ key }) => key === item[itemKeyProperty],
                    )?.badge;
                    return (
                      <ListItem
                        key={item[itemKeyProperty]}
                        _hover={{ bg: bgHoverColor }}
                        alignItems="center"
                        bg={selected === index ? bgHoverColor : undefined}
                        cursor="pointer"
                        display="flex"
                        fontSize="sm"
                        px={3}
                        py={1}
                        onClick={() => {
                          setSearchValue("");
                          onChange?.(item);
                          if (closeOnSelect) onClose();
                        }}
                      >
                        {itemRenderfn(item, searchValue)}
                        {badge && <Badge ml="auto" {...badge} />}
                      </ListItem>
                    );
                  })
                ) : (
                  <Text color="gray.500" fontSize="sm" px={3} py={1}>
                    {isLoadingSuggestions ? "Loading..." : "Nothing found"}
                  </Text>
                )}
              </List>
            </PopoverBody>
          </PopoverContent>
        </PortalWrapper>
      </Box>
    </Popover>
  );
}

const AutocompleteSelect = forwardRef(AutocompleteSelectInner) as (<
  TSuggestion extends Record<string, any>,
>(
  props: AutocompleteSelectProps<TSuggestion> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof AutocompleteSelectInner>) & { displayName: string };

AutocompleteSelect.displayName = "AutocompleteSelect";

export default AutocompleteSelect;
