import { useMemo } from "react";
import { Text } from "@chakra-ui/react";

/**
 * Component for displaying text that has been entered by a customer.
 * This is a hyper simplified alternative for a full markdown parser/renderer.
 *
 * Features:
 * - New paragraph on 2+ newlines
 * - Line break on single newline
 */
export function CustomerTextInputDisplay({
  children,
}: {
  children: string;
}): React.ReactNode {
  const result = useMemo(() => {
    const paragraphs = children.split(/\n{2,}/g);

    return paragraphs.map((p, idx) => {
      return (
        <Text
          key={idx}
          marginTop={idx > 0 ? "1em" : undefined}
          whiteSpace="pre-line"
        >
          {p}
        </Text>
      );
    });
  }, [children]);

  return result;
}
