import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardProps,
  Center,
  Flex,
  Grid,
  Heading,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
  useToken,
  VisuallyHidden,
} from "@chakra-ui/react";

import { ErrorResponse } from "@bucketco/shared/api";
import { Flag, FlagVersion } from "@bucketco/shared/flagAPI";
import { Paginated } from "@bucketco/shared/types/Paginated";

import RollbackIcon from "@/common/assets/rollback-icon.svg?react";
import { ConfirmationDialog } from "@/common/components/ConfirmationDialog";
import { CustomerTextInputDisplay } from "@/common/components/CustomerTextInputDisplay";
import EmptyState from "@/common/components/EmptyState";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { UserDisplay } from "@/common/components/UserDisplay";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { fullFormattedDateTime } from "@/common/utils/datetime";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { FlagsEmptyStateIllustration } from "@/flags/components/FlagsEmptyStateIllustration";
import { useFlagData } from "@/flags/data/useFlagData";
import { useFlagVersionsCreateMutation } from "@/flags/data/useFlagVersionCreateMutation";
import { useFlagVersionsData } from "@/flags/data/useFlagVersionsData";
import { useStagesData } from "@/stage/data/useStagesData";

import { FlagRulesDisplay } from "./FlagRulesDisplay";

const darkModeCardBorderToken = "appBorder";

export default function FlagVersions({
  environmentId,
  featureId,
  flagId,
}: {
  environmentId: string;
  featureId: string;
  flagId: string;
}) {
  const {
    data: flag,
    isLoading: flagIsLoading,
    isError: flagLoadingError,
  } = useFlagData(flagId);

  const {
    data: versions,
    isLoading: versionsAreLoading,
    isError: versionsLoadingError,
  } = useFlagVersionsData(environmentId, flagId);

  const {
    data: stages,
    isLoading: stagesAreLoading,
    isError: stagesLoadingError,
  } = useStagesData();

  const errorToast = useErrorToast();

  useEffect(() => {
    segmentAnalytics.page("Feature Flag Versions");
  });

  useEffect(() => {
    if (flagLoadingError || versionsLoadingError || stagesLoadingError) {
      errorToast({
        title: "Failed to load targeting rules versions",
        description:
          "An error occurred while loading the targeting rules versions. Please try again.",
      });
    }
  }, [flagLoadingError, versionsLoadingError, errorToast, stagesLoadingError]);

  if (
    flagIsLoading ||
    versionsAreLoading ||
    stagesAreLoading ||
    !flag ||
    !versions ||
    !stages
  ) {
    return (
      <Center flexGrow={1} minH="lg">
        <Spinner color="dimmed" size="sm" />
      </Center>
    );
  }

  return (
    <Log
      environmentId={environmentId}
      featureId={featureId}
      flag={flag}
      versions={versions}
    />
  );
}

function Log({
  environmentId,
  featureId,
  flag,
  versions,
}: {
  environmentId: string;
  featureId: string;
  flag: Flag;
  versions: Paginated<FlagVersion, "version">;
}) {
  const gridGap = useToken("sizes", 12);
  const leftColumnWidth = "260px";

  const toast = useToast();
  const errorToast = useErrorToast();
  const [rollbackVersion, setRollbackVersion] = useState<
    FlagVersion | undefined
  >();

  return (
    <>
      <VisuallyHidden>
        <h2>Log</h2>
      </VisuallyHidden>
      {versions.data.length === 0 ? (
        <Center flexGrow={1} my={8}>
          <EmptyState description="No flag versions to show yet" flexGrow={1}>
            <FlagsEmptyStateIllustration />
          </EmptyState>
        </Center>
      ) : (
        <Grid
          gap={gridGap}
          gridTemplateColumns={`${leftColumnWidth} auto`}
          mb={10}
        >
          {versions.data.map((version) => {
            return (
              <Box
                key={version.id}
                _dark={{
                  ":has(+ *):after": {
                    bgColor: darkModeCardBorderToken,
                  },
                }}
                as="article"
                display="grid"
                gridColumn="1 / 3"
                gridTemplateColumns="subgrid"
                gridTemplateRows="subgrid"
                sx={{
                  position: "relative",

                  // Draw a line between the cards
                  ":has(+ *):after": {
                    content: "''",
                    position: "absolute",
                    width: "1px",
                    height: gridGap,
                    bottom: 0,
                    left: `calc(2 * ${gridGap} + ${leftColumnWidth})`,
                    transform: "translateY(100%)",

                    bgColor: "gray.100",
                  },
                }}
              >
                <Flex as="header" direction="column" gap="4">
                  <Flex direction="column" gap="1">
                    <Text color="dimmed">
                      {fullFormattedDateTime(version.createdAt)}
                    </Text>

                    <Flex alignItems="center" gap="3">
                      <Heading as="h3" size="md">
                        Version #{version.version}{" "}
                      </Heading>
                      {version.currentVersion ? (
                        <Badge colorScheme="brand" size="md" variant="solid">
                          Current
                        </Badge>
                      ) : (
                        <Button
                          isDisabled={!version.isValid}
                          leftIcon={<RollbackIcon />}
                          size="xs"
                          variant="outline"
                          onClick={() => setRollbackVersion(version)}
                        >
                          Roll back
                        </Button>
                      )}
                    </Flex>
                    {!version.isValid && (
                      <Text color={"red.500"} fontSize={"xs"}>
                        Flag rules are no longer valid
                      </Text>
                    )}
                  </Flex>

                  <Flex direction="column" gap="1">
                    <UserDisplay user={version.createdBy} />
                    {version.changeDescription ? (
                      <Box pl={7}>
                        <CustomerTextInputDisplay>
                          {version.changeDescription ?? "No note"}
                        </CustomerTextInputDisplay>
                      </Box>
                    ) : (
                      <Text color="dimmed" pl={7}>
                        No note
                      </Text>
                    )}
                  </Flex>
                </Flex>

                <LogCard isCurrent={version.currentVersion} maxW="wideForm">
                  <CardBody>
                    <FlagRulesDisplay
                      envId={environmentId}
                      rules={version.rules}
                      showEstimatedTargetAudience={version.isValid}
                    />
                  </CardBody>
                </LogCard>
              </Box>
            );
          })}
        </Grid>
      )}

      {rollbackVersion && (
        <FlagRollbackConfirm
          featureId={featureId}
          flag={flag}
          rollbackVersion={rollbackVersion}
          onCancel={() => setRollbackVersion(undefined)}
          onError={(error) => {
            errorToast({
              title: "Targeting rules version roll back failed",
              description:
                error?.error.code === "not_possible"
                  ? "The current version has changed, refresh the flag history and try again."
                  : error?.error?.message,
              duration: 5000,
              isClosable: true,
            });
          }}
          onSuccess={() => {
            toast({
              title: "Targeting rules version rolled back",
              status: "success",
              duration: 2000,
              isClosable: true,
            });

            segmentAnalytics.track("Feature Flag Rules Rolled Back");

            setRollbackVersion(undefined);
          }}
        />
      )}
    </>
  );
}

// TODO: We should consider theming and adding variants to Card instead
// TODO: Replace colors with input.setBorder and input.setBackground tokens
function LogCard({
  isCurrent,
  children,
  ...cardProps
}: { isCurrent: boolean } & CardProps) {
  const currentBgColor = useToken(
    "colors",
    useColorModeValue("brand.50", "brand.900"),
  );

  if (isCurrent) {
    return (
      <Card
        borderRadius="xl"
        {...cardProps}
        _dark={{
          borderColor: "brand.800",
          bgColor: `rgb(from ${currentBgColor} r g b / .4)`,
        }}
        bgColor={`rgb(from ${currentBgColor} r g b / .5)`}
        borderColor="brand.200"
        variant="outline"
      >
        {children}
      </Card>
    );
  }

  return (
    <Card
      borderRadius="xl"
      {...cardProps}
      _dark={{
        bgColor: "gray.800",
        borderColor: darkModeCardBorderToken,
      }}
      bgColor="gray.25"
      borderColor="gray.100"
      variant="outline"
    >
      {children}
    </Card>
  );
}

type FlagRollbackConfirmProps = {
  featureId: string;
  flag: Flag;
  rollbackVersion: FlagVersion;
  onCancel: () => void;
  onSuccess: (newVersion: FlagVersion) => void;
  onError: (error: ErrorResponse | undefined) => void;
};

function FlagRollbackConfirm({
  featureId,
  flag,
  rollbackVersion,
  onCancel,
  onSuccess,
  onError,
}: FlagRollbackConfirmProps) {
  const form = useForm({
    defaultValues: {
      changeDescription: `Rolled back to version #${rollbackVersion?.version}`,
    },
  });

  useEffect(() => {
    form.reset({
      changeDescription: `Rolled back to version #${rollbackVersion?.version}`,
    });
  }, [rollbackVersion, form]);

  const rollbackMutation = useFlagVersionsCreateMutation(
    featureId,
    flag.id,
    flag.currentVersions.map((v) => v.id),
  );

  return (
    <ConfirmationDialog
      _contentProps={{ maxW: "2xl" }}
      confirmLabel="Roll back"
      description={
        <FormProvider {...form}>
          <Text>
            Are you sure you want to roll back to{" "}
            <strong>version #{rollbackVersion?.version}</strong>?
          </Text>
          <Text>
            A new version will created based on the following rollout
            conditions:
          </Text>

          <FlagRulesDisplay
            envId={rollbackVersion?.environment.id}
            rules={rollbackVersion?.rules}
            w="full"
          />

          <ManagedFormControl
            label="Add a note"
            name="changeDescription"
            render={({ field }) => (
              <Textarea
                {...field}
                placeholder="Increased rollout percentage after positive feedback from beta testers and no significant technical issues."
              />
            )}
          />
        </FormProvider>
      }
      isLoading={rollbackMutation.isPending}
      isOpen={Boolean(rollbackVersion)}
      title={`Roll back to version #${rollbackVersion?.version}`}
      onCancel={onCancel}
      onClose={() => {}}
      onConfirm={() => {
        rollbackMutation.mutate(
          {
            changeDescription: form.getValues("changeDescription"),
            versions: [
              {
                environmentId: rollbackVersion?.environment.id,
                rules:
                  rollbackVersion?.rules.map(({ id: _id, ...rule }) => rule) ||
                  [],
              },
            ],
          },
          {
            onError: (error) => onError(error.response?.data),
            onSuccess: (flagVersions) => {
              onSuccess(flagVersions[0]);
            },
          },
        );
      }}
    />
  );
}
