import { ReactNode } from "react";
import { RiCloseLine, RiLayoutColumnLine } from "react-icons/ri";
import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Column, flexRender, HeaderContext } from "@tanstack/react-table";

import { MenuReorderItemOption } from "@/common/components/Menu/MenuReorderItemOption";
import { MenuReorderOptionGroup } from "@/common/components/Menu/MenuReorderOptionGroup";
import { MenuArrow } from "@/common/components/MenuArrow";
import MenuDescription from "@/common/components/MenuDescription";
import { DragType } from "@/common/types/dragDropTypes";
import { getId } from "@/common/utils/datatable";

type Props<TData> = {
  columns: Column<TData>[];
  columnVisible: string[];
  columnOrder: string[];
  customFooter?: ReactNode;
  frozenColumnId?: string;
  onColumnVisibleChange: (shown: string[]) => void;
  onColumnOrderChange: (order: string[]) => void;
  onColumnRemove?: (columnId: string) => void;
  onColumnReset?: () => void;
};

export const DataTableCustomizer = <TData,>({
  columns,
  columnVisible,
  columnOrder,
  customFooter,
  frozenColumnId,
  onColumnVisibleChange,
  onColumnOrderChange,
  onColumnRemove,
  onColumnReset,
}: Props<TData>) => {
  return (
    <Menu closeOnSelect={false} placement="bottom-end">
      <MenuButton
        aria-label="Customize columns"
        as={Button}
        leftIcon={<RiLayoutColumnLine size={16} />}
        size="sm"
        variant="outline"
      >
        Columns
      </MenuButton>
      <MenuList
        color="chakra-body-text"
        // workaround for to avoid horizontal scrollbar when positioned to the right of the screen
        rootProps={{ style: { transform: "scale(0)" } }}
      >
        <MenuArrow />
        <MenuDescription>Columns to show in the table:</MenuDescription>
        <MenuDivider my={0} />
        {frozenColumnId && (
          <>
            <MenuItem height={8} pl={30} isDisabled>
              <Checkbox
                colorScheme="brand"
                isChecked={true}
                marginEnd={2}
                pointerEvents="none"
                size="sm"
              />
              {flexRender(
                columns[0]?.columnDef?.header,
                {} as HeaderContext<TData, unknown>,
              )}
            </MenuItem>
            <MenuDivider my={0} />
          </>
        )}
        <MenuReorderOptionGroup
          as="ul"
          order={columnOrder}
          type="checkbox"
          value={columnVisible}
          onChange={onColumnVisibleChange}
          onOrderChange={(order) => {
            onColumnOrderChange(
              frozenColumnId ? [frozenColumnId, ...order] : order,
            );
          }}
        >
          {columns.map((column) =>
            frozenColumnId !== getId(column) ? (
              <MenuReorderItemOption
                key={column.id}
                as="li"
                dragType={DragType.MenuReorderOption}
                isDisabled={
                  columnVisible.length === 1 &&
                  columnVisible.includes(getId(column)!)
                }
                rightIcon={
                  column.columnDef.meta?.isRemovable ? (
                    <IconButton
                      aria-label="Remove column"
                      icon={<RiCloseLine size={16} />}
                      size="2xs"
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        onColumnRemove?.(column.id);
                      }}
                    />
                  ) : undefined
                }
                value={column.id}
              >
                {flexRender(
                  column.columnDef.header,
                  {} as HeaderContext<TData, unknown>,
                )}
              </MenuReorderItemOption>
            ) : undefined,
          )}
        </MenuReorderOptionGroup>
        <Flex justify="space-between" px={1}>
          {customFooter}
          <Button
            color="dimmed"
            mx={2}
            my={2}
            size="sm"
            type="reset"
            variant="ghost"
            onClick={onColumnReset}
          >
            Reset
          </Button>
        </Flex>
      </MenuList>
    </Menu>
  );
};
