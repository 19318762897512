import { z } from "zod";

import { nameSchema } from "./schemas/nameSchema";
import { ColumnState } from "./types/columns";
import { APIEmptyResponse, APIResponse } from "./api";
import {
  FeatureListColumn,
  FeatureListColumnSchema,
  FeatureListSortBy,
  FeatureListSortBySchema,
} from "./featureAPI";
import { SlackChannel, SlackChannelSchema } from "./slackConnectionAPI";

export interface FeatureViewDTO {
  id: string;
  name: string;
  slackChannel: SlackChannel | null;
  slackWeeklyReport: boolean;
  createdAt: string;
  isAllFeatureView: boolean;
  columns: ColumnState<FeatureListColumn>[];
  columnSortKey?: FeatureListSortBy;
  columnSortDesc?: boolean;
}

export const CreateFeatureViewArgsSchema = z
  .object({
    name: nameSchema,
    slackChannel: SlackChannelSchema.nullish(),
    slackWeeklyReport: z.boolean().optional(),
    featureIds: z.array(z.string()).optional(),
    columns: z
      .array(
        z.object({
          id: FeatureListColumnSchema,
          shown: z.boolean(),
        }),
      )
      .optional(),
    columnSortKey: FeatureListSortBySchema.optional(),
    columnSortDesc: z.boolean().optional(),
  })
  .strict();

export const PatchFeatureViewArgsSchema = CreateFeatureViewArgsSchema.partial();

export type PatchFeatureViewArgsType = z.infer<
  typeof PatchFeatureViewArgsSchema
>;

export const PatchAllFeatureViewArgsSchema = CreateFeatureViewArgsSchema.pick({
  columns: true,
  columnSortKey: true,
  columnSortDesc: true,
  slackChannel: true,
  slackWeeklyReport: true,
});

export type CreateFeatureViewArgsType = z.infer<
  typeof CreateFeatureViewArgsSchema
>;

export interface FeatureViewAPI {
  "/apps/:appId/feature-views": {
    GET: {
      response: APIResponse<FeatureViewDTO[]>;
      params: { appId: string };
    };
    POST: {
      body: CreateFeatureViewArgsType;
      response: APIResponse<FeatureViewDTO>;
      params: { appId: string };
    };
  };
  "/apps/:appId/feature-views/:viewId": {
    GET: {
      response: APIResponse<FeatureViewDTO>;
      params: { appId: string; viewId: string };
    };
    PATCH: {
      body: PatchFeatureViewArgsType;
      response: APIResponse<FeatureViewDTO>;
      params: { appId: string; viewId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; viewId: string };
    };
  };
}
