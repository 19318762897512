import {
  AccessorFn,
  ColumnDef,
  createColumnHelper,
  DeepKeys,
  DeepValue,
  DisplayColumnDef,
  GroupColumnDef,
  IdentifiedColumnDef,
  RowData,
  StringOrTemplateHeader,
} from "@tanstack/react-table";

type ColumnArg<TData, TSortableAccessors, TAccessor, TValue> =
  TAccessor extends AccessorFn<TData>
    ? DisplayColumnDef<TData, TValue>
    : TAccessor extends TSortableAccessors
    ? IdentifiedColumnDef<TData, TValue>
    : Omit<IdentifiedColumnDef<TData, TValue>, "enableSorting"> & {
        enableSorting: false;
      };

export type ExtendedColumnHelper<
  TData extends RowData,
  TSortableAccessors,
  THeader = void,
> = {
  accessor: <
    TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
      ? TReturn
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor>
      : never,
  >(
    accessor: TAccessor,
    column: Omit<
      ColumnArg<TData, TSortableAccessors, TAccessor, TValue>,
      "header"
    > & {
      header: THeader extends void
        ? StringOrTemplateHeader<TData, TValue>
        : THeader;
    },
  ) => ColumnDef<TData, TValue>;
  display: (column: DisplayColumnDef<TData>) => ColumnDef<TData, unknown>;
  group: (column: GroupColumnDef<TData>) => ColumnDef<TData, unknown>;
};

export function createSortableColumnHelper<
  TData extends RowData,
  SortableAccessors extends string,
  THeader = void,
>() {
  return createColumnHelper<TData>() as ExtendedColumnHelper<
    TData,
    SortableAccessors,
    THeader
  >;
}
