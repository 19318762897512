import { useColorModeValue, useToken } from "@chakra-ui/react";
import { transparentize } from "color2k";

import { FeatureGoal } from "@bucketco/shared/featureAPI";

export type GoalStatus = "evaluating" | "achieved";

type ColorSet = {
  fg: string;
  bg: string;
};

export const getGoalStatus = (goal: FeatureGoal) => {
  if (goal.currentValue >= goal.threshold) return "achieved";
  return "evaluating";
};

export function useGoalStatusColors(): Record<GoalStatus, ColorSet>;
export function useGoalStatusColors(status?: GoalStatus): ColorSet;
export function useGoalStatusColors(status?: GoalStatus) {
  const colors = {
    evaluating: useColorModeValue("yellow.600", "orange.300"),
    achieved: useColorModeValue("green.600", "green.400"),
  };

  const colorSets = {
    evaluating: {
      fg: colors.evaluating,
      bg: transparentize(useToken("colors", colors.evaluating), 0.9),
    },
    achieved: {
      fg: colors.achieved,
      bg: transparentize(useToken("colors", colors.achieved), 0.9),
    },
  };

  if (status !== undefined) return colorSets[status];
  return colorSets;
}
