import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { CompaniesQuery } from "@bucketco/shared/companyAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";

export function useCompaniesData(
  params?: Omit<CompaniesQuery, "envId">,
  enabled = true,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: companyQueryKeys.list(appId, envId, params),
    queryFn: () =>
      api
        .get<`/apps/:appId/companies`>(`/apps/${appId}/companies`, {
          params: { ...params, envId: envId! },
        })
        .then((res) => res.data),
    enabled: !!appId && !!envId && enabled,
    placeholderData: keepPreviousData,
  });
}
