import { FC, ReactNode } from "react";
import { Box, Divider } from "@chakra-ui/react";

import AppSidebar from "@/app/components/AppSidebar";
import { useAuthContext } from "@/auth/contexts/authContext";
import ErrorBoundary from "@/common/components/ErrorBoundary";
import { useEnvironmentColors } from "@/environment/hooks/useEnvironmentColors";

const APP_MIN_WIDTH = 1240;
const SIDEBAR_WIDTH = 190;
const SIDEBAR_SELECTOR = "bucket-primary-nav";
const MAIN_GUTTER = 6;

const AppLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { currentEnv } = useAuthContext();
  const colors = useEnvironmentColors();

  return (
    <>
      <Box
        h="100vh"
        id={SIDEBAR_SELECTOR}
        left={0}
        position={"fixed"}
        sx={{ "@media print": { display: "none" } }}
        top={0}
        width={SIDEBAR_WIDTH}
      >
        <AppSidebar h="100%" minH="md" />
      </Box>
      <Box
        as="main"
        bg="appBackground"
        borderBottomWidth={0}
        borderColor="appBorder"
        borderRadius="md"
        borderStyle="solid"
        borderWidth={1}
        boxShadow="sm"
        display="flex"
        flexDirection="column"
        m={`${MAIN_GUTTER}px`}
        minHeight={`calc(100vh - ${2 * MAIN_GUTTER}px)`}
        minW={`${APP_MIN_WIDTH - SIDEBAR_WIDTH - 2 * MAIN_GUTTER}px`} // force into desktop size until we have better mobile support
        ml={SIDEBAR_WIDTH}
        pos="relative"
        sx={{
          "@media print": { marginLeft: 0 },
        }}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </Box>
      {!currentEnv?.isProduction && (
        <Divider
          borderBottomWidth={2}
          borderColor={colors.nonProduction.icon}
          position="fixed"
          top={0}
          zIndex={2}
        />
      )}
    </>
  );
};

export default AppLayout;
