import { Outlet } from "react-router-dom";

import GoToHome from "@/auth/components/GoToHome";
import { useAuthContext } from "@/auth/contexts/authContext";

export default function RequireAuth() {
  const { user } = useAuthContext();

  if (!user?.isAdmin) {
    return <GoToHome />;
  }

  return <Outlet />;
}
