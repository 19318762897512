import { Box, BoxProps } from "@chakra-ui/react";

import useChartTokens from "@/common/charts/hooks/useChartTokens";

type Props = BoxProps & {
  value: number;
  color?: string;
  bgColor?: string;
  size?: number | string;
};

export const SimplePieChart = ({
  value,
  color,
  bgColor,
  size = 3,
  ...rest
}: Props) => {
  const { colors } = useChartTokens();

  return (
    <Box
      backgroundImage={`conic-gradient(${color ?? colors.neutral} ${value}%, ${
        bgColor ?? colors.neutralVeryLight
      } 0)`}
      borderRadius="full"
      boxSize={size}
      flexShrink={0}
      {...rest}
    />
  );
};
