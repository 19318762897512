import { Flex, FlexProps, useColorModeValue } from "@chakra-ui/react";

export default function CardContainer({
  children,
  lightModeBgColor = "gray.25",
  darkModeBorderColor = "gray.800",
  ...props
}: {
  lightModeBgColor?: FlexProps["color"];
  darkModeBorderColor?: FlexProps["color"];
  children: React.ReactNode;
} & FlexProps) {
  const bg = useColorModeValue(lightModeBgColor, "gray.900");
  const borderWidth = useColorModeValue("0px", "1px");
  const borderColor = useColorModeValue("transparent", darkModeBorderColor);

  return (
    <Flex
      alignItems="stretch"
      bg={bg}
      borderColor={borderColor}
      borderRadius="lg"
      borderWidth={borderWidth}
      direction="column"
      gap={2}
      p={1}
      pt={2}
      w="full"
      {...props}
    >
      {children}
    </Flex>
  );
}
