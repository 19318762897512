import { RiChat1Line } from "react-icons/ri";
import { Link } from "@chakra-ui/react";

import EmptyState from "@/common/components/EmptyState";

type Props = {
  isLoading?: boolean;
  isFiltered?: boolean;
};

export const FeedbackEmptyState = ({ isLoading, isFiltered }: Props) => {
  return (
    <EmptyState
      description={
        isFiltered ? (
          "Try adjusting the filters"
        ) : (
          <>
            You can start collecting feedback automatically with{" "}
            <Link href="https://docs.bucket.co/product-handbook/automated-feedback-surveys">
              automated feedback surveys
            </Link>{" "}
            through our{" "}
            <Link
              href="https://docs.bucket.co/quickstart/supported-languages-frameworks"
              target="_blank"
            >
              SDKs
            </Link>{" "}
            or{" "}
            <Link href="https://docs.bucket.co/api/http-api" target="_blank">
              HTTP API
            </Link>
          </>
        )
      }
      flexGrow={1}
      icon={<RiChat1Line size="48px" />}
      isLoading={isLoading}
      title={isFiltered ? "No feedback found" : "No feedback yet"}
    />
  );
};
