/* -------------------------------------------------------------------------------------------------
 * useMenuReorderOptionGroup: Manages the order and state of multiple selectable menu options
 * -----------------------------------------------------------------------------------------------*/

import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useControllableState, usePrevious } from "@chakra-ui/react";

export type UseMenuReorderOptionGroupProps = {
  children?: React.ReactNode;
  order?: string[];
  uncheckedCanReorder?: boolean;
  onOrderChange?: (order: string[]) => void;
} & (
  | {
      type?: "radio";
      value?: string;
      defaultValue?: string;
      onChange?: (value: string) => void;
    }
  | {
      type?: "checkbox";
      value?: string[];
      defaultValue?: string[];
      onChange?: (value: string[]) => void;
    }
);

export function useMenuReorderOptionGroup({
  children,
  type = "radio",
  value: valueProp,
  order: orderProp,
  defaultValue,
  uncheckedCanReorder = true,
  onChange: onChangeProp,
  onOrderChange: onOrderChangeProp,
  ...htmlProps
}: UseMenuReorderOptionGroupProps = {}) {
  const prevOrderProp = usePrevious(orderProp);
  const isRadio = type === "radio";

  const fallback = isRadio ? "" : [];

  const validChildren = useMemo(
    () =>
      Children.toArray(children).filter((child) =>
        isValidElement(child),
      ) as ReactElement[],
    [children],
  );

  const [value, setValue] = useControllableState({
    defaultValue: defaultValue ?? fallback,
    value: valueProp,
    onChange: onChangeProp as any,
  });

  const [order, setOrder] = useControllableState({
    defaultValue: validChildren.flatMap((child) => {
      if ((child.type as any).id !== "MenuReorderItemOption") return [];
      return child.props.value;
    }),
    value: orderProp,
    onChange: onOrderChangeProp,
  });

  // internal state for reordering to updating data during drag
  const [internalOrder, setInternalOrder] = useState(order);

  // we don't want to include values that are not present in the children
  const filteredOrder = useMemo(() => {
    let baseOrder = internalOrder;
    // if we are using controlled `order` and it changes, we want to use that
    if (orderProp && prevOrderProp !== orderProp) {
      baseOrder = orderProp;
    }
    return baseOrder.flatMap((value) => {
      const child = validChildren.find(
        (child) =>
          (child.type as any).id === "MenuReorderItemOption" &&
          child.props.value === value,
      );
      if (!child) return [];
      return [value];
    });
  }, [internalOrder, orderProp, prevOrderProp, validChildren]);

  const onChange = useCallback(
    (selectedValue: string) => {
      if (type === "radio" && typeof value === "string") {
        setValue(selectedValue);
      }
      if (type === "checkbox" && Array.isArray(value)) {
        const nextValue = value.includes(selectedValue)
          ? value.filter((item) => item !== selectedValue)
          : value.concat(selectedValue);
        setValue(nextValue);
      }
    },
    [value, setValue, type],
  );

  const onOptionMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newOrder = filteredOrder.slice();
      const item = newOrder.splice(dragIndex, 1);
      newOrder.splice(hoverIndex, 0, item[0]);
      setInternalOrder(newOrder);
    },
    [filteredOrder, setInternalOrder],
  );

  const onOptionDrop = useCallback(
    (_dragIndex: number, _hoverIndex: number) => {
      setOrder(filteredOrder);
    },
    [filteredOrder, setOrder],
  );

  const clones = validChildren
    .sort((a, b) => {
      if ((a.type as any).id !== "MenuReorderItemOption") return 0;
      if ((b.type as any).id !== "MenuReorderItemOption") return 0;
      return (
        filteredOrder.indexOf(a.props.value) -
        filteredOrder.indexOf(b.props.value)
      );
    })
    .map((child, index) => {
      /**
       * We've added an internal `id` to each `MenuItemOption`,
       * let's use that for type-checking.
       *
       * We can't rely on displayName or the element's type since
       * they can be changed by the user.
       */
      if ((child.type as any).id !== "MenuReorderItemOption") return child;

      const onClick = (event: MouseEvent) => {
        onChange(child.props.value);
        child.props.onClick?.(event);
      };

      const isChecked =
        type === "radio"
          ? child.props.value === value
          : value.includes(child.props.value);

      return cloneElement(child, {
        type,
        index,
        isChecked,
        uncheckedCanReorder,
        onClick,
        onOptionMove,
        onOptionDrop,
      });
    });

  return {
    ...htmlProps,
    children: clones,
  };
}
