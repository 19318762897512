import { Text, TextProps } from "@chakra-ui/react";

import { formatNumber } from "../utils/numberFormatting";

import CompactNumber from "./CompactNumber";

type Props = {
  value: number;
  compact?: boolean;
} & TextProps;

export default function NumberCell({ value, compact, ...props }: Props) {
  return (
    <Text
      fontSize="sm"
      style={{ fontVariantNumeric: "tabular-nums" }}
      textAlign={!compact ? "right" : undefined}
      {...props}
    >
      {compact ? <CompactNumber value={value} /> : formatNumber(value)}
    </Text>
  );
}
