import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  StageDTO,
  StagePatchArgs,
  StagePostArgs,
} from "@bucketco/shared/stageAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { stageQueryKeys } from "./stageQueryKeys";

type StagePatchArgsWithId = StagePatchArgs & {
  id: string;
};

export function useStageUpdateMutation(
  options: Omit<
    UseMutationOptions<
      StageDTO,
      AxiosError<ErrorResponse>,
      StagePatchArgsWithId
    >,
    "mutationKey" | "mutationFn"
  > = {},
) {
  const { appId } = useCurrentEnv();
  const queryClient = useQueryClient();
  const { onSuccess, ...otherOptions } = options;

  return useMutation({
    mutationKey: ["stage", "update"],
    mutationFn: async (data) => {
      const { id, ...updatedData } = data;
      return api
        .patch<"/apps/:appId/stages/:stageId">(
          `/apps/${appId}/stages/${id}`,
          updatedData,
        )
        .then((res) => res.data.stage);
    },
    onSuccess: (updatedStage, data, context) => {
      queryClient.invalidateQueries({
        queryKey: stageQueryKeys.list(appId),
      });
      onSuccess?.(updatedStage, data, context);
    },
    ...otherOptions,
  });
}

export function useStageCreateMutation(
  options: Omit<
    UseMutationOptions<StageDTO, AxiosError<ErrorResponse>, StagePostArgs>,
    "mutationKey" | "mutationFn"
  > = {},
) {
  const { appId } = useCurrentEnv();
  const queryClient = useQueryClient();
  const { onSuccess, ...otherOptions } = options;

  return useMutation({
    mutationKey: ["stage", "create"],
    mutationFn: async (data) => {
      return api
        .post<"/apps/:appId/stages">(`/apps/${appId}/stages`, data)
        .then((res) => res.data.stage);
    },
    onSuccess: (createdStage, data, context) => {
      queryClient.invalidateQueries({
        queryKey: stageQueryKeys.list(appId),
      });
      onSuccess?.(createdStage, data, context);
    },
    ...otherOptions,
  });
}
