import {
  dark,
  experimental__simple,
  experimental_createTheme,
} from "@clerk/themes";

export const bucketDark = experimental_createTheme({
  baseTheme: dark,
});

export const bucketLight = experimental_createTheme({
  baseTheme: experimental__simple,
});
