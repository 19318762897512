import { z } from "zod";

import { APIEmptyResponse, APIResponse } from "./api";
import {
  EnvironmentSelectionQuerySchema,
  EnvironmentSelectionQueryType,
} from "./environmentAPI";
import { PatchS3ConnectionSchema, S3Connection } from "./s3ConnectionAPI";

export const CsvExportIntervalSchema = z.enum(["current", "30d", "3m", "6m"]);
export type CsvExportIntervalType = z.infer<typeof CsvExportIntervalSchema>;

export const CompaniesFeaturesExportQuerySchema = z.object({
  interval: CsvExportIntervalSchema.optional().default("current"),
});

export const DataExportCadenceSchema = z.enum(["daily", "weekly"]);
export type DataExportCadenceType = z.infer<typeof DataExportCadenceSchema>;

export const DataExportTypeSchema = z.enum(["companyFeatures"]);
export type DataExportTypeType = z.infer<typeof DataExportTypeSchema>;

export type DataExportConfiguration = {
  createdAt: string;
  updatedAt: string;

  s3Connection: S3Connection;
  cadence: DataExportCadenceType;
};

export type DataExportHistoryItem = {
  createdAt: string;
  periodStart: string;
  success: boolean;
  message: string;
  duration: number;
};

export const PatchDataExportConfigurationSchema = z
  .object({
    cadence: DataExportCadenceSchema,
    s3ConnectionId: z.string().optional(),
    s3Connection: PatchS3ConnectionSchema.optional(),
  })
  .strict()
  .refine((a) => {
    return !!a.s3ConnectionId || !!a.s3Connection;
  }, "Either s3ConnectionId or s3Connection must be provided");

export type PatchDataExportConfigurationType = z.infer<
  typeof PatchDataExportConfigurationSchema
>;

export const DataExportConfigurationParamsSchema = z
  .object({
    appId: z.string(),
    type: DataExportTypeSchema,
  })
  .strict();

export type DataExportConfigurationParamsType = z.infer<
  typeof DataExportConfigurationParamsSchema
>;

export const DataExportHistoryQuerySchema =
  EnvironmentSelectionQuerySchema.extend({
    count: z.preprocess(
      (a) => parseInt(z.string().default("10").parse(a), 10),
      z.number().min(1).max(100),
    ),
  }).strict();

export type DataExportHistoryQueryType = z.input<
  typeof DataExportHistoryQuerySchema
>;

export interface ExportAPI {
  "/apps/:appId/export/:type": {
    GET: {
      response: APIResponse<DataExportConfiguration | null>;
      params: DataExportConfigurationParamsType;
      query: EnvironmentSelectionQueryType;
    };
    PATCH: {
      body: PatchDataExportConfigurationType;
      response: APIResponse<DataExportConfiguration>;
      params: DataExportConfigurationParamsType;
      query: EnvironmentSelectionQueryType;
    };
    DELETE: {
      response: APIEmptyResponse;
      params: DataExportConfigurationParamsType;
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/export/:type/history": {
    GET: {
      response: APIResponse<DataExportHistoryItem[]>;
      params: DataExportConfigurationParamsType;
      query: DataExportHistoryQueryType;
    };
  };
}
