import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { ClerkProvider } from "@clerk/clerk-react";

import AdminIndex from "@/admin";
import RequireAdmin from "@/admin/components/RequireAdmin";
import AdminOrg from "@/admin/pages/AdminOrg";
import AppLayout from "@/app/components/AppLayout";
import Debugger from "@/app/pages/Debugger";
import DebuggerDistinct from "@/app/pages/DebuggerDistinct";
import DebuggerRecent from "@/app/pages/DebuggerRecent";
import GetStarted from "@/app/pages/GetStarted";
import Invite from "@/app/pages/Invite";
import NewApp from "@/app/pages/NewApp";
import GoToDefaultEnv from "@/auth/components/GoToDefaultEnv";
import GoToHome from "@/auth/components/GoToHome";
import OrgSuspended from "@/auth/components/OrgSuspended";
import RequireBootstrapped from "@/auth/components/RequireBoostrapped";
import RequireEnvironment from "@/auth/components/RequireEnvironment";
import { AuthProvider } from "@/auth/contexts/authContext";
import Auth from "@/auth/pages/Auth";
import { FeatureFlagsProvider } from "@/common/hooks/useFeatureFlags";
import { CLERK_PUBLISHABLE_KEY } from "@/common/utils/env";
import Companies from "@/company/pages/Companies";
import Company from "@/company/pages/Company";
import { CompanyAttributes } from "@/company/pages/CompanyAttributes";
import CompanyFeatures from "@/company/pages/CompanyFeatures";
import CompanyFeedback from "@/company/pages/CompanyFeedback";
import CompanyUsers from "@/company/pages/CompanyUsers";
import { OrphanFeatureDropTarget } from "@/feature/components/OrphanFeatureDropTarget";
import Feature from "@/feature/pages/Feature";
import FeatureAnalyze from "@/feature/pages/FeatureAnalyze";
import Features from "@/feature/pages/Features";
import FeatureSettings from "@/feature/pages/FeatureSettings";
import FeatureTargeting from "@/feature/pages/FeatureTargeting";
import FeatureTargetingVersions from "@/feature/pages/FeatureTargetingVersions";
import FeatureViewReportScreenshot from "@/feature/pages/FeatureViewReportScreenshot";
import NewFeature from "@/feature/pages/NewFeature";
import Feedback from "@/feedback/pages/Feedback";
import { EditFeedback, NewFeedback } from "@/feedback/pages/NewFeedback";
import GlobalSettings from "@/global-settings/pages/GlobalSettings";
import InvalidRoute from "../pages/InvalidRoute";
import { bucketDark, bucketLight } from "../styles/clerk";

import "@/app/styles/app.css";

function AppRoutes() {
  const clerkTheme = useColorModeValue(bucketLight, bucketDark);

  return (
    <ClerkProvider
      afterSignOutUrl="/"
      appearance={{
        baseTheme: clerkTheme,
      }}
      publishableKey={CLERK_PUBLISHABLE_KEY}
    >
      <Routes>
        <Route
          element={<FeatureViewReportScreenshot />}
          path="apps/:appId/screenshots/feature-view-report"
        />

        <Route element={<Auth mode="login" />} path="login" />
        <Route element={<Auth mode="signup" />} path="signup" />
        <Route
          element={
            <AuthProvider>
              <FeatureFlagsProvider>
                <RequireBootstrapped>
                  <Outlet />
                </RequireBootstrapped>
              </FeatureFlagsProvider>
            </AuthProvider>
          }
        >
          <Route element={<GoToHome />} path="/" />
          <Route element={<OrgSuspended />} path="org-suspended" />
          <Route element={<RequireAdmin />} path="admin">
            <Route element={<AdminIndex />} index />
            <Route element={<AdminOrg />} path=":orgId" />
          </Route>

          <Route
            element={<Invite />}
            path="/orgs/:orgName-:orgId/invite/:inviteKey"
          />
          <Route element={<GetStarted />} path="get-started" />
          <Route element={<NewApp />} path="apps/new" />

          <Route element={<GoToDefaultEnv />} path="envs/current/*" />
          <Route
            element={
              <RequireEnvironment>
                <AppLayout>
                  <Outlet />
                  <OrphanFeatureDropTarget />
                </AppLayout>
              </RequireEnvironment>
            }
            path="envs/:envName-:envId"
          >
            <Route element={<Navigate to="features" replace />} path="" />

            <Route element={<Features />} path="features">
              <Route element={<NewFeature />} path="new" />
            </Route>

            <Route
              element={<Feature />}
              path="features/:featureName-:featureId"
            >
              <Route element={<Navigate to="analyze" replace />} path="" />
              <Route element={<FeatureAnalyze />} path="analyze" />
              <Route element={<FeatureTargeting />} path="targeting">
                <Route element={<FeatureTargetingVersions />} path="versions" />
              </Route>
              <Route path="settings">
                <Route element={<Navigate to="general" replace />} path="" />
                <Route element={<FeatureSettings />} path=":section" />
              </Route>
            </Route>

            <Route path="settings">
              <Route element={<Navigate to="org-general" replace />} path="" />
              <Route element={<GlobalSettings />} path=":section" />
            </Route>

            <Route element={<Companies />} path="companies" />
            <Route element={<Company />} path="companies/:companyId">
              <Route element={<Navigate to="features" replace />} path="" />
              <Route element={<CompanyFeatures />} path="features" />
              <Route element={<CompanyFeedback />} path="feedback">
                <Route element={<NewFeedback />} path="new" />
                <Route element={<EditFeedback />} path="edit/:feedbackId" />
              </Route>
              <Route element={<CompanyUsers />} path="users" />
              <Route element={<CompanyAttributes />} path="attributes" />
            </Route>

            <Route
              element={<Navigate to="debugger" replace />}
              path="tracking"
            />
            <Route element={<Debugger />} path="debugger">
              <Route element={<Navigate to="recent" replace />} path="" />
              <Route element={<DebuggerRecent />} path="recent" />
              <Route element={<DebuggerDistinct />} path="distinct" />
            </Route>

            <Route element={<Feedback />} path="feedback">
              <Route element={<NewFeedback />} path="new" />
              <Route element={<EditFeedback />} path="edit/:feedbackId" />
            </Route>
          </Route>
        </Route>
        <Route element={<InvalidRoute />} path="*" />
      </Routes>
    </ClerkProvider>
  );
}

export default AppRoutes;
