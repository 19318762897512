import type { CoreContext, CorePlugin } from "@segment/analytics-core";

type GroupIdResolver = () => string | undefined | null;

export function mixpanelGroupPlugin(
  groupIdResolver: GroupIdResolver,
): CorePlugin {
  return {
    name: "Mixpanel group plugin",
    version: "1.0.0",
    type: "enrichment",
    isLoaded: () => true,
    async load() {},

    track: augmentWithGroupId.bind(null, groupIdResolver),
    page: augmentWithGroupId.bind(null, groupIdResolver),
  };
}

function augmentWithGroupId(
  groupIdResolver: GroupIdResolver,
  ctx: CoreContext,
): CoreContext {
  const groupId = groupIdResolver();

  if (groupId) {
    ctx.event.context = {
      groupId: groupId,
      ...ctx.event.context,
    };
  }

  return ctx;
}
