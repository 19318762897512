import { useMemo } from "react";
import { HStack, Progress, ProgressProps, TextProps } from "@chakra-ui/react";

import { SatisfactionScore } from "@bucketco/shared/schemas/satisfactionScore";

import { StarsRateLabel } from "@/feature/components/StarsRateLabel";
import { useSatisfactionColors } from "@/feedback/components/FeedbackSatisfaction";

type SatisfactionRateProgressProps = ProgressProps & {
  satisfactionRate: number;
  fontSize?: TextProps["fontSize"];
};

export function SatisfactionRateProgress({
  satisfactionRate,
  fontSize,
  ...rest
}: SatisfactionRateProgressProps) {
  const satisfactionColors = useSatisfactionColors();
  const satisfiedColor = useMemo(
    () =>
      satisfactionColors[
        Math.min(5, Math.floor(satisfactionRate * 5) + 1) as SatisfactionScore
      ],
    [satisfactionRate, satisfactionColors],
  );
  return (
    <HStack>
      <Progress
        color={satisfiedColor}
        max={1}
        min={0}
        minWidth={"60px"}
        size="sm"
        value={satisfactionRate}
        {...rest}
      />
      <StarsRateLabel
        fontSize={fontSize}
        rate={satisfactionRate}
        whiteSpace="nowrap"
      />
    </HStack>
  );
}
