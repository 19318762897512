import { ForwardedRef, forwardRef } from "react";

import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import {
  SwitchButtonGroup,
  SwitchButtonGroupProps,
} from "@/common/components/SwitchButtonGroup";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";

export type FormSwitchButtonGroupProps<T extends string = string> =
  FormElementBaseProps & SwitchButtonGroupProps<T>;

function FormSwitchButtonGroupInner<T extends string = string>(
  { _control, onChange, onBlur, ...rest }: FormSwitchButtonGroupProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const [managedProps, inputProps] = useManagedFormControlProps(rest);
  return (
    <ManagedFormControl
      {..._control}
      {...managedProps}
      render={({ field }) => (
        <SwitchButtonGroup
          {...inputProps}
          {...field}
          ref={mergeRefs(field.ref, ref)}
          onBlur={mergeFunctions(field.onBlur, onBlur)}
          onChange={mergeFunctions(field.onChange as any, onChange)}
        />
      )}
    />
  );
}

const FormSwitchButtonGroup = forwardRef(FormSwitchButtonGroupInner) as <
  T extends string = string,
>(
  props: FormSwitchButtonGroupProps<T> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof FormSwitchButtonGroupInner>;

export default FormSwitchButtonGroup;
