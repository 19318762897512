import { useSearchParam } from "@/common/hooks/useSearchParam";

export type TableDisplayMode = "tree" | "list";

export function useTableDisplayModeParam(): [
  TableDisplayMode,
  (mode: TableDisplayMode) => void,
] {
  const [mode, setMode] = useSearchParam<TableDisplayMode>("tableDisplayMode", {
    allowlist: ["tree", "list"],
    fallback: "tree",
    storage: localStorage,
  });

  return [mode, setMode];
}
