import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

export const TabsTheme = defineMultiStyleConfig({
  variants: {
    chip: (props) => ({
      tablist: {
        borderBottomWidth: "1px",
        borderColor: "appBorder",
      },
      tab: {
        position: "relative",
        fontSize: "md",
        fontWeight: "medium",
        color: mode("gray.450", "gray.550")(props),
        py: 2,
        px: 0,
        mx: 1,
        mb: "-1px",
        _active: { bg: "inherit" },
        borderBottom: "0px",
        "&[aria-selected=true]": {
          position: "relative",
          color:
            props.colorScheme === "gray"
              ? mode("gray.900", "gray.50")(props)
              : mode(
                  `${props.colorScheme}.500`,
                  `${props.colorScheme}.400`,
                )(props),
          ":after": {
            bg: mode("gray.900", "gray.50")(props),
            width: "33%",
          },
        },
        ":after": {
          content: "''",
          position: "absolute",
          bottom: "-0.5px",
          width: "0%",
          height: "2px",
          bg: "transparent",
          borderRadius: "full",
          transition: "all 0.1s ease-in-out",
        },
        // using this variant requires a Tab > div.innerTab wrapping the inner tab content
        ".innerTab": {
          py: "5px",
          px: 3,
          borderRadius: "md",
          transition: "background 0.2s",
        },
        _hover: {
          ".innerTab": {
            bg: mode(`gray.50`, `gray.750`)(props),
          },
        },
        "&:focus-visible": {
          boxShadow: "none !important",
          ".innerTab": { boxShadow: "focus" },
        },
      },
    }),
    line: (props) => ({
      tab: {
        "&[aria-selected=true]": {
          color: mode(
            `${props.colorScheme}.500`,
            `${props.colorScheme}.400`,
          )(props),
        },
      },
    }),
    sideMenu: (props) => ({
      root: {
        display: "grid",
        gridTemplateColumns: "clamp(230px, 20%, 270px) auto",
      },
      tablist: {
        borderRightWidth: "1px",
        borderRightColor: "appBorder",
        p: 3,
      },
      tab: {
        borderRadius: "lg",
        py: 2,
        px: 3,
        my: "1px",
        justifyContent: "flex-start",
        color: mode("gray.500", "gray.500")(props),
        fontWeight: "medium",
        _hover: {
          bg: mode("gray.50", "gray.750")(props),
        },
        "&[aria-selected=true]": {
          bg: mode("gray.50", "gray.750")(props),
          color: mode("gray.900", "gray.50")(props),
        },
      },
      tabpanels: {
        p: 2,
      },
    }),
    "soft-rounded": () => ({
      tablist: {
        py: 2,
        px: 4,
        borderBottomWidth: "1px",
        borderColor: "appBorder",
      },
    }),
  },
  defaultProps: {
    colorScheme: "brand",
  },
});
