import { z } from "zod";

import { PaginationQueryBaseSchema } from "./schemas/dataTableSchema";
import { BucketUser } from "./types/BucketUser";
import { Paginated } from "./types/Paginated";
import { APIResponse } from "./api";
import { EnvironmentSelectionQuerySchema } from "./environmentAPI";
import {
  COMPANY_ID_CONTEXT_FIELD,
  getFilterCount,
  MAX_ROLLOUT_THRESHOLD,
  UIFilter,
  UIFilterSchema,
} from "./filter";
import { SlackChannel } from "./slackConnectionAPI";

const partialRolloutThresholdErrorMessage =
  "Rollout threshold should be between 0% and 100%";

export const CreateFlagRuleSchema = z
  .object({
    name: z.string().nullish(),
    filter: UIFilterSchema.refine(
      (filter) => {
        return getFilterCount(filter) > 0;
      },
      { message: "At least one filter condition is required" },
    ),
    partialRolloutThreshold: z
      .number({
        required_error: partialRolloutThresholdErrorMessage,
        invalid_type_error: partialRolloutThresholdErrorMessage,
      })
      .int()
      .min(0)
      .max(MAX_ROLLOUT_THRESHOLD, {
        message: partialRolloutThresholdErrorMessage,
      })
      .default(MAX_ROLLOUT_THRESHOLD),
    partialRolloutContextAttribute: z
      .string()
      .default(COMPANY_ID_CONTEXT_FIELD),
  })
  .strict();

export type CreateFlagRuleArgs = z.input<typeof CreateFlagRuleSchema>;

export const CreateFlagVersionSchema = z
  .object({
    environmentId: z.string(),
    rules: z.array(CreateFlagRuleSchema),
  })
  .strict();

export type CreateFlagVersionArgs = z.input<typeof CreateFlagVersionSchema>;

export const CreateNewFlagVersionsSchema = z.object({
  stageId: z.string().nullish(),
  changeDescription: z.string().max(512).nullish(),
  versions: z.array(CreateFlagVersionSchema).refine(
    (envs) => {
      const environmentIds = envs.flatMap(({ environmentId }) =>
        environmentId ? [environmentId] : [],
      );
      return new Set(environmentIds).size === environmentIds.length;
    },
    { message: "Duplicate environmentIds are not allowed" },
  ),
});

export type CreateNewFlagVersionsArgs = z.input<
  typeof CreateNewFlagVersionsSchema
>;

export type FlagRule = {
  id: string;
  partialRolloutThreshold: number; // 0 - 100000
  partialRolloutContextAttribute: string;
  filter: UIFilter;
};

export type FlagVersion = {
  id: string;
  environment: {
    id: string;
    isProduction: boolean;
    name: string;
    order: number;
  };
  rules: FlagRule[];
  isValid: boolean;

  version: number;
  currentVersion: boolean;

  createdAt: string;
  createdBy: BucketUser | null;

  changeDescription: string | null;
};

export type Flag = {
  id: string;
  featureId: string;

  key: string;
  description: string | null;

  slackChannel: SlackChannel | null;
  slackNotificationsEnabled: boolean;

  stageId: string | null;
  currentVersions: FlagVersion[];

  createdAt: string;
  createdBy: BucketUser | null;

  updatedAt: string;
  updatedBy: BucketUser | null;
};

export const FlagVersionQuerySchema = PaginationQueryBaseSchema({
  pageSize: 50,
}).merge(EnvironmentSelectionQuerySchema);
export type FlagVersionQueryType = z.input<typeof FlagVersionQuerySchema>;

export const FlagEventEvalContextKeysQuerySchema = z
  .object({
    prefix: z.enum(["user", "company", "other"]),
    filter: z.string().optional(),
  })
  .merge(EnvironmentSelectionQuerySchema)
  .strict();

export type FlagEventEvalContextKeysQueryType = z.input<
  typeof FlagEventEvalContextKeysQuerySchema
>;

export const FlagEventEvalContextKeyValuesQuerySchema = z
  .object({
    key: z.string().min(1),
    filter: z.string().optional(),
  })
  .merge(EnvironmentSelectionQuerySchema)
  .strict();

export type FlagEventEvalContextKeyValuesQueryType = z.input<
  typeof FlagEventEvalContextKeyValuesQuerySchema
>;

export const CreateFlagVersionsQuerySchema = z.object({
  sendNotification: z.enum(["true", "false"]).optional(),
  currentVersionIds: z.union([z.undefined(), z.string().array()]),
});

export type CreateFlagVersionsQueryType = z.input<
  typeof CreateFlagVersionsQuerySchema
>;

export interface FlagAPI {
  "/apps/:appId/flags/:flagId": {
    GET: {
      response: APIResponse<Flag>;
      params: {
        appId: string;
        flagId: string;
      };
    };
  };
  "/apps/:appId/flags/:flagId/versions": {
    GET: {
      response: APIResponse<Paginated<FlagVersion, "version">>;
      params: {
        appId: string;
        flagId: string;
      };
      query: FlagVersionQueryType;
    };
    POST: {
      body: CreateNewFlagVersionsArgs;
      response: APIResponse<{
        flagVersions: FlagVersion[];
      }>;
      params: { appId: string; flagId: string };
      query: CreateFlagVersionsQueryType;
    };
  };
  "/apps/:appId/flags/context/keys": {
    GET: {
      response: APIResponse<string[]>;
      params: {
        envId: string;
      };
      query: FlagEventEvalContextKeysQueryType;
    };
  };
  "/apps/:appId/flags/context/values": {
    GET: {
      response: APIResponse<string[]>;
      params: {
        envId: string;
      };
      query: FlagEventEvalContextKeyValuesQueryType;
    };
  };
}
