import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

export function useFeedbackPromptingStatus() {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: ["apps", appId, "environments", envId, "feedbackPromptingStatus"],

    queryFn: async () => {
      if (!envId) {
        return {
          lastSeen: null,
          configured: false,
        };
      }

      const res = await api.get<"/apps/:appId/feedback-prompting-status">(
        `/apps/${appId}/feedback-prompting-status`,
        { params: { envId: envId! } },
      );
      return res.data;
    },

    enabled: !!appId && !!envId,
  });
}
