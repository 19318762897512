import { Text } from "@chakra-ui/react";

import PercentageNumber from "@/common/components/PercentageNumber";
import {
  GoalStatus,
  useGoalStatusColors,
} from "@/feature/hooks/useGoalStatusColors";

export function GoalStatusPercentage({
  status,
  progress,
  progressIndeterminate = false,
  size,
}: {
  status: GoalStatus;
  progress?: number;
  progressIndeterminate?: boolean;
  size: number;
}) {
  const colorSet = useGoalStatusColors(status);

  if (typeof progress !== "number") return null;
  if (progressIndeterminate) return null;

  return (
    <Text
      as="span"
      color={colorSet.fg}
      fontSize={`${size}px`}
      lineHeight={`${size}px`}
    >
      <PercentageNumber value={progress} />
    </Text>
  );
}
