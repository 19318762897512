import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { companyQueryKeys } from "./companyQueryKeys";

export default function useCompanyAttributesData(enabled: boolean = true) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: companyQueryKeys.attributes(appId, envId),

    queryFn: () =>
      api
        .get<"/apps/:appId/company-attributes">(
          `/apps/${appId}/company-attributes`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data.attributes),
    enabled: !!appId && !!envId && enabled,
  });
}
