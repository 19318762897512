import slug from "slug";

export function generateFeatureKey(
  input: string,
  existingKeys: string[],
): string {
  const keySlug = slug(input);

  if (!existingKeys.includes(keySlug)) {
    return keySlug;
  } else {
    const lastPart = keySlug.split("-").pop();

    if (!lastPart || isNaN(Number(lastPart))) {
      return `${keySlug}-1`;
    } else {
      const base = keySlug.slice(0, keySlug.length - lastPart.length);
      const newNumber = Number(lastPart) + 1;
      return `${base}${newNumber}`;
    }
  }
}
