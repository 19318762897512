import { FC } from "react";

import { locale } from "@/common/utils/locale";
import { formatLargeNumber } from "@/common/utils/numberFormatting";

const fullNumberFormatter = new Intl.NumberFormat(locale);

interface CompactNumberProps {
  value: number;

  /**
   * Display `+` sign in front of positive numbers.
   *
   * Use for displaying differences
   */
  showSign?: boolean;
}

const CompactNumber: FC<CompactNumberProps> = ({
  value,
  showSign = false,
}: CompactNumberProps) => {
  const sign = showSign && value > 0 ? "+" : "";
  const titleFormat = `${sign}${fullNumberFormatter.format(value)}`;
  const display = `${sign}${formatLargeNumber(value)}`;

  let title: string | undefined = undefined;

  // Only display a title with long format if it differs from the UI
  if (titleFormat !== display) {
    title = titleFormat;
  }

  return <span title={title}>{display}</span>;
};

export default CompactNumber;
