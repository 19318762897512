import { useMemo } from "react";
import dayjs from "dayjs";

import SimpleBarChart from "@/common/charts/components/SimpleBarChart";
import { UseFeedbackPromptStatisticsType } from "@/common/hooks/useFeedbackPromptStatistics";

export default function FeedbackPromptStatisticsChart({
  data: stats,
  isLoading,
}: UseFeedbackPromptStatisticsType) {
  const data = useMemo(() => {
    if (stats === undefined) return [];

    return stats.periods.map((period) => ({
      ...period,
      epoch: dayjs(period.periodStart).startOf("day").unix(),
    }));
  }, [stats]);

  return (
    <SimpleBarChart
      data={data}
      dataKeys={[
        {
          key: "repliedCount",
          name: "Responded",
          color: "primary",
        },
        {
          key: "dismissedCount",
          name: "Dismissed",
          color: "problem",
        },
        {
          key: "otherCount",
          name: "Ignored",
          color: "neutralLight",
        },
      ]}
      height={100}
      isLoading={isLoading}
      tooltip={{
        labelFormatter: (epoch: any) => {
          return dayjs.unix(epoch).format("MMM D");
        },
      }}
      xAxis={{
        interval: 0, // Force ticks and labels to always show
        tickFormatter: (epoch: any, idx) => {
          return idx === 0
            ? dayjs.unix(epoch).format("MMM D")
            : idx === 6
            ? "Today"
            : "";
        },
      }}
    />
  );
}
