import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { featureQueryKeys } from "./featureQueryKeys";

export function useFeatureRolloutStatusData(featureId?: string) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: featureQueryKeys.singleFeatureRolloutStatus(
      appId,
      envId,
      featureId,
    ),
    queryFn: async () => {
      return api
        .get<"/apps/:appId/features/:featureId/rollout-status">(
          `/apps/${appId}/features/${featureId}/rollout-status`,
        )
        .then((res) => res.data);
    },
    enabled: !!appId && !!envId && !!featureId,
    refetchInterval: 10000,
    refetchOnWindowFocus: "always",
  });
}
