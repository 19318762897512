import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { EnvironmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";

export default function GoToHome() {
  const { currentEnv, currentOrg, isLoading, user } = useAuthContext();
  const navigate = useNavigate();

  // Force a server request when redirecting to allow
  // for backend analytics calls
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!user?.isAdmin && currentOrg?.suspendedAt) {
      // Early exit out of page reloads if the org is suspended
      navigate("/org-suspended");
    } else if (currentEnv) {
      window.location.assign(EnvironmentUrl(currentEnv));
    } else if (currentOrg && currentOrg.apps.length === 0) {
      window.location.assign("/apps/new");
    } else {
      window.location.assign("/get-started");
    }
  }, [user, currentEnv, currentOrg, isLoading, navigate]);

  return null;
}
