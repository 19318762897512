import {
  FeatureListQuery,
  FeatureNamesQueryType,
} from "@bucketco/shared/featureAPI";

import { featureViewQueryKeys } from "@/app/data/featureViewQueryKeys";

const qk = {
  list: (
    appId?: string,
    envId?: string,
    params?: Omit<FeatureListQuery, "envId">,
  ) =>
    [
      "apps",
      appId,
      "environments",
      envId,
      "features",
      ...(params ? [params] : []),
    ] as const,

  listNames: (appId?: string, params: FeatureNamesQueryType = {}) =>
    ["apps", appId, "names", params] as const,

  single: (appId?: string, envId?: string, companyId?: string) =>
    [...qk.list(appId, envId), companyId] as const,

  singleMetrics: (appId?: string, envId?: string, featureId?: string) =>
    [...qk.single(appId, envId, featureId), "metrics"] as const,

  singleMetricsCurrent: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [...qk.singleMetrics(appId, envId, featureId), "current", params] as const,

  singleMetricsHistorical: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [
      ...qk.singleMetrics(appId, envId, featureId),
      "historical",
      params,
    ] as const,

  singleFeatureFeedbackHistogram: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [
      ...qk.single(appId, envId, featureId),
      "feedback",
      "histogram",
      params,
    ] as const,

  singleFeatureFeedbackCampaign: (
    appId?: string,
    envId?: string,
    featureId?: string,
  ) =>
    [
      ...qk.single(appId, envId, featureId),
      "featureFeedbackCampaigns",
    ] as const,

  keys: (appId?: string) => [appId, "featureKeys"] as const,

  singleFeatureRolloutStatus: (
    appId?: string,
    envId?: string,
    featureId?: string,
  ) => [...qk.single(appId, envId, featureId), "rollout"] as const,

  singleFeatureCompanies: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) => [...qk.single(appId, envId, featureId), "companies", params] as const,

  singleFeatureCompanyUsers: (
    appId?: string,
    envId?: string,
    featureId?: string,
    companyId?: string,
  ) => [
    ...qk.singleFeatureCompanies(appId, envId, featureId),
    companyId,
    "users",
  ],

  featureViews: (appId?: string, featureId?: string) =>
    [
      ...featureViewQueryKeys.singleFeatureRelationViews(appId),
      featureId,
    ] as const,
};

export { qk as featureQueryKeys };
