import { Link as RouterLink } from "react-router-dom";
import { Box, Flex, Link } from "@chakra-ui/react";

import { FeatureSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import { MarkdownDisplay } from "@/common/components/MarkdownDisplay";
import { SectionHeading } from "@/common/components/SectionHeading";
import {
  SidebarContainer,
  SidebarSection,
} from "@/common/components/SidebarLayout";
import EnvironmentActivity from "@/feature/components/EnvironmentActivity";
import { FeatureKeyDisplay } from "@/flags/components/FeatureKeyDisplay";
import { useFeatureData } from "../data/useFeatureData";

import FeatureViewsSelector from "./FeatureViewsSelector";

type Props = {
  featureId?: string;
};

export default function FeatureSidebar({ featureId }: Props) {
  const { currentEnv } = useAuthContext();
  const { data: feature } = useFeatureData(featureId);

  return (
    <SidebarContainer>
      {feature && (
        <SidebarSection>
          <Flex direction="column" gap={2}>
            <SectionHeading variant="subtle">Description</SectionHeading>
            {feature.description?.length ? (
              <Box>
                <MarkdownDisplay value={feature?.description} />
              </Box>
            ) : (
              <Flex align="center" fontSize="sm" minH={7}>
                <Link
                  _hover={{ textDecoration: "underline" }}
                  as={RouterLink}
                  color="dimmed"
                  to={FeatureSettingsUrl(currentEnv!, feature)}
                >
                  Add a description
                </Link>
              </Flex>
            )}
          </Flex>
        </SidebarSection>
      )}
      <SidebarSection mr={-2}>
        <FeatureKeyDisplay featureKey={feature?.key} />
      </SidebarSection>
      <SidebarSection>
        <EnvironmentActivity featureId={featureId} />
      </SidebarSection>
      <SidebarSection mr={-2}>
        <FeatureViewsSelector featureId={featureId} />
      </SidebarSection>
    </SidebarContainer>
  );
}
