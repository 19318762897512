import { Link as RouterLink } from "react-router-dom";
import { chakra, HStack, Link, StackProps, Text } from "@chakra-ui/react";

import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import EnvironmentSvg from "@/common/assets/environment-dot-circle-fill.svg?react";
import { useEnvironmentColors } from "@/environment/hooks/useEnvironmentColors";

const EnvironmentIcon = chakra(EnvironmentSvg);

type Props = {
  environment: Pick<EnvironmentDTO, "name" | "isProduction">;
  link?: boolean;
  iconColor?: StackProps["color"];
} & StackProps;

export const EnvironmentDisplayName = ({
  environment,
  link = false,
  color,
  iconColor,
  ...stackProps
}: Props) => {
  const { currentEnv } = useAuthContext();
  const colors = useEnvironmentColors();
  const envColor =
    colors[environment.isProduction ? "production" : "nonProduction"];

  const component = (
    <HStack spacing={1} {...stackProps}>
      <EnvironmentIcon
        boxSize={3}
        color={iconColor ? iconColor : envColor.icon}
        flexShrink={0}
      />
      <Text
        as="span"
        color={color ? color : envColor.text}
        fontWeight="medium"
        isTruncated
      >
        {environment.name}
      </Text>
    </HStack>
  );

  if (link && currentEnv) {
    return (
      <Link
        as={RouterLink}
        style={{ textDecoration: "none" }}
        to={GlobalSettingsUrl(currentEnv, "app-environments")}
      >
        {component}
      </Link>
    );
  } else {
    return component;
  }
};
