import { useCallback, useEffect, useState } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { ButtonGroup, Flex, Switch } from "@chakra-ui/react";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { FeatureMetricsHistoricalQuery } from "@bucketco/shared/featureAPI";
import { OptionalKeys } from "@bucketco/shared/utils/types";
import {
  WidgetDTO,
  WidgetFeatureMetricConfiguration,
  WidgetFeatureMetrics,
} from "@bucketco/shared/widgetAPI";

import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import FormCancel from "@/common/components/Form/FormCancel";
import { FormFrequencyPicker } from "@/common/components/Form/FormFrequencyPicker";
import FormNumberInput from "@/common/components/Form/FormNumberInput";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { fetchFeatureHistoricalData } from "@/feature/data/useFeatureHistoricalData";
import { WidgetFeatureMetricConfigurationWithThresholdType } from "@/widget/components/WidgetFeatureMetric/WidgetFeatureMetric";
import { WidgetMetricSelector } from "@/widget/components/WidgetFeatureMetric/WidgetMetricSelector";
import useWidgetMetricOptions from "@/widget/hooks/useWidgetMetricOptions";
import { getCurrentValue, getSuggestedThreshold } from "@/widget/utils/widget";
import { FormRootError } from "../../../common/components/Form/FormRootError";

export function WidgetForm({
  form,
  configuration,
  implicitConfiguration = {},
  submitLabel = "Save",
  widgetMutation,
  historicalDataParams,
  onDone,
}: {
  form: UseFormReturn<WidgetFeatureMetricConfigurationWithThresholdType>;
  configuration: WidgetFeatureMetricConfiguration;
  implicitConfiguration?: Partial<WidgetFeatureMetricConfiguration>;
  submitLabel?: string;
  widgetMutation: UseMutationResult<WidgetDTO, AxiosError<ErrorResponse>, any>;
  historicalDataParams: OptionalKeys<FeatureMetricsHistoricalQuery, "envId">;
  onDone: (configuration?: WidgetFeatureMetricConfiguration) => void;
}) {
  const { appId, envId } = useCurrentEnv();

  const metrics = useWidgetMetricOptions();
  const queryClient = useQueryClient();

  const submitHandler = useFormMutationSubmitHandler(
    form,
    widgetMutation,
    (widget) =>
      onDone(widget.configuration as WidgetFeatureMetricConfiguration),
    {
      prepareVariables: (values) => {
        // enableThreshold isn't sent to the BE, but it decides whether
        // threshold should be set or not
        const { enableThreshold, ...configuration } = values;
        configuration.threshold = enableThreshold ? values.threshold : null;
        return { configuration };
      },
    },
  );

  const isPercentage =
    WidgetFeatureMetrics[configuration.metric].type === "percentage";

  const { data: selectedFeature } = useFeatureData(configuration.featureId);

  const selectedMetric = metrics.find((m) =>
    m.measurements.some((mm) => mm.id === configuration.metric),
  );
  const selectedMeasurement = selectedMetric?.measurements.find(
    (mm) => mm.id === configuration.metric,
  );

  const [recalculatingThresholdLoading, setRecalculatingThresholdLoading] =
    useState(false);

  const recalculateThreshold = useCallback(
    async (configuration: WidgetFeatureMetricConfiguration) => {
      if (recalculatingThresholdLoading) return;
      let newCurrentValue = 0;
      setRecalculatingThresholdLoading(true);
      form.setValue("threshold", null);
      try {
        // Use queryClient to de-duplicate request with useWidgetData
        const data = await queryClient.fetchQuery({
          queryKey: featureQueryKeys.singleMetricsHistorical(
            appId,
            envId,
            configuration.featureId,
            {
              ...historicalDataParams,
              metric: configuration.metric,
            },
          ),
          queryFn: fetchFeatureHistoricalData(appId, configuration.featureId, {
            envId: envId!,
            ...historicalDataParams,
            metric: configuration.metric,
          }),
        });
        newCurrentValue = getCurrentValue(data.timeseries) ?? 0;
      } catch {
        // If fetch fails we fall back to 0.
      }
      const suggestedThreshold = getSuggestedThreshold(
        form.getValues("metric"),
        newCurrentValue,
      );
      if (form.getValues("threshold") !== suggestedThreshold) {
        form.setValue("threshold", suggestedThreshold);
      }
      setRecalculatingThresholdLoading(false);
    },
    [
      appId,
      envId,
      form,
      historicalDataParams,
      queryClient,
      recalculatingThresholdLoading,
    ],
  );

  const enabledThreshold = form.watch("enableThreshold");
  const currentThreshold = form.watch("threshold");

  // calculate suggested threshold on load if no threshold has been set earlier
  useEffect(() => {
    if (currentThreshold === null && !recalculatingThresholdLoading) {
      recalculateThreshold(configuration);
    }
  }, [
    configuration,
    currentThreshold,
    recalculateThreshold,
    recalculatingThresholdLoading,
  ]);

  return (
    <FormProvider {...form}>
      <Flex as="form" direction="column" gap={4} onSubmit={submitHandler}>
        {!implicitConfiguration.featureId && (
          <ManagedFormControl
            label="Feature"
            name="featureId"
            placeholder="Select a feature"
            render={({ field }) => (
              <FeatureAutocompleteSelect
                {...field}
                usePortal
                onChange={(featureName) => {
                  field.onChange(featureName?.id ?? null);
                  if (featureName) {
                    recalculateThreshold({
                      ...configuration,
                      featureId: featureName.id,
                    });
                  }
                }}
              />
            )}
          />
        )}

        {!implicitConfiguration.metric && (
          <ManagedFormControl
            label="Metric"
            name="metric"
            render={({ field }) => (
              <WidgetMetricSelector
                {...field}
                selectedFeature={selectedFeature}
                onChange={(metric) => {
                  field.onChange(metric);
                  recalculateThreshold({
                    ...configuration,
                    metric,
                  });
                }}
              />
            )}
          />
        )}

        {!implicitConfiguration.threshold && (
          <Flex direction="column" gap={0} w="full">
            <ManagedFormControl
              alignItems="center"
              isDisabled={form.formState.isSubmitting}
              label="Set goal"
              name="enableThreshold"
              render={({ field }) => (
                <Flex align="center" justify="flex-end">
                  <Switch
                    {...field}
                    colorScheme="brand"
                    isChecked={field.value}
                    size="sm"
                  />
                </Flex>
              )}
              horizontal
            />
            {selectedMeasurement?.id === "averageFrequency" ? (
              <>
                <FormFrequencyPicker
                  flexGrow={1}
                  isDisabled={
                    !enabledThreshold || recalculatingThresholdLoading
                  }
                  name="threshold"
                  placeholder={
                    recalculatingThresholdLoading
                      ? "Calculating suggestion.."
                      : undefined
                  }
                  size="sm"
                  usePortal
                />
              </>
            ) : isPercentage ? (
              <FormNumberInput
                _inputField={{
                  placeholder: recalculatingThresholdLoading
                    ? "Calculating suggestion.."
                    : undefined,
                }}
                flexGrow={1}
                isDisabled={!enabledThreshold || recalculatingThresholdLoading}
                max={
                  selectedMeasurement
                    ? selectedMeasurement.maxThreshold * 100
                    : undefined
                }
                min={1}
                name="threshold"
                scaleFactor={100}
                size="sm"
                unit={"%"}
              />
            ) : (
              <FormNumberInput
                _inputField={{
                  placeholder: recalculatingThresholdLoading
                    ? "Calculating suggestion.."
                    : undefined,
                }}
                flexGrow={1}
                isDisabled={!enabledThreshold || recalculatingThresholdLoading}
                max={
                  selectedMeasurement
                    ? selectedMeasurement.maxThreshold
                    : undefined
                }
                min={1}
                name="threshold"
                scaleFactor={1}
                size="sm"
              />
            )}
          </Flex>
        )}

        <FormRootError />

        <ButtonGroup>
          <FormSubmitLegacy allowPristine>{submitLabel}</FormSubmitLegacy>
          <FormCancel color="dimmed" onClick={() => onDone(configuration)}>
            Cancel
          </FormCancel>
        </ButtonGroup>
      </Flex>
    </FormProvider>
  );
}
