import { Flex } from "@chakra-ui/react";

import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useCompanyFeatureUsersTableState } from "@/company/hooks/useCompanyTableStates";

export const CompanyUsersFilters = () => {
  const { featureFilter, onFeatureFilterChange } =
    useCompanyFeatureUsersTableState();

  return (
    <Flex align="center" gap={4}>
      <FeatureAutocompleteSelect
        placeholder="Filter by feature"
        value={featureFilter}
        onChange={(value) => {
          onFeatureFilterChange(value?.id);
          segmentAnalytics.track("Feedbacks Filter Updated", {
            type: "featureFilter",
            value: featureFilter,
          });
        }}
      />
    </Flex>
  );
};
