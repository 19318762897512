import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";

import api from "@/common/utils/api";

export function useAdminDemoAppDeleteMutation(orgId: string, appId: string) {
  const queryClient = useQueryClient();

  return useMutation<object, AxiosResponse<ErrorResponse>>({
    mutationKey: ["admin", "org", orgId, "app", appId],
    mutationFn: () =>
      api
        .delete<"/admin/:orgId/apps/:appId">(`/admin/${orgId}/apps/${appId}`)
        .then((res) => res.data),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["orgs"],
      });
    },
  });
}
