import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { AdminCreateOrgType } from "@bucketco/shared/adminAPI";
import { ErrorResponse } from "@bucketco/shared/api";
import { Org } from "@bucketco/shared/organizationAPI";

import api from "@/common/utils/api";

export function useAdminOrgCreateMutation() {
  const queryClient = useQueryClient();

  return useMutation<Org, AxiosResponse<ErrorResponse>, AdminCreateOrgType>({
    mutationKey: ["admin", "org"],
    mutationFn: (body) =>
      api.post<"/admin">("/admin", body).then((res) => res.data),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["orgs"],
      });
    },
  });
}
