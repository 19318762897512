import { Cell } from "recharts";

import useChartTokens from "@/common/charts/hooks/useChartTokens";

export const incompleteBarPatternId = (name: string) =>
  `incompleteBarPattern-${name}`;

// generate svg stripe pattern for bars
export function IncompleteBarPattern({
  colorName,
  colorValue,
}: {
  colorName: string;
  colorValue: string;
}) {
  const { colors } = useChartTokens();

  return (
    <pattern
      height="15"
      id={incompleteBarPatternId(colorName)}
      patternTransform="rotate(60)"
      patternUnits="userSpaceOnUse"
      width="15"
    >
      <rect fill={colorValue} height="15" width="15" x="0" y="0"></rect>
      <line
        stroke={colors.incompleteBar}
        strokeOpacity="0.4"
        strokeWidth="15"
        x1="0"
        x2="0"
        y="0"
        y2="15"
      />
    </pattern>
  );
}

// needs to be direct descendant of <Bar> recharts component
// only apply incomplete pattern for last entry
export const IncompleteBarCells = ({
  data,
  color,
}: {
  data: any[];
  color: string;
}) => (
  <>
    {data.map((_, index, series) => (
      <Cell
        key={`cell-${index}`}
        {...(index === series.length - 1
          ? {
              fill: `url(#${incompleteBarPatternId(color)})`,
            }
          : {})}
      />
    ))}
  </>
);
