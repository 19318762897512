import { z } from "zod";

import { nameSchema } from "./schemas/nameSchema";
import { APIEmptyResponse, APIResponse } from "./api";

export type S3Credentials = Omit<CreateS3ConnectionType, "name">;

export type OpaqueS3Credentials = Omit<
  S3Credentials,
  "accessKeyId" | "secretAccessKey"
>;

export type S3Connection = {
  id: string;
  createdAt: string;
  updatedAt: string;

  name: string;
  credentials: OpaqueS3Credentials;
};

export const S3ProviderSchema = z.enum(["AWS", "GCP", "custom"]);
export type S3ProviderType = z.infer<typeof S3ProviderSchema>;

export const CreateS3ConnectionBaseSchema = z
  .object({
    name: nameSchema.max(128).optional(),
    accessKeyId: z.string().length(20),
    secretAccessKey: z.string().length(40),
    bucket: z
      .string()
      .min(3)
      .max(63)
      .refine((s) => s.length && s.match(/^[a-z0-9.-]+$/), {
        message:
          "Bucket can only contain numbers, lower case letters, _, - and .",
      }),
    key: z
      .string()
      .max(128)
      .refine((s) => !s?.length || s.match(/^[a-zA-Z0-9_.-/]+$/), {
        message: "Key can only contain numbers, letters, _, -, / and .",
      })
      .optional(),
    region: z.string().min(1),
    provider: S3ProviderSchema,
    endpoint: z
      .string()
      .url()
      .or(z.literal("").transform(() => undefined))
      .or(z.undefined()),
  })
  .strict();

export const CreateS3ConnectionSchema =
  CreateS3ConnectionBaseSchema.strict().refine((a) => {
    return a.provider !== "custom" || !!a.endpoint;
  }, "Endpoint must be provided if provider is custom");

export const PatchS3ConnectionSchema = CreateS3ConnectionBaseSchema.extend({
  accessKeyId: z
    .string()
    .min(1)
    .max(128)
    .optional()
    .or(z.literal("").transform(() => undefined)),

  secretAccessKey: z
    .string()
    .min(1)
    .max(128)
    .optional()
    .or(z.literal("").transform(() => undefined)),
})
  .strict()
  .refine((a) => {
    return a.provider !== "custom" || !!a.endpoint;
  }, "Endpoint must be provided if provider is custom")
  .refine(
    (a) => {
      const keysPresent = a.accessKeyId != null && a.secretAccessKey != null;
      const keysAbsent = a.accessKeyId == null && a.secretAccessKey == null;
      return keysPresent || keysAbsent;
    },
    {
      message:
        "Both accessKeyId and secretAccessKey must be supplied together or not at all.",
    },
  );

export type CreateS3ConnectionType = z.infer<typeof CreateS3ConnectionSchema>;

export type PatchS3ConnectionType = z.infer<typeof PatchS3ConnectionSchema>;

export interface S3ConnectionAPI {
  "/orgs/:orgId/s3-connections": {
    GET: {
      response: APIResponse<S3Connection[]>;
      params: { orgId: string };
    };
    POST: {
      body: CreateS3ConnectionType;
      response: APIResponse<S3Connection>;
      params: { orgId: string };
    };
  };
  "/orgs/:orgId/s3-connections/:s3ConnectionId": {
    GET: {
      response: APIResponse<S3Connection>;
      params: { orgId: string; s3ConnectionId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { orgId: string; s3ConnectionId: string };
    };
    PATCH: {
      body: PatchS3ConnectionType;
      response: APIResponse<S3Connection>;
      params: { orgId: string; s3ConnectionId: string };
    };
  };
}
