import type { SatisfactionScore } from "./schemas/satisfactionScore";
import type { FeedbackSource } from "./feedbackAPI";

export const UPLOADIO_ALLOWED_HOST = "https://upcdn.io/kW15ayo/";
export const TRIAL_PERIOD_DAYS = 28;
export const SEARCH_DEBOUNCE_MS = 250;

export const SATISFACTION_LABELS: Record<SatisfactionScore, string> = {
  0: "Not specified",
  1: "Very dissatisfied",
  2: "Dissatisfied",
  3: "Neutral",
  4: "Satisfied",
  5: "Very satisfied",
} as const;

export const SATISFACTION_LABELS_SHORT: Record<SatisfactionScore, string> = {
  0: "Not specified",
  1: "V. dissatisfied",
  2: "Dissatisfied",
  3: "Neutral",
  4: "Satisfied",
  5: "V. satisfied",
} as const;

export const FEEDBACK_SOURCE_LABELS: Record<FeedbackSource, string> = {
  api: "API",
  manual: "Manual",
  prompt: "Prompt",
  sdk: "SDK",
  widget: "Widget",
};
