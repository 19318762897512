import { useMemo } from "react";
import { Link, Text } from "@chakra-ui/react";
import { z } from "zod";

import {
  CompanyFeatureUsersList,
  CompanyFeatureUsersSortBySchema,
  FeatureCompanyUserDTO,
} from "@bucketco/shared/companyAPI";

import CompactNumber from "@/common/components/CompactNumber";
import { DataTable } from "@/common/components/DataTable/DataTable";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import TimestampCell from "@/common/components/TimestampCell";
import { useSafeDataTableStateProps } from "@/common/hooks/useDataTableParamState";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { CompanyUsersEmptyState } from "@/company/components/CompanyUsersEmptyState";
import { CompanyUsersFilters } from "@/company/components/CompanyUsersFilters";
import { useCompanyFeatureUsersTableState } from "@/company/hooks/useCompanyTableStates";

type Props = {
  data?: CompanyFeatureUsersList;
  isLoading?: boolean;
  isFetching?: boolean;
};

export function CompanyFeatureUsersTable({
  data,
  isLoading,
  isFetching,
}: Props) {
  const tableState = useCompanyFeatureUsersTableState();
  // We don't want to pass along invalid props to the data table
  const safeTableProps = useSafeDataTableStateProps(tableState);

  const columnHelper = createSortableColumnHelper<
    FeatureCompanyUserDTO,
    z.infer<typeof CompanyFeatureUsersSortBySchema>
  >();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ row }) => <Text>{row.original.name || row.original.id}</Text>,
      }),
      columnHelper.accessor("firstUsed", {
        header: "First used",
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <TimestampCell value={value} />;
          }

          return <NotAvailableCell />;
        },
      }),
      columnHelper.accessor("lastUsed", {
        header: "Last used",
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <TimestampCell value={value} />;
          }

          return <NotAvailableCell />;
        },
      }),
      columnHelper.accessor("eventCount", {
        header: "Feature interactions",
        cell: (cell) => <CompactNumber value={cell.getValue()} />,
      }),
      columnHelper.accessor("email", {
        header: "Email",
        cell: (cell) => {
          const value = cell.getValue();

          if (value) {
            return <Link href={`mailto:${value}`}>{value}</Link>;
          }

          return <NotAvailableCell />;
        },
      }),
    ];
  }, [columnHelper]);

  const users = data?.data || [];

  return (
    <DataTable
      columns={columns}
      data={users}
      emptyState={<CompanyUsersEmptyState isLoading={isLoading} />}
      isFetching={isFetching}
      meta={data?.metadata}
      pageSize={50}
      tableId="company-feature-users"
      toolbarLeftActions={<CompanyUsersFilters />}
      totalCount={data?.totalCount ?? 0}
      canCustomize
      canPaginate
      canSort
      {...safeTableProps}
    />
  );
}
