import useCompanyAttributesData from "@/company/data/useCompanyAttributesData";
import { useFlagContextAttributeNames } from "@/flags/data/useFlagContextAttributeData";
import { EntityType } from "../data/useAttributeValues";

export type AttributeSourceEntityType = Extract<
  EntityType,
  | "company"
  | "flag-context-company"
  | "flag-context-user"
  | "flag-context-other"
>;

export function useAttributeNames(type?: AttributeSourceEntityType) {
  const {
    data: companyAttributes = [],
    isLoading: companyAttributesLoading,
    isError: companyAttributesError,
  } = useCompanyAttributesData(type == "company");

  const {
    data: companyContextAttributes = [],
    isLoading: companyContextAttributesLoading,
    isError: companyContextAttributesError,
  } = useFlagContextAttributeNames(
    type === "flag-context-company" ? "company" : undefined,
  );

  const {
    data: userContextAttributes = [],
    isLoading: userContextAttributesLoading,
    isError: userContextAttributesError,
  } = useFlagContextAttributeNames(
    type === "flag-context-user" ? "user" : undefined,
  );

  const {
    data: otherContextAttributes = [],
    isLoading: otherContextAttributesLoading,
    isError: otherContextAttributesError,
  } = useFlagContextAttributeNames(
    type == "flag-context-other" ? "other" : undefined,
  );

  switch (type) {
    case "company":
      return {
        attributes: companyAttributes,
        isLoading: companyAttributesLoading,
        isError: companyAttributesError,
      };
    case "flag-context-company":
      return {
        attributes: companyContextAttributes,
        isLoading: companyContextAttributesLoading,
        isError: companyContextAttributesError,
      };
    case "flag-context-user":
      return {
        attributes: userContextAttributes,
        isLoading: userContextAttributesLoading,
        isError: userContextAttributesError,
      };
    case "flag-context-other":
      return {
        attributes: otherContextAttributes,
        isLoading: otherContextAttributesLoading,
        isError: otherContextAttributesError,
      };
    default:
      return {
        attributes: [],
        isLoading: false,
        isError: false,
      };
  }
}
