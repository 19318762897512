import { useSegments } from "@/company/data/useSegments";

export default function useActiveSegment() {
  const { data } = useSegments(undefined, {
    select(segments) {
      return segments.find(
        (segment) => segment.system && segment.name === "Active",
      );
    },
  });

  return data;
}
