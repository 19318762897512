// from https://usehooks.com/useDebounce/
import { useEffect, useState } from "react";

// T is a generic type for value parameter
export function useDebounce<T>(
  value: T,
  delay: number,
  cb?: (value: T) => void,
): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        if (cb) cb(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay, cb], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
