import { FC } from "react";
import { Text, TextProps } from "@chakra-ui/react";

import { locale } from "@/common/utils/locale";
import {
  formatPercentage,
  HighPercentageFormatter,
} from "@/common/utils/numberFormatting";

const fullPercentageFormatter = new Intl.NumberFormat(locale, {
  style: "percent",
  maximumFractionDigits: 3,
});

interface PercentageNumberProps {
  /**
   * A fractional percentage
   *
   * @example
   * ```
   * <PercentageNumberProps value={0.1} /> // Output: 10%
   * ```
   */
  value: number;

  /**
   * By default, decimals are shown for percentages below 10.
   *
   * Set this to false to remove all decimals and round to the nearest whole number.
   */
  showdecimals?: boolean;

  /**
   * Display `+` sign in front of positive numbers.
   *
   * Use for displaying differences
   */
  showSign?: boolean;
}

const PercentageNumber: FC<PercentageNumberProps & TextProps> = ({
  value,
  showdecimals = false,
  showSign = false,
  ...textProps
}) => {
  const sign = showSign && value > 0 ? "+" : "";
  const titleFormat = `${sign}${fullPercentageFormatter.format(value)}`;
  const display =
    showdecimals === false
      ? `${sign}${HighPercentageFormatter.format(value)}`
      : `${sign}${formatPercentage(value)}`;

  let title: string | undefined = undefined;

  // Only display a title with long format if it differs from the UI
  if (titleFormat !== display) {
    title = titleFormat;
  }

  return (
    <Text as="span" {...textProps} title={title}>
      {display}
    </Text>
  );
};

export default PercentageNumber;
