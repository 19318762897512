type ListItem<TData> = TData & {
  id: string;
};

type TreeItem<
  TData,
  TParentKey extends string & keyof TData,
> = ListItem<TData> & {
  subRows: Array<TreeChild<TData, TParentKey>>;
};
type TreeChild<TData, TParentKey extends string & keyof TData> = TreeItem<
  TData,
  TParentKey
> &
  Record<TParentKey, string>;

export function getItemTree<TData, TParentKey extends string & keyof TData>(
  items: Array<ListItem<TData>>,
  parentKey: TParentKey,
): Array<TData & TreeItem<TData, TParentKey>> {
  const parentLookup = new Map<string, TreeItem<TData, TParentKey>>();
  const nodes: Array<TreeItem<TData, TParentKey>> = [];
  const orphans: Array<TreeChild<TData, TParentKey>> = [];

  for (const feature of items) {
    const treeItem: TreeItem<TData, TParentKey> = {
      ...feature,
      subRows: [] as Array<TreeChild<TData, TParentKey>>,
    };
    parentLookup.set(treeItem.id, treeItem);

    if (isTreeChild(treeItem, parentKey)) {
      const parent = parentLookup.get(treeItem[parentKey]);

      if (parent) {
        parent.subRows.push(treeItem);
      } else {
        orphans.push(treeItem);
      }
    } else {
      nodes.push(treeItem);
    }
  }

  // If children come before their parents, we'll pick up stragglers here
  for (const orphan of orphans) {
    const parent = parentLookup.get(orphan[parentKey]);

    if (parent) {
      parent.subRows.push(orphan);
    } else {
      // TODO: Should we deal with this case? Could happen if a parent is deleted and references are not cleared
    }
  }

  return nodes;
}

function isTreeChild<TData, TParentKey extends string & keyof TData>(
  item: TreeItem<TData, TParentKey>,
  parentKey: TParentKey,
): item is TreeChild<TData, TParentKey> {
  return typeof item[parentKey] === "string";
}

export function flattenItemTree<TItem extends { subRows?: Array<TItem> }>(
  tree: Array<TItem>,
): Array<TItem> {
  return tree.flatMap((i) => {
    return [i, ...flattenItemTree(i.subRows ?? [])];
  });
}
