import React from "react";
import { Box, BoxProps, Center, useColorModeValue } from "@chakra-ui/react";

import BucketLogo from "@/common/assets/bucket-logo-currentcolor.svg?react";

export default function CenteredLayout({
  children,
  showBucketLogo = false,
  ...props
}: {
  children: React.ReactNode;
  showBucketLogo?: boolean;
} & BoxProps) {
  const logoColor = useColorModeValue("gray.900", "white");

  return (
    <>
      {showBucketLogo && (
        <Box color={logoColor} left={6} pos="absolute" top={6}>
          <BucketLogo width="20" />
        </Box>
      )}
      <Center
        bg={useColorModeValue("white", "gray.900")}
        minH="100vh"
        px={6}
        py={16}
        width="100%"
        {...props}
      >
        {children}
      </Center>
    </>
  );
}
