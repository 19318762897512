import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";

import { adminMembersQueryKey } from "@/admin/data/useAdminMembers";
import api from "@/common/utils/api";

export function useAdminMemberDeleteMutation(orgId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    object,
    AxiosResponse<ErrorResponse>,
    { memberId: string }
  >({
    mutationKey: ["admin", "org", orgId, "member"],
    mutationFn: (variables) =>
      api
        .delete<"/admin/:orgId/members/:memberId">(
          `/admin/${orgId}/members/${variables.memberId}`,
        )
        .then((res) => res.data),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: adminMembersQueryKey(orgId) });
    },
  });
}
