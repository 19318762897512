import equal from "fast-deep-equal";

import {
  FeatureListColumn,
  FeatureListSortBy,
} from "@bucketco/shared/featureAPI";
import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";

import { useDataTableParamState } from "@/common/hooks/useDataTableParamState";

export const useFeaturesTableState = (view: FeatureViewDTO) => {
  const featureViewSorting = view.columnSortKey
    ? [
        {
          id: view.columnSortKey,
          desc: view.columnSortDesc ?? true,
        },
      ]
    : [
        {
          id: "createdAt" as FeatureListSortBy,
          desc: true,
        },
      ];

  // Create table state from feature view data and url params.
  const tableState = useDataTableParamState<
    FeatureListSortBy,
    FeatureListColumn
  >({
    sorting: featureViewSorting,
    columnStates: view.columns,
  });

  const isDirty =
    !equal(tableState.columnStates, view.columns) ||
    !equal(tableState.sorting, featureViewSorting);

  return {
    ...tableState,
    isDirty,
  };
};
