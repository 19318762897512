import { useMemo } from "react";
import { Flex, HStack, Text, Tooltip } from "@chakra-ui/react";

import { FeatureGoal } from "@bucketco/shared/featureAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";
import { WidgetFeatureMetrics } from "@bucketco/shared/widgetAPI";

import NotAvailableCell from "@/common/components/NotAvailableCell";
import { GoalDescription } from "@/feature/components/Goals/GoalDescription";
import { GoalStatusIcon } from "@/feature/components/Goals/GoalStatusIcon";
import { getGoalStatus } from "@/feature/hooks/useGoalStatusColors";

export function GoalCell({
  goals,
  max,
  maxWithDescription,
  withTooltip = true,
}: {
  goals: FeatureGoal[];
  max?: number;
  maxWithDescription?: number;
  withTooltip?: boolean;
}) {
  const filtered = useMemo(
    () => (max ? goals.slice(0, max) : goals),
    [goals, max],
  );

  if (goals.length === 0) {
    return <NotAvailableCell />;
  }

  return (
    <Flex
      alignItems="center"
      flexWrap="wrap"
      fontSize="sm"
      gap={3}
      whiteSpace="nowrap"
    >
      {filtered.map((goal) => {
        const definition = WidgetFeatureMetrics[goal.metric];
        const expandedCell =
          maxWithDescription === undefined ||
          (definition && goals.length <= maxWithDescription);

        return (
          <Tooltip
            key={goal.widgetId}
            label={
              withTooltip && !expandedCell ? (
                <GoalDescription goal={goal} showThreshold />
              ) : undefined
            }
          >
            <HStack spacing={1.5}>
              <GoalStatusIcon
                progress={getFraction(goal.currentValue, goal.threshold)}
                //progressIndeterminate={goal.progressIndeterminate}
                showPercentage={false}
                size={16}
                status={getGoalStatus(goal)}
              />
              {expandedCell && (
                <GoalDescription goal={goal} colored showThreshold />
              )}
            </HStack>
          </Tooltip>
        );
      })}

      {max !== undefined && goals.length > max ? (
        <Text color="dimmed" fontSize="sm">
          + {goals.length - max}
        </Text>
      ) : null}
    </Flex>
  );
}
