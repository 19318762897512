import { forwardRef } from "react";
import { Input } from "@chakra-ui/react";

import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";
import AutocompleteSelect, {
  AutocompleteSelectProps,
  getStringFilterFunction,
  getStringRenderFunction,
} from "@/common/components/AutocompleteSelect";
import useSlackChannels from "@/common/hooks/useSlackChannels";

export type SlackChannelAutocompleteSelectProps = {
  value: SlackChannel | undefined | null;
  onChange: (channel: SlackChannel | null) => void;
  placeholder?: string;
  includeDefaultChannel?: boolean;
} & Omit<
  AutocompleteSelectProps<SlackChannel>,
  | "value"
  | "onChange"
  | "isLoadingSuggestions"
  | "itemFilterFn"
  | "itemKeyProperty"
  | "itemRenderfn"
  | "suggestions"
  | "showClearButton"
>;

export const SlackChannelAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  SlackChannelAutocompleteSelectProps
>(function SlackChannelAutocompleteSelect(
  {
    size,
    value,
    onChange,
    placeholder = "No channel selected",
    isDisabled = false,
    includeDefaultChannel = true,
  },
  ref,
) {
  const { isLoading, isError, data: channels = [] } = useSlackChannels();
  const { currentEnv, currentApp } = useAuthContext();

  const defaultSlackChannel =
    currentEnv?.slackChannel ||
    currentApp?.environments.find((env) => env.isProduction)?.slackChannel;

  if (isLoading) {
    return <Input placeholder="Loading channels..." size={size} disabled />;
  }

  if (isError) {
    return <Input placeholder="Error loading channels" size={size} disabled />;
  }

  if (!channels?.length) {
    return <Input placeholder="No channels found" size={size} disabled />;
  }

  const selectedChannel = channels.find(({ id }) => id === value?.id);

  const actualPlaceholder =
    (includeDefaultChannel &&
      defaultSlackChannel?.name &&
      `${defaultSlackChannel.name} (default)`) ||
    placeholder;

  return (
    <AutocompleteSelect
      ref={ref}
      aria-label="Select a Slack channel"
      isDisabled={isDisabled}
      itemFilterFn={getStringFilterFunction("name")}
      itemKeyProperty="id"
      itemRenderfn={getStringRenderFunction("name")}
      leftIcon={<SlackHashLogo height="14" />}
      maxSuggestions={50}
      placeholder={actualPlaceholder}
      showClearButton={includeDefaultChannel}
      size={size}
      suggestions={channels}
      value={selectedChannel}
      onChange={onChange}
      onClear={() => onChange(null)}
    />
  );
});
