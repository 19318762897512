import { Text, TextProps } from "@chakra-ui/react";

type Props = {
  value: boolean;
} & TextProps;

export default function BooleanCell({ value, ...props }: Props) {
  return (
    <Text fontFamily={"mono"} fontSize="sm" textAlign={"right"} {...props}>
      {value.toString()}
    </Text>
  );
}
