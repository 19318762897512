import { Box } from "@chakra-ui/react";

export default function OrgSuspendedRibbon() {
  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: 999,
        bgColor: "red.500",
        color: "white",
        paddingX: 16,
        paddingY: 1,
        transformOrigin: "center",
        transform: "translateX(-45%) rotate(-45deg) translateY(16px)",
        boxShadow: "0 3px 10px -5px rgba(0, 0, 0, 1)",
      }}
    >
      Suspended
    </Box>
  );
}
