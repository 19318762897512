import { Avatar, AvatarProps } from "@chakra-ui/react";

const sizeTransformations = {
  "2xs": "orglogo-48",
  xs: "orglogo-48",
  sm: "orglogo-48",
  xl: "orglogo-192",
};

type Sizes = keyof typeof sizeTransformations;

const getUrl = (url: string, size: Sizes) =>
  url.replace("/raw/", `/${sizeTransformations[size]}/`);

export default function OrgLogo({
  name,
  url,
  size,
  ...props
}: {
  name: string;
  url: string | null;
  size: Sizes;
} & AvatarProps) {
  const urlWithTransformation = url ? getUrl(url, size) : null;
  return (
    <Avatar
      bg={urlWithTransformation ? "transparent" : "brand.500"}
      borderRadius="25%"
      color="white"
      ignoreFallback={!!urlWithTransformation}
      name={name}
      size={size}
      src={urlWithTransformation ?? undefined}
      {...props}
    />
  );
}
