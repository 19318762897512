import { useSegments } from "@/company/data/useSegments";

export default function useAllSegment() {
  const { data } = useSegments(undefined, {
    select(segments) {
      return segments.find((segment) => segment.isAllSegment);
    },
  });

  return data;
}
