import { useFormContext } from "react-hook-form";
import { RiCheckLine } from "react-icons/ri";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";

import PointerPressIcon from "@/common/assets/pointer-press.svg?react";
import AutocompleteInput from "@/common/components/AutocompleteInput";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { useEventNames } from "@/common/data/useEventNames";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";

type FormEventAutoSuggestInputProps = FormElementBaseProps & {
  name: string;
  label?: string;
} & InputProps;

export default function FormEventAutoCompleteInput({
  name,
  size = "md",
  ...rest
}: FormEventAutoSuggestInputProps) {
  const [managedProps, inputProps] = useManagedFormControlProps(rest);
  return (
    <ManagedFormControl
      {...managedProps}
      name={name}
      render={({ field }) => (
        <FormEventAutoCompleteInputOnly
          {...field}
          {...inputProps}
          name={name}
          size={size}
        />
      )}
      size={size}
    />
  );
}

export function FormEventAutoCompleteInputOnly({
  name,
  ...inputProps
}: Omit<FormEventAutoSuggestInputProps, "label">) {
  const form = useFormContext();

  const { data: events = [] } = useEventNames();

  const currentValue = form.watch(name) ?? "";

  const isTracked = events.includes(currentValue);

  return (
    <AutocompleteInput
      maxSuggestions={50}
      search={currentValue}
      suggestions={events.map((e) => ({ key: e, label: e }))}
      onValuePick={(pick) =>
        form.setValue(name, pick.key, {
          shouldDirty: true,
          shouldValidate: true,
        })
      }
    >
      <InputGroup>
        <InputLeftElement color="dimmed" pointerEvents="none">
          <PointerPressIcon height="16px" width="16px" />
        </InputLeftElement>
        <Input
          autoComplete="off"
          background="appBackground"
          type="search"
          {...inputProps}
          {...form.register(name)}
        />

        {currentValue.trim()
          ? isTracked && (
              <InputRightElement color="dimmed">
                <RiCheckLine size={16} />
              </InputRightElement>
            )
          : null}
      </InputGroup>
    </AutocompleteInput>
  );
}
