import { HTMLAttributes, ReactNode, useMemo, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { RiDraggable } from "react-icons/ri";
import {
  Box,
  ButtonProps,
  Checkbox,
  MenuIcon,
  MenuItemProps,
  useMenuOption,
  UseMenuOptionOptions,
  useMenuStyles,
} from "@chakra-ui/react";
import { cx } from "@chakra-ui/shared-utils";
import {
  chakra,
  forwardRef,
  SystemProps,
  SystemStyleObject,
} from "@chakra-ui/system";
import type { Identifier, XYCoord } from "dnd-core";

import { DragType, DragTypeItemMap } from "@/common/types/dragDropTypes";

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const StyledMenuItem = forwardRef<ButtonProps, "button">(
  (props, ref) => {
    const { type, ...rest } = props;
    const styles = useMenuStyles();

    /**
     * Given another component, use its type if present
     * Else, use no type to avoid invalid html, e.g. <a type="button" />
     * Else, fall back to "button"
     */
    const btnType = rest.as || type ? type ?? undefined : "button";

    const buttonStyles: SystemStyleObject = useMemo(
      () => ({
        textDecoration: "none",
        color: "inherit",
        userSelect: "none",
        display: "flex",
        width: "100%",
        alignItems: "center",
        textAlign: "start",
        flex: "0 0 auto",
        outline: 0,
        cursor: "pointer",
        ...styles.item,
      }),
      [styles.item],
    );

    return (
      <chakra.button ref={ref} type={btnType} {...rest} __css={buttonStyles} />
    );
  },
);

export interface MenuReorderItemOptionProps
  extends UseMenuOptionOptions,
    Omit<MenuItemProps, keyof UseMenuOptionOptions | "icon"> {
  /**
   * @type SystemProps["mr"]
   */
  iconSpacing?: SystemProps["mr"];
  index?: number;
  rightIcon?: ReactNode;
  uncheckedCanReorder?: boolean;
  onOptionMove?: (dragIndex: number, hoverIndex: number) => void;
  onOptionDrop?: (dragIndex: number, hoverIndex: number) => void;
  dragType: DragType;
}

export const MenuReorderItemOption = forwardRef<
  MenuReorderItemOptionProps,
  "button"
>(
  (
    {
      iconSpacing = "0.5rem",
      index,
      uncheckedCanReorder = true,
      rightIcon,
      onOptionMove,
      onOptionDrop,
      dragType,
      ...rest
    },
    ref,
  ) => {
    const optionProps = useMenuOption(
      rest,
      ref,
    ) as HTMLAttributes<HTMLButtonElement>;

    const canDrag =
      !rest.isDisabled &&
      (rest.isChecked || (!rest.isChecked && uncheckedCanReorder));

    const innerRef = useRef<HTMLButtonElement>(null);
    const [{ handlerId }, drop] = useDrop<
      DragItem,
      void,
      { handlerId: Identifier | null }
    >({
      accept: dragType,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: DragItem, monitor) {
        if (!innerRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index!;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = innerRef.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        onOptionMove?.(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
      drop(item: DragItem, monitor) {
        if (monitor.didDrop()) {
          return;
        }
        onOptionDrop?.(item.index, index!);
      },
    });

    const [{ isDragging }, drag, preview] = useDrag<
      DragTypeItemMap[DragType.MenuReorderOption],
      unknown,
      { isDragging: boolean }
    >({
      type: dragType,
      canDrag: () => canDrag,
      item: () => {
        return {
          id: optionProps.id!,
          index: index!,
          isChecked: rest.isChecked ?? false,
          children: optionProps.children!,
        };
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0 : 1;
    drop(innerRef);
    preview(getEmptyImage());

    return (
      <StyledMenuItem
        {...optionProps}
        ref={innerRef}
        className={cx("chakra-menu__menuitem-option", rest.className)}
        data-handler-id={handlerId}
        height={8}
        opacity={opacity}
        py={0}
      >
        <Box
          ref={drag}
          boxSize={3}
          marginEnd={iconSpacing}
          sx={{
            cursor: canDrag ? "grab" : "pointer",
            "&:active": {
              cursor: canDrag ? "grabbing" : "pointer",
            },
          }}
        >
          {canDrag && (
            <MenuIcon color="dimmed">
              <RiDraggable />
            </MenuIcon>
          )}
        </Box>
        <Checkbox
          colorScheme="brand"
          isChecked={rest.isChecked}
          marginEnd={iconSpacing}
          pointerEvents="none"
          size="sm"
        />
        <span style={{ flex: 1 }}>{optionProps.children}</span>
        {rightIcon && (
          <MenuIcon marginStart={iconSpacing}>{rightIcon}</MenuIcon>
        )}
      </StyledMenuItem>
    );
  },
);

MenuReorderItemOption.id = "MenuReorderItemOption";

MenuReorderItemOption.displayName = "MenuReorderItemOption";
