import React, { useMemo } from "react";
import { Text, TextProps } from "@chakra-ui/react";

type Props = TextProps & {
  children: string;
};

/**
 * A special version of Text that also allows for word breaking on underscores.
 */
export const TextWordBreak = ({ children, ...rest }: Props) => {
  const words = useMemo(() => children.split("_"), [children]);
  return (
    <Text overflowWrap="anywhere" {...rest}>
      {words.map((word, i) => (
        <React.Fragment key={i}>
          {word}
          {i < words.length - 1 && "_"}
          {i < words.length - 1 && <wbr />}
        </React.Fragment>
      ))}
    </Text>
  );
};
