import { RefObject, useEffect, useState } from "react";

import useElementSize from "@/common/hooks/useElementSize";

/**
 * Forked from https://github.com/amorriscode/use-overflow
 * Adapted to support Resize Observer
 */
export function useOverflow(
  ref: RefObject<HTMLElement>,
  forceUpdate?: string | number | boolean,
): {
  refXOverflowing: boolean;
  refYOverflowing: boolean;
  refXScrollBegin: boolean;
  refXScrollEnd: boolean;
  refYScrollBegin: boolean;
  refYScrollEnd: boolean;
} {
  const [refXOverflowing, setRefXOverflowing] = useState(false);
  const [refYOverflowing, setRefYOverflowing] = useState(false);

  const [refXScrollBegin, setRefXScrollBegin] = useState(true);
  const [refXScrollEnd, setRefXScrollEnd] = useState(false);

  const [refYScrollBegin, setRefYScrollBegin] = useState(true);
  const [refYScrollEnd, setRefYScrollEnd] = useState(false);

  const size = useElementSize(ref);

  useEffect((): any => {
    if (!ref?.current) {
      return;
    }
    const currentRef = ref.current;

    const isXOverflowing = ref.current.scrollWidth > ref.current.clientWidth;
    const isYOverflowing = ref.current.scrollHeight > ref.current.clientHeight;

    if (refXOverflowing !== isXOverflowing) {
      setRefXOverflowing(isXOverflowing);
    }

    if (refYOverflowing !== isYOverflowing) {
      setRefYOverflowing(isYOverflowing);
    }

    const handleScroll = (): void => {
      if (!ref?.current) return;

      const rect = ref.current.getBoundingClientRect();

      // Handle X Overflow
      const offsetRight = ref.current.scrollWidth - rect.width;
      if (Math.ceil(ref.current.scrollLeft) >= Math.floor(offsetRight)) {
        if (refXScrollEnd === false) setRefXScrollEnd(true);
      } else {
        setRefXScrollEnd(false);
      }

      if (ref.current.scrollLeft <= 0) {
        setRefXScrollBegin(true);
      } else {
        setRefXScrollBegin(false);
      }

      // Handle Y Overflow
      const offsetBottom = ref.current.scrollHeight - rect.height;
      if (Math.ceil(ref.current.scrollTop) >= Math.floor(offsetBottom)) {
        if (refYScrollEnd === false) setRefYScrollEnd(true);
      } else {
        setRefYScrollEnd(false);
      }

      if (ref.current.scrollTop <= 0) {
        setRefYScrollBegin(true);
      } else {
        setRefYScrollBegin(false);
      }
    };

    ref.current.addEventListener("scroll", handleScroll);

    return (): void => currentRef.removeEventListener("scroll", handleScroll);
  }, [
    ref,
    refXOverflowing,
    refXScrollEnd,
    refYOverflowing,
    refYScrollEnd,
    size?.height,
    size?.width,
    forceUpdate,
  ]);

  return {
    refXOverflowing,
    refYOverflowing,
    refXScrollBegin,
    refXScrollEnd,
    refYScrollBegin,
    refYScrollEnd,
  };
}
