import * as React from "react";
import useResizeObserver from "@react-hook/resize-observer";

export default function useElementSize<T extends HTMLElement>(
  target: React.RefObject<T> | null,
) {
  const [size, setSize] = React.useState<DOMRect>();

  React.useLayoutEffect(() => {
    if (target?.current) {
      setSize(target?.current?.getBoundingClientRect());
    }
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
}
