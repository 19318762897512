import { forwardRef } from "react";
import { MdPlaylistAdd, MdPlaylistAddCheck } from "react-icons/md";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";

import { AttributeField } from "@bucketco/shared/attributeFilter";

import { fieldIcons } from "@/common/components/AttributeFilters";
import AutocompleteInput from "@/common/components/AutocompleteInput";

type RuleAttributeProps = Omit<InputProps, "value" | "onChange"> & {
  value: AttributeField["key"] | undefined;
  attributes: AttributeField[];
  isLoading?: boolean;
  onChange: (nextValue: AttributeField["key"]) => void;
};

const RuleAttribute = forwardRef<HTMLInputElement, RuleAttributeProps>(
  ({ value, attributes, isLoading, onChange, ...rest }, ref) => {
    const isTracked = attributes.some((attr) => attr.key === value);
    const attribute = attributes.find((attr) => attr.key === value);

    return (
      <AutocompleteInput
        maxSuggestions={50}
        search={value ?? ""}
        suggestions={attributes.map((a) => ({
          key: a.key,
          label: a.label || a.key,
          icon: fieldIcons[a.icon ?? "custom"],
        }))}
        onValuePick={(pick) => onChange(pick.key)}
      >
        <InputGroup>
          <InputLeftElement color="gray.500" pl="2" pointerEvents="none">
            {isLoading ? (
              <Spinner color="dimmed" size="xs" />
            ) : (
              fieldIcons[attribute?.icon ?? "custom"]
            )}
          </InputLeftElement>
          <Input
            ref={ref}
            autoComplete="off"
            background="appBackground"
            data-testid="rule-attribute"
            placeholder={isLoading ? "Loading attributes..." : "Attribute"}
            type="search"
            value={attribute?.label ?? value ?? ""}
            onChange={(e) => {
              onChange(attribute?.system ? "" : e.target.value);
            }}
            {...rest}
          />
          <InputRightElement h="100%">
            {value?.trim() ? (
              isTracked ? (
                <MdPlaylistAddCheck opacity={0.3} size={24} />
              ) : (
                <MdPlaylistAdd opacity={0.3} size={24} />
              )
            ) : null}
          </InputRightElement>
        </InputGroup>
      </AutocompleteInput>
    );
  },
);

RuleAttribute.displayName = "RuleAttribute";

export default RuleAttribute;
