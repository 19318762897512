import { Tooltip } from "@chakra-ui/react";

import { SatisfactionScore } from "@bucketco/shared/schemas/satisfactionScore";

import {
  FeedbackSatisfactionIcon,
  satisfactionOptions,
} from "@/feedback/components/FeedbackSatisfaction";

export default function SatisfactionCell({
  value,
}: {
  value: SatisfactionScore;
}) {
  return (
    <Tooltip
      label={`${satisfactionOptions.find((o) => o.value === value)
        ?.a11yLabel} (${value}/5)`}
      openDelay={300}
      hasArrow
    >
      <FeedbackSatisfactionIcon value={value} colored />
    </Tooltip>
  );
}
