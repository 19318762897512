import isEqual from "lodash/isEqual";

import {
  COMPANY_ID_CONTEXT_FIELD,
  MAX_ROLLOUT_THRESHOLD,
  reduce,
} from "@bucketco/shared/filter";

import { EnvironmentListItemDTO } from "../environmentAPI";
import { CreateFlagRuleArgs, CreateFlagVersionArgs } from "../flagAPI";

export type EnvironmentRules = {
  environment: {
    id: string;
  };
  rules: CreateFlagRuleArgs[];
};

/**
 * Finds the rules in the `target` array that are not present in the `source` array,
 * and returns a new array containing those rules.
 *
 * @param source - An array of flag rules representing the existing rules.
 * @param target - An array of flag rules representing the new rules to be added.
 * @returns A new array of flag rules containing the rules that are present in `target` but not in `source`.
 */
export function addedRules(
  source: CreateFlagRuleArgs[],
  target: CreateFlagRuleArgs[],
): CreateFlagRuleArgs[] {
  const result: CreateFlagRuleArgs[] = [];
  for (const rule of target) {
    if (!source.find((sr) => isEqual(sr, rule))) {
      result.push(rule);
    }
  }
  return result;
}

/**
 * Merges two arrays of flag rules, keeping the rule with the higher `partialRolloutThreshold`
 * value for each unique combination of `partialRolloutContextAttribute` and `filter`.
 *
 * @param source - An array of existing flag rules.
 * @param target - An array of new flag rules to be merged.
 * @returns A new array of merged flag rules.
 */
export function mergeRules(
  source: CreateFlagRuleArgs[],
  target: CreateFlagRuleArgs[],
): CreateFlagRuleArgs[] {
  const result: CreateFlagRuleArgs[] = [];

  for (const rule of [...source, ...target]) {
    // Check if the rule already exists in the result array
    const existingIndex = result.findIndex(
      (existing) =>
        (existing.partialRolloutContextAttribute ??
          COMPANY_ID_CONTEXT_FIELD) ===
          (rule.partialRolloutContextAttribute ?? COMPANY_ID_CONTEXT_FIELD) &&
        isEqual(reduce(existing.filter), reduce(rule.filter)),
    );

    if (existingIndex >= 0) {
      const existing = result[existingIndex];
      // If the rule already exists, keep the one with the higher `partialRolloutThreshold` value
      if (
        (rule.partialRolloutThreshold ?? MAX_ROLLOUT_THRESHOLD) >
        (existing.partialRolloutThreshold ?? MAX_ROLLOUT_THRESHOLD)
      ) {
        result[existingIndex] = rule;
      }
    } else {
      result.push(rule);
    }
  }

  return result;
}

/**
 * Merges two arrays of flag versions, keeping the version with the higher `partialRolloutThreshold`
 * value for each environment if a version exists in both arrays.
 *
 * @param newVersions - An array of new flag versions to be merged.
 * @param prevVersions - An array of previous flag versions to be merged.
 * @param currentVersions - An array of current flag versions to be merged.
 * @returns A new array of merged flag versions, one for each environment.
 */
export function mergeVersions(
  newVersions: CreateFlagVersionArgs[],
  prevVersions: CreateFlagVersionArgs[],
  currentVersions: CreateFlagVersionArgs[],
) {
  return prevVersions.map(({ environmentId, rules }, index) => ({
    environmentId,
    rules: mergeRules(
      newVersions[index].rules,
      // Identify the rules that have been added to the previous version
      addedRules(rules, currentVersions[index].rules),
    ),
  }));
}

/**
 * Expands a partial array of environment rules with all available environments.
 *
 * @param environments - An array of environments.
 * @param rules - An partial array of environment rules.
 * @returns A new array of environment-specific rules,
 * where each item contains the rules for a specific environment.
 */
export function expandEnvironmentRules(
  environments: EnvironmentListItemDTO[],
  rules: EnvironmentRules[],
) {
  return environments.map((env) => {
    const version = rules.find((cv) => cv.environment.id === env.id);
    return {
      environmentId: env.id,
      rules:
        // If the environment has a version, use its rules with default values
        version?.rules?.map(
          ({
            filter,
            partialRolloutThreshold,
            partialRolloutContextAttribute,
          }) => ({
            filter,
            partialRolloutThreshold:
              partialRolloutThreshold ?? MAX_ROLLOUT_THRESHOLD,
            partialRolloutContextAttribute:
              partialRolloutContextAttribute ?? COMPANY_ID_CONTEXT_FIELD,
          }),
        ) ?? [],
    };
  });
}

/**
 * Creates a flag rule that filters users based on their membership in a specific segment.
 *
 * @param segmentId - The ID of the segment to filter users by.
 * @returns A flag rule object that can be used to configure a feature flag.
 */
export function createSegmentRule(segmentId: string) {
  return {
    partialRolloutThreshold: MAX_ROLLOUT_THRESHOLD,
    partialRolloutContextAttribute: COMPANY_ID_CONTEXT_FIELD,
    filter: {
      type: "segment",
      operator: "SEGMENT",
      segmentId: segmentId,
    },
  } satisfies CreateFlagRuleArgs;
}

/**
 * Creates a flag rule that filters users based on their email domain.
 *
 * @param domain - The email domain to filter users by.
 * @returns A flag rule object that can be used to configure a feature flag.
 */
export function createEmailDomainRule(domain: string) {
  return {
    partialRolloutThreshold: MAX_ROLLOUT_THRESHOLD,
    partialRolloutContextAttribute: COMPANY_ID_CONTEXT_FIELD,
    filter: {
      type: "userAttribute",
      field: "email",
      operator: "CONTAINS",
      values: [`@${domain}`],
    },
  } satisfies CreateFlagRuleArgs;
}
