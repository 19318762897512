import { z } from "zod";

export const PaginationQueryBaseSchema = (
  {
    sortOrder = "asc",
    pageIndex = 0,
    pageSize = 20,
  }: {
    sortOrder?: "asc" | "desc";
    pageIndex?: number;
    pageSize?: number;
  } = {
    sortOrder: "asc",
    pageIndex: 0,
    pageSize: 20,
  },
) =>
  z.object({
    sortOrder: z.enum(["asc", "desc"]).default(sortOrder),
    pageIndex: z.coerce.number().int().nonnegative().default(pageIndex),
    pageSize: z.coerce
      .number()
      .int()
      .nonnegative()
      .min(1)
      .max(100)
      .default(pageSize),
  });
