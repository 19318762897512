import { DependencyList, useCallback, useMemo } from "react";

import { CompanyFeatureDTO } from "@bucketco/shared/companyAPI";
import { FeatureListItem } from "@bucketco/shared/featureAPI";

type Feature = FeatureListItem | CompanyFeatureDTO;

export function useFeaturesSearch<T extends Feature>(
  features: T[],
  options: {
    enabled: boolean;
    predicate: (feature: T) => boolean;
  },
  deps: DependencyList | undefined = [],
) {
  const isChildMatch = useCallback(
    (f: T): boolean => {
      return (
        hasSubRows(f) &&
        f.subRows.some((r) => {
          return options.predicate(r) || isChildMatch(r);
        })
      );
    },
    [options],
  );

  return useMemo(
    () =>
      !options.enabled
        ? features
        : features
            .filter(
              (feature) => options.predicate(feature) || isChildMatch(feature),
            )
            .map((feature) => ({
              ...feature,
              subRows: hasSubRows(feature)
                ? feature.subRows.filter(
                    (s) => options.predicate(s) || isChildMatch(s),
                  )
                : undefined,
            })),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- deps are external
    [features, isChildMatch, options, ...deps],
  );
}

type FeatureListItemWithSubRows<T extends Feature> = T & {
  subRows: T[];
};

function hasSubRows<T extends Feature>(
  f: T,
): f is FeatureListItemWithSubRows<T> {
  return "subRows" in f && Array.isArray(f.subRows);
}
