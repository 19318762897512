import { useColorModeValue } from "@chakra-ui/react";

export function useEnvironmentColors() {
  const colors = {
    production: {
      icon: useColorModeValue("brand.500", "brand.400"),
      text: "chakra-body-text",
    },
    nonProduction: {
      icon: useColorModeValue("orange.500", "orange.400"),
      text: "chakra-body-text",
    },
  };
  return colors;
}
