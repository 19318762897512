import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { StageDTO } from "@bucketco/shared/stageAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { stageQueryKeys } from "./stageQueryKeys";

export const useStageData = <TData = StageDTO>(
  stageId?: string,
  queryOptions?: Omit<
    UseQueryOptions<StageDTO, AxiosError<ErrorResponse>, TData>,
    "queryKey" | "queryFn" | "initialData"
  > & { initialData?: () => undefined },
) => {
  const { appId } = useCurrentEnv();
  return useQuery({
    queryKey: stageQueryKeys.single(appId, stageId),
    queryFn: () =>
      api
        .get<"/apps/:appId/stages/:stageId">(`/apps/${appId}/stages/${stageId}`)
        .then((res) => res.data.stage),
    enabled: !!appId && !!stageId,
    ...queryOptions,
  });
};
