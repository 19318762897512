import { Box, useColorModeValue } from "@chakra-ui/react";
import {
  Bar,
  BarChart as RechartsBarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";

import useChartTokens, {
  useChartColorTokens,
} from "@/common/charts/hooks/useChartTokens";
import { epochToShortDate } from "@/common/utils/datetime";

const BAR_GAP = 3;
const MAX_BAR_SIZE = 24;

export default function SimpleBarChart({
  data = [],
  dataKeys = [],
  isLoading = false,
  yAxis = {},
  xAxis = {},
  tooltip = {},
  height = 300,
}: {
  data?: any[];
  dataKeys: {
    key: string;
    name: string;
    color: keyof useChartColorTokens;
  }[];
  isLoading?: boolean;
  yAxis?: YAxisProps;
  xAxis?: XAxisProps;
  tooltip?: any;
  height?: number;
  highlightLatest?: boolean;
}) {
  const { colors, fontSizes, radii } = useChartTokens();

  return (
    <Box
      sx={{
        ".recharts-tooltip-cursor": {
          fill: useColorModeValue("gray.50", "gray.750"),
        },
      }}
    >
      <ResponsiveContainer height={height} width="100%">
        <RechartsBarChart
          barCategoryGap={BAR_GAP}
          barGap={BAR_GAP}
          data={data}
          maxBarSize={MAX_BAR_SIZE}
          stackOffset="sign"
          syncId="a"
        >
          <ReferenceLine stroke={colors.grid} y={0} yAxisId="left" isFront />
          <XAxis
            axisLine={false}
            dataKey="epoch"
            dy={8}
            fontSize={fontSizes.axisLabel}
            interval="preserveEnd"
            minTickGap={0}
            opacity={1}
            stroke={useColorModeValue("gray.500", "gray.500")}
            tick={{
              fill: colors.axisLabel,
            }}
            tickFormatter={epochToShortDate}
            tickLine={{
              stroke: colors.axis,
            }}
            {...xAxis}
          />
          <YAxis type="number" yAxisId="left" hide {...yAxis} />
          <Tooltip
            contentStyle={{
              borderColor: colors.tooltipBorder,
              backgroundColor: colors.tooltipBg,
              borderRadius: radii.tooltip,
              fontSize: fontSizes.tooltip,
            }}
            isAnimationActive={false}
            {...tooltip}
          />
          {!isLoading &&
            dataKeys.map(({ key, name, color }, stackIdx) => {
              const laterStacks = dataKeys.slice(stackIdx + 1);

              return (
                <Bar
                  key={key}
                  animationDuration={0}
                  dataKey={key}
                  fill={colors[color as keyof typeof colors]}
                  name={name}
                  stackId="a"
                  yAxisId="left"
                >
                  {data.map((entry, cellIdx) => {
                    // Only the last stack should have rounded corners,
                    // so figure out if it's this one.
                    const isLastStackWithData = laterStacks.every(
                      (stack) => entry[stack.key] === 0,
                    );

                    return (
                      <Cell
                        key={cellIdx}
                        radius={
                          // The type here is broken, hence the `as any`.
                          // It does support an array.
                          isLastStackWithData
                            ? ([3, 3, 0, 0] as any)
                            : ([0, 0, 0, 0] as any)
                        }
                      />
                    );
                  })}
                </Bar>
              );
            })}
        </RechartsBarChart>
      </ResponsiveContainer>
    </Box>
  );
}
