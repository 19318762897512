import { useCallback, useEffect, useState } from "react";
import { Text, Tooltip } from "@chakra-ui/react";
import { ConfigType } from "dayjs";
import doCapitalize from "lodash/capitalize";

import { fullFormattedDateTime } from "@/common/utils/datetime";
import dayjs from "@/common/utils/dayjs";

const HIGHLIGHT_PERIOD_MS = 6 * 60 * 1000; // Six minutes

type NearestUnit = "second" | "hour" | "day";
type ClampType = "past" | "future";

type Props = {
  value?: ConfigType;
  autoUpdate?: boolean;
  leftAlign?: boolean;
  fontSize?: string;
  capitalize?: boolean;
  highlight?: boolean;
  color?: string;
  nearestUnit?: NearestUnit;
  clamp?: ClampType;
  showTooltip?: boolean;
};

export function formatRelativeTime(
  value: dayjs.Dayjs,
  now: dayjs.Dayjs,
  nearestUnit: NearestUnit,
  clamp?: ClampType,
) {
  const clampedValue =
    clamp === "past"
      ? dayjs.min(value, now)!
      : clamp === "future"
      ? dayjs.max(value, now)!
      : value;

  let relative = clampedValue.from(now);

  if (nearestUnit === "hour") {
    const hours = now.diff(clampedValue, "hours");
    if (hours < 1) {
      relative = "less than an hour ago";
    }
  } else if (nearestUnit === "day") {
    if (clampedValue.isSame(now, "day")) {
      relative = "today";
    }
  }

  return relative;
}

export default function TimestampCell({
  value,
  autoUpdate = false,
  leftAlign = false,
  fontSize = "sm",
  capitalize = true,
  highlight = true,
  color,
  nearestUnit = "second",
  clamp,
  showTooltip = true,
}: Props) {
  const [time, setTime] = useState(
    formatRelativeTime(dayjs(value), dayjs(), nearestUnit, clamp),
  );
  const [isHighlighted, setIsHighlighted] = useState(
    HIGHLIGHT_PERIOD_MS >
      Math.abs(
        clamp === "past"
          ? dayjs.min(dayjs(value), dayjs())!.diff()
          : clamp === "future"
          ? dayjs.max(dayjs(value), dayjs())!.diff()
          : dayjs(value).diff(),
      ),
  );

  const update = useCallback(
    (newValue: ConfigType) => {
      const now = dayjs();
      const newDayjs = dayjs(newValue);
      const clampedValue =
        clamp === "past"
          ? dayjs.min(newDayjs, now)!
          : clamp === "future"
          ? dayjs.max(newDayjs, now)!
          : newDayjs;
      // No need to pass clamp to formatRelativeTime, it's already clamped
      setTime(formatRelativeTime(clampedValue, now, nearestUnit));
      setIsHighlighted(HIGHLIGHT_PERIOD_MS > Math.abs(clampedValue.diff()));
    },
    [nearestUnit, clamp],
  );

  // Make sure relative timestamps are updated every minute, if enabled
  useEffect(() => {
    if (!autoUpdate) return;
    const timer = setInterval(() => {
      update(value);
    }, 60000);
    return () => clearInterval(timer);
  }, [autoUpdate, update, value]);

  // Update the relative timestamp when the value changes
  useEffect(() => {
    update(value);
  }, [update, value]);

  if (!value) return null;

  return (
    <Tooltip
      isDisabled={!showTooltip}
      label={fullFormattedDateTime(value)}
      openDelay={300}
      placement={leftAlign ? "bottom-start" : undefined}
      hasArrow
    >
      <Text
        as="time"
        color={highlight && isHighlighted ? "green.500" : color}
        dateTime={dayjs(value).toISOString()}
        fontSize={fontSize}
        whiteSpace="nowrap"
      >
        {capitalize ? doCapitalize(time) : time}
      </Text>
    </Tooltip>
  );
}
