import { forwardRef, memo } from "react";
import { Box, HTMLChakraProps, SystemStyleObject } from "@chakra-ui/react";

interface GroupProps extends HTMLChakraProps<"div"> {
  attached?: boolean;
  direction?: "horizontal" | "vertical";
}

export const Group = memo(
  forwardRef<HTMLDivElement, GroupProps>(function Group(
    { attached, gap = 2, direction = "horizontal", ...props },
    ref,
  ) {
    const styles: SystemStyleObject = {
      display: direction === "horizontal" ? "inline-flex" : "flex",
      flexDirection: direction === "horizontal" ? "row" : "column",
      gap: gap,
      isolation: "isolate",
    };

    const attachedStyles: SystemStyleObject = {
      position: "relative",
      alignItems: "stretch",
      gap: 0,

      "& > *": {
        _focusVisible: {
          zIndex: 1,
        },
      },

      ...(direction === "horizontal"
        ? {
            "& > *:has(+ *)": {
              borderEndRadius: "0!",
              marginEnd: "-1px",
            },
            "& > * + *": {
              borderStartRadius: "0!",
            },
          }
        : {
            "& > *:has(+ *)": {
              borderBottomRadius: "0!",
              marginBottom: "-1px",
            },
            "& > * + *": {
              borderTopRadius: "0!",
            },
          }),
    };

    return (
      <Box
        ref={ref}
        {...props}
        sx={attached ? { ...styles, ...attachedStyles } : styles}
      >
        {props.children}
      </Box>
    );
  }),
);
