import { useQuery } from "@tanstack/react-query";

import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

export function useEventAttributeNames(eventName?: string) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: commonQueryKeys.eventAttributeNames(appId, envId, eventName),
    queryFn: () =>
      api
        .get<"/apps/:appId/events/by-name/:eventName/attributes">(
          `/apps/${appId}/events/by-name/${encodeURIComponent(
            eventName!,
          )}/attributes`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data.attributes),
    enabled: !!eventName?.trim() && !!appId && !!envId,
  });
}
