import { ReactNode } from "react";
import { RiCheckLine, RiErrorWarningLine } from "react-icons/ri";
import {
  Badge,
  Box,
  Center,
  FormLabel,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import { AutoFeedbackSurveysButton } from "@/common/components/CommonIntegrationButtons";
import TimestampCell from "@/common/components/TimestampCell";
import { useFeedbackPromptingStatus } from "../data/useFeedbackPromptingStatus";

type Props = {
  children?: ReactNode;
  showTitle?: boolean;
  showInstalled?: boolean;
  showLastActivity?: boolean;
  description?: string;
  showLink?: boolean;
};

const defaultInstallDescription = `
Automated feedback surveys enable you to automatically gather qualitative feedback from your users based on feature interactions.
To enable this feature and start gathering user feedback, you will need to
connect using the Bucket SDKs.
`;

export default function FeedbackPromptingInstalledWrapper({
  children,
  showTitle = true,
  showInstalled = true,
  showLastActivity = true,
  description = defaultInstallDescription,
  showLink = true,
}: Props) {
  const status = useFeedbackPromptingStatus();

  if (status.data?.configured && !showTitle && !showInstalled) {
    return <>{children}</>;
  }

  return (
    <VStack align="flex-start" spacing={5}>
      <VStack align="flex-start" spacing={3}>
        {showTitle && <FormLabel pb={0}>Automated feedback surveys</FormLabel>}
        {status.isLoading && (
          <Center height="10%">
            <Spinner size="sm" />
          </Center>
        )}
        {status.data?.configured && showInstalled && (
          <HStack>
            <Badge colorScheme="green" size="md" variant="subtle">
              <HStack spacing={1}>
                <RiCheckLine size={16} />
                <Text fontSize="sm">SDK installed</Text>
              </HStack>
            </Badge>
            {showLastActivity && (
              <Text color="dimmed" fontSize="sm">
                Last client activity{" "}
                <TimestampCell
                  capitalize={false}
                  nearestUnit="hour"
                  value={status.data.lastSeen}
                />
              </Text>
            )}
          </HStack>
        )}
        {status.data && !status.data.configured && (
          <>
            <Badge colorScheme="orange" size="md" variant="subtle">
              <HStack spacing={1}>
                <RiErrorWarningLine size={16} />
                <Text fontSize="sm">SDK not installed</Text>
              </HStack>
            </Badge>
            <Text color="dimmed" fontSize="sm" maxWidth="lg">
              {description}
            </Text>
            {showLink && (
              <Box>
                <AutoFeedbackSurveysButton />
              </Box>
            )}
          </>
        )}
      </VStack>
      {status.data?.configured && children}
    </VStack>
  );
}
