import { useMemo } from "react";

import { useAuthContext } from "@/auth/contexts/authContext";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { useSegments } from "@/company/data/useSegments";

export function useSegmentParam(fallback?: string) {
  const { currentApp } = useAuthContext();
  const { data: segments } = useSegments();

  const fallbackSegment = useMemo(() => {
    if (!fallback) return null;
    return (
      segments?.find(
        (segment) => segment.id === fallback || segment.name === fallback,
      )?.id ?? null
    );
  }, [fallback, segments]);

  const allowlist = useMemo(
    () => (segments ? segments.map((segment) => segment.id) : []),
    [segments],
  );

  return useSearchParam<string | null>("segment", {
    storage: localStorage,
    storageKey: `${currentApp?.id}:segment`,
    fallback: fallbackSegment,
    allowlist,
  });
}
