import { useMemo } from "react";
import { RiArrowDownSLine, RiForbid2Line } from "react-icons/ri";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  SatisfactionScore,
  SatisfactionSpan,
} from "@bucketco/shared/schemas/satisfactionScore";

import MenuDescription from "@/common/components/MenuDescription";
import { FeedbackSatisfactionIcon } from "@/feedback/components/FeedbackSatisfaction";

export type SatisfactionSpanWithAny = SatisfactionSpan | "any";

export const spanToScore = (
  span: SatisfactionSpan | undefined,
): SatisfactionScore[] => {
  switch (span) {
    case "satisfied":
      return [4, 5];
    case "neutral":
      return [3];
    case "unsatisfied":
      return [1, 2];
    case "none":
      return [0];
    default:
      return [0, 1, 2, 3, 4, 5];
  }
};

export function SatisfactionFilter({
  value,
  onChange,
  ...rest
}: {
  value?: SatisfactionSpan;
  onChange: (value?: SatisfactionSpan) => void;
} & Omit<MenuProps, "children">) {
  const noSatisfactionIconColor = useColorModeValue("gray.400", "gray.600");
  const satisfactionFilterOptions: Record<
    SatisfactionSpanWithAny,
    JSX.Element
  > = useMemo(
    () => ({
      any: (
        <Flex alignItems="center" gap={2}>
          <Box>Any satisfaction</Box>
        </Flex>
      ),
      satisfied: (
        <Flex alignItems="center" gap={2}>
          <FeedbackSatisfactionIcon size={18} value={4} colored />
          <Box>Satisfied (4-5)</Box>
        </Flex>
      ),
      neutral: (
        <Flex alignItems="center" gap={2}>
          <FeedbackSatisfactionIcon size={18} value={3} colored />
          <Box>Neutral (3)</Box>
        </Flex>
      ),
      unsatisfied: (
        <Flex alignItems="center" gap={2}>
          <FeedbackSatisfactionIcon size={18} value={2} colored />
          <Box>Unsatisfied (1-2)</Box>
        </Flex>
      ),
      none: (
        <Flex alignItems="center" gap={2}>
          <Box color={noSatisfactionIconColor}>
            <RiForbid2Line size={18} />
          </Box>
          <Box>Unknown satisfaction</Box>
        </Flex>
      ),
    }),
    [noSatisfactionIconColor],
  );

  return (
    <Menu {...rest}>
      <MenuButton
        as={Button}
        color={!value ? "gray.500" : undefined}
        rightIcon={
          <Box fontSize="xl" mr={-2}>
            <RiArrowDownSLine />
          </Box>
        }
        variant="input"
        {...rest}
      >
        {!value
          ? satisfactionFilterOptions.any
          : satisfactionFilterOptions[value]}
      </MenuButton>
      <MenuList>
        <MenuDescription>Filter by satisfaction:</MenuDescription>
        <MenuDivider my={0} />
        <MenuOptionGroup
          value={value ?? "any"}
          onChange={(value) =>
            onChange(value !== "any" ? (value as SatisfactionSpan) : undefined)
          }
        >
          <MenuItemOption value={"any"}>
            {satisfactionFilterOptions.any}
          </MenuItemOption>
          <MenuItemOption value="satisfied">
            {satisfactionFilterOptions.satisfied}
          </MenuItemOption>
          <MenuItemOption value="neutral">
            {satisfactionFilterOptions.neutral}
          </MenuItemOption>
          <MenuItemOption value="unsatisfied">
            {satisfactionFilterOptions.unsatisfied}
          </MenuItemOption>
          <MenuItemOption value="none">
            {satisfactionFilterOptions.none}
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
