import { z } from "zod";

export function nullablePartial<TSchema extends z.AnyZodObject>(
  schema: TSchema,
) {
  const entries = Object.entries(schema.shape) as [
    keyof TSchema["shape"],
    z.ZodTypeAny,
  ][];

  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value.nullable().optional();
      return acc;
    },
    {} as {
      [key in keyof TSchema["shape"]]: z.ZodOptional<
        z.ZodNullable<TSchema["shape"][key]>
      >;
    },
  );

  return z.object(newProps);
}

export type NullablePartial<T> = {
  [K in keyof T]: T[K] | null;
};

export type NonEmpty<T extends any[]> = [T[number], ...T[number][]];

export type NonOptional<
  T extends Record<string, unknown>,
  K extends keyof T,
> = Required<Pick<T, K>> & Omit<T, K>;

export type OptionalKeys<
  T extends Record<string, unknown>,
  K extends keyof T,
> = Omit<T, K> & Partial<Pick<T, K>>;

/**
 * Utility type to recursively generate paths for all properties in an object.
 * @template T - The object type.
 * @returns A union type of all path properties.
 * @example
 * // Usage
 * type MyObject = {
 *   foo: {
 *     bar: string;
 *   };
 *   baz: number;
 * };
 *
 * type MyPaths = ObjectPaths<MyObject>;
 * // MyPaths will be equal to:
 * // "foo" | "baz" | "foo.bar"
 */
export type Paths<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${"" | `.${Paths<T[K]>}`}`;
    }[keyof T]
  : never;

/**
 * Utility type to recursively generate leaf path for all properties in an object.
 * @template T - The object type to extract leaf properties from.
 * @returns A union type of all leaf properties.
 * @example
 * // Usage
 * type MyObject = {
 *   foo: {
 *     bar: string;
 *   };
 *   baz: number;
 * };
 *
 * type MyLeaves = Leaves<MyObject>;
 * // MyLeaves will be equal to:
 * // "baz" | "foo.bar"
 */
export type Leaves<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never
        ? ""
        : `.${Leaves<T[K]>}`}`;
    }[keyof T]
  : never;

export type AllOrNone<T> = T | { [K in keyof T]?: never };
