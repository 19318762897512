import { HStack, Switch, Text } from "@chakra-ui/react";
import { ResponsiveValue } from "@chakra-ui/system";

import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

import { SlackChannelAutocompleteSelect } from "@/common/components/slack/SlackChannelAutocompleteSelect";

type SlackNotificationStatusToggleProps = {
  slackChannel: SlackChannel;
  size:
    | ResponsiveValue<(string & object) | "sm" | "md" | "lg" | "xs" | "2xs">
    | undefined;
  value: boolean;
  onChange?: (value: boolean) => void;
};

export function SlackNotificationStatusToggle({
  slackChannel,
  size = "md",
  value,
  onChange,
}: SlackNotificationStatusToggleProps) {
  return (
    <HStack>
      {slackChannel && (
        <HStack>
          <Switch
            isChecked={value}
            isReadOnly={!onChange}
            size={size == "xs" || size == "2xs" ? "sm" : size}
            onChange={(e) => {
              onChange?.(e.target.checked);
            }}
          />

          <Text color="gray.500" fontSize={size}>
            Send notifications to
          </Text>

          <SlackChannelAutocompleteSelect
            isActive={false}
            isDisabled={true}
            size={"xs"}
            value={slackChannel}
            onChange={() => {}}
          />
        </HStack>
      )}
    </HStack>
  );
}
