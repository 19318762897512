import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";

export function useBillingUsage() {
  const { currentOrg } = useAuthContext();

  return useQuery({
    queryKey: ["org", currentOrg],

    queryFn: () =>
      api
        .get<"/orgs/:orgId/monthly-tracked-users">(
          `/orgs/${currentOrg?.id}/monthly-tracked-users`,
        )
        .then((res) =>
          res.data.map((d) => ({
            month: new Date(d.month),
            trackedUsers: d.trackedUsers,
          })),
        ),

    enabled: Boolean(currentOrg),
  });
}
