import startCase from "lodash/startCase";

import {
  FeatureMetric,
  frequencyNumberToText,
} from "@bucketco/shared/featureAPI";

import {
  formatNumber,
  formatPercentage,
} from "@/common/utils/numberFormatting";

export const formatWidgetValue = (
  value: number,
  isPercentage?: boolean,
  metric?: FeatureMetric,
) => {
  if (metric === "averageFrequency") {
    return startCase(frequencyNumberToText(value));
  }

  return isPercentage
    ? formatPercentage(value)
    : formatNumber(Math.round(value));
};
