import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { RiCloseLine } from "react-icons/ri";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";

import { useAuthContext } from "@/auth/contexts/authContext";
import OrgLogo from "@/common/components/OrgLogo";
import { UploadLogoButton } from "@/global-settings/components/UploadLogoButton";

export default function OrganizationLogoPicker() {
  const form = useFormContext();
  const { currentOrg } = useAuthContext();
  const removeButtonBg = useColorModeValue("white", "gray.800");

  const orgId = currentOrg!.id;
  const logoUrl = form.watch("logoUrl");

  const setLogoUrl = useCallback(
    (url: string | null) => {
      form.setValue("logoUrl", url, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    },
    [form],
  );

  return (
    <FormControl>
      <FormLabel>Logo</FormLabel>
      <Flex direction="column" gap={3} placeItems="flex-start">
        <Box pos="relative">
          <OrgLogo name={currentOrg!.name} size="xl" url={logoUrl} />
          <Box pos="absolute" right={-2} top={-2}>
            {logoUrl && (
              <Tooltip label="Remove" placement="right" hasArrow>
                <IconButton
                  aria-label="Remove"
                  bg={removeButtonBg}
                  icon={<RiCloseLine size={16} />}
                  size="2xs"
                  variant="outline"
                  isRound
                  onClick={() => setLogoUrl(null)}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
        <UploadLogoButton orgId={orgId} onChange={setLogoUrl}>
          {({ onClick }) => (
            <Button variant="outline" onClick={onClick}>
              Upload an image
            </Button>
          )}
        </UploadLogoButton>
      </Flex>
      <FormErrorMessage>
        <ErrorMessage name="logo" />
      </FormErrorMessage>
    </FormControl>
  );
}
