export default function navigateToLogin() {
  const { pathname, search, hash } = window.location;

  const loginUrl = new URL("/login", window.location.origin);
  loginUrl.searchParams.set("redirect_url", pathname + search + hash);

  window.location.href = loginUrl.toString();

  return null;
}
