import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { FeatureListQuery } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { getItemTree } from "@/common/utils/listItemTree";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

export function useFeaturesData(
  params?: Omit<FeatureListQuery, "envId">,
  enabled?: boolean,
) {
  const { appId, envId } = useCurrentEnv();
  const baseParams = { useTargetingRules: true, ...params };
  return useQuery({
    queryKey: featureQueryKeys.list(appId, envId, baseParams),
    queryFn: () =>
      api
        .get<"/apps/:appId/features">(`/apps/${appId}/features`, {
          params: { ...baseParams, envId: envId! },
        })
        .then((res) => res.data),
    enabled: !!appId && !!envId && enabled,
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData,
    refetchInterval(query) {
      if (
        query.state.data?.data &&
        query.state.data.data.some(
          (feature) => feature.processingStatus === "bootstrapping",
        )
      ) {
        return 500;
      }
      return false;
    },
    select({ data, ...rest }) {
      return {
        data:
          rest.metadata.sortType === "hierarchical"
            ? getItemTree(data, "sortKey")
            : data,
        ...rest,
      };
    },
  });
}
