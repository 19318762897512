import React from "react";
import { Alert, AlertDescription, AlertIcon, Card } from "@chakra-ui/react";

import { BaseWidgetProps } from "@/widget/components/types";

type Props = BaseWidgetProps;

export const WidgetTypeInvalid = ({ configuration }: Props) => {
  return (
    <Card p={6}>
      <Alert status="warning">
        <AlertIcon />
        <AlertDescription>
          Widget type &quot;{configuration.type}&quot; does not exist
        </AlertDescription>
      </Alert>
    </Card>
  );
};
