import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  chakra,
  Link,
  Text,
} from "@chakra-ui/react";
import startCase from "lodash/startCase";

import { useAuthContext } from "@/auth/contexts/authContext";
import EnvironmentsSvg from "@/common/assets/environments-dots-circles-fill.svg?react";

const EnvironmentsIcon = chakra(EnvironmentsSvg);

type Props = AlertProps & {
  entity: string;
};

export const DeleteAffectEnvironments = ({ entity, ...rest }: Props) => {
  const { currentEnv } = useAuthContext();
  if (currentEnv?.isProduction) {
    return null;
  }

  return (
    <Alert alignItems="flex-start" status="warning" {...rest}>
      <AlertIcon mt={1}>
        <EnvironmentsIcon />
      </AlertIcon>
      <AlertDescription>
        <Text as="span" fontWeight="semibold">
          Warning!
        </Text>{" "}
        Deleting the {entity.toLowerCase()} will affect all environments
        including{" "}
        <Text as="span" fontWeight="semibold">
          Production
        </Text>
        .{" "}
        <Link
          href="https://docs.bucket.co/product-handbook/environments"
          target="_blank"
        >
          Learn more
        </Link>
      </AlertDescription>
    </Alert>
  );
};

export const CreateAffectEnvironments = ({ entity, ...rest }: Props) => {
  const { currentEnv } = useAuthContext();
  if (currentEnv?.isProduction) return null;
  return (
    <Alert alignItems="flex-start" status="info" {...rest}>
      <AlertIcon mt={1}>
        <EnvironmentsIcon />
      </AlertIcon>
      <AlertDescription>
        <Text as="span" fontWeight="semibold">
          Heads-up!
        </Text>{" "}
        {startCase(entity)} in Bucket are shared across all environments.{" "}
        <Link
          href="https://docs.bucket.co/product-handbook/environments"
          target="_blank"
        >
          Learn more
        </Link>
      </AlertDescription>
    </Alert>
  );
};

export const OnlyProductionEnvironment = ({ entity, ...rest }: Props) => {
  const { currentEnv } = useAuthContext();
  if (currentEnv?.isProduction) {
    return null;
  }

  return (
    <Alert alignItems="flex-start" status="info" {...rest}>
      <AlertIcon mt={1}>
        <EnvironmentsIcon />
      </AlertIcon>
      <AlertDescription>
        <Text as="span" fontWeight="semibold">
          Heads-up!
        </Text>{" "}
        {startCase(entity)} are only available in the production environment.{" "}
        <Link
          href="https://docs.bucket.co/product-handbook/environments"
          target="_blank"
        >
          Learn more
        </Link>
      </AlertDescription>
    </Alert>
  );
};

export const AffectsSpecificEnvironment = ({ entity, ...rest }: Props) => {
  const { currentEnv } = useAuthContext();
  if (currentEnv?.isProduction) {
    return null;
  }

  return (
    <Alert alignItems="flex-start" status="info" {...rest}>
      <AlertIcon mt={1}>
        <EnvironmentsIcon />
      </AlertIcon>
      <AlertDescription>
        <Text as="span" fontWeight="semibold">
          Heads-up!
        </Text>{" "}
        {startCase(entity)} settings are defined at an environment level.{" "}
        <Link
          href="https://docs.bucket.co/product-handbook/environments"
          target="_blank"
        >
          Learn more
        </Link>
      </AlertDescription>
    </Alert>
  );
};
