import { useEffect, useState } from "react";
import { RiCheckFill, RiMore2Fill } from "react-icons/ri";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { AppDTO } from "@bucketco/shared/appAPI";
import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { Org } from "@bucketco/shared/organizationAPI";
import { EnvironmentUrl } from "@bucketco/shared/urls";

import { useAdminDemoAppDeleteMutation } from "@/admin/data/useAdminDemoAppDeleteMutation";
import { useAdminRunAppScenarioMutation } from "@/admin/data/useAdminRunAppScenarioMutation";
import { useAuthContext } from "@/auth/contexts/authContext";
import SimpleSelect from "@/common/components/SimpleSelect";
import api from "@/common/utils/api";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

export default function AdminOrgApps({ org }: { org: Org }) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>App</Th>
          <Th>Publishable key</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {org.apps.map((app) => (
          <AppRow
            key={app.id}
            app={app}
            env={app.environments.find((env) => env.isProduction)!}
            org={org}
          />
        ))}
      </Tbody>
    </Table>
  );
}

function AppRow({
  org,
  app,
  env,
}: {
  org: Org;
  app: AppDTO;
  env: EnvironmentDTO;
}) {
  const { setActiveOrg } = useAuthContext();
  const appDeleteMutation = useAdminDemoAppDeleteMutation(org.id, app.id);
  const releasesDemoDisclosure = useDisclosure();

  const [demoAppUserId, setDemoAppUserId] = useState(crypto.randomUUID());

  const demoAppUrl = new URL("https://live-satisfaction-demo.vercel.app/");
  demoAppUrl.searchParams.set(
    "publishableKey",
    app.environments[0].publishableKey,
  );
  demoAppUrl.searchParams.set("userId", demoAppUserId);

  return (
    <Tr key={app.id}>
      <Td>
        <Flex alignItems="center">
          <Link
            href={EnvironmentUrl(app.environments[0]!)}
            onClick={() => {
              setActiveOrg(org.id, { refresh: false });
            }}
          >
            {app.name}
          </Link>
          {app.demo ? <Badge mx="2">Demo</Badge> : null}
        </Flex>
      </Td>
      <Td>{env.publishableKey}</Td>
      <Td textAlign="right">
        <ButtonGroup>
          <Button
            colorScheme="red"
            isDisabled={!app.demo}
            size="sm"
            title={
              !app.demo ? "Only demo apps can be deleted by admins" : undefined
            }
            onClick={() => appDeleteMutation.mutate()}
          >
            Delete
          </Button>
          <Menu placement="bottom-end">
            <MenuButton
              aria-label="More options"
              as={IconButton}
              color="dimmed"
              icon={<RiMore2Fill />}
              size="sm"
              variant="ghost"
            />
            <MenuList>
              <MenuItem onClick={() => releasesDemoDisclosure.onOpen()}>
                Releases demo
              </MenuItem>
              <MenuItem
                as={Link}
                href={demoAppUrl.toString()}
                target="_blank"
                onClick={() => setDemoAppUserId(crypto.randomUUID())}
              >
                Open Slick demo app
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
      </Td>
      <ReleasesDemoModal
        app={app}
        disclosure={releasesDemoDisclosure}
        org={org}
      />
    </Tr>
  );
}

function ReleasesDemoModal({
  org,
  app,
  disclosure,
}: {
  org: Org;
  app: AppDTO;
  disclosure: ReturnType<typeof useDisclosure>;
}) {
  const [featureId, setFeatureId] = useState<string | undefined>(undefined);
  const { data, isLoading } = useQuery({
    queryKey: featureQueryKeys.listNames(app.id),

    queryFn: () =>
      api
        .get<"/apps/:appId/features/names">(`/apps/${app.id}/features/names`)
        .then((res) => res.data),
  });

  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      setFeatureId(data[0].id);
    }
  }, [data]);

  const appScenario1Mutation = useAdminRunAppScenarioMutation(
    org.id,
    app.id,
    "releases-events-1",
    { featureId: featureId ?? "" },
  );
  const appScenario2Mutation = useAdminRunAppScenarioMutation(
    org.id,
    app.id,
    "releases-events-2",
    { featureId: featureId ?? "" },
  );

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent gap={0} pb={8}>
        <ModalHeader>Releases demo</ModalHeader>
        <ModalBody>
          <Box pt={4}>
            <SimpleSelect
              isLoading={isLoading}
              options={data?.map((d) => ({ label: d.name, value: d.id })) ?? []}
              placeholder="Select a feature"
              value={featureId}
              onChange={(e) => setFeatureId(e)}
            />
          </Box>
          <Table>
            <Thead>
              <Th></Th>
              <Th></Th>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <Text fontWeight="bold">Step 1</Text>
                  <Text>All: 15% / 5% / 0%</Text>
                  <Text>Business: 2% / 1% / 0%</Text>
                </Td>
                <Td>
                  <Button
                    isDisabled={!app.demo || !featureId}
                    rightIcon={
                      appScenario1Mutation.isPending ? (
                        <Spinner size="xs" />
                      ) : appScenario1Mutation.isSuccess ? (
                        <RiCheckFill />
                      ) : undefined
                    }
                    size="sm"
                    title={
                      !app.demo
                        ? "Only demo apps can have scenarios run"
                        : undefined
                    }
                    onClick={() => appScenario1Mutation.mutate()}
                  >
                    Run
                  </Button>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text fontWeight="bold">Step 2</Text>
                  <Text>All: 67% / 51% / 44%</Text>
                  <Text>Business: 10% / 8% / 6%</Text>
                </Td>
                <Td>
                  <Button
                    isDisabled={!app.demo || !featureId}
                    rightIcon={
                      appScenario2Mutation.isPending ? (
                        <Spinner size="xs" />
                      ) : appScenario2Mutation.isSuccess ? (
                        <RiCheckFill />
                      ) : undefined
                    }
                    size="sm"
                    title={
                      !app.demo
                        ? "Only demo apps can have scenarios run"
                        : undefined
                    }
                    onClick={() => appScenario2Mutation.mutate()}
                  >
                    Run
                  </Button>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
