import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { CreateOrgArgsType, Org } from "@bucketco/shared/organizationAPI";

import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export function useOrgCreateMutation() {
  return useMutation<Org, AxiosError<ErrorResponse>, CreateOrgArgsType>({
    mutationFn: (data) =>
      api.post<"/orgs">("/orgs", data).then(async (res) => {
        const org = res.data.org;

        try {
          await trackOrgCreation(org);
        } catch {
          // Not much we can do for a failing side-effect
        }

        return org;
      }),
  });
}

// Browser tracking destinations only tracking of "Account Created"
// Keep the destinations opt-in here to avoid duplicate tracking on cloud
function trackOrgCreation(org: Org) {
  return segmentAnalytics.track(
    "Account Created",
    {
      properties: {
        account_name: org.name,
      },
      context: {
        groupId: org.id,
      },
    },
    {
      integrations: {
        // Avoid duplicating this event on cloud destinations
        All: false,
        // Enable for all browser-only destinations
        "Twitter Ads": true,
        "LinkedIn conversion tracking": true,
      },
    },
  );
}
