import { useColorModeValue, useToken } from "@chakra-ui/react";

import { useStarsFunnelColors } from "@/feature/hooks/useStarsFunnelColors";

export type useChartColorTokens = ReturnType<typeof useChartTokens>["colors"];

export default function useChartTokens() {
  const [
    gridColor,
    axisColor,
    axisLabelColor,
    lineColor,
    primaryColor,
    primaryLightColor,
    triedItColor,
    triedItLightColor,
    neutralColor,
    neutralLightColor,
    neutralVeryLightColor,
    problemColor,
    audienceColor,
    audienceLightColor,
    activeColor,
    activeLightColor,
    tooltipBgColor,
    tooltipBorderColor,
    highlightBgColor,
    dotFillColor,
    scatterDotLabelColor,
    cursorColor,
    incompleteBarColor,
    referenceLabelBgColor,
    referenceLabelBorderColor,
    referenceLabelTextColor,
    partialHighlightColor,
    evaluatingColor,
  ] = useToken("colors", [
    useColorModeValue("gray.300", "gray.700"),
    useColorModeValue("gray.400", "gray.600"),
    useColorModeValue("gray.800", "whiteAlpha.900"),
    useColorModeValue("blackAlpha.700", "whiteAlpha.900"),
    useColorModeValue("brand.500", "brand.400"),
    useColorModeValue("brand.200", "brand.800"),
    useColorModeValue("yellow.500", "yellow.500"),
    useColorModeValue("yellow.200", "yellow.800"),
    useColorModeValue("gray.400", "gray.600"),
    useColorModeValue("gray.300", "gray.550"),
    useColorModeValue("gray.200", "gray.700"),
    useColorModeValue("red.200", "red.400"),
    useColorModeValue("brand.500", "brand.500"),
    useColorModeValue("brand.300", "brand.800"),
    useColorModeValue("green.500", "green.500"),
    useColorModeValue("green.300", "green.800"),
    useColorModeValue("white", "gray.800"),
    useColorModeValue("gray.250", "gray.650"),
    useColorModeValue("orange.200", "yellow.900"),
    useColorModeValue("white", "gray.800"),
    useColorModeValue("white", "gray.800"),
    "gray.500",
    useColorModeValue("white", "gray.900"),
    useColorModeValue("white", "gray.750"),
    useColorModeValue("gray.400", "gray.600"),
    useColorModeValue("gray.600", "gray.400"),
    useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
    useColorModeValue("yellow.500", "orange.300"),
  ]);

  const { triedColor: triedColorMode, retainedColor: retainedColorMode } =
    useStarsFunnelColors();

  const [triedColor, retainedColor] = useToken("colors", [
    triedColorMode,
    retainedColorMode,
  ]);

  const [auditMatrixBad, auditMatrixGood, auditMatrixNeutral] = useToken(
    "colors",
    [
      useColorModeValue("orange.200", "orange.900"),
      useColorModeValue("brand.200", "brand.900"),
      useColorModeValue("gray.200", "gray.900"),
    ],
  );

  const [axisLabelFontSize] = useToken("fontSizes", ["xs"]);
  const [tooltipFontSize] = useToken("fontSizes", ["sm"]);

  const [tooltipRadii] = useToken("radii", ["md"]);

  return {
    colors: {
      grid: gridColor,
      axis: axisColor,
      axisLabel: axisLabelColor,
      line: lineColor,
      primary: primaryColor,
      primaryLight: primaryLightColor,
      triedIt: triedItColor,
      triedItLight: triedItLightColor,
      neutral: neutralColor,
      neutralLight: neutralLightColor,
      neutralVeryLight: neutralVeryLightColor,
      problem: problemColor,
      audience: audienceColor,
      audienceLight: audienceLightColor,
      active: activeColor,
      activeLight: activeLightColor,
      evaluating: evaluatingColor,
      tooltipBg: tooltipBgColor,
      tooltipBorder: tooltipBorderColor,
      highlightBg: highlightBgColor,
      dotFill: dotFillColor,
      scatterDotLabel: scatterDotLabelColor,
      cursor: cursorColor,
      incompleteBar: incompleteBarColor,
      auditMatrixBad,
      auditMatrixGood,
      auditMatrixNeutral,
      tried: triedColor,
      retained: retainedColor,
      referenceLabelBg: referenceLabelBgColor,
      referenceLabelBorder: referenceLabelBorderColor,
      referenceLabelText: referenceLabelTextColor,
      partialHighlight: partialHighlightColor,
    },
    fontSizes: {
      axisLabel: axisLabelFontSize,
      tooltip: tooltipFontSize,
    },
    radii: {
      tooltip: tooltipRadii,
    },
    sizes: {
      yAxisWidth: 52,
      lineStrokeWidth: 3,
    },
    properties: {
      dot: {
        r: 3,
        fill: dotFillColor,
        stroke: primaryColor,
        strokeDasharray: "0",
      },
      activeDot: {
        r: 4.5,
        stroke: dotFillColor,
        strokeWidth: 1.5,
      },
    },
  };
}
