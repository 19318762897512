import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { calc, createMultiStyleConfigHelpers } from "@chakra-ui/react";

import { buttonSizes, focusResetStyle } from "@/theme/helpers";

const numberInputStepperSize = {
  stepper: {
    fontSize: calc("1rem").multiply(0.5).toString(),
  },
};

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  numberInputAnatomy.keys,
);

export const NumberInputTheme = defineMultiStyleConfig({
  defaultProps: {
    size: "sm",
  },
  variants: {
    outline: {
      field: {
        _focus: focusResetStyle as any, // TS doesn't like the null values for some reason
      },
    },
  },
  sizes: {
    xs: {
      field: {
        ...buttonSizes.xs,
      },
    },
    sm: {
      ...numberInputStepperSize,
      field: {
        ...buttonSizes.sm,
        borderRadius: "md",
      },
    },
    md: {
      ...numberInputStepperSize,
      field: {
        ...buttonSizes.md,
      },
    },
    lg: {
      field: {
        ...buttonSizes.lg,
      },
    },
  },
});
