import React, { useEffect, useState } from "react";
import { Spinner, SpinnerProps } from "@chakra-ui/react";
import { useSpinDelay } from "spin-delay";

export function LoadingSpinner({
  variant,
  delay = 300,
  minDuration = 200,
  ...spinnerProps
}: {
  variant?: "subtle";
  delay?: number;
  minDuration?: number;
} & SpinnerProps) {
  const [isLoading, setIsLoading] = useState(false);
  const showSpinner = useSpinDelay(isLoading, { delay, minDuration });

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const variantProps =
    variant === "subtle"
      ? {
          color: "dimmed",
          size: "xs",
        }
      : undefined;

  return showSpinner ? (
    <Spinner size="sm" {...variantProps} {...spinnerProps} />
  ) : null;
}
