import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RiAddLine, RiProhibitedLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Spinner,
  Switch,
  Text,
} from "@chakra-ui/react";

import { AttributeField } from "@bucketco/shared/attributeFilter";
import { EnvironmentListItemDTO } from "@bucketco/shared/environmentAPI";
import {
  emptyFilter,
  getFilterCount,
  UIFilterType,
  wrapWithFilterGroup,
} from "@bucketco/shared/filter";
import { TargetingFilterGroup } from "@bucketco/shared/filterAPI";
import { CreateFlagRuleArgs } from "@bucketco/shared/flagAPI";

import ApproximatelySvg from "@/common/assets/approximately.svg?react";
import { AndOrList } from "@/common/components/AndOrList";
import CardContainer from "@/common/components/CardContainer";
import { DeleteIconButton } from "@/common/components/CommonIconButtons";
import {
  FilterGroup,
  SupportedFlagFilterTypes,
} from "@/common/components/filters/FilterGroup";
import FormNumberInput from "@/common/components/Form/FormNumberInput";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { useEstimateTargetAudience } from "@/common/data/useEstimateTargetAudience";
import pluralize from "@/common/utils/pluralize";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";

const ApproximatelyIcon = chakra(ApproximatelySvg);

function adjustFlagAttributeFields(
  filterType: UIFilterType,
  fields: AttributeField[],
) {
  if (filterType === "userAttribute") {
    fields = [
      {
        key: "id",
        label: "User ID",
        type: "text",
        icon: "id",
        system: true,
      },
      ...fields.filter((f) => f.key !== "id"),
    ];
  }

  return fields;
}

const SegmentStatelessRestrictionsWarning = () => (
  <Text fontSize="xs" lineHeight={1.5}>
    Segments with <strong>First seen</strong>, <strong>Last seen</strong> or{" "}
    <strong>Feature metric</strong> filters are not supported for targeting
    rules.
  </Text>
);

type RuleFormProps = {
  baseName: string;
  remove: () => void;
};

function RuleForm({ baseName = "rules", remove }: RuleFormProps) {
  const { setValue } = useFormContext();
  const rollout = useWatch({
    name: `${baseName}.partialRolloutThreshold`,
  });
  const [showRollout, setShowRollout] = useState(rollout < 100000);
  return (
    <Box as="fieldset">
      <Flex>
        <ManagedFormControl
          _inputGroup={{
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          name={`${baseName}.filter`}
          render={({ field: { value, onChange } }) => (
            <FilterGroup
              adjustAttributeFields={adjustFlagAttributeFields}
              context="flag"
              segmentItemFilter={(s) => s.stateless}
              segmentItemFilterReason={() => (
                <SegmentStatelessRestrictionsWarning />
              )}
              showItemsIcons={true}
              types={SupportedFlagFilterTypes}
              value={wrapWithFilterGroup(value)}
              flatten
              onChange={onChange}
            />
          )}
        />
        <FormControl
          alignItems="center"
          display="flex"
          height="28px"
          mr={2}
          mt={0.5}
          w="auto"
        >
          <FormLabel
            cursor="pointer"
            fontSize="sm"
            htmlFor={`${baseName}.gradual`}
            mr={2}
            pb={0}
          >
            Gradual
          </FormLabel>
          <Switch
            id={`${baseName}.gradual`}
            isChecked={showRollout}
            size="sm"
            onChange={(e) => {
              setShowRollout(e.target.checked);
              if (!e.target.checked) {
                setValue(`${baseName}.partialRolloutThreshold`, 100000, {
                  shouldDirty: true,
                });
              }
            }}
          />
        </FormControl>
        <DeleteIconButton
          color="dimmed"
          label="Remove"
          mt={0.5}
          onClick={() => remove()}
        />
      </Flex>

      {showRollout && (
        <Flex
          alignItems="center"
          borderTopColor="appBorder"
          borderTopWidth={1}
          gap={4}
          mt={2}
          pt={2}
        >
          <FormNumberInput
            _control={{ w: 24 }}
            _label={{ fontWeight: "medium" }}
            fontSize="sm"
            max={100}
            min={0}
            name={`${baseName}.partialRolloutThreshold`}
            scaleFactor={0.001} // 0-100% = 0-100000
            unit="%"
          />
          <Text color="dimmed" fontSize="sm">
            Roll-out to a part of companies matched by the filters
          </Text>
        </Flex>
      )}
    </Box>
  );
}

type RulesFormProps = {
  baseName: string;
  environment: EnvironmentListItemDTO;
  fields: (CreateFlagRuleArgs & { id: string })[];
  version?: number;
  append: (value: CreateFlagRuleArgs) => void;
  remove: (index: number) => void;
};

export function RulesForm({
  baseName = "rules",
  environment,
  fields,
  version,
  append,
  remove,
}: RulesFormProps) {
  const rules: TargetingFilterGroup =
    useWatch({
      name: baseName,
    })?.filter(
      ({ filter }: TargetingFilterGroup[number]) => getFilterCount(filter) > 0,
    ) ?? [];
  const {
    data: estimate,
    isLoading: isLoadingEstimate,
    isSuccess: isSuccessEstimate,
  } = useEstimateTargetAudience(
    {
      envIds: [environment.id],
      filterGroups: [rules],
    },
    {
      enabled: rules.length > 0,
      select: (data) => data[environment.id]?.filterGroups[0] ?? 0,
    },
  );

  return (
    <CardContainer>
      <Flex direction="row" justify="space-between" px={3}>
        <Flex alignItems="flex-end" direction="row" gap={2}>
          <EnvironmentDisplayName environment={environment} />
          {version && (
            <Text
              as={Link}
              color="dimmed"
              fontSize="sm"
              textDecoration="underline"
              to={`versions?environment=${environment.id}`}
            >
              v{version}
            </Text>
          )}
        </Flex>
        {isLoadingEstimate ? (
          <Spinner color="dimmed" size="sm" />
        ) : isSuccessEstimate ? (
          <Flex align="center" direction="row" gap={1}>
            <ApproximatelyIcon
              boxSize={3.5}
              color="dimmed"
              display="inline-block"
            />
            <Text as="span" fontSize="sm">
              {estimate}
            </Text>
            <Text as="span" color="dimmed" fontSize="sm">
              {pluralize("company", estimate, "companies")}
            </Text>
          </Flex>
        ) : null}
      </Flex>

      {fields.length === 0 ? (
        <HStack color="dimmed" mt={2} px={3} spacing={1.5}>
          <RiProhibitedLine />
          <Text fontSize="sm">No one has access</Text>
        </HStack>
      ) : (
        <AndOrList
          conjunction="or"
          conjunctionProps={{ ml: 4, my: -1, zIndex: 1 }}
          direction="vertical"
          gap="0"
        >
          {fields.map(({ id }, index) => (
            <Card key={id} p={3} w="full">
              <RuleForm
                baseName={`${baseName}.${index}`}
                remove={() => remove(index)}
              />
            </Card>
          ))}
        </AndOrList>
      )}

      <Box position="relative">
        <ButtonGroup display="flex" position="relative">
          <Button
            color="dimmed"
            leftIcon={<RiAddLine size={16} />}
            size="sm"
            variant="ghost"
            onClick={() =>
              append({
                partialRolloutThreshold: 100000,
                filter: emptyFilter,
              })
            }
          >
            Add rule
          </Button>
        </ButtonGroup>
      </Box>
    </CardContainer>
  );
}
