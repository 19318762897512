import { useState } from "react";
import { Box, Button, HStack, Text } from "@chakra-ui/react";

export default function ExpandableTextCell({ value }: { value: string }) {
  const [showFull, setShowFull] = useState(false);
  const isOverLimit = value.length > 240;
  const limitedValue = value.slice(0, 240);

  return (
    <HStack align="flex-start" spacing={0.5}>
      <Text as="span" color="gray.500">
        &quot;
      </Text>
      <Box>
        <Box as="span" mr={isOverLimit ? 2 : undefined}>
          <Text as="span">
            {!isOverLimit || showFull ? value : `${limitedValue}...`}
          </Text>
          {(!isOverLimit || showFull) && (
            <Text as="span" color="gray.500" ml={0.5}>
              &quot;
            </Text>
          )}
        </Box>
        {isOverLimit && (
          <Button
            color="gray.500"
            fontSize="xs"
            type="button"
            variant="link"
            onClick={() => setShowFull((cur) => !cur)}
          >
            {showFull ? "Show less" : "Show more"}
          </Button>
        )}
      </Box>
    </HStack>
  );
}
