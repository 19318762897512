import { useMemo } from "react";
import { Alert, AlertDescription } from "@chakra-ui/react";

import { Dependee, UIFilter } from "@bucketco/shared/filter";

import { AndOrList } from "@/common/components/AndOrList";
import { useFilterCheck } from "@/common/data/useFilterCheck";
import pluralize from "@/common/utils/pluralize";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";

type Props = {
  dependee: Dependee;
  filter: UIFilter;
};

export const ActiveFeaturesAlert = ({ dependee, filter }: Props) => {
  const {
    data: checkDetails,
    isLoading: isChecking,
    isError: checkError,
  } = useFilterCheck(dependee, filter);

  const canUpdateSegment = useMemo(() => {
    if (isChecking || checkError) {
      return false;
    }

    if (
      checkDetails?.cycle ||
      (checkDetails?.features.length && !checkDetails?.stateless)
    ) {
      return false;
    }

    return true;
  }, [checkDetails, isChecking, checkError]);

  const features = checkDetails?.features;

  if (!features?.length) {
    return null;
  }

  // todo: render error

  return (
    <Alert alignItems="flex-start" maxW="sm" status="warning">
      <AlertDescription lineHeight={1.5}>
        The {pluralize("feature", features.length)}{" "}
        <AndOrList
          conjunction="and"
          direction="horizontal"
          display="inline-flex"
          verticalAlign="bottom"
        >
          {features.map((f) => (
            <FeatureDisplay key={f.id} display="inline-flex" feature={f} link />
          ))}
        </AndOrList>{" "}
        {pluralize("is", features.length)} used by this segment.{" "}
        {!canUpdateSegment ? (
          <>
            which includes prohibited <strong>First seen</strong>,{" "}
            <strong>Last seen</strong> or <strong>feature metrics</strong>{" "}
            filters. Remove these to update or create a new segment.
          </>
        ) : (
          <>
            Filter changes may affect the number of companies with access to the{" "}
            {pluralize("feature", features.length)}. Review the filters before
            saving or create a new segment to avoid unexpected changes.
          </>
        )}
      </AlertDescription>
    </Alert>
  );
};
