const qk = {
  list: (appId?: string, params?: Record<string, any>) =>
    params
      ? (["apps", appId, "stages", params] as const)
      : (["apps", appId, "stages"] as const),

  single: (appId?: string, stageId?: string) =>
    [...qk.list(appId), stageId] as const,
};

export { qk as stageQueryKeys };
