/**
 * A safe fraction calculator that avoids division by zero
 * and replaces nullish values with zero
 */
export function getFraction(
  part: number | undefined | null,
  whole: number | undefined | null,
): number {
  if (typeof whole !== "number") {
    whole = 0;
  }

  if (typeof part !== "number") {
    part = 0;
  }

  return whole === 0 ? 0 : part / whole;
}
