import { FormEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";

import { AdminCreateOrgType } from "@bucketco/shared/adminAPI";
import { AdminUrl } from "@bucketco/shared/urls";

import AdminOrgSwitcher from "@/admin/components/AdminOrgSwitcher";
import { useAdminOrgCreateMutation } from "@/admin/data/useAdminOrgCreateMutation";

export default function AdminIndex() {
  const createOrgMutation = useAdminOrgCreateMutation();
  const navigate = useNavigate();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    createOrgMutation.mutate(
      Object.fromEntries(formData.entries()) as AdminCreateOrgType,
      {
        onSuccess(data) {
          navigate(AdminUrl(data.id));
        },
      },
    );
  };

  return (
    <Box as="main" marginX="auto" maxW="compactForm" padding={4}>
      <Flex direction="column" gap={4}>
        <Heading as="h1">Bucket Admin</Heading>
        <Text fontSize="lg">
          Select an organization to administrate, or create a new one with you
          as the first member
        </Text>

        <Box>
          <FormLabel htmlFor="admin-org-switcher">Go to organization</FormLabel>
          <AdminOrgSwitcher id="admin-org-switcher" size="lg" />
        </Box>

        <Divider />

        <form onSubmit={handleSubmit}>
          <Card p={4}>
            <Flex direction="column" gap={2}>
              <Box>
                <FormLabel htmlFor="org-name">Organization name</FormLabel>
                <Input
                  autoComplete="off"
                  id="org-name"
                  minLength={1}
                  name="name"
                  placeholder="Acme Inc."
                  required
                />
              </Box>

              <ButtonGroup>
                <Button type="submit">Create new organization</Button>
              </ButtonGroup>
            </Flex>
          </Card>
        </form>
      </Flex>
    </Box>
  );
}
