import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { FeatureDetail } from "@bucketco/shared/featureAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

export function useFeatureData(featureId?: string | null) {
  const { appId, envId } = useCurrentEnv();
  return useQuery<FeatureDetail, AxiosError<ErrorResponse>>({
    queryKey: featureQueryKeys.single(appId, featureId!),

    queryFn: () =>
      api
        .get<"/apps/:appId/features/:featureId">(
          `/apps/${appId}/features/${featureId}`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data.feature),
    enabled: !!appId && !!featureId,
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useFeatureKeys() {
  const { appId } = useCurrentEnv();
  return useQuery({
    queryKey: featureQueryKeys.keys(appId),
    queryFn: () =>
      api
        .get<"/apps/:appId/features/keys">(`/apps/${appId}/features/keys`)
        .then((res) => res.data),
    enabled: !!appId,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
