import { Box, HStack, useColorModeValue } from "@chakra-ui/react";

import { FunnelStep } from "@bucketco/shared/featureAPI";

type StarsFunnelStepVisualizationProps = {
  step: FunnelStep;
  count?: number;
};

const stepToIndex = {
  company: 0,
  segment: 1,
  tried: 2,
  adopted: 3,
  retained: 4,
};

export function StarsFunnelStepVisualization({
  step,
  count = 3,
}: StarsFunnelStepVisualizationProps) {
  const [reachedColor, dropOffColor, unreachedColor] = useColorModeValue(
    ["brand.300", "red.300", "gray.100"],
    ["brand.500", "red.500", "gray.600"],
  );
  return (
    <HStack alignItems="flex-end" height={2} spacing="1px">
      {Array.from({ length: count }, (_, i) => (
        <Box
          key={i}
          bg={
            i + 1 < stepToIndex[step]
              ? reachedColor
              : i + 1 === stepToIndex[step]
              ? dropOffColor
              : unreachedColor
          }
          borderRadius="1px"
          height={`${100 - (30 * i) / count}%`}
          width={1}
        />
      ))}
    </HStack>
  );
}
