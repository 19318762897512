import { ReactNode, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { feedbackQueryKeys } from "@/feedback/data/feedbackQueryKeys";

export default function DeleteFeedbackDialog({
  feedbackId,
  children,
}: {
  feedbackId: string;
  children: (props: { openDialog: () => void }) => ReactNode;
}) {
  const { envId, appId } = useCurrentEnv();
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();

  const { mutate: deleteMutate, isPending: isDeleteLoading } = useMutation({
    mutationFn: () =>
      api.delete<"/apps/:appId/feedback/:feedbackId">(
        `/apps/${appId}/feedback/${feedbackId}`,
        { params: { envId: envId! } },
      ),

    onSuccess: () => {
      segmentAnalytics.track("Feedback Deleted");
      queryClient.invalidateQueries({
        queryKey: feedbackQueryKeys.list(appId, envId),
      });
      toast({
        title: "Feedback entry deleted",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      onClose();
    },

    onError: () => {
      errorToast({
        description: "Couldn't delete feedback",
      });
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  return (
    <>
      {children({ openDialog: onOpen })}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete feedback</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this?
              <br />
              <br />
              You can&apos;t undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelRef}
                  _hover={{ color: useColorModeValue("gray.700", "gray.300") }}
                  color="gray.500"
                  isDisabled={isDeleteLoading}
                  variant="ghost"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={isDeleteLoading}
                  onClick={() => deleteMutate()}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
