import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  alertAnatomy.keys,
);

export const AlertTheme = defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: "md",
    },
  },
  sizes: {
    sm: {
      container: {
        fontSize: "sm",
        px: 3,
        py: 2,
      },
      icon: {
        w: 4,
        h: 5,
        marginEnd: 2,
      },
    },
  },
  defaultProps: {
    size: "sm",
  },
});
