import { Box } from "@chakra-ui/react";

import Attributes from "@/company/components/CompanyAttributes";

export function CompanyAttributes() {
  return (
    <Box px={6} py={6}>
      <Attributes />
    </Box>
  );
}
