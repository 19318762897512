import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { SubsegmentQueryType } from "@bucketco/shared/featureAPI";
import { FeatureFeedbackHistogram } from "@bucketco/shared/featureFeedbackAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { featureQueryKeys } from "./featureQueryKeys";

export function useFeatureFeedbackHistogramData(
  featureId?: string,
  params?: SubsegmentQueryType,
) {
  const { appId, envId } = useCurrentEnv();

  return useQuery<FeatureFeedbackHistogram, AxiosResponse<ErrorResponse>>({
    queryKey: featureQueryKeys.singleFeatureFeedbackHistogram(
      appId,
      envId,
      featureId,
      params,
    ),

    queryFn: () =>
      api
        .get<"/apps/:appId/features/:featureId/feedback/histogram">(
          `/apps/${appId}/features/${featureId}/feedback/histogram`,
          { params: { ...params, envId: envId! } },
        )
        .then((res) => res.data),
    enabled: !!appId && !!envId && !!featureId,
  });
}
