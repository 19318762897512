import { ForwardedRef, forwardRef, ReactNode } from "react";
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  useColorModeValue,
  useFormControl,
} from "@chakra-ui/react";

export type ButtonDefinition<T extends string = string> = {
  id: T;
  label: string | ReactNode;
  isDisabled?: boolean;
  leftIcon?: ButtonProps["leftIcon"];
};

export type SwitchButtonGroupProps<T extends string = string> = Omit<
  ButtonGroupProps,
  "onChange" | "onFocus" | "onBlur"
> & {
  buttons: Array<ButtonDefinition<T>>;
  value?: T | null;
  onChange?: (value: T, prevValue: T | null) => void;
  onFocus?: ButtonProps["onFocus"];
  onBlur?: ButtonProps["onBlur"];
};

function SwitchButtonGroupInner<T extends string = string>(
  {
    buttons,
    value,
    onChange,
    onFocus,
    onBlur,
    ...rest
  }: SwitchButtonGroupProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const formControlProps = useFormControl<HTMLButtonElement>({
    ...rest,
    onFocus,
    onBlur,
  });

  const activeColor = useColorModeValue("gray.900", "gray.50");
  const activeBg = useColorModeValue("gray.50", "gray.800");
  const inactiveColor = useColorModeValue("gray.500", "gray.500");

  return (
    <ButtonGroup as="section" variant="outline" isAttached {...rest}>
      {buttons.map(({ id, label, isDisabled, leftIcon }, index) => (
        <Button
          key={id}
          ref={index === 0 ? ref : undefined}
          aria-pressed={value === id}
          bg={value === id ? activeBg : undefined}
          color={value === id ? activeColor : inactiveColor}
          isDisabled={isDisabled || formControlProps.disabled}
          leftIcon={leftIcon ?? undefined}
          onBlur={formControlProps.onBlur}
          onClick={() => onChange?.(id, value ?? null)}
          onFocus={formControlProps.onFocus}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export const SwitchButtonGroup = forwardRef(SwitchButtonGroupInner) as <
  T extends string = string,
>(
  props: SwitchButtonGroupProps<T> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof SwitchButtonGroupInner>;
