import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { CompanyFeaturesQuery } from "@bucketco/shared/companyAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { getItemTree } from "@/common/utils/listItemTree";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";

export function useCompanyFeaturesData(
  companyId?: string,
  params?: Omit<CompanyFeaturesQuery, "envId">,
  enabled?: boolean,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: companyQueryKeys.listFeatures(appId, envId, companyId, params),
    queryFn: () =>
      api
        .get<"/apps/:appId/companies/:companyId/features">(
          `/apps/${appId}/companies/${encodeURIComponent(companyId!)}/features`,
          {
            params: { ...params, envId: envId! },
          },
        )
        .then((res) => res.data),
    select({ data, ...rest }) {
      return {
        data:
          rest.metadata.sortType === "hierarchical"
            ? getItemTree(data, "parentFeatureId")
            : data,
        ...rest,
      };
    },
    placeholderData: keepPreviousData,
    enabled: !!appId && !!envId && !!companyId && enabled,
  });
}
