import {
  Flex,
  FlexProps,
  Grid,
  GridItem,
  GridItemProps,
  GridProps,
  Table,
  TableProps,
} from "@chakra-ui/react";

import ErrorBoundary from "@/common/components/ErrorBoundary";

type SidebarLayoutProps = GridProps & {
  sidebarContent: React.ReactNode;
  sidebarProps?: GridItemProps;
};

export default function SidebarLayout({
  children,
  sidebarContent,
  sidebarProps,
  ...props
}: SidebarLayoutProps) {
  return (
    <Grid
      h="100%"
      templateColumns="repeat(3, 1fr) clamp(230px, 20%, 270px)"
      w="100%"
      {...props}
    >
      <GridItem
        borderRight="1px solid"
        borderRightColor="appBorder"
        colSpan={3}
        display="flex"
        flexGrow={1}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </GridItem>
      <GridItem as="aside" colSpan={1} {...sidebarProps}>
        <ErrorBoundary>{sidebarContent}</ErrorBoundary>
      </GridItem>
    </Grid>
  );
}

export function SidebarContainer({ children, ...rest }: FlexProps) {
  return (
    <Flex direction="column" gap={6} p={6} {...rest}>
      {children}
    </Flex>
  );
}

export function SidebarSection({ children, ...rest }: FlexProps) {
  return (
    <Flex direction="column" gap={4} {...rest}>
      {children}
    </Flex>
  );
}

export function SidebarTable({ children, ...props }: TableProps) {
  return (
    <Table
      {...props}
      fontSize="sm"
      size="xs"
      sx={{
        td: {
          py: 1,
        },
      }}
      variant="unstyled"
    >
      {children}
    </Table>
  );
}
