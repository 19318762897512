import { useQuery } from "@tanstack/react-query";

import api from "@/common/utils/api";

export function useOrgsData() {
  return useQuery({
    queryKey: ["orgs"],
    queryFn: () => api.get<"/orgs">("/orgs").then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity,
  });
}
