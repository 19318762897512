import { useQuery } from "@tanstack/react-query";

import { featureViewQueryKeys } from "@/app/data/featureViewQueryKeys";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

export function useFeatureViewsData() {
  const { appId } = useCurrentEnv();

  return useQuery({
    queryKey: featureViewQueryKeys.list(appId),

    queryFn: () =>
      api
        .get<"/apps/:appId/feature-views">(`/apps/${appId}/feature-views`)
        .then((res) => res.data),
    enabled: !!appId,
  });
}
