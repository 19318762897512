import React from "react";
import {
  Link,
  LinkProps,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

/**
 * This component is adapted from https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx
 * to add support for also matching specific url search params
 * as part of evaluating of a link is currently active
 */
export const NavLink = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & {
    params: Record<string, string>;
    matchSearchParams?: Array<string>;
    end?: boolean;
  }
>(function NavLinkWithRef(
  {
    "aria-current": ariaCurrentProp = "page",
    className: classNameProp = "",
    to,
    children,
    matchSearchParams = [],
    end = true,
    ...rest
  },
  ref,
) {
  const path = useResolvedPath(to);
  const location = useLocation();

  const toPathname = path.pathname;
  const toParams = new URLSearchParams(path.search);
  const locationPathname = location.pathname;
  const locationSearch = new URLSearchParams(location.search);

  const matchesSearchParams =
    matchSearchParams.length > 0
      ? matchSearchParams.every(
          (key) => toParams.get(key) === locationSearch.get(key),
        )
      : Array.from(toParams.entries()).every(
          ([key, value]) => locationSearch.get(key) === value,
        );

  const matchesPath = end
    ? locationPathname === toPathname
    : locationPathname.startsWith(toPathname);

  const isActive = matchesPath && matchesSearchParams;

  const ariaCurrent = isActive ? ariaCurrentProp : undefined;

  const className = [classNameProp, isActive ? "active" : null]
    .filter(Boolean)
    .join(" ");

  return (
    <Link
      {...rest}
      ref={ref}
      aria-current={ariaCurrent}
      className={className}
      to={to}
    >
      {children}
    </Link>
  );
});
