import { forwardRef, useEffect, useMemo, useState } from "react";
import { Avatar, Flex, Text } from "@chakra-ui/react";

import { SEARCH_DEBOUNCE_MS } from "@bucketco/shared/constants";

import AutocompleteSelect, {
  AutocompleteSelectProps,
  highlightStringMatch,
} from "@/common/components/AutocompleteSelect";
import { useDebounce } from "@/common/hooks/useDebounce";
import {
  CompanySearchType,
  useCompanyAutosuggest,
} from "@/company/data/useCompanyAutosuggest";
import { useCompanyData } from "@/company/data/useCompanyData";

export type CompanyAutocompleteSelectProps = {
  value?: CompanySearchType & Record<string, any>;
  onChange: (value?: CompanySearchType) => void;
} & Omit<
  AutocompleteSelectProps<CompanySearchType>,
  | "value"
  | "onChange"
  | "isLoadingSuggestions"
  | "itemFilterFn"
  | "itemKeyProperty"
  | "itemRenderfn"
  | "suggestions"
  | "value"
  | "onChange"
  | "onClear"
  | "onSearchInput"
>;

export const CompanyAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  CompanyAutocompleteSelectProps
>(function CompanyAutocompleteSelect(
  {
    value: valueProp,
    onChange,
    placeholder = "Acme Inc.",
    ...autocompleteProps
  }: CompanyAutocompleteSelectProps,
  ref,
) {
  // The value prop might only have a company id, or the name might be outdated
  // Fetch just the company with that id and use it internally for correct displays
  const { data: valueEnhancement } = useSingleCompanySuggestion(valueProp?.id);
  useEffect(() => {
    if (valueProp && valueEnhancement) {
      if (
        valueEnhancement.id !== valueProp.id ||
        valueEnhancement.name !== valueProp.name
      ) {
        setValue(valueEnhancement);
      }
    }
  }, [valueProp, valueEnhancement]);

  const [value, setValue] = useState<CompanySearchType | undefined>(
    valueProp?.id === valueEnhancement?.id ? valueEnhancement : valueProp,
  );

  const [searchValue, setSearchValue] = useState<string>("");
  const companySearchValue = useDebounce(searchValue, SEARCH_DEBOUNCE_MS);

  const { data: companyData = [], isFetching } =
    useCompanyAutosuggest(companySearchValue);

  const companySuggestions = useMemo(() => {
    if (value) {
      return [value, ...companyData.filter((c) => c.id !== value?.id)];
    }

    return companyData;
  }, [companyData, value]);

  return (
    <AutocompleteSelect
      ref={ref}
      isLoadingSuggestions={isFetching}
      itemFilterFn={(c, search) => {
        return (
          c.name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()) ||
          c.id.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
      }}
      itemKeyProperty="id"
      itemRenderfn={(c, search) => (
        <Flex alignItems="center" gap={2} py={0.5} whiteSpace="nowrap">
          <Avatar name={c.name || c.id} size="2xs" />
          <Text>
            {search
              ? highlightStringMatch(c.name || c.id, search)
              : c.name || c.id}
          </Text>
          {c.name ? (
            <Text color="dimmedDisabled">
              ({search ? highlightStringMatch(c.id, search) : c.id})
            </Text>
          ) : null}
        </Flex>
      )}
      placeholder={placeholder}
      suggestions={companySuggestions}
      value={value}
      showClearButton
      onChange={(value) => {
        const newValue = value ?? undefined;
        setSearchValue("");
        setValue(newValue);
        onChange(newValue);
      }}
      onClear={() => {
        setValue(undefined);
      }}
      onSearchInput={setSearchValue}
      {...autocompleteProps}
    />
  );
});

CompanyAutocompleteSelect.displayName = "CompanyAutocompleteSelect";

function useSingleCompanySuggestion(companyId?: string) {
  return useCompanyData(companyId, {
    select(company) {
      return {
        id: company.id,
        name: company.name ?? undefined,
      };
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
