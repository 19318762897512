import { useParams } from "react-router-dom";

import SidebarLayout from "@/common/components/SidebarLayout";
import FeatureSidebar from "../components/FeatureSidebar";
import { useFeatureSidebar } from "../hooks/useFeatureSidebar";

type FeatureSideBarWrapperProps = {
  children: React.ReactNode;
};

export default function FeatureSideBarWrapper({
  children,
}: FeatureSideBarWrapperProps) {
  const { featureId } = useParams();
  const { isVisible } = useFeatureSidebar(featureId);

  if (!isVisible) {
    return children;
  }

  return (
    <SidebarLayout
      flexGrow={1}
      sidebarContent={<FeatureSidebar featureId={featureId} />}
    >
      {children}
    </SidebarLayout>
  );
}
