import {
  Box,
  Flex,
  FlexProps,
  LayoutProps,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";

import { IndentType } from "@/common/utils/tree";

export function TreeVisualization({
  indents,
  expandedDescenderHeight = "16px",
  ...flexProps
}: {
  indents: Array<IndentType>;
  expandedDescenderHeight?: LayoutProps["h"];
} & FlexProps) {
  const indentWidth = "20px";
  const borderColor = useToken(
    "colors",
    useColorModeValue("gray.200", "gray.650"),
  );

  const verticalLineGradient = `linear-gradient(90deg, transparent, transparent calc((${indentWidth} / 2) - var(--tree-viz-width) / 2), var(--tree-viz-color) calc((${indentWidth} / 2) - var(--tree-viz-width) / 2), var(--tree-viz-color) calc((${indentWidth} / 2) + var(--tree-viz-width) / 2), transparent calc((${indentWidth} / 2) + var(--tree-viz-width) / 2))`;
  const bendyBorder = (
    <Box
      borderBottomLeftRadius="lg"
      borderBottomWidth={"var(--tree-viz-width)"}
      borderColor={borderColor}
      borderLeftWidth={"var(--tree-viz-width)"}
      h={"calc(50% + 1px)"}
      ml={`calc(${indentWidth} / 2 - var(--tree-viz-width) / 2)`}
    />
  );

  const children = indents.map((indent, idx) => {
    switch (indent) {
      case ".":
        return <Box key={idx}></Box>;
      case "│":
        return <Box key={idx} bg={verticalLineGradient}></Box>;
      case "├":
        return (
          <Box key={idx} bg={verticalLineGradient}>
            {bendyBorder}
          </Box>
        );
      case "└":
        return <Box key={idx}>{bendyBorder}</Box>;
      case "▽":
        return (
          <Box
            key={idx}
            bg={verticalLineGradient}
            bottom="0"
            h={expandedDescenderHeight}
            position="absolute"
            right={`-${indentWidth}`}
          ></Box>
        );
    }
  });

  if (indents.length === 0) {
    return null;
  }

  return (
    <Flex
      flex="0 0 auto"
      mr="2px"
      pointerEvents="none"
      position="relative"
      sx={{
        "--tree-viz-color": borderColor,
        "--tree-viz-width": "2px",
        "& > *": { flex: "0 0 auto", w: indentWidth },
      }}
      aria-hidden
      {...flexProps}
    >
      {children}
    </Flex>
  );
}
