import { forwardRef } from "react";
import { Input, InputProps } from "@chakra-ui/react";

import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";

export type FormInputProps = FormElementBaseProps & Omit<InputProps, "value">;

const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  ({ _control, onChange, onBlur, ...rest }, ref) => {
    const [managedProps, inputProps] = useManagedFormControlProps(rest);
    return (
      <ManagedFormControl
        {..._control}
        {...managedProps}
        render={({ field }) => (
          <Input
            {...inputProps}
            {...field}
            ref={mergeRefs(field.ref, ref)}
            onBlur={mergeFunctions(field.onBlur, onBlur)}
            onChange={mergeFunctions(field.onChange, onChange)}
          />
        )}
      />
    );
  },
);
FormInput.displayName = "FormInput";

export default FormInput;
