import { FeatureName } from "@bucketco/shared/featureAPI";

export function getFeatureParentNames(
  features: Array<FeatureName>,
  featureId: string,
): Array<FeatureName> {
  const feature = features.find((f) => f.id === featureId);

  if (!feature || feature.parentFeatureId === null) {
    return [];
  }

  const hierarchy: Array<FeatureName> = [];
  let currentParentId: string | null = feature.parentFeatureId;

  while (currentParentId) {
    const parentFeature = features.find((f) => f.id === currentParentId);

    if (!parentFeature) {
      break;
    }

    hierarchy.unshift(parentFeature);

    currentParentId = parentFeature.parentFeatureId;
  }

  return hierarchy;
}
