import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { AdminCreateDemoAppType } from "@bucketco/shared/adminAPI";
import { ErrorResponse } from "@bucketco/shared/api";
import { AppDTO } from "@bucketco/shared/appAPI";

import api from "@/common/utils/api";

export function useAdminDemoAppCreateMutation(orgId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    AppDTO,
    AxiosResponse<ErrorResponse>,
    AdminCreateDemoAppType
  >({
    mutationKey: ["admin", "org", orgId, "app"],
    mutationFn: (body) =>
      api
        .post<"/admin/:orgId/apps">(`/admin/${orgId}/apps`, body)
        .then((res) => res.data),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["orgs"],
      });
    },
  });
}
