import React, { useMemo } from "react";

import { systemAttributeFieldKeys } from "@bucketco/shared/attributeFilter";
import { CompaniesColumn } from "@bucketco/shared/companyAPI";
import {
  FilterGroup as FilterGroupModel,
  UIFilter,
  wrapWithFilterGroup,
} from "@bucketco/shared/filter";
import { SegmentDTO } from "@bucketco/shared/segmentAPI";

import { FilterGroup } from "@/common/components/filters/FilterGroup";
import { useCompaniesTableState } from "@/company/hooks/useCompaniesTableState";

type Props = {
  segment: SegmentDTO;
};

export const SegmentFilters = ({ segment }: Props) => {
  const {
    companyFilter,
    onCompanyFilterChange,
    columnStates,
    onColumnStatesChange,
  } = useCompaniesTableState(segment);

  const rootFilter = useMemo<FilterGroupModel<UIFilter>>(
    () => wrapWithFilterGroup(companyFilter),
    [companyFilter],
  );

  return (
    <FilterGroup
      context="segment"
      types={[
        "companyAttribute",
        "segment",
        "featureMetric",
        "featureTargeting",
      ]}
      value={rootFilter}
      flatten
      onChange={(filterGroup, filterAction) => {
        if (filterAction.action === "create") {
          let columnId: CompaniesColumn | undefined;
          if (
            filterAction.filter.type === "companyAttribute" &&
            !systemAttributeFieldKeys.includes(filterAction.filter.field)
          ) {
            columnId = `attributes.${filterAction.filter.field}`;
          } else if (filterAction.filter.type === "featureMetric") {
            columnId = `featureMetrics.${filterAction.filter.featureId}.${filterAction.filter.metric}`;
          }
          if (columnId && columnStates.every(({ id }) => id !== columnId)) {
            onColumnStatesChange([
              ...columnStates,
              {
                id: columnId,
                shown: true,
              },
            ]);
          }
        }

        onCompanyFilterChange(filterGroup);
      }}
    />
  );
};
