import { Box, keyframes, useToken } from "@chakra-ui/react";

const scanAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

interface ScannerProps {
  color?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  speed?: string;
}

export default function RadarSpinner({
  size = "sm",
  color = "current",
  speed = "3s",
}: ScannerProps) {
  const colorToken = useToken("colors", color);

  const sizeMap = {
    xs: "1rem",
    sm: "1.25rem",
    md: "1.5rem",
    lg: "1.75rem",
    xl: "2rem",
  } as const;

  // convert size to number for calculations
  const sizeVal = sizeMap[size];
  const sizeNum = parseFloat(sizeVal);
  const unit = "rem";
  const innerSize = `${sizeNum * 0.8333333333}${unit}`; // 5/6 of the outer size
  const positionOffset = `${sizeNum * 0.08333333333}${unit}`; // 1/12 of the outer size

  return (
    <Box
      borderRadius="50%"
      height={sizeVal}
      position="relative"
      width={sizeVal}
    >
      <Box
        animation={`${scanAnimation} ${speed} infinite linear`}
        background={`conic-gradient(transparent 20%, ${colorToken})`}
        borderRadius="50%"
        height={innerSize}
        left={positionOffset}
        position="absolute"
        top={positionOffset}
        width={innerSize}
      />
    </Box>
  );
}
