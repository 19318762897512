import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import isMatch from "lodash/isMatch";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  WidgetConfiguration,
  WidgetDTO,
  WidgetPatchArgs,
  WidgetPostArgs,
} from "@bucketco/shared/widgetAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import pluralize from "@/common/utils/pluralize";

import { widgetQueryKeys } from "./widgetQueryKeys";

type PreviousCaches = {
  queryKey: QueryKey;
  previous: WidgetDTO | WidgetDTO[];
}[];

function predicate(queryKey: QueryKey, configuration: WidgetConfiguration) {
  const filter = queryKey.at(-1);
  // List of all widgets without filter
  if (filter === "widgets") return true;
  // Lists with a filter matching the configuration
  return !!filter && isMatch(configuration, filter);
}

export function useWidgetCreateMutation<
  TArgs extends WidgetPostArgs | WidgetPostArgs[],
>() {
  const { appId } = useCurrentEnv();

  const queryClient = useQueryClient();
  const errorToast = useErrorToast();

  return useMutation<
    TArgs extends WidgetPostArgs[] ? WidgetDTO[] : WidgetDTO,
    AxiosError<ErrorResponse>,
    TArgs
  >({
    mutationKey: ["widget", "create"],

    mutationFn: (data) => {
      if (Array.isArray(data)) {
        return Promise.all(
          data.map((d) =>
            api
              .post<"/apps/:appId/widgets">(`/apps/${appId}/widgets`, d)
              .then((res) => res.data.widget),
          ),
        ) as any;
      } else {
        return api
          .post<"/apps/:appId/widgets">(`/apps/${appId}/widgets`, data)
          .then((res) => res.data.widget);
      }
    },

    onSuccess(data) {
      const listKey = widgetQueryKeys.list(appId);

      function addToList(widget: WidgetDTO) {
        // Create the new single widget cache
        queryClient.setQueryData(
          widgetQueryKeys.single(appId, widget.id),
          widget,
        );

        // Add the new widget to all cached lists
        queryClient.setQueriesData<WidgetDTO[]>(
          {
            queryKey: listKey,
            predicate: ({ queryKey }) =>
              predicate(queryKey, widget.configuration),
          },
          (oldList) => (oldList ? [...oldList, widget] : [widget]),
        );
      }

      if (Array.isArray(data)) {
        data.forEach(addToList);
      } else {
        addToList(data);
      }

      // todo: do we need to invalidate the query?
    },
    onError() {
      errorToast({
        description: "Failed to create the widget",
      });
    },
  });
}

export function useWidgetUpdateMutation(widgetId: string) {
  const { appId } = useCurrentEnv();

  const queryClient = useQueryClient();
  const errorToast = useErrorToast();

  return useMutation<WidgetDTO, AxiosError<ErrorResponse>, WidgetPatchArgs>({
    mutationKey: ["widget", "update"],

    mutationFn: (data) =>
      api
        .patch<"/apps/:appId/widgets/:widgetId">(
          `/apps/${appId}/widgets/${widgetId}`,
          data,
        )
        .then((res) => res.data.widget),

    onSuccess(data) {
      const listKey = widgetQueryKeys.list(appId);

      // Update the single widget cache
      queryClient.setQueryData(widgetQueryKeys.single(appId, data.id), data);

      // Update the matching widget in all cached lists
      queryClient.setQueriesData<WidgetDTO[]>(
        {
          queryKey: listKey,
          predicate: ({ queryKey }) => predicate(queryKey, data.configuration),
        },
        (oldList) =>
          oldList?.map((widget) => (widget.id === data.id ? data : widget)),
      );
      // todo: do we need to invalidate the query?
    },
    onError(_, variables) {
      errorToast({
        description: `Failed to create the ${pluralize(
          "widget",
          Array.isArray(variables) ? variables.length : 1,
        )}`,
      });
    },
  });
}

export function useWidgetDeleteMutation(widgetId: string) {
  const { appId } = useCurrentEnv();

  const queryClient = useQueryClient();
  const errorToast = useErrorToast();

  const listKey = widgetQueryKeys.list(appId);
  const singleKey = widgetQueryKeys.single(appId, widgetId);

  return useMutation<
    object,
    AxiosError<ErrorResponse>,
    void,
    {
      previous: PreviousCaches;
    }
  >({
    mutationKey: ["widget", "delete"],

    mutationFn: () =>
      api
        .delete<"/apps/:appId/widgets/:widgetId">(
          `/apps/${appId}/widgets/${widgetId}`,
        )
        .then((res) => res.data),

    onMutate() {
      // Store changes to revert on error
      const previous: PreviousCaches = [];

      // Remove the single widget cache
      const widget = queryClient.getQueryData<WidgetDTO>(singleKey);
      if (widget) {
        previous.push({
          queryKey: singleKey,
          previous: widget,
        });
        queryClient.removeQueries({ queryKey: singleKey });
      }

      // Update the matching widget in all cached lists
      queryClient.setQueriesData<WidgetDTO[]>(
        {
          queryKey: listKey,
        },
        (oldList) => {
          if (
            Array.isArray(oldList) &&
            oldList.some((widget) => widget.id === widgetId)
          ) {
            previous.push({
              queryKey: listKey,
              previous: oldList,
            });
            return oldList.filter((widget) => widget.id !== widgetId);
          }
          return oldList;
        },
      );

      return { previous };
    },
    onError(error, _variables, context) {
      if (context?.previous) {
        context.previous.forEach((mutation) => {
          queryClient.setQueryData(mutation.queryKey, mutation.previous);
        });
      }

      errorToast({
        description: "Failed to delete the widget",
      });
    },
    onSettled() {
      // todo: do we need to invalidate the query?
    },
  });
}
