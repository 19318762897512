import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  useColorModeValue,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";

type FormErrorProps = {
  name: string;
  children: ReactNode;
} & PopoverProps;

export default function FloatingFormError({
  name,
  children,
  ...props
}: FormErrorProps) {
  const form = useFormContext();

  const textColor = useColorModeValue("red.500", "red.400");
  const isInvalid = !!form.formState.errors[name];

  return (
    <Popover autoFocus={false} isOpen={isInvalid} {...props}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody color={textColor} fontSize="sm">
          <ErrorMessage name={name} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
