import { ReactNode, useCallback } from "react";
import { RiAddLine } from "react-icons/ri";
import { Button, Flex } from "@chakra-ui/react";

import {
  AdjustAttributeFieldsCallback,
  FilterGroup as FilterGroupModel,
  UIFilter,
  UIFilterType,
} from "@bucketco/shared/filter";
import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";

import { AndOrList } from "../AndOrList";

import FilterDropdown from "./FilterDropdown";
import { FilterItem } from "./FilterItem";

type FilterGroupProps = {
  value: FilterGroupModel<UIFilter> | undefined;
  types?: UIFilterType[];
  readonly?: boolean;
  flatten?: boolean;
  adjustAttributeFields?: AdjustAttributeFieldsCallback;
  segmentItemFilter?: (segment: SegmentDTOWithTotalCompanies) => boolean;
  segmentItemFilterReason?: () => ReactNode;
  showItemsIcons?: boolean;
  warnings?: Partial<Record<UIFilterType, ReactNode>>;
  onChange?: (
    value: FilterGroupModel<UIFilter>,
    action: {
      action: "create" | "update" | "delete";
      filter: UIFilter;
    },
  ) => void;
  context?: FilterContextType;
};

export type FilterContextType = "flag" | "segment";

export const SupportedCompanyFilterTypes: UIFilterType[] = [
  "companyAttribute",
  "segment",
  "featureTargeting",
  "featureMetric",
];

export const SupportedFlagFilterTypes: UIFilterType[] = [
  "companyAttribute",
  "userAttribute",
  "otherContext",
  "segment",
  "featureTargeting",
];

export function FilterGroup({
  types = SupportedCompanyFilterTypes,
  value,
  context,
  onChange,
  warnings,
  readonly = false,
  adjustAttributeFields,
  segmentItemFilter,
  segmentItemFilterReason,
  showItemsIcons = true,
  flatten = false,
}: FilterGroupProps) {
  const renderFilter = useCallback(
    (filter: UIFilter | undefined, index = 0, depth = 0) => {
      // Create a "unique" id based on the filter object to render correct filter drop-down
      const key = btoa(JSON.stringify(filter));
      if (!filter || filter.type === "group") {
        const render = (
          <>
            {filter?.filters?.length ? (
              <AndOrList
                conjunction={filter.operator}
                direction="horizontal"
                flatten={flatten}
                flexWrap="wrap"
              >
                {filter.filters.map((f, index) =>
                  renderFilter(f, index, depth + 1),
                )}
              </AndOrList>
            ) : (
              readonly && (
                <FilterItem
                  filter={undefined}
                  showIcon={showItemsIcons}
                  size="sm"
                />
              )
            )}
            {depth === 0 && !readonly && (
              <FilterDropdown
                key={key}
                adjustAttributeFields={adjustAttributeFields}
                context={context}
                filter={undefined}
                segmentItemFilter={segmentItemFilter}
                segmentItemFilterReason={segmentItemFilterReason}
                types={types}
                warnings={warnings}
                onSave={(newFilter) => {
                  // Does not currently handle nested groups
                  const newFilterGroup = {
                    type: "group" as const,
                    operator: value?.operator ?? "and",
                    filters: value?.filters
                      ? [...value.filters, newFilter]
                      : [newFilter],
                  };
                  onChange?.(newFilterGroup, {
                    action: "create",
                    filter: newFilter,
                  });
                }}
              >
                {({ triggerRef, toggle }) => (
                  <Button
                    ref={triggerRef as any}
                    color="dimmed"
                    flexShrink={0}
                    iconSpacing={1}
                    leftIcon={<RiAddLine size={16} />}
                    pl={1}
                    pr={2}
                    variant="ghost"
                    onClick={toggle}
                  >
                    Add filter
                  </Button>
                )}
              </FilterDropdown>
            )}
          </>
        );

        if (flatten) {
          return render;
        }

        return (
          <Flex key={key} align="center" flexWrap="wrap" gap={2}>
            {render}
          </Flex>
        );
      } else if (filter.type !== "negation") {
        if (readonly) {
          return (
            <FilterItem filter={filter} showIcon={showItemsIcons} size="sm" />
          );
        }

        return (
          <FilterDropdown
            key={key}
            adjustAttributeFields={adjustAttributeFields}
            context={context}
            filter={filter}
            segmentItemFilter={segmentItemFilter}
            segmentItemFilterReason={segmentItemFilterReason}
            types={types}
            warnings={warnings}
            onSave={(updatedFilter) => {
              // Does not currently handle nested groups
              const newFilterGroup = {
                type: "group" as const,
                operator: value?.operator ?? "and",
                filters: value?.filters?.map((f, idx) =>
                  idx === index ? updatedFilter : f,
                ),
              };
              onChange?.(newFilterGroup, {
                action: "update",
                filter: updatedFilter,
              });
            }}
          >
            {({ triggerRef, toggle }) => (
              <FilterItem
                ref={triggerRef}
                filter={filter}
                showIcon={showItemsIcons}
                size="sm"
                showRemove
                onClick={() => toggle()}
                onRemove={() => {
                  // Does not currently handle nested groups
                  const newFilterGroup = {
                    type: "group" as const,
                    operator: value?.operator ?? "and",
                    filters: value?.filters?.filter((_, idx) => idx !== index),
                  };
                  onChange?.(newFilterGroup, {
                    action: "delete",
                    filter: filter,
                  });
                }}
              />
            )}
          </FilterDropdown>
        );
      }
    },
    [
      flatten,
      readonly,
      showItemsIcons,
      adjustAttributeFields,
      context,
      segmentItemFilter,
      segmentItemFilterReason,
      types,
      warnings,
      value?.operator,
      value?.filters,
      onChange,
    ],
  );

  const rendered = renderFilter(value, 0, 0);

  if (flatten) {
    return rendered;
  }

  return (
    <Flex alignItems="center" flexWrap="wrap" gap={2}>
      {rendered}
    </Flex>
  );
}
