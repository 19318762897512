import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Center, Flex, Link, Spinner, Text } from "@chakra-ui/react";
import { SignIn, SignUp, useClerk } from "@clerk/clerk-react";

import { redirectUrlSchema } from "@bucketco/shared/schemas/redirectUrlSchema";
import { LoginUrl, SignupUrl } from "@bucketco/shared/urls";

import CenteredLayout from "@/common/components/CenteredLayout";
import GoToHome from "../components/GoToHome";
import { useAuthContext } from "../contexts/authContext";

type Props = {
  mode: "login" | "signup";
};

export default function Auth({ mode }: Props) {
  const { user, isLoading } = useAuthContext();
  const { loaded } = useClerk();

  const parsedRedirect = redirectUrlSchema.safeParse(
    new URLSearchParams(window.location.search).get("redirect_url"),
  );
  const redirectUrl = parsedRedirect.success ? parsedRedirect.data : "/";
  const redirectParams = new URLSearchParams({
    redirect_url: redirectUrl,
  });

  const isInvite = redirectUrl.includes("/invite/");

  const text = useMemo<string | null>(() => {
    if (isInvite) {
      if (mode === "signup") {
        return "To accept your invitation, sign up below.";
      } else {
        return "To accept your invitation, sign in below.";
      }
    }
    return null;
  }, [isInvite, mode]);

  if (user) {
    return <GoToHome />;
  }

  return (
    <CenteredLayout bg="appSidebarBackground">
      <Helmet>
        <title>{mode === "login" ? "Login" : "Sign-up"}</title>
      </Helmet>
      <Box>
        {loaded && !isLoading ? (
          <Flex alignItems="center" direction="column" gap={8} maxW="sm">
            {text && (
              <Text
                color="dimmed"
                fontSize="sm"
                fontWeight="medium"
                textAlign="center"
              >
                {text}
              </Text>
            )}
            {mode === "login" ? (
              <SignIn
                fallbackRedirectUrl={redirectUrl}
                signUpFallbackRedirectUrl={redirectUrl}
                signUpUrl={SignupUrl(redirectParams)}
              />
            ) : (
              <SignUp
                fallbackRedirectUrl={redirectUrl}
                signInFallbackRedirectUrl={redirectUrl}
                signInUrl={LoginUrl(redirectParams)}
              />
            )}
            <Text
              color="dimmed"
              fontSize="xs"
              fontWeight="medium"
              textAlign="center"
            >
              By continuing, you&apos;re agreeing to our{" "}
              <Link href="https://bucket.co/pages/policies" target="_blank">
                policies
              </Link>
            </Text>
          </Flex>
        ) : (
          <Center h="100vh">
            <Spinner mr={2} size="sm" />
          </Center>
        )}
      </Box>
    </CenteredLayout>
  );
}
