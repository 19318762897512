import { useId } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { RiDraggable, RiTriangleFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { Box, Button, Flex, MenuIcon, Text, useToast } from "@chakra-ui/react";
import { CellContext, Row } from "@tanstack/react-table";

import { FeatureName } from "@bucketco/shared/featureAPI";
import { getFeatureParentNames } from "@bucketco/shared/utils/getFeatureParentNames";

import { ErrorResponseDisplay } from "@/common/components/ErrorResponseDisplay";
import { TreeVisualization } from "@/common/components/TreeVisualization";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { DragType } from "@/common/types/dragDropTypes";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { IndentType } from "@/common/utils/tree";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";
import { useFeatureUpdateMutation } from "@/feature/data/useFeatureUpdateMutation";

export function FeatureNameCell<TRow extends FeatureName>({
  row,
  table,
}: CellContext<TRow, string>) {
  const featureDisplayId = useId();
  const buttonId = useId();

  const [subsegment] = useSubsegmentParam();

  const toast = useToast();
  const { companyId } = useParams();
  const featureUpdateMutation = useFeatureUpdateMutation(companyId!);

  const { data: featureNames = [] } = useFeatureNamesData();

  const [{ canDrag }, drag, preview] = useDrag<
    FeatureName,
    { dropTarget?: "feature" | "unset_drop_area" },
    { canDrag: boolean }
  >(
    () => ({
      type: DragType.FeatureDisplay,
      item: () => {
        segmentAnalytics.track("Feature Tree Drag/Drop", {
          drag_state: "drag",
        });
        return row.original;
      },
      // DON'T SET OPTIONS UNLESS ALSO USING A DEPENDENCY
      // SEE: https://github.com/react-dnd/react-dnd/issues/3345
      // options: {
      //   dropEffect: "copy",
      // },
      collect(monitor) {
        return {
          canDrag: monitor.canDrag(),
        };
      },
      end(_, monitor) {
        if (monitor.didDrop()) {
          const dropResult = monitor.getDropResult();

          if (dropResult?.dropTarget) {
            segmentAnalytics.track("Feature Tree Drag/Drop", {
              drag_state: "drop",
              drop_target: dropResult.dropTarget,
            });
          } else {
            segmentAnalytics.track("Feature Tree Drag/Drop", {
              drag_state: "cancel",
            });
          }
        }
      },
    }),
    [row.original],
  );

  const [{ isOver, canDrop }, drop] = useDrop<
    FeatureName,
    unknown,
    { isOver: boolean; canDrop: boolean }
  >(
    () => ({
      accept: DragType.FeatureDisplay,
      collect(monitor) {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
      canDrop(feature) {
        const dropFeature = row.original;

        // Don't attempt to re-set the current parent feature, or assign self as parent
        if ([feature.id, feature.parentFeatureId].includes(dropFeature.id)) {
          return false;
        }

        // Can't drop a feature on its own children
        const dropParents = getFeatureParentNames(featureNames, dropFeature.id);
        if (dropParents.some((p) => p.id === feature.id)) {
          return false;
        }

        return true;
      },
      drop(feature) {
        featureUpdateMutation.mutate(
          {
            id: feature.id,
            parentFeatureId: row.original.id,
          },
          {
            onSuccess(modifiedFeature) {
              segmentAnalytics.track("Feature Options Updated", {
                section: "Features Table Feature Cell",
                feature_parent_modified:
                  feature.parentFeatureId !== modifiedFeature.parentFeatureId,
              });
              toast({
                title: `Feature "${feature.name}" is now a child of "${row.original.name}"`,
                status: "success",
                duration: 1500,
              });
            },
            onError(error) {
              toast({
                title: (
                  <Box>
                    <Text>{`Unable to move "${feature.name}" inside "${row.original.name}".`}</Text>
                    <ErrorResponseDisplay response={error.response?.data} />
                  </Box>
                ),
                status: "error",
                duration: 5000,
              });
            },
          },
        );

        return { dropTarget: "feature" };
      },
    }),
    [row.original, featureNames, featureUpdateMutation, toast],
  );

  if ((table.options.meta as any).sortType !== "hierarchical") {
    return (
      <FeatureDisplay
        feature={row.original}
        flex="0 1 auto"
        id={featureDisplayId}
        subsegmentId={subsegment[0]}
        autoFeedbackSurveyIndicator
        link
        onClick={(e) => e.stopPropagation()}
      />
    );
  }

  preview(getEmptyImage());

  // When the feature is a child in a tree, but is displayed as a root-node
  // because its parents are not a part of the same feature view; show the
  // parents names as well
  const shouldShowParentNames =
    row.original.parentFeatureId !== undefined &&
    row.getParentRow() === undefined;

  return (
    <Flex
      ref={drop}
      data-candrop={isOver && canDrop}
      flex="0 1 auto"
      ml={-6}
      my={-3.5}
      pl={6}
      position="relative"
      py={3.5}
      transition={"all 200ms ease-in-out"}
    >
      {/* Droppable indicator */}
      {canDrop && (
        <Box
          aria-hidden="true"
          background="currentColor"
          borderRadius="2px"
          h="10px"
          left={2}
          opacity={isOver ? "0.7" : "0.1"}
          pointerEvents="none"
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          transition="opacity 200ms"
          w="3px"
        ></Box>
      )}

      {/* Drag handle */}
      <Box
        ref={drag}
        background="appBackground"
        border="1px solid"
        borderColor="appBorder"
        cursor={canDrag ? "grab" : "pointer"}
        left={1}
        opacity="0"
        position="absolute"
        py={1}
        rounded="md"
        sx={{
          "&:active": {
            cursor: canDrag ? "grabbing" : "pointer",
          },
        }}
        top="50%"
        transform="translateY(-50%)"
        transition="opacity 150ms ease-in-out"
        zIndex="1"
        aria-hidden
        data-draghandle
      >
        <MenuIcon color="dimmed">
          <RiDraggable />
        </MenuIcon>
      </Box>

      <TreeVisualization
        indents={getTreeVisualizationTypesFromTableRow(row)}
        my={-3.5}
      />

      <FeatureDisplay
        feature={row.original}
        flex="0 1 auto"
        id={featureDisplayId}
        includeParents={shouldShowParentNames}
        subsegmentId={subsegment[0]}
        autoFeedbackSurveyIndicator
        link
        onClick={(e) => e.stopPropagation()}
      />

      {/* Children expansion indicator  */}
      {row.getCanExpand() ? (
        <Box flex="0 0" ml={2} mr={-4}>
          <Button
            aria-controls={row.subRows.map((row) => row.id).join(" ")}
            aria-expanded={row.getIsExpanded()}
            aria-label={`${row.subRows.length} more below`}
            aria-labelledby={[buttonId, featureDisplayId].join(" ")}
            color="dimmed"
            colorScheme="gray"
            gap={1}
            id={buttonId}
            rounded="full"
            size="2xs"
            title={`${row.getIsExpanded() ? "Collapse" : "Expand"} subtree`}
            variant="outlineOnHover"
            onClick={(e) => {
              e.stopPropagation();
              row.getToggleExpandedHandler()();
            }}
          >
            <RiTriangleFill
              color="dimmed"
              size={7}
              style={{
                transform: `rotate(${row.getIsExpanded() ? 180 : 90}deg)`,
                transition: "150ms ease-in-out",
              }}
            />
            {!row.getIsExpanded() && <span>{row.getLeafRows().length}</span>}
          </Button>
        </Box>
      ) : null}
    </Flex>
  );
}

function getTreeVisualizationTypesFromTableRow<TData>(
  row: Row<TData>,
): Array<IndentType> {
  const parentRows = row.getParentRows().slice(1);

  const result: Array<IndentType> = parentRows.map((p) => {
    if (hasNextSibling(p)) {
      return "│";
    } else {
      return ".";
    }
  });

  // Handle current row
  if (row.getParentRow()) {
    result.push(hasNextSibling(row) ? "├" : "└");
  }

  // Render a tree root if the children are expanded
  if (row.getCanExpand() && row.getIsExpanded()) {
    result.push("▽");
  }

  return result;
}

function hasNextSibling<TData>(row: Row<TData>): boolean {
  const parentRow = row.getParentRow();

  if (!parentRow) {
    return false;
  }

  const siblings = parentRow.subRows;

  if (siblings.indexOf(row) < siblings.length - 1) {
    return true;
  } else {
    return false;
  }
}
