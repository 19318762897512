import { useMemo, useRef } from "react";
import isEqual from "lodash/isEqual";

import {
  ManagedFormControlKeys,
  ManagedFormControlKeysList,
  ManagedFormControlProps,
} from "@/common/components/Form/ManagedFormControl";
import { partitionObject } from "@/common/utils/partitionObject";

/** Omit render from components that extend ManagedFormControl with their own input. */
export type ManagedFormControlKeyProps = Omit<
  Pick<ManagedFormControlProps, ManagedFormControlKeys>,
  "render"
>;

/** Type for splitting key props used by the ManageFormControl into root level keeping rest inside `_control`. */
export type FormElementBaseProps = ManagedFormControlKeyProps & {
  _control?: Omit<ManagedFormControlProps, ManagedFormControlKeys>;
};

/** Remove render from the list of keys. */
export const ManagedFormControlKeysListWithoutRender =
  ManagedFormControlKeysList.filter((key) => key !== "render");

/**
 * Split key props used by the ManageFormControl from remaining props used by the form element.
 * @param props All props passed to the form element.
 * @returns An array of two objects, the first containing the key props, the second containing the remaining props.
 */
export const useManagedFormControlProps = <T extends Record<string, unknown>>(
  props: T,
) => {
  const propsRef = useRef(props);
  const signalRef = useRef<number>(0);
  if (!isEqual(props, propsRef.current)) {
    propsRef.current = props;
    signalRef.current += 1;
  }
  return useMemo(
    () =>
      partitionObject(
        propsRef.current,
        ManagedFormControlKeysListWithoutRender,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signalRef.current],
  );
};
