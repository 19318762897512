import { chakra } from "@chakra-ui/react";

import StageFillSvg from "@/common/assets/stage-icon-fill.svg?react";
import StageLineSvg from "@/common/assets/stage-icon-line.svg?react";
import StagesFillSvg from "@/common/assets/stages-icon-fill.svg?react";
import StagesLineSvg from "@/common/assets/stages-icon-line.svg?react";
import { useStageColor } from "@/stage/hooks/useStagesColor";

export const StageLineIcon = chakra(StageLineSvg);
export const StagesLineIcon = chakra(StagesLineSvg);
export const StageFillIcon = chakra(StageFillSvg);
export const StagesFillIcon = chakra(StagesFillSvg);

export type StageIconProps = {
  colorIndex?: number;
  variant?: "fill" | "line";
} & React.ComponentProps<typeof StageLineIcon>;

export const StageIcon = ({
  colorIndex = 0,
  variant = "fill",
  ...rest
}: StageIconProps) => {
  const color = useStageColor(colorIndex);
  if (variant === "fill") {
    return <StageFillIcon color={color} {...rest} />;
  } else {
    return <StageLineIcon color={color} {...rest} />;
  }
};
