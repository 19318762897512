import { useRef } from "react";
import { useOutsideClick } from "@chakra-ui/react";

/**
 * Use this together with Popover when in controlled mode (i.e. you control the isOpen state)
 */
export default function useOutsideClickPopover({
  isOpen,
  onToggle,
}: {
  isOpen: boolean;
  onToggle: () => void;
}) {
  const contentRef = useRef<HTMLElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  useOutsideClick({
    ref: contentRef,
    handler: (e) => {
      if (e.target !== triggerRef.current) onToggle();
    },
    enabled: isOpen,
  });
  return { contentRef, triggerRef };
}
