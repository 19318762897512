import { UIFilter } from "@bucketco/shared/filter";
import {
  deserializeFilterParam,
  serializeFilterParam,
} from "@bucketco/shared/urls";

import { useSearchParam } from "@/common/hooks/useSearchParam";

export function useFilterParam(fallback: UIFilter) {
  return useSearchParam<UIFilter>("filter", {
    fallback,
    serialize: serializeFilterParam,
    deserialize: deserializeFilterParam,
  });
}
