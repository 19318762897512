import { forwardRef } from "react";

import { FeatureName } from "@bucketco/shared/featureAPI";

import AutocompleteSelect, {
  AutocompleteSelectProps,
  EmphasizedSuggestion,
  getStringFilterFunction,
} from "@/common/components/AutocompleteSelect";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";

export type FeatureAutocompleteSelectProps = {
  value?: string;
  emphasized?: EmphasizedSuggestion[];
  onChange: (value?: FeatureName) => void;
  itemRenderfn?: AutocompleteSelectProps<FeatureName>["itemRenderfn"];
  itemFilterFn?: AutocompleteSelectProps<FeatureName>["itemFilterFn"];
} & Omit<
  AutocompleteSelectProps<FeatureName>,
  | "value"
  | "onChange"
  | "isLoadingSuggestions"
  | "itemFilterFn"
  | "itemKeyProperty"
  | "itemRenderfn"
  | "suggestions"
>;

export const FeatureAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  FeatureAutocompleteSelectProps
>(function FeatureAutocompleteSelect(
  {
    value: featureIdProp,
    emphasized,
    placeholder = "Select a feature",
    onChange,
    itemRenderfn = (item, search) => (
      <FeatureDisplay feature={item} highlightText={search} size="sm" />
    ),
    itemFilterFn = getStringFilterFunction("name"),
    ...autocompleteProps
  }: FeatureAutocompleteSelectProps,
  ref,
) {
  const { data: featureNames = [], isFetching } = useFeatureNamesData();
  const value = featureNames.find((f) => f.id === featureIdProp);

  return (
    <AutocompleteSelect
      ref={ref}
      emphasized={emphasized}
      isLoadingSuggestions={isFetching}
      itemFilterFn={itemFilterFn}
      itemKeyProperty="id"
      itemRenderfn={itemRenderfn}
      placeholder={placeholder}
      suggestions={featureNames}
      value={value}
      showClearButton
      onChange={(value) => {
        onChange(value ?? undefined);
      }}
      onClear={() => {
        onChange(undefined);
      }}
      {...autocompleteProps}
    />
  );
});

FeatureAutocompleteSelect.displayName = "FeatureAutocompleteSelect";
