import { Spinner, SpinnerProps } from "@chakra-ui/react";
import { AnimatePresence, motion, MotionProps } from "framer-motion";

export const MotionSpinner = motion<SpinnerProps>(Spinner);

export default function AnimatedSpinner({
  show = false,
  size = "sm",
  ...props
}: SpinnerProps & MotionProps & { show: boolean }) {
  return (
    <AnimatePresence>
      {show && (
        <MotionSpinner
          key="spinner"
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          size={size}
          transition={{ type: "tween", duration: 0.1 } as any}
          {...props}
        />
      )}
    </AnimatePresence>
  );
}
