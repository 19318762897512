import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, cssVar } from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const $popperBg = cssVar("popper-bg");
const $arrowBg = cssVar("popper-arrow-bg");
const $arrowShadowColor = cssVar("popper-arrow-shadow-color");
const $menuBg = cssVar("menu-bg");

const baseStyle = definePartsStyle({
  button: {
    bg: "appBackground",
  },
  list: {
    bg: "popoverBackground",
    borderColor: "popoverBorder",
    boxShadow: "md",
    padding: 0,
    [$arrowBg.variable]: "colors.popoverBackground",
    [$arrowShadowColor.variable]: `colors.popoverBorder`,
    [$menuBg.variable]: "popoverBackground",
    _dark: {
      [$menuBg.variable]: "popoverBackground",
      [$popperBg.variable]: "colors.popoverBackground",
      [$arrowShadowColor.variable]: `colors.popoverBorder`,
    },
  },
  groupTitle: {
    fontWeight: "medium",
    color: "dimmed",
  },
  item: {
    borderRadius: 0,
    fontWeight: "medium",

    _hover: {
      bg: "gray.50",
      _dark: {
        bg: "gray.750",
      },
    },
    _focus: {
      bg: "gray.50",
      _dark: {
        bg: "gray.750",
      },
    },
  },
});

const paddedVariant = definePartsStyle({
  list: {
    padding: 2,
  },
  item: {
    borderRadius: "md",
  },
});

export const MenuTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    xs: {
      groupTitle: {
        fontSize: "sm",
        px: 2,
        mt: 2,
        mb: 0.5,
      },
      item: {
        fontSize: "sm",
        py: 1,
        px: 2,
      },
    },
    sm: {
      groupTitle: {
        fontSize: "sm",
        mx: 2.5,
        mt: 2.5,
        mb: 1,
      },
      item: {
        fontSize: "sm",
        py: 1.5,
        px: 2.5,
      },
    },
    md: {
      groupTitle: {
        fontSize: "sm",
        mx: 3,
        mt: 3,
        mb: 1.5,
      },
      item: {
        fontSize: "sm",
        py: 2,
        px: 3,
      },
    },
  },
  variants: {
    padded: paddedVariant,
  },
  defaultProps: {
    size: "sm",
  },
});
