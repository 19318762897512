import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, GridItem, Link, Progress } from "@chakra-ui/react";

import {
  SatisfactionScore,
  SatisfactionSpan,
} from "@bucketco/shared/schemas/satisfactionScore";
import { FeedbackUrl } from "@bucketco/shared/urls";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { useAuthContext } from "@/auth/contexts/authContext";
import CompactNumber from "@/common/components/CompactNumber";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import PercentageNumber from "@/common/components/PercentageNumber";
import { useFeatureFeedbackHistogramData } from "@/feature/data/useFeatureFeedbackHistogramData";
import {
  FeedbackSatisfactionIcon,
  useSatisfactionColors,
} from "@/feedback/components/FeedbackSatisfaction";

const scoreOrder: Array<SatisfactionScore> = [5, 4, 3, 2, 1];

type Props = {
  featureId: string;
  featureName?: string;
  subsegmentId?: string;
};

export default function SatisfactionHistogram({
  featureId,
  subsegmentId,
}: Props) {
  const { currentEnv } = useAuthContext();
  const satisfactionColors = useSatisfactionColors();
  const histogramQuery = useFeatureFeedbackHistogramData(featureId, {
    subsegment: subsegmentId,
  });

  return (
    <Grid
      alignItems="center"
      fontSize="sm"
      gap={3}
      maxW="xs"
      rowGap={4}
      templateColumns="min-content 1fr min-content min-content"
    >
      {scoreOrder.map((score) => {
        const scoreIndex = score === 0 ? "noScore" : score;
        const scoreFraction = histogramQuery.data
          ? getFraction(
              histogramQuery.data[scoreIndex],
              histogramQuery.data.total,
            )
          : 0;

        return (
          <React.Fragment key={`sat-${scoreIndex}`}>
            <FeedbackSatisfactionIcon colored={true} value={score} />

            <Progress
              color={satisfactionColors[score]}
              height="6px"
              max={1}
              min={0}
              value={scoreFraction}
            />
            <GridItem textAlign="right">
              <PercentageNumber value={scoreFraction} />
            </GridItem>

            {!histogramQuery.isSuccess ? (
              <NotAvailableCell />
            ) : (
              <Link
                as={RouterLink}
                color="dimmed"
                to={FeedbackUrl(currentEnv!, {
                  feature: featureId,
                  satisfaction: satisfactionScoreToSpan(score),
                })}
              >
                <CompactNumber value={histogramQuery.data[scoreIndex]} />
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

function satisfactionScoreToSpan(score: SatisfactionScore): SatisfactionSpan {
  switch (score) {
    case 1:
    case 2:
      return "unsatisfied";
    case 3:
      return "neutral";
    case 4:
    case 5:
      return "satisfied";
    default:
      return "neutral";
  }
}
