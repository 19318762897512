import { useAuthContext } from "@/auth/contexts/authContext";
import { OnlyProductionEnvironment } from "@/environment/components/EnvironmentCallouts";
import SlackOrgIntegration from "@/global-settings/components/SlackOrgIntegration";

type Props = {
  productionOnly?: boolean;
  children?: React.ReactNode;
};

export default function SlackConnectionGuard({
  productionOnly = false,
  children,
}: Props) {
  const { currentEnv, currentOrg } = useAuthContext();

  if (productionOnly && !currentEnv?.isProduction) {
    <OnlyProductionEnvironment entity="slack reports" />;
  }

  if (currentOrg?.hasSlackConnection) {
    return children;
  }

  return <SlackOrgIntegration />;
}
