import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const variants = {
  filled: definePartsStyle((props) => ({
    container: {
      backgroundColor: mode("gray.25", "gray.800")(props),
      borderWidth: "1px",
      borderColor: "appBorder",
      boxShadow: "none",
    },
  })),
  elevated: definePartsStyle((props) => ({
    container: {
      backgroundColor: mode("white", "gray.850")(props),
      borderWidth: mode("0px", "1px")(props),
      borderTopWidth: mode("0.5px", "1px")(props),
      borderColor: "appBorder",
      boxShadow: "base",
    },
  })),
  outline: definePartsStyle((_props) => ({
    container: {
      borderColor: "appBorder",
      boxShadow: "none",
    },
  })),
};

export const CardTheme = defineMultiStyleConfig({ variants });
