import { Text, TextProps, useColorModeValue } from "@chakra-ui/react";

type MarkProps = Omit<TextProps, "color" | "bgcolor" | "display">;

export default function Mark({ children, ...props }: MarkProps) {
  const bgColor = useColorModeValue("brand.100", "brand.700");

  return (
    <Text {...props} as="mark" bgColor={bgColor} color="inherit">
      {children}
    </Text>
  );
}
