import { useFormContext } from "react-hook-form";
import { Card, HStack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";

import FormNumberInput from "@/common/components/Form/FormNumberInput";
import pluralize from "@/common/utils/pluralize";

export default function SettingsSTARSSteps() {
  const { watch } = useFormContext();

  const strategy = watch("adoptionEvaluationStrategy");
  const adoptionStrategyEventCountMinEventCount = watch(
    "adoptionStrategyEventCountMinEventCount",
  );
  const adoptionWindowSizeInWeeks = watch("adoptionWindowSizeInWeeks");
  const adoptionStrategyFrequencyMinDaysCount = watch(
    "adoptionStrategyFrequencyMinDaysCount",
  );

  return (
    <Card variant="filled" w="full">
      <Table
        sx={{
          "td:first-of-type": {
            fontWeight: "semibold",
          },
          "tr:last-child": {
            td: {
              borderBottom: "none",
            },
          },
        }}
      >
        <Tbody>
          {strategy === "eventCount" && (
            <>
              <Tr>
                <Td>Tried</Td>
                <Td>
                  <Text color="dimmed">Sent at least one event</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>Adopted</Td>
                <Td>
                  <HStack>
                    <Text color="dimmed">Sent at least</Text>
                    <FormNumberInput
                      _control={{ w: 110 }}
                      min={1}
                      name="adoptionStrategyEventCountMinEventCount"
                      size="sm"
                      unit="events"
                    />
                    <Text color="dimmed">within</Text>
                    <FormNumberInput
                      _control={{ w: 105 }}
                      min={1}
                      name="adoptionWindowSizeInWeeks"
                      size="sm"
                      unit="weeks"
                    />
                  </HStack>
                </Td>
              </Tr>
              <Tr>
                <Td>Retained</Td>
                <Td>
                  <Text color="dimmed">
                    Sent at least {adoptionStrategyEventCountMinEventCount}{" "}
                    events within the last {adoptionWindowSizeInWeeks} weeks
                  </Text>
                </Td>
              </Tr>
            </>
          )}
          {strategy === "frequency" && (
            <>
              <Tr>
                <Td>Tried</Td>
                <Td>
                  <Text color="dimmed">Sent at least one event</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>Adopted</Td>
                <Td>
                  <HStack>
                    <Text color="dimmed">Sent an event on at least </Text>
                    <FormNumberInput
                      _control={{ w: 100 }}
                      min={1}
                      name="adoptionStrategyFrequencyMinDaysCount"
                      size="sm"
                      unit="days"
                    />
                    <Text color="dimmed">within</Text>
                    <FormNumberInput
                      _control={{ w: 110 }}
                      isDisabled={false}
                      min={1}
                      name="adoptionWindowSizeInWeeks"
                      size="sm"
                      unit="weeks"
                    />
                  </HStack>
                </Td>
              </Tr>
              <Tr>
                <Td>Retained</Td>
                <Td>
                  <Text color="dimmed">
                    Sent an event on at least{" "}
                    {adoptionStrategyFrequencyMinDaysCount} days within the last{" "}
                    {adoptionWindowSizeInWeeks}{" "}
                    {pluralize("week", adoptionWindowSizeInWeeks)} (rolling)
                  </Text>
                </Td>
              </Tr>
            </>
          )}
        </Tbody>
      </Table>
    </Card>
  );
}
