import { HStack, StackProps, Text } from "@chakra-ui/react";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";

export type FooterNoteProps = {
  icon?: React.ReactElement;
  text: string;
  tooltip?: {
    text: string;
    docsURL?: string;
  };
} & StackProps;

export function FooterNote({
  icon,
  text,
  tooltip,
  ...stackProps
}: FooterNoteProps) {
  return (
    <HStack {...stackProps} spacing={1}>
      {icon && (
        <Text as="span" color="dimmed" fontSize="sm">
          {icon}
        </Text>
      )}
      <Text as="span" color="dimmed" fontSize="sm">
        {text}
      </Text>
      {tooltip && (
        <InfoIconTooltip
          _content={{ lineHeight: "base", whiteSpace: "normal" }}
          docsURL={tooltip.docsURL}
          text={tooltip.text}
        />
      )}
    </HStack>
  );
}
