import { Key, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { Link, Outlet, To, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Spinner,
  Tab as ChakraTab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import ErrorBoundary from "@/common/components/ErrorBoundary";

function Tab({
  children,
  to,
  ...props
}: { children: React.ReactNode; to: To } & TabProps) {
  return (
    <ChakraTab as={Link} to={to} {...props}>
      <Box className="innerTab">{children}</Box>
    </ChakraTab>
  );
}

export type Route<T = unknown> = {
  route: To;
  count?: number;
  countIsLoading?: boolean;
  name: ReactNode;
} & T;

type RouterTabsProps<T> = {
  routes: Route<T>[];
  tabListProps: TabListProps;
  titleTemplate?: string;
  rightActions?: ReactNode;
  activeRouteIndex: number;
  isLoading?: boolean;
  onTabChange?: (route: Route<T>) => void;
};

export default function RouterTabs<T>({
  routes,
  tabListProps,
  titleTemplate,
  rightActions,
  activeRouteIndex,
  isLoading = false,
  onTabChange,
}: RouterTabsProps<T>) {
  const activeRoute = routes[activeRouteIndex];
  const countBg = useColorModeValue("gray.50", "gray.750");

  return (
    <Tabs
      as={Flex}
      colorScheme="gray"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      index={activeRouteIndex}
      variant="chip"
      isLazy
      isManual
      onChange={(index) => {
        onTabChange?.(routes[index]);
      }}
    >
      <Helmet titleTemplate={titleTemplate}>
        <title>{activeRoute.name}</title>
      </Helmet>
      <TabList alignItems="center" {...(tabListProps ?? {})}>
        {routes.map(({ route, count, countIsLoading, name }) => (
          <Tab key={toKey(route)} to={route}>
            <HStack gap={0}>
              <Text>{name}</Text>
              {count !== undefined && (
                <Text bg={countBg} borderRadius={4} fontSize="xs" px={1}>
                  {count}
                </Text>
              )}
              {countIsLoading && <Spinner size="xs" />}
            </HStack>
          </Tab>
        ))}
        <Flex ml="auto">{rightActions}</Flex>
      </TabList>

      <TabPanels display="flex" flexDirection="column" flexGrow={1}>
        {routes.map(({ route }) => (
          <TabPanel
            key={toKey(route)}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            p={0}
            position="relative"
          >
            <ErrorBoundary>{!isLoading && <Outlet />}</ErrorBoundary>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}

const toKey = (to: To): Key => {
  return typeof to === "string" ? to : to.pathname!;
};

export function useActivePathRouteIndex<T>(routes: Route<T>[]) {
  const { pathname } = useLocation();

  return Math.max(
    routes.findIndex((r) => {
      return typeof r.route === "string"
        ? pathname.startsWith(r.route)
        : r.route.pathname !== undefined &&
            pathname.startsWith(r.route.pathname);
    }),
    0,
  );
}
