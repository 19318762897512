import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";

import { featureViewQueryKeys } from "@/app/data/featureViewQueryKeys";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import api from "@/common/utils/api";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

import { useFeatureFeatureViews } from "./useFeatureFeatureViews";

function useUpdateFeatureViewsMutation(
  featureId?: string,
  currentFeatureViewIds?: string[],
  remove?: boolean,
) {
  const { appId } = useCurrentEnv();
  const toast = useToast();
  const errorToast = useErrorToast();

  const queryClient = useQueryClient();

  const queryKey = featureQueryKeys.featureViews(appId, featureId);

  return useMutation<string[], AxiosError<ErrorResponse>, FeatureViewDTO>({
    mutationKey: queryKey,

    mutationFn: async (view) => {
      if (!currentFeatureViewIds || !featureId) {
        throw new Error("Missing featureId or currentFeatureViewIds");
      }

      const newFeatureViewIds = remove
        ? currentFeatureViewIds.filter((id) => id !== view.id)
        : currentFeatureViewIds.concat(view.id);

      const res = await api.put<"/apps/:appId/features/:featureId/views">(
        `/apps/${appId}/features/${featureId}/views`,
        newFeatureViewIds,
      );

      return res.data;
    },
    onSuccess: async (_, view) => {
      toast({
        title: `Feature ${remove ? "removed from" : "added to"} feature view: ${
          view.name
        }`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      await queryClient.cancelQueries({ queryKey: queryKey, exact: true });
      await queryClient.invalidateQueries({ queryKey: queryKey });
      await queryClient.invalidateQueries({
        queryKey: featureViewQueryKeys.single(appId, view.id),
      });
    },
    onError: async (error, view) => {
      errorToast({
        title: `Failed ${remove ? "to remove" : "to add"} feature ${
          remove ? "from" : "to"
        } feature view: ${view.name}`,
        description: error.response?.data.error.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
}

export function useAddToFeatureViewMutation(featureId?: string) {
  const { data: featureViewsIds } = useFeatureFeatureViews(featureId);
  return useUpdateFeatureViewsMutation(featureId, featureViewsIds);
}

export function useRemoveFromFeatureViewMutation(featureId?: string) {
  const { data: featureViewsIds } = useFeatureFeatureViews(featureId);
  return useUpdateFeatureViewsMutation(featureId, featureViewsIds, true);
}

export function useAddToNewFeatureViewMutation(featureId?: string) {
  const { appId, envId } = useCurrentEnv();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorToast = useErrorToast();

  const queryKey = featureQueryKeys.featureViews(appId, featureId);

  return useMutation<FeatureViewDTO, AxiosError<ErrorResponse>, string>({
    mutationKey: queryKey,

    mutationFn: async (name: string) => {
      const res = await api.post<"/apps/:appId/feature-views">(
        `/apps/${appId}/feature-views`,
        {
          name,
          featureIds: featureId ? [featureId] : [],
        },
      );

      return res.data;
    },
    onSuccess: async (data) => {
      toast({
        title: `New feature view created: ${data.name}`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      await queryClient.cancelQueries({ queryKey: queryKey, exact: true });

      await queryClient.invalidateQueries({ queryKey: queryKey });

      await queryClient.invalidateQueries({
        queryKey: featureViewQueryKeys.list(appId),
      });
      queryClient.invalidateQueries({
        queryKey: featureQueryKeys.single(appId, envId, featureId),
      });
    },
    onError: (error) => {
      errorToast({
        title: "Failed to create new feature view",
        description: error.response?.data.error.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
}
