import { MouseEventHandler } from "react";

export function linkClickModifierHandler(
  url: string,
  cb?: MouseEventHandler,
): MouseEventHandler {
  return (e) => {
    const { altKey, ctrlKey, metaKey, shiftKey, button } = e;

    // Pressing command or control opens link in a new tab
    if (metaKey || ctrlKey || button === 1) {
      return window.open(url, "_blank");
    }

    // Pressing shift opens link in a new window
    if (shiftKey) {
      return window.open(url, "_blank", ``);
    }

    // Pressing alt triggers a download of the url
    if (altKey) {
      // We probably don't want to attempt this
    }

    return cb?.(e);
  };
}
