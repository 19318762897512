import { useQuery } from "@tanstack/react-query";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

export function useUserFeedbackPromptingStatus(userId?: string) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: ["apps", appId, "environments", envId, "users", userId, "status"],

    queryFn: async () => {
      if (!appId || !userId) {
        return Promise.resolve("unspecified");
      }

      const res = await api.get<"/apps/:appId/users/:userId/status">(
        `/apps/${appId}/users/${userId}/status`,
        { params: { envId: envId! } },
      );
      return res.data.status;
    },

    enabled: !!appId && !!envId && !!userId,
  });
}
