/**
 * Merge multiple functions into one.
 * @param functions - Functions to merge.
 * @returns Merged function.
 */
export const mergeFunctions = <T extends (...args: any[]) => void>(
  ...functions: (T | undefined)[]
): T => {
  return ((...args: any[]) => {
    functions.forEach((fn) => fn?.(...args));
  }) as T;
};
