import axios from "@ianmartorell/restyped-axios";
import { AxiosError } from "axios";

import APIDef from "@bucketco/shared/api";

import { API_URL } from "@/common/utils/env";
import navigateToLogin from "@/common/utils/navigateToLogin";

// TODO: Cypress tests that imports this file (AttributeFilters.tsx)
// currently breaks because Cypress uses CRA's webpack template and files in "shared"
// is not allowed due to the ModuleScopePlugin
// Example of the error here: https://github.com/bucketco/bucket-web/actions/runs/3144577835/jobs/5110767881
// Temp workaround is to define the const directly in here instead

// import { SCOPED_ACCESS_TOKEN_AUTH_HEADER } from "@bucketco/shared/screenshot";
const SCOPED_ACCESS_TOKEN_AUTH_HEADER = "featureAccessToken";

function headers() {
  // The following code allows a user/bot to request a page and pass
  // a token along in the hash: /path#token=<token>. The token is
  // extracted here and sent along in any requests made from the page.
  // It allows us to give out URLs that render without additional
  // authentication such as a cookie or similar. It's useful for rendering
  // graphs from external services.
  //
  // We use the hash instead of a regular query parameter to avoid
  // sending the hash to the server and get it logged there.
  const hash = window.location.hash;
  const searchParamsFromHash = new URLSearchParams(hash.substring(1));
  const token = searchParamsFromHash.get(SCOPED_ACCESS_TOKEN_AUTH_HEADER);
  const headers = token
    ? {
        Authorization: `${SCOPED_ACCESS_TOKEN_AUTH_HEADER} ${token}`,
      }
    : undefined;
  return headers;
}

function api() {
  axios.defaults.withCredentials = true;

  const apiInstance = axios.create<APIDef>({
    baseURL: API_URL,
    headers: headers(),
  });

  apiInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    (err: AxiosError) => {
      // User is not authenticated
      // Go to login page with a redirect back to the current location
      if (err.response?.status === 401) {
        navigateToLogin();
      }

      throw err;
    },
  );

  return apiInstance;
}

export default api();
