import { Box, Table, Tbody, Td, Tr, useColorModeValue } from "@chakra-ui/react";

import { UseFeedbackPromptStatisticsType } from "@/common/hooks/useFeedbackPromptStatistics";

export default function FeedbackPromptStatisticsTable({
  data: stats,
}: UseFeedbackPromptStatisticsType) {
  const repliedColor = useColorModeValue("brand.500", "brand.500");
  const dismissedColor = useColorModeValue("red.200", "red.400");
  const ignoredColor = useColorModeValue("gray.300", "gray.550");

  return (
    <Table
      fontSize="sm"
      size="xs"
      sx={{
        td: {
          py: 1,
          px: 4,
        },
      }}
      variant="unstyled"
    >
      <Tbody>
        <Tr>
          <Td color="dimmed" fontWeight="medium" verticalAlign="top">
            Total asked (7d)
          </Td>
          <Td textAlign="right">{stats?.counts.total}</Td>
        </Tr>
        <Tr>
          <Td
            alignItems="center"
            color="dimmed"
            display="flex"
            fontWeight="medium"
            gap={1.5}
            verticalAlign="top"
          >
            <Box bg={ignoredColor} h={2} rounded="full" w={2} /> Ignored
          </Td>
          <Td textAlign="right">{stats?.counts.other}</Td>
        </Tr>
        <Tr>
          <Td
            alignItems="center"
            color="dimmed"
            display="flex"
            fontWeight="medium"
            gap={1.5}
            verticalAlign="top"
          >
            <Box bg={dismissedColor} h={2} rounded="full" w={2} /> Dismissed
          </Td>
          <Td textAlign="right">{stats?.counts.dismissed}</Td>
        </Tr>
        <Tr>
          <Td
            alignItems="center"
            color="dimmed"
            display="flex"
            fontWeight="medium"
            gap={1.5}
            verticalAlign="top"
          >
            <Box bg={repliedColor} h={2} rounded="full" w={2} /> Responded
          </Td>
          <Td textAlign="right">{stats?.counts.replied}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
