import { Box, Flex, TagLabel, Text } from "@chakra-ui/react";

import { SimplePieChart } from "@/common/charts/components/SimplePieChart";

type EmptyFilterItemProps = {
  showIcon?: boolean;
};

export function EmptyFilterItem({ showIcon }: EmptyFilterItemProps) {
  return (
    <TagLabel whiteSpace="normal">
      <Flex align="center" columnGap={2} wrap="wrap">
        {showIcon && (
          <Box as="span" color="dimmed" fontSize="14px">
            <SimplePieChart value={100} />
          </Box>
        )}
        <Text as="span">No filter, all companies included</Text>
      </Flex>
    </TagLabel>
  );
}
