import React, { ReactNode } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Link, Text } from "@chakra-ui/react";
import { QueryStatus } from "@tanstack/react-query";

import { FeatureSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import EmptyState, { EmptyStateProps } from "@/common/components/EmptyState";

type Props = Omit<EmptyStateProps, "isLoading"> & {
  status?: QueryStatus;
  children?: ReactNode;
  hasData?: boolean;
};

export const WidgetState = ({ status, hasData, children, ...rest }: Props) => {
  const { currentEnv } = useAuthContext();
  const { featureId, featureName } = useParams();

  if (status === "success" && hasData) {
    return <>{children}</>;
  }
  return (
    <EmptyState
      description={
        status === "pending" ? null : status === "error" ? (
          "Could not load feedback"
        ) : (
          <Text color="dimmed" fontSize="sm">
            Start collecting feedback with{" "}
            <Link
              as={RouterLink}
              to={FeatureSettingsUrl(
                currentEnv!,
                { id: featureId!, name: featureName! },
                "feedback",
              )}
            >
              automated feedback surveys
            </Link>{" "}
            in no time
          </Text>
        )
      }
      h="full"
      w="full"
      {...rest}
    />
  );
};
