import { Text, TextProps, Tooltip } from "@chakra-ui/react";

import { HighPercentageFormatter } from "@/common/utils/numberFormatting";

const labels = {
  1: "Very low",
  2: "Low",
  3: "Medium",
  4: "High",
  5: "Very high",
} as const;
type RateStep = keyof typeof labels;

type StarsRateLabelProps = TextProps & {
  /** A fraction in the range [0;1] */
  rate: number;
};

export function StarsRateLabel({ rate, ...rest }: StarsRateLabelProps) {
  const rateStep = Math.min(5, Math.floor(rate * 5) + 1) as RateStep;

  return (
    <Tooltip
      label={HighPercentageFormatter.format(rate)}
      openDelay={300}
      placement="top"
      hasArrow
    >
      <Text as="span" {...rest}>
        {labels[rateStep]}
      </Text>
    </Tooltip>
  );
}
