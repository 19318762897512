import { useAuthContext } from "@/auth/contexts/authContext";
import { API_URL } from "@/common/utils/env";

export default function useSlackConnectionUrl(): string {
  const { currentOrg, currentApp } = useAuthContext();

  if (!currentOrg || !currentApp) {
    return "";
  }

  const redirect = window.location.href.replace(window.location.origin, "");

  const searchParams = {
    appId: currentApp.id,
    appName: currentApp.name,
    redirect,
  };
  return `${API_URL}/orgs/${currentOrg.id}/connect-slack?${new URLSearchParams(
    searchParams,
  ).toString()}`;
}
