import { FunctionComponent, SVGProps } from "react";
import {
  chakra,
  ChakraComponent,
  ChakraProps,
  ColorMode,
  useColorMode,
} from "@chakra-ui/react";

import StepAdoptedSvg from "@/common/assets/step-adopted.svg?react";
import StepAdoptedLightSvg from "@/common/assets/step-adopted-light.svg?react";
import StepNeverSvg from "@/common/assets/step-never.svg?react";
import StepNoneSvg from "@/common/assets/step-none.svg?react";
import StepNoneLightSvg from "@/common/assets/step-none-light.svg?react";
import StepRetainedSvg from "@/common/assets/step-retained.svg?react";
import StepRetainedLightSvg from "@/common/assets/step-retained-light.svg?react";
import StepSatisfiedSvg from "@/common/assets/step-satisfied.svg?react";
import StepSatisfiedLightSvg from "@/common/assets/step-satisfied-light.svg?react";
import StepTriedSvg from "@/common/assets/step-tried.svg?react";
import StepTriedLightSvg from "@/common/assets/step-tried-light.svg?react";

type Step = "none" | "never" | "tried" | "adopted" | "retained" | "satisfied";

const ICONS: Record<
  ColorMode,
  Record<Step, ChakraComponent<FunctionComponent<SVGProps<SVGSVGElement>>>>
> = {
  light: {
    none: chakra(StepNoneLightSvg),
    never: chakra(StepNeverSvg),
    adopted: chakra(StepAdoptedLightSvg),
    retained: chakra(StepRetainedLightSvg),
    satisfied: chakra(StepSatisfiedLightSvg),
    tried: chakra(StepTriedLightSvg),
  },
  dark: {
    none: chakra(StepNoneSvg),
    never: chakra(StepNeverSvg),
    adopted: chakra(StepAdoptedSvg),
    retained: chakra(StepRetainedSvg),
    satisfied: chakra(StepSatisfiedSvg),
    tried: chakra(StepTriedSvg),
  },
};

type Props = ChakraProps & { step: Step };

export default function StarsChartIcon({ step, ...props }: Props) {
  const x = useColorMode();
  const Icon = ICONS[x.colorMode][step];

  return <Icon {...props} />;
}
