import { useQuery } from "@tanstack/react-query";

import { FlagVersionQueryType } from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";
import { flagQueryKeys } from "@/flags/data/flagQueryKeys";

export function useFlagVersionsData(envId?: string, flagId?: string) {
  const { currentApp } = useAuthContext();

  const params: FlagVersionQueryType = {
    sortOrder: "desc",
    pageSize: 100,
    pageIndex: 0,
    envId: envId!,
  };

  return useQuery({
    queryKey: flagQueryKeys.versions(currentApp?.id, flagId, params),
    queryFn: () =>
      api
        .get<"/apps/:appId/flags/:flagId/versions">(
          `/apps/${currentApp?.id}/flags/${flagId}/versions`,
          { params },
        )
        .then((res) => res.data),
    enabled: !!currentApp && !!envId && !!flagId,
  });
}
