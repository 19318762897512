import React, { ReactNode } from "react";
import { RiBracesLine } from "react-icons/ri";
import { Box, Flex, HStack, Td, Text, Tr } from "@chakra-ui/react";

import PointerPressIcon from "@/common/assets/pointer-press.svg?react";
import SimpleTable from "@/common/components/SimpleTable";
import { useEventNames } from "@/common/data/useEventNames";
import useCompanyAttributesData from "@/company/data/useCompanyAttributesData";

const Item = React.memo(function Item({
  value,
  icon,
}: {
  value: string;
  icon: ReactNode;
}) {
  return (
    <Tr key={value}>
      <Td>
        <HStack spacing={2}>
          <Box color="dimmed">{icon}</Box>
          <Text fontSize="md" fontWeight="medium">
            {value}
          </Text>
        </HStack>
      </Td>
    </Tr>
  );
});

function ListEvents() {
  const { data: events, isLoading } = useEventNames();
  return (
    <>
      <SimpleTable
        columns={["Events"]}
        rows={events ?? []}
        rowTemplate={(event) => (
          <Item
            key={event}
            icon={<PointerPressIcon height="16px" width="16px" />}
            value={event}
          />
        )}
      />
      {!isLoading && events?.length === 0 && (
        <EmptyText>No events found</EmptyText>
      )}
    </>
  );
}

function ListAttributes() {
  const { data: attributesRaw, isLoading } = useCompanyAttributesData();

  // only list custom attributes
  const attributes = attributesRaw?.filter((i) => !i.system).map((i) => i.key);

  return (
    <>
      <SimpleTable
        columns={["Company attributes"]}
        rows={attributes ?? []}
        rowTemplate={(attribute) => (
          <Item
            key={attribute}
            icon={<RiBracesLine size={16} />}
            value={attribute}
          />
        )}
      />
      {!isLoading && attributes?.length === 0 && (
        <EmptyText>No company attributes found</EmptyText>
      )}
    </>
  );
}

function EmptyText({ children }: { children: ReactNode }) {
  return (
    <Text color="gray.500" fontSize="sm" px={6} py={4}>
      {children}
    </Text>
  );
}

export default function TrackingDistinct() {
  return (
    <Flex flexGrow={1}>
      <Box w="100%">
        <ListEvents />
      </Box>
      <Box borderLeft="1px solid" borderLeftColor="appBorder" w="100%">
        <ListAttributes />
      </Box>
    </Flex>
  );
}
