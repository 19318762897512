import { Navigate, useMatch } from "react-router";

import { EnvironmentUrl } from "@bucketco/shared/urls";

import InvalidRoute from "@/app/pages/InvalidRoute";
import { useAuthContext } from "@/auth/contexts/authContext";

export default function GoToDefaultEnv() {
  const { currentEnv } = useAuthContext();

  const match = useMatch("/envs/current/*");
  if (!currentEnv || !match || !match.params) return <InvalidRoute />;
  const path = match.params?.["*"];
  const newPath = EnvironmentUrl(currentEnv) + "/" + path;

  return <Navigate to={newPath} replace />;
}
