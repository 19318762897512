import { useEffect, useMemo, useRef, useState } from "react";
import { RiAddFill, RiCloseFill } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuProps,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import partition from "lodash/partition";

import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";
import { FeatureViewUrl } from "@bucketco/shared/urls";

import { useFeatureViewsData } from "@/app/data/useFeatureViewsData";
import { useAuthContext } from "@/auth/contexts/authContext";
import FeatureViewsIcon from "@/common/assets/feature-views-icon.svg?react";
import { highlightStringMatch } from "@/common/components/AutocompleteSelect";
import MenuDescription from "@/common/components/MenuDescription";
import MotionListItem from "@/common/components/MotionListItem";
import { SectionHeading } from "@/common/components/SectionHeading";
import { useFeatureFeatureViews } from "../data/useFeatureFeatureViews";
import {
  useAddToFeatureViewMutation,
  useAddToNewFeatureViewMutation,
  useRemoveFromFeatureViewMutation,
} from "../data/useFeatureViewMutations";

type FeatureViewsSelectorProps = {
  featureId?: string;
  popupMenuPlacement?: MenuProps["placement"];
  maxFeatureViewsToShow?: number;
};

function FeatureViewsSelector({
  featureId,
  popupMenuPlacement = "bottom-start",
  maxFeatureViewsToShow = 10,
}: FeatureViewsSelectorProps) {
  const { currentEnv } = useAuthContext();
  const { data: allFeatureViews, isLoading: isListLoading } =
    useFeatureViewsData();
  const { data: selectedFeatureViewsIds = [], isLoading: isCurrentLoading } =
    useFeatureFeatureViews(featureId);

  const [searchTerm, setSearchTerm] = useState("");
  const [focusMode, setFocusMode] = useState<-1 | 0 | 1>(0);

  const [selectedFeatureViews, availableFeatureViews] = useMemo(() => {
    const all = (allFeatureViews || []).filter(
      (view) => !view.isAllFeatureView,
    );
    return partition(all, (view) => selectedFeatureViewsIds.includes(view.id));
  }, [allFeatureViews, selectedFeatureViewsIds]);

  const [filteredFeatureViews, showCreateNew] = useMemo(() => {
    const term = searchTerm.trim().toLowerCase();
    const fil = availableFeatureViews.filter((view) =>
      view.name.toLowerCase().includes(term),
    );

    const cre =
      searchTerm.length > 0 &&
      fil.findIndex((view) => view.name.toLowerCase() === term) === -1;

    return [fil, cre];
  }, [searchTerm, availableFeatureViews]);

  const { mutate: addFeatureView } = useAddToFeatureViewMutation(featureId);
  const { mutate: removeFeatureView } =
    useRemoveFromFeatureViewMutation(featureId);

  const { mutate: addNewFeatureView } =
    useAddToNewFeatureViewMutation(featureId);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const firstMenuItemRef = useRef<HTMLButtonElement>(null);
  const lastMenuItemRef = useRef<HTMLButtonElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleMenuClose = () => {
    setSearchTerm("");
    onClose();
  };

  const handleFeatureViewAdd = (view: FeatureViewDTO) => {
    addFeatureView(view);
    handleMenuClose();
  };

  const handleFeatureViewRemove = (view: FeatureViewDTO) => {
    removeFeatureView(view);
    handleMenuClose();
  };

  const handleCreateFeatureView = (name: string) => {
    name = name.trim();
    if (name) {
      addNewFeatureView(name);
      handleMenuClose();
    }
  };

  useEffect(() => {
    switch (focusMode) {
      case -1:
        (lastMenuItemRef.current || firstMenuItemRef.current)?.focus();
        break;
      case 0:
        searchInputRef.current?.focus();
        break;
      case 1:
        (firstMenuItemRef.current || lastMenuItemRef.current)?.focus();
        break;
    }
  }, [focusMode]);

  const isLoading = isListLoading || isCurrentLoading;
  return (
    <Flex direction="column">
      <Flex align="center" direction="row">
        <SectionHeading variant="subtle">
          {selectedFeatureViews.length === 0
            ? "Add to feature views"
            : "Feature views"}
        </SectionHeading>
        <Spacer />
        {isLoading && !isOpen ? (
          <Box mx={2}>
            <Spinner color="dimmed" size="xs" />
          </Box>
        ) : (
          <Menu
            autoSelect={true}
            closeOnSelect={false}
            initialFocusRef={searchInputRef}
            isLazy={true}
            isOpen={isOpen}
            placement={popupMenuPlacement}
            onClose={handleMenuClose}
          >
            <MenuButton
              aria-label="Add to feature view"
              as={IconButton}
              color="dimmed"
              icon={<RiAddFill size={16} />}
              size="xs"
              variant="ghost"
              isRound
              onClick={onOpen}
            />
            <MenuList
              onFocus={() => focusMode === 0 && searchInputRef.current?.focus()}
              onKeyDown={(e) => {
                if (e.key === "Tab" && focusMode !== 0) {
                  searchInputRef.current?.focus();
                }
              }}
            >
              <MenuDescription>Select feature view to add to:</MenuDescription>
              <Box p={1}>
                <Input
                  ref={searchInputRef}
                  placeholder="Feature view name..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  onFocus={() => setFocusMode(0)}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "Tab") {
                      setFocusMode(-1);
                    } else if (e.key === "ArrowDown") {
                      setFocusMode(1);
                    }
                  }}
                />
              </Box>
              <MenuDivider my={0} />
              {filteredFeatureViews
                .slice(0, maxFeatureViewsToShow)
                .map((view, index) => (
                  <MenuItem
                    key={view.id}
                    ref={
                      index === 0
                        ? firstMenuItemRef
                        : index === filteredFeatureViews.length - 1
                        ? lastMenuItemRef
                        : undefined
                    }
                    closeOnSelect={true}
                    icon={<FeatureViewsIcon height="16px" width="16px" />}
                    iconSpacing={1}
                    onClick={() => handleFeatureViewAdd(view)}
                  >
                    {highlightStringMatch(view.name, searchTerm)}
                  </MenuItem>
                ))}
              <MenuDivider my={0} />
              {showCreateNew && (
                <MenuItem
                  key="create-new"
                  ref={lastMenuItemRef}
                  icon={<RiAddFill size={16} />}
                  iconSpacing={1}
                  isFocusable={true}
                  onClick={() => handleCreateFeatureView(searchTerm)}
                >
                  Create <strong>{searchTerm}</strong>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
      </Flex>
      <AnimatePresence initial={false}>
        {selectedFeatureViews.map((view) => (
          <MotionListItem key={view.id}>
            <Flex align="center" justify="space-between" width="100%">
              <Link
                as={RouterLink}
                textDecoration={"none"}
                to={FeatureViewUrl(currentEnv!, view.id)}
              >
                <HStack w={"100%"}>
                  <Box color="dimmed">
                    <FeatureViewsIcon height="16px" width="16px" />
                  </Box>
                  <Text fontSize="sm">{view.name}</Text>
                </HStack>
              </Link>
              <IconButton
                aria-label="Remove from feature view"
                color="dimmed"
                size="xs"
                variant="ghost"
                isRound
                onClick={() => handleFeatureViewRemove(view)}
              >
                <RiCloseFill size={16} />
              </IconButton>
            </Flex>
          </MotionListItem>
        ))}
      </AnimatePresence>
    </Flex>
  );
}

export default FeatureViewsSelector;
