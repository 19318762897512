import {
  Table,
  TableColumnHeaderProps,
  TableProps,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

interface THeaderObject {
  children: React.ReactNode;
  props: TableColumnHeaderProps;
}

function isTHeaderObject(value: any): value is THeaderObject {
  return Boolean(value.props) && Boolean(value.children);
}

interface SimpleTableProps<RowType> {
  colProps?: React.HTMLProps<HTMLTableColElement>[];
  columns: (THeaderObject | React.ReactNode)[];
  rows: readonly RowType[];
  rowTemplate: (row: RowType, index: number) => JSX.Element;
}

export default function SimpleTable<RowType>({
  colProps = [],
  columns,
  rows,
  rowTemplate,
  ...props
}: TableProps & SimpleTableProps<RowType>) {
  return (
    <Table w="100%" {...props}>
      <colgroup>
        {colProps.map((props, idx) => (
          <col key={idx} {...props} />
        ))}
      </colgroup>
      <Thead>
        <Tr>
          {columns.map((column, index) => {
            if (isTHeaderObject(column)) {
              return (
                <Th {...column.props} key={index}>
                  {column.children}
                </Th>
              );
            }

            return <Th key={index}>{column}</Th>;
          })}
        </Tr>
      </Thead>
      <Tbody>{rows.map((row, index) => rowTemplate(row, index))}</Tbody>
    </Table>
  );
}
