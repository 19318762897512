import { FunnelStep } from "@bucketco/shared/featureAPI";

import StarsStateIcon from "@/common/components/StarsStateIcon";
import { StarsFunnelStepVisualization } from "@/feature/components/StarsFunnelStepVisualization";

export type StarsFunnelDescription = {
  id: FunnelStep;
  label: string;
  description: string;
  visualization: JSX.Element;
};

export const starsFunnelStepDescriptions: StarsFunnelDescription[] = [
  {
    id: "company",
    label: "None",
    description: "never interacted",
    visualization: <StarsFunnelStepVisualization step="company" />,
  },
  {
    id: "tried",
    label: "Tried",
    description: "only tried",
    visualization: <StarsFunnelStepVisualization step="tried" />,
  },
  {
    id: "adopted",
    label: "Adopted",
    description: "used but then churned",
    visualization: <StarsFunnelStepVisualization step="adopted" />,
  },
  {
    id: "retained",
    label: "Retained",
    description: "currently using it",
    visualization: <StarsFunnelStepVisualization step="retained" />,
  },
];

export const starsFunnelStateDescriptions: StarsFunnelDescription[] = [
  {
    id: "company",
    label: "Never",
    description: "never interacted",
    visualization: <StarsStateIcon state="never" />,
  },
  {
    id: "tried",
    label: "Tried",
    description: "only tried the feature (in Tried step)",
    visualization: <StarsStateIcon state="tried" />,
  },
  {
    id: "retained",
    label: "Retained",
    description: "currently using it (in Retained step)",
    visualization: <StarsStateIcon state="retained" />,
  },
  {
    id: "adopted",
    label: "Churned",
    description: "used but then churned (in Adopted step)",
    visualization: <StarsStateIcon state="churned" />,
  },
];
