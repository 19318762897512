import { MenuGroup, MenuGroupProps } from "@chakra-ui/react";
import { cx } from "@chakra-ui/shared-utils";

import {
  useMenuReorderOptionGroup,
  UseMenuReorderOptionGroupProps,
} from "@/common/components/Menu/useMenuReorderOptionGroup";

export type MenuReorderOptionGroupProps = UseMenuReorderOptionGroupProps &
  Omit<MenuGroupProps, "value" | "defaultValue" | "onChange">;

export const MenuReorderOptionGroup: React.FC<MenuReorderOptionGroupProps> = (
  props,
) => {
  const { className, title, ...rest } = props;
  const ownProps = useMenuReorderOptionGroup(rest);
  return (
    <MenuGroup
      className={cx("chakra-menu__option-group", className)}
      title={title}
      {...ownProps}
    />
  );
};

MenuReorderOptionGroup.displayName = "MenuReorderOptionGroup";
