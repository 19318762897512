import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet, useNavigate } from "react-router";
import {
  Alert,
  AlertDescription,
  Divider,
  HStack,
  VisuallyHidden,
} from "@chakra-ui/react";

import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";
import { FeatureViewUrl, GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useFeatureViewsData } from "@/app/data/useFeatureViewsData";
import { useAuthContext } from "@/auth/contexts/authContext";
import HeaderLayout from "@/common/components/HeaderLayout";
import SlackStatusBadge from "@/common/components/slack/SlackStatusBadge";
import { UpsertPopover } from "@/common/components/UpsertPopover";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useFeatureViewParam } from "@/common/hooks/useFeatureViewParam";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { useTableDisplayModeParam } from "@/common/hooks/useTableDisplayMode";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { FeaturesTable } from "@/feature/components/FeaturesTable";
import { FeatureViewNavigator } from "@/feature/components/FeatureViewNavigator";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";
import { useFeaturesData } from "@/feature/data/useFeaturesData";
import { useFeatureViewUpsertMutations } from "@/feature/data/useFeatureViewUpsertMutations";
import { useFeaturesTableState } from "@/feature/hooks/useFeaturesTableState";

function Page({ view }: { view: FeatureViewDTO }) {
  const { currentEnv } = useAuthContext();
  const navigate = useNavigate();
  const [subsegment, _setSubsegment, isSubsegmentLoading] = useSubsegmentParam([
    "Active",
  ]);
  const [displayMode] = useTableDisplayModeParam();

  const { isDirty, onSortingChange, onColumnStatesChange } =
    useFeaturesTableState(view);

  const {
    data: features = [],
    isLoading: isLoadingFeatures,
    isError: isErrorFeatures,
  } = useFeatureNamesData({
    viewId: view.id,
  });

  const featureViewUpsert = useFeatureViewUpsertMutations(view, features, {
    onSuccess: ({ id }, { action }) => {
      if (action == "create") navigate(FeatureViewUrl(currentEnv!, id));
    },
  });

  const { sorting } = useFeaturesTableState(view);

  // We only handle sorting one column for now
  const { id: sortBy, desc: sortDesc } = sorting[0] ?? {};
  const { data, isLoading, isFetching } = useFeaturesData(
    {
      sortBy,
      sortOrder:
        sortDesc === true ? "desc" : sortDesc === false ? "asc" : undefined,
      view: view.id,
      subsegment: subsegment[0],
      sortType: displayMode === "tree" ? "hierarchical" : "flat",
    },
    !isSubsegmentLoading,
  );

  useEffect(() => {
    segmentAnalytics.page("Features", {
      view: view.isAllFeatureView ? view.name.toLowerCase() : "custom",
    });
  }, [view.isAllFeatureView, view.name]);

  return (
    <>
      <VisuallyHidden>
        <h1>{view.name}</h1>
      </VisuallyHidden>
      <HeaderLayout
        actions={
          <HStack spacing={4}>
            {isDirty && (
              <>
                <UpsertPopover
                  canUpdate={!isLoadingFeatures && !isErrorFeatures}
                  entity="Feature view"
                  mutation={featureViewUpsert}
                  onReset={() => {
                    onSortingChange([]);
                    onColumnStatesChange([]);
                  }}
                >
                  {isErrorFeatures && (
                    <Alert status="warning">
                      <AlertDescription>
                        Creation of a new feature view is disabled because
                        features could not be loaded.
                      </AlertDescription>
                    </Alert>
                  )}
                </UpsertPopover>
                <Divider
                  alignSelf="stretch"
                  height="auto"
                  orientation="vertical"
                />
              </>
            )}
          </HStack>
        }
        title={
          <HStack ml={-2} spacing={1}>
            <FeatureViewNavigator currentView={view} />
            {currentEnv && (
              <SlackStatusBadge
                active={view.slackWeeklyReport}
                settingsLink={GlobalSettingsUrl(
                  currentEnv,
                  "app-feature-views",
                )}
                slackChannel={view.slackChannel}
                tooltip="Weekly report is sent to the Slack channel containing the all features in the view and highlighting the new features added in the last week."
              />
            )}
          </HStack>
        }
        borderless
      >
        <Helmet>
          <title>{`Features › ${view.name}`}</title>
        </Helmet>
        <FeaturesTable
          data={data}
          isFetching={isFetching}
          isLoading={isLoading || isSubsegmentLoading}
          subsegment={subsegment}
          view={view}
        />
        <Outlet />
      </HeaderLayout>
    </>
  );
}

export default function Features() {
  const errorToast = useErrorToast();
  const [viewId, setViewId] = useFeatureViewParam();

  const { data: featureViews, isSuccess } = useFeatureViewsData();
  const allFeaturesView = featureViews?.find((v) => v.isAllFeatureView);
  const currentView = viewId
    ? featureViews?.find((v) => v.id === viewId)
    : allFeaturesView;

  useEffect(() => {
    if (viewId && isSuccess && !currentView) {
      errorToast({
        description: `Could not find the feature view, redirecting to All features`,
        duration: 4000,
      });

      if (allFeaturesView) {
        setViewId(allFeaturesView.id);
      }
    }
  }, [viewId, isSuccess, currentView, errorToast, setViewId, allFeaturesView]);

  if (!currentView) return;

  return <Page key={currentView.id} view={currentView} />;
}
