import { ReactNode } from "react";
import {
  Grid,
  GridProps,
  ListItem as ChakraListItem,
  ListItemProps as ChakraListItemProps,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  DragControls,
  motion,
  MotionProps,
  useMotionValue,
} from "framer-motion";

import { ReorderListItem } from "@/common/components/ReorderList";
import { useRaisedShadow } from "@/common/hooks/useRaisedShadow";

const MotionGrid = motion<GridProps>(Grid);

export type ListLayoutProps = {
  children: ReactNode;
  showAsCard?: boolean;
} & GridProps &
  MotionProps;

export function ListLayout({
  children,
  showAsCard = false,
  ...props
}: ListLayoutProps) {
  const cardBg = useColorModeValue("gray.25", "gray.800");
  const cardBorder = useColorModeValue("gray.100", "gray.750");

  return (
    <MotionGrid
      alignItems="center"
      bg={showAsCard ? cardBg : undefined}
      border={showAsCard ? "1px solid" : undefined}
      borderColor={showAsCard ? cardBorder : undefined}
      borderRadius="lg"
      gridGap={4}
      minH={10}
      px={4}
      py={1}
      layout
      {...props}
    >
      {children}
    </MotionGrid>
  );
}

type ListItemProps<T> = Omit<
  ChakraListItemProps,
  "value" | "color" | keyof MotionProps
> & {
  value?: T;
  canDrag?: boolean;
  dragControls?: DragControls;
  children?: ReactNode;
};

export const ListItem = <T,>({
  value,
  canDrag,
  dragControls,
  children,
  ...rest
}: ListItemProps<T>) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const cardBg = useColorModeValue("gray.25", "gray.800");
  const cardBorder = useColorModeValue("gray.100", "gray.750");

  const sharedProps = {
    display: "grid",
    gridTemplateColumns: "2fr 1fr 1fr 64px",
    alignItems: "center",
    bg: cardBg,
    border: "1px solid",
    borderColor: cardBorder,
    borderRadius: "lg",
    gridGap: 4,
    minH: 10,
    px: 4,
    py: 2,
    ...rest,
  };

  if (canDrag) {
    return (
      <ReorderListItem
        {...sharedProps}
        animate={{ opacity: 1 }}
        dragControls={canDrag ? dragControls : undefined}
        dragListener={canDrag ? false : undefined}
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        position="relative"
        style={{ y, boxShadow }}
        value={value}
      >
        {children}
      </ReorderListItem>
    );
  } else {
    return <ChakraListItem {...sharedProps}>{children}</ChakraListItem>;
  }
};
