import { HStack, Text, TextProps, Tooltip } from "@chakra-ui/react";

import { FunnelStep } from "@bucketco/shared/featureAPI";

import { starsFunnelStateDescriptions } from "@/feature/data/starsFunnelDescriptions";

import NotAvailableCell from "./NotAvailableCell";

type Props = {
  value: FunnelStep;
} & TextProps;

export default function FunnelStateCell({ value, ...props }: Props) {
  const description = starsFunnelStateDescriptions.find(
    ({ id }) => id === value,
  );

  return value !== "company" && description ? (
    <Tooltip
      label={description.description}
      openDelay={300}
      placement="bottom-start"
      hasArrow
    >
      <HStack spacing={2}>
        {description.visualization}
        <Text {...props}>{description.label}</Text>
      </HStack>
    </Tooltip>
  ) : (
    <NotAvailableCell />
  );
}
