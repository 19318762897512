import { useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

export function useSidebar(...args: (string | undefined)[]) {
  const context = args.filter(Boolean).join(".");

  const key = `side-bar:${context}`;
  const [isVisible, setShow] = useLocalStorage(key, true);

  return useMemo(
    () => ({
      isVisible: context ? isVisible : false,
      show: context ? () => setShow(true) : () => {},
      hide: context ? () => setShow(false) : () => {},
    }),
    [context, isVisible, setShow],
  );
}
