import { ForwardedRef, forwardRef, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  Text,
  useColorModeValue,
  useFormControl,
} from "@chakra-ui/react";

import {
  AttributeField,
  AttributeFilterType,
} from "@bucketco/shared/attributeFilter";

import AutocompleteInput from "@/common/components/AutocompleteInput";
import { MultiValueInput } from "@/common/components/MultiValueInput";
import {
  EntityType,
  useAttributeValues,
} from "@/common/data/useAttributeValues";
import { useDebounce } from "@/common/hooks/useDebounce";

export type FieldType =
  | "autoComplete"
  | "select"
  | "multiSelect"
  | "number"
  | "date"
  | "list"
  | "none";

export function getFieldTypeFromAttributeType(
  type: AttributeFilterType,
): FieldType {
  switch (type) {
    case "text":
      return "autoComplete";
    case "number":
      return "number";
    case "date":
      return "date";
    case "list":
      return "list";
    default:
      return "none";
  }
}

type RuleValueProps<T extends string> = Omit<
  ButtonProps & InputProps & NumberInputProps,
  "value" | "onChange" | "type" | "autoComplete"
> & {
  onChange: (nextValue: T | T[]) => void;
  attributeKey?: AttributeField["key"];
  entityType: EntityType;
  entityValue?: string;
  fieldType: FieldType;
  value: T | T[];
};

function RuleValueInner<T extends string>(
  {
    value,
    onChange,
    attributeKey,
    fieldType,
    entityType,
    entityValue,
    ...rest
  }: RuleValueProps<T>,
  ref: ForwardedRef<HTMLInputElement | HTMLButtonElement>,
) {
  switch (fieldType) {
    case "autoComplete": {
      return (
        <AutoCompleteValueField
          ref={ref as ForwardedRef<HTMLButtonElement>}
          attributeKey={attributeKey}
          entityType={entityType}
          entityValue={entityValue}
          value={value as T}
          onChange={onChange}
          {...rest}
        />
      );
    }
    case "select": {
      return (
        <SelectValueField
          ref={ref as ForwardedRef<HTMLButtonElement>}
          attributeKey={attributeKey}
          entityType={entityType}
          entityValue={entityValue}
          value={value as T}
          onChange={onChange}
          {...rest}
        />
      );
    }
    case "multiSelect": {
      return (
        <MultiSelectValueField
          ref={ref as ForwardedRef<HTMLButtonElement>}
          attributeKey={attributeKey}
          entityType={entityType}
          value={value as T[]}
          onChange={onChange}
          {...rest}
        />
      );
    }
    case "number": {
      return (
        <InputGroup>
          <NumberInput
            ref={ref}
            background="appBackground"
            min={0}
            role="input"
            value={value as T}
            w="100%"
            onChange={(nextValue) => onChange(nextValue as T)}
            {...rest}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </InputGroup>
      );
    }
    case "date": {
      return (
        <InputGroup>
          <NumberInput
            ref={ref}
            bg="appBackground"
            min={0}
            role="input"
            value={value as T}
            w="100%"
            onChange={(nextValue) => onChange(nextValue as T)}
            {...rest}
          >
            <NumberInputField />
            <InputRightElement
              color="dimmed"
              fontSize="xs"
              fontWeight="semibold"
              h="100%"
              px={3}
              right={6}
              textTransform="uppercase"
              w="auto"
            >
              days ago
            </InputRightElement>
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </InputGroup>
      );
    }
    case "list":
      return (
        <AutoCompleteMultiValueField
          attributeKey={attributeKey}
          entityType={entityType}
          entityValue={entityValue}
          placeholder="Attribute value"
          value={(value ?? []) as any as T[]}
          onChange={(nextValue) => onChange(nextValue as T[])}
        />
      );
    case "none":
      return null;
  }
}

// AutoComplete

const RuleValue = forwardRef(RuleValueInner) as (<T extends string>(
  props: RuleValueProps<T> & {
    ref?: ForwardedRef<HTMLInputElement | HTMLButtonElement>;
  },
) => ReturnType<typeof RuleValueInner>) & { displayName: string };

RuleValue.displayName = "RuleValue";

export default RuleValue;

type AutoCompleteValueFieldProps<T extends string> = Omit<
  InputProps,
  "type" | "value" | "onChange"
> & {
  value: T | undefined;
  onChange: (nextValue: T) => void;
  attributeKey?: AttributeField["key"];
  entityType: EntityType;
  entityValue?: string;
};

function AutoCompleteValueFieldInner<T extends string>(
  {
    value,
    onChange,
    attributeKey,
    entityType,
    entityValue,
    ...rest
  }: AutoCompleteValueFieldProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const searchValue = useDebounce(value ? String(value) : "", 100);

  const { data = [] } = useAttributeValues({
    entityType,
    entityValue,
    attributeKey,
    searchValue,
  });

  return (
    <AutocompleteInput
      search={searchValue}
      suggestions={data.map((v) => ({
        key: v.value,
        label: v.label,
        icon: v.icon,
      }))}
      onValuePick={(pick) => onChange(pick.key as T)}
    >
      <Input
        ref={ref}
        background="appBackground"
        placeholder="Attribute value"
        role="input"
        type="text"
        value={value}
        onChange={(e) => onChange(e.currentTarget.value as T)}
        {...rest}
      />
    </AutocompleteInput>
  );
}

const AutoCompleteValueField = forwardRef(AutoCompleteValueFieldInner) as (<
  T extends string,
>(
  props: AutoCompleteValueFieldProps<T> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof AutoCompleteValueFieldInner>) & { displayName: string };

AutoCompleteValueField.displayName = "AutoCompleteValueField";

// Autocomplete multi-value
type AutoCompleteMultiValueFieldProps<T extends string = string> = Omit<
  InputProps,
  "type" | "value" | "onChange"
> & {
  value: T[];
  onChange: (nextValue: T[]) => void;
  attributeKey?: AttributeField["key"];
  entityType: EntityType;
  entityValue?: string;
};

const AutoCompleteMultiValueField = forwardRef<
  HTMLInputElement,
  AutoCompleteMultiValueFieldProps
>(function AutoCompleteMultiValueField(
  { value, onChange, attributeKey, entityType, entityValue, ...rest },
  ref,
) {
  const [searchValue, setSearchValue] = useState("");

  const { data = [] } = useAttributeValues({
    entityType,
    entityValue,
    attributeKey,
    searchValue: useDebounce(searchValue ? String(searchValue) : "", 100),
  });

  const filteredSuggestions = data.filter((s) => !value.includes(s.value));

  return (
    <AutocompleteInput
      search={searchValue}
      suggestions={filteredSuggestions.map((v) => ({
        key: v.value,
        label: v.label,
        icon: v.icon,
      }))}
      onValuePick={(pick) => onChange([...value, pick.key])}
    >
      <MultiValueInput
        ref={ref}
        {...rest}
        values={value}
        onChange={(values) => onChange(values)}
        onSearchInput={setSearchValue}
      />
    </AutocompleteInput>
  );
});

// Select

type SelectValueFieldProps<T extends string> = Omit<
  ButtonProps,
  "type" | "value" | "onChange"
> & {
  value: T | undefined;
  onChange: (nextValue: T) => void;
  attributeKey?: AttributeField["key"];
  entityType: EntityType;
  entityValue?: string;
};

function SelectValueFieldInner<T extends string>(
  {
    value,
    onChange,
    attributeKey,
    entityType,
    entityValue,
    ...rest
  }: SelectValueFieldProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  // Custom input components (such as menu rather than select)
  // need to hook into the form control context to handle state
  const formControlProps = useFormControl<HTMLButtonElement>(rest);
  const { data = [] } = useAttributeValues({
    entityType,
    entityValue,
    attributeKey,
  });

  const selection = data.find((d) => d.value === value);

  const activeColor = useColorModeValue("brand.500", "brand.300");

  return (
    <Menu size="xs">
      <MenuButton
        ref={ref}
        as={Button}
        background="appBackground"
        data-testid="rule-operator"
        isDisabled={formControlProps.disabled}
        px={3}
        rightIcon={
          <Box fontSize="xl" mr={-2}>
            <RiArrowDownSLine />
          </Box>
        }
        variant="input"
        {...formControlProps}
      >
        {selection ? (
          <HStack spacing={2}>
            {selection?.icon && (
              <Box color="dimmed" fontSize="14px">
                {selection?.icon}
              </Box>
            )}
            <Text display="block" noOfLines={1}>
              {selection?.label}
            </Text>
          </HStack>
        ) : (
          <Text color="dimmed">Select a value</Text>
        )}
      </MenuButton>
      <MenuList maxH="sm" overflowY="auto">
        {data.map((option) => (
          <MenuItem
            key={option.value}
            color={value === option.value ? activeColor : undefined}
            icon={option.icon}
            iconSpacing={2}
            onClick={() => onChange(option.value as T)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

const SelectValueField = forwardRef(SelectValueFieldInner) as (<
  T extends string,
>(
  props: SelectValueFieldProps<T> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof SelectValueFieldInner>) & { displayName: string };

SelectValueField.displayName = "SelectValueField";

// MultiSelect

type MultiSelectValueFieldProps<T extends string> = Omit<
  ButtonProps,
  "type" | "value" | "onChange"
> & {
  value: T[];
  onChange: (nextValue: T[]) => void;
  attributeKey?: AttributeField["key"];
  entityType: EntityType;
};

function MultiSelectValueFieldInner<T extends string>(
  {
    value,
    onChange,
    attributeKey,
    entityType,
    ...rest
  }: MultiSelectValueFieldProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const formControlProps = useFormControl<HTMLButtonElement>(rest);
  const { data = [] } = useAttributeValues({
    entityType,
    attributeKey,
  });

  const selection = data.filter((d) => value.includes(d.value as T));

  const activeColor = useColorModeValue("brand.500", "brand.300");

  const Selection = () => {
    if (selection.length === 0) {
      return <Text color="dimmed">Select values</Text>;
    }

    const showMax = 2;
    return (
      <HStack spacing={1}>
        {selection.slice(0, showMax).map((s) => (
          <HStack key={s.value} spacing={0.5}>
            {s.icon && (
              <Box color="dimmed" fontSize="14px">
                {s.icon}
              </Box>
            )}
            <Text>{s.label}</Text>
          </HStack>
        ))}
        {selection.length > showMax && <Text as="span">...</Text>}
      </HStack>
    );
  };

  return (
    <Menu closeOnSelect={false} size="xs">
      <MenuButton
        ref={ref}
        as={Button}
        background="appBackground"
        data-testid="rule-operator"
        isDisabled={formControlProps.disabled}
        px={3}
        rightIcon={
          <Box fontSize="xl" mr={-2}>
            <RiArrowDownSLine />
          </Box>
        }
        variant="input"
        {...formControlProps}
      >
        <Selection />
      </MenuButton>
      <MenuList maxH="sm" overflowY="auto">
        <MenuOptionGroup
          type="checkbox"
          value={value}
          onChange={(nextValue) => onChange(nextValue as T[])}
        >
          {data.map((option) => (
            <MenuItemOption
              key={option.value}
              color={
                value.includes(option.value as T) ? activeColor : undefined
              }
              iconSpacing={1}
              value={option.value}
            >
              <HStack spacing={0}>
                {option.icon}
                <Text>{option.label}</Text>
              </HStack>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

const MultiSelectValueField = forwardRef(MultiSelectValueFieldInner) as (<
  T extends string,
>(
  props: MultiSelectValueFieldProps<T> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof MultiSelectValueFieldInner>) & { displayName: string };

SelectValueField.displayName = "MultiSelectValueField";
