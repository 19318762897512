import { useParams } from "react-router-dom";

import { useAuthContext } from "@/auth/contexts/authContext";

// This hook is used to get the current environment and app from the URL params.
// This is a temporary solution until we have a better way to handle this.
// TODO ENV -- remove this hook when we have a better way to handle this
export function useCurrentEnv() {
  const { currentEnv, currentApp } = useAuthContext();
  const { appId, envId } = useParams();

  const queryArgs = new URLSearchParams(window.location.search);

  const actualEnvId = queryArgs.get("envId") || envId || currentEnv?.id;
  const actualAppId = appId || currentApp?.id;

  return { envId: actualEnvId, appId: actualAppId };
}
