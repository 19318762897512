import { z } from "zod";

import { nameSchema } from "./schemas/nameSchema";
import { APIEmptyResponse, APIResponse } from "./api";
import { SlackChannel, SlackChannelSchema } from "./slackConnectionAPI";

// Post & Patch DTO

export const EnvironmentPostSchema = z
  .object({
    name: nameSchema.max(128),
    order: z.number().min(1).optional(),
    slackChannel: SlackChannelSchema.optional(),
    feedbackPromptSnoozePeriodSeconds: z.number().default(604800),
  })
  .strict();
export type EnvironmentPostArgs = z.input<typeof EnvironmentPostSchema>;

export const EnvironmentPatchSchema = EnvironmentPostSchema.partial().strict();
export type EnvironmentPatchArgs = z.input<typeof EnvironmentPatchSchema>;

// Model

export type EnvironmentDTO = {
  id: string;
  name: string;
  appId: string;
  isProduction: boolean;
  publishableKey: string;
  secretKey: string;
  order: number;
  feedbackPromptSnoozePeriodSeconds: number;
  slackChannel: SlackChannel | null;
};

export type EnvironmentListItemDTO = Pick<
  EnvironmentDTO,
  "id" | "name" | "order" | "isProduction"
>;

// Query

export const EnvironmentsSortByColumns = ["name", "order"] as const;
const EnvironmentsQuerySortBySchema = z.enum(EnvironmentsSortByColumns);
export type EnvironmentsQuerySortBy = z.infer<
  typeof EnvironmentsQuerySortBySchema
>;

export const EnvironmentsQuerySchema = z
  .object({
    sortOrder: z.enum(["asc", "desc"]).default("asc"),
    sortBy: EnvironmentsQuerySortBySchema.default("order"),
  })
  .strict();

export type EnvironmentsQuery = z.input<typeof EnvironmentsQuerySchema>;

export const EnvironmentSelectionQuerySchema = z
  .object({
    envId: z.string().min(1),
  })
  .strict();

export type EnvironmentSelectionQueryType = z.input<
  typeof EnvironmentSelectionQuerySchema
>;

// API

export interface EnvironmentAPI {
  "/apps/:appId/environments": {
    POST: {
      body: EnvironmentPostArgs;
      response: APIResponse<{
        environment: EnvironmentDTO;
      }>;
      params: { appId: string };
    };
    GET: {
      response: APIResponse<{
        environments: EnvironmentDTO[];
        sortBy: EnvironmentsQuerySortBy;
        sortOrder: "asc" | "desc";
      }>;
      params: { appId: string };
      query: EnvironmentsQuery;
    };
  };
  "/apps/:appId/environments/:envId": {
    GET: {
      response: APIResponse<{
        environment: EnvironmentDTO;
      }>;
      params: { appId: string; envId: string };
    };
    PATCH: {
      body: EnvironmentPatchArgs;
      response: APIResponse<{
        environment: EnvironmentDTO;
      }>;
      params: { appId: string; envId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; envId: string };
    };
  };
}
