import { appendErrors, FieldError } from "react-hook-form";
import { ZodIssue } from "zod";

/**
 * Convert Zod errors to React-hook-form errors
 *
 * Adapted from https://github.com/react-hook-form/resolvers/blob/259b4dcf128aba909e1108b269c96dca6d5be9b9/zod/src/zod.ts
 *
 * @see [HookForm Zod resolver](https://github.com/react-hook-form/resolvers#zod)
 *
 */
export function convertZodErrorsToHookFormErrors(
  zodErrors: ZodIssue[] = [],
  options: {
    criteriaMode: "firstError" | "all";
  } = { criteriaMode: "firstError" },
): Record<string, FieldError> {
  const errorQueue = zodErrors.slice();
  const validateAllFieldCriteria = options.criteriaMode === "all";

  const errors: Record<string, FieldError> = {};
  for (; errorQueue.length; ) {
    const error = errorQueue[0];
    const { code, message, path } = error;
    const _path = path.join(".");

    if (!errors[_path]) {
      if ("unionErrors" in error) {
        const unionError = error.unionErrors[0].errors[0];

        errors[_path] = {
          message: unionError.message,
          type: unionError.code,
        };
      } else {
        errors[_path] = { message, type: code };
      }
    }

    if ("unionErrors" in error) {
      error.unionErrors.forEach((unionError) =>
        unionError.errors.forEach((e) => errorQueue.push(e)),
      );
    }

    if (validateAllFieldCriteria) {
      const types = errors[_path].types;
      const messages = types && types[error.code];

      errors[_path] = appendErrors(
        _path,
        validateAllFieldCriteria,
        errors,
        code,
        messages
          ? ([] as string[]).concat(messages as string[], error.message)
          : error.message,
      ) as FieldError;
    }

    errorQueue.shift();
  }

  return errors;
}
