import { ReactNode } from "react";
import { Text, TextProps } from "@chakra-ui/react";

export default function MenuDescription({
  children,
  ...props
}: { children: ReactNode } & TextProps) {
  return (
    <Text
      as="div"
      color="dimmed"
      fontSize="sm"
      fontWeight="normal"
      px={3}
      py={2}
      {...props}
    >
      {children}
    </Text>
  );
}
