import { useEffect } from "react";
import { RiCodeSSlashLine, RiSideBarFill, RiSideBarLine } from "react-icons/ri";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Link,
  Tooltip,
  useColorModeValue,
  useToast,
  VisuallyHidden,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { useLocalStorage } from "usehooks-ts";

import { FeatureSettingsUrl, FeatureTargetingUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AutoFeedbackSurveysIcon from "@/common/assets/auto-feedback-surveys-icon.svg?react";
import HeaderLayout from "@/common/components/HeaderLayout";
import { LoadingSpinner } from "@/common/components/LoadingSpinner";
import MotionBox from "@/common/components/MotionBox";
import MotionFadeIn from "@/common/components/MotionFadeIn";
import RouterTabs, {
  Route,
  useActivePathRouteIndex,
} from "@/common/components/RouterTabs";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { FeatureTreeNavigation } from "@/feature/components/FeatureTreeNavigation";
import { RolloutStatusTag } from "@/feature/components/RolloutStatusTag";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";
import { useFeatureSidebar } from "../hooks/useFeatureSidebar";

type FeatureTabRoute = Route<{
  id: "analyze" | "targeting" | "settings";
  hasSidebar?: boolean;
}>;

export default function Feature() {
  const { featureId } = useParams();
  const { currentEnv } = useAuthContext();
  const navigate = useNavigate();
  const toast = useToast();
  const [subsegment] = useSubsegmentParam();
  const params = new URLSearchParams();
  if (subsegment.length) {
    subsegment.forEach((subsegment) => params.append("subsegment", subsegment));
  }
  const search = params.toString();
  const {
    isVisible: sideBarVisible,
    show: showSidebar,
    hide: hideSidebar,
  } = useFeatureSidebar(featureId);

  const { data: featureNames } = useFeatureNamesData();
  const featureName = featureNames?.find((f) => f.id === featureId);

  const { data: feature, isLoading, isError } = useFeatureData(featureId);
  useEffect(() => {
    if (isError) {
      navigate("..");
      toast({
        title: "Could not retrieve feature",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isError, navigate, toast]);

  const tabsRoutes: FeatureTabRoute[] = [
    {
      id: "analyze",
      route: { ...useResolvedPath("analyze"), search },
      name: "Analyze",
      hasSidebar: true,
    },
    {
      id: "targeting",
      route: { ...useResolvedPath("targeting"), search },
      name: "Targeting",
      hasSidebar: true,
    },
    {
      id: "settings",
      route: { ...useResolvedPath("settings"), search },
      name: "Settings",
    },
  ];
  const activeRouteIndex = useActivePathRouteIndex(tabsRoutes);
  const activeTab = tabsRoutes[activeRouteIndex];

  const autoFeedbackSurveyColor = useColorModeValue("brand.500", "brand.400");

  return (
    <>
      <VisuallyHidden>
        <h1>{feature?.name}</h1>
      </VisuallyHidden>
      <HeaderLayout
        title={
          featureName && (
            <HStack ml={-3} spacing={4}>
              <FeatureTreeNavigation rootFeatureId={featureId} />
              <AnimatePresence initial={false}>
                {isLoading || !feature ? (
                  <LoadingSpinner variant="subtle" />
                ) : (
                  <MotionFadeIn alignItems="center" display="flex" gap={4}>
                    {feature?.autoFeedbackSurveysEnabled && (
                      <Tooltip
                        label="Automated feedback surveys are enabled"
                        maxW="52"
                        textAlign="center"
                        hasArrow
                      >
                        <Link
                          as={RouterLink}
                          color={autoFeedbackSurveyColor}
                          to={FeatureSettingsUrl(
                            currentEnv!,
                            featureName,
                            "feedback",
                          )}
                        >
                          <AutoFeedbackSurveysIcon
                            aria-hidden="true"
                            height={18}
                            width={18}
                          />
                        </Link>
                      </Tooltip>
                    )}
                    <RolloutStatusTag
                      featureId={featureId}
                      link={FeatureTargetingUrl(currentEnv!, featureName)}
                    />
                  </MotionFadeIn>
                )}
              </AnimatePresence>
            </HStack>
          )
        }
        borderless
      >
        <RouterTabs
          activeRouteIndex={activeRouteIndex}
          isLoading={isLoading}
          rightActions={
            <ButtonGroup spacing={1}>
              <ShowInstructionsButton currentTab={activeTab} />
              {activeTab?.hasSidebar && (
                <IconButton
                  aria-label="Toggle sidebar"
                  color="dimmed"
                  icon={
                    <Box transform="rotate(180deg)">
                      {sideBarVisible ? <RiSideBarFill /> : <RiSideBarLine />}
                    </Box>
                  }
                  size="sm"
                  variant="ghost"
                  onClick={() =>
                    sideBarVisible ? hideSidebar() : showSidebar()
                  }
                />
              )}
            </ButtonGroup>
          }
          routes={tabsRoutes}
          tabListProps={{ px: 2 }}
          titleTemplate={`Feature › ${feature?.name} › %s`}
        />
      </HeaderLayout>
    </>
  );
}

function ShowInstructionsButton({
  currentTab,
}: {
  currentTab?: FeatureTabRoute;
}) {
  const { featureId } = useParams();
  const [isVisible, setIsVisible] = useLocalStorage(`${featureId}:setup`, true);

  const showOnTab = ["analyze", "targeting"].includes(currentTab?.id ?? "");

  return (
    showOnTab && (
      <AnimatePresence initial={false}>
        {!isVisible && (
          <MotionBox
            key="show-instructions"
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: "0.5rem" }}
            initial={{ opacity: 0, translateY: "0.5rem" }}
            transition={{ duration: "0.1", ease: "easeInOut" }}
            layoutScroll
          >
            <Button
              color="dimmed"
              leftIcon={<RiCodeSSlashLine size={16} />}
              variant="ghost"
              onClick={() => setIsVisible(true)}
            >
              Show instructions
            </Button>
          </MotionBox>
        )}
      </AnimatePresence>
    )
  );
}
