import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  CreateNewFlagVersionsArgs,
  FlagVersion,
} from "@bucketco/shared/flagAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";
import { flagQueryKeys } from "@/flags/data/flagQueryKeys";

export function useFlagVersionsCreateMutation(
  featureId: string,
  flagId: string,
  currentVersionIds?: string[],
  sendNotification?: boolean,
) {
  const { appId } = useCurrentEnv();
  const queryClient = useQueryClient();

  return useMutation<
    FlagVersion[],
    AxiosError<ErrorResponse>,
    CreateNewFlagVersionsArgs
  >({
    mutationFn: (formData) =>
      api
        .post<"/apps/:appId/flags/:flagId/versions">(
          `/apps/${appId}/flags/${flagId}/versions`,
          formData,
          {
            params: {
              sendNotification: sendNotification
                ? sendNotification
                  ? "true"
                  : "false"
                : undefined,
              currentVersionIds: currentVersionIds,
            },
          },
        )
        .then((response) => response.data.flagVersions),

    onSuccess: (versions) => {
      queryClient.invalidateQueries({
        queryKey: flagQueryKeys.single(appId, flagId),
      });
      queryClient.invalidateQueries({
        queryKey: flagQueryKeys.versions(appId, flagId),
      });
      if (featureId) {
        for (const version of versions) {
          queryClient.invalidateQueries({
            queryKey: featureQueryKeys.singleFeatureRolloutStatus(
              appId,
              version.environment.id,
              featureId,
            ),
          });
        }
      }
    },
  });
}
