import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { environmentQueryKeys } from "./environmentQueryKeys";

export const useEnvironmentData = <TData = EnvironmentDTO>(
  envId?: string,
  queryOptions?: Omit<
    UseQueryOptions<EnvironmentDTO, AxiosError<ErrorResponse>, TData>,
    "queryKey" | "queryFn" | "initialData"
  > & { initialData?: () => undefined },
) => {
  const { appId } = useCurrentEnv();

  return useQuery({
    queryKey: environmentQueryKeys.single(appId, envId),

    queryFn: () =>
      api
        .get<"/apps/:appId/environments/:envId">(
          `/apps/${appId}/environments/${envId}`,
        )
        .then((res) => res.data.environment),
    enabled: !!appId && !!envId,
    ...queryOptions,
  });
};
