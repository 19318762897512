import { useColorModeValue } from "@chakra-ui/color-mode";
import { Button, Text, VStack } from "@chakra-ui/react";

import EmptyStateTriedDark from "@/common/assets/empty-state-companies-tried-dark.svg?react";
import EmptyStateTriedLight from "@/common/assets/empty-state-companies-tried-light.svg?react";
import EmptyState, { EmptyStateProps } from "@/common/components/EmptyState";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { useCompanyFeaturesTableState } from "@/company/hooks/useCompanyTableStates";

export const CompanyFeaturesEmptyState = (props: EmptyStateProps) => {
  const [featureFilter, setFeatureFilter] = useSearchParam("featureFilter");
  const { searchQuery, onSearchQueryChange } = useCompanyFeaturesTableState();
  const emptyIllustration = useColorModeValue(
    <EmptyStateTriedLight />,
    <EmptyStateTriedDark />,
  );

  return (
    <EmptyState
      description={
        <VStack>
          <Text>No features to show</Text>
          {!props.isLoading && (featureFilter || searchQuery) && (
            <Button
              color="gray.500"
              fontSize="sm"
              variant="link"
              onClick={() => {
                setFeatureFilter(null);
                onSearchQueryChange("");
              }}
            >
              Clear filters
            </Button>
          )}
        </VStack>
      }
      flexGrow={1}
      pt="24"
      {...props}
    >
      {emptyIllustration}
    </EmptyState>
  );
};
