import {
  RuntimeVariablesInputType,
  RuntimeVariablesSchema,
  RuntimeVariablesType,
} from "@bucketco/shared/schemas/runtimeVariablesSchema";

declare global {
  interface Window {
    env: RuntimeVariablesInputType | RuntimeVariablesType;
    HubSpotConversations?: {
      widget?: {
        open?: () => void;
      };
    };
  }
}

// `window.env` is set when served by the Node.js server
// `import.meta.env` is set while development by Webpack/CRA
window.env = window?.env ?? import.meta.env ?? {};
const parsed = RuntimeVariablesSchema.parse(window.env);

window.env = parsed;

export const {
  ENVIRONMENT,
  SEGMENT_WRITE_KEY,
  UPLOADIO_API_KEY,
  UPLOADIO_ORGLOGOS_PATH,
  HEROKU_SLUG_COMMIT,
  CLERK_PUBLISHABLE_KEY,
  API_URL,
  DOGFOOD_TRACKING_KEY,
  FEATURES,
} = parsed;

export const BUCKET_ORG_ID = "or3fo84G0xFr4D";
export const LINKEDIN_PARTNER_ID = "6297641";
