import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  EnvironmentDTO,
  EnvironmentsQuery,
} from "@bucketco/shared/environmentAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { environmentQueryKeys } from "./environmentQueryKeys";

export const useEnvironmentsData = <TData = EnvironmentDTO[]>(
  searchParams?: EnvironmentsQuery,
  queryOptions?: Omit<
    UseQueryOptions<EnvironmentDTO[], AxiosError<ErrorResponse>, TData>,
    "queryKey" | "queryFn" | "initialData"
  > & { initialData?: () => undefined },
) => {
  const { appId } = useCurrentEnv();

  // Avoid literal undefined as it becomes the string 'undefined' in search params
  const cleanSearchParams: EnvironmentsQuery | undefined =
    searchParams &&
    Object.fromEntries(
      Object.entries(searchParams).filter(([_key, value]) => {
        return typeof value !== "undefined";
      }),
    );

  const dataUrl = `/apps/${appId}/environments?${new URLSearchParams(
    cleanSearchParams as Record<string, string>,
  ).toString()}`;

  return useQuery({
    queryKey: environmentQueryKeys.list(appId, cleanSearchParams),

    queryFn: () =>
      api
        .get<"/apps/:appId/environments">(dataUrl)
        .then((res) => res.data.environments),
    enabled: !!appId,
    ...queryOptions,
  });
};
