import { useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { FeatureWidgets } from "@/feature/components/FeatureWidgets";
import { SetupInstructions } from "@/feature/components/SetupInstructions";
import FeatureSideBarWrapper from "../components/FeatureSidebarWrapper";

export default function FeatureAnalyze() {
  const { featureId } = useParams();

  return (
    <FeatureSideBarWrapper>
      <Flex
        direction="column"
        flexGrow={1}
        flexShrink={1}
        minWidth={0}
        px={6}
        py={4}
      >
        <SetupInstructions featureId={featureId} />
        <FeatureWidgets featureId={featureId!} />
      </Flex>
    </FeatureSideBarWrapper>
  );
}
