import { SegmentsQueryType } from "@bucketco/shared/segmentAPI";

const qk = {
  list: (
    appId?: string,
    envId?: string,
    params?: Omit<SegmentsQueryType, "envId">,
  ) =>
    [
      "apps",
      appId,
      "environments",
      envId,
      "segments",
      ...(params ? [params] : []),
    ] as const,
};

export { qk as segmentQueryKeys };
