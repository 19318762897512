import { useCallback } from "react";
import { RiExternalLinkLine, RiFileCopyLine } from "react-icons/ri";
import {
  ButtonGroup,
  CardProps,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
  Tooltip,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { useLocalStorage } from "usehooks-ts";

import LinearLogo from "@/common/assets/linear-logo.svg?react";
import CodeBlock, {
  SupportedHighlightLanguage,
} from "@/common/components/CodeBlock";
import SimpleSelect from "@/common/components/SimpleSelect";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export type CodeExampleType = {
  key: string;
  label: string;
  highlighterLanguage?: SupportedHighlightLanguage;
  code: string;
  docsLink?: string;
};

export type CodeExampleProps = {
  examples: CodeExampleType[];
  initialExample: CodeExampleType["key"];
  /** Use to automatically track events when switching tabs and sharing the example */
  trackingEventBase?: string;
  sharingPreface?: string;
  header?: string;
} & CardProps;

export function CodeExample({
  examples,
  initialExample,
  trackingEventBase,
  sharingPreface,
  ...props
}: CodeExampleProps) {
  const [currentKey, setCurrentKey] = useLocalStorage(
    "code-example-language",
    initialExample,
  );
  const currentExample = examples.find((x) => x.key === currentKey);

  if (!currentExample) return;

  const prefacedMarkdown = [
    `Code example for ${currentExample.label}:`,
    [
      "```" + currentExample.highlighterLanguage,
      currentExample.code,
      "```",
    ].join("\n"),
    `[More details in Bucket](${window.location.href})`,
  ]
    .filter(Boolean)
    .join("\n\n");

  return (
    <Flex
      as="section"
      direction="column"
      fontSize="sm"
      gap={3}
      p={4}
      position="relative"
      w="full"
      {...props}
    >
      <ButtonGroup
        position="absolute"
        right={3}
        size="xs"
        spacing={2}
        top={3}
        variant="outline"
      >
        <CopyClipboardButton
          code={currentExample.code}
          trackingEventBase={trackingEventBase}
        />
        <TrackInLinearButton
          markdown={prefacedMarkdown}
          title={sharingPreface ?? "Bucket TODO"}
          trackingEventBase={trackingEventBase}
        />
        <SimpleSelect
          minW="28"
          options={examples.map(({ key, label }) => ({ value: key, label }))}
          value={currentExample.key}
          onChange={(key) => setCurrentKey(key)}
        />
      </ButtonGroup>
      <CodeBlock language={currentExample.highlighterLanguage}>
        {currentExample.code}
      </CodeBlock>
      {currentExample.docsLink && (
        <Link
          bottom={3}
          color="dimmed"
          href={currentExample.docsLink}
          position="absolute"
          right={3}
          target="_blank"
          textDecoration="none"
        >
          <HStack>
            <Text fontSize="sm">Open docs</Text>
            <RiExternalLinkLine size={14} />
          </HStack>
        </Link>
      )}
    </Flex>
  );
}

export function CopyClipboardButton({
  code,
  trackingEventBase,
}: {
  code: string;
  trackingEventBase?: string;
}) {
  const { hasCopied, onCopy } = useClipboard(code);
  const toast = useToast();

  const handleCopy = useCallback(() => {
    onCopy();
    toast({
      title: "Code copied",
      status: "success",
      duration: 2000,
      isClosable: true,
    });

    if (trackingEventBase) {
      segmentAnalytics.track(`${trackingEventBase} Shared`, {
        action: "copy markdown",
      });
    }
  }, [onCopy, toast, trackingEventBase]);

  return (
    <Tooltip
      closeOnClick={false}
      label={hasCopied ? "Copied!" : "Copy to clipboard"}
    >
      <IconButton
        aria-label="Copy to clipboard"
        icon={<RiFileCopyLine size="14" />}
        onClick={handleCopy}
      />
    </Tooltip>
  );
}

export function TrackInLinearButton({
  title,
  markdown,
  trackingEventBase,
}: {
  title: string;
  markdown: string;
  trackingEventBase?: string;
}) {
  const url = new URL("https://linear.app/new");

  url.searchParams.set("title", title);
  url.searchParams.set("description", markdown);

  return (
    <Tooltip label={"Create Linear issue"}>
      <IconButton
        aria-label="Create Linear issue"
        as={Link}
        href={url.toString()}
        icon={<LinearLogo color="#5E6AD2" opacity="1" width="14" />}
        target="_blank"
        onClick={() => {
          if (trackingEventBase) {
            segmentAnalytics.track(`${trackingEventBase} Shared`, {
              action: "linear task",
            });
          }
        }}
      />
    </Tooltip>
  );
}
