import { locale } from "./locale";

export const NumberFormatter = Intl.NumberFormat(locale);
export const LargeNumberFormatter = Intl.NumberFormat(locale, {
  notation: "compact",
});

export const FractionalPercentageFormatter = Intl.NumberFormat(locale, {
  style: "percent",
  maximumSignificantDigits: 1,
});
export const LowPercentageFormatter = Intl.NumberFormat(locale, {
  style: "percent",
  maximumFractionDigits: 1,
});
export const HighPercentageFormatter = Intl.NumberFormat(locale, {
  style: "percent",
  maximumFractionDigits: 0,
});

export function formatNumber(value: number) {
  return NumberFormatter.format(value);
}

export function formatLargeNumber(value: number) {
  return LargeNumberFormatter.format(value);
}

export function formatPercentage(fraction: number) {
  if (fraction < 0.01) {
    return FractionalPercentageFormatter.format(fraction);
  }

  if (fraction < 0.1) {
    return LowPercentageFormatter.format(fraction);
  }

  return HighPercentageFormatter.format(fraction);
}
