import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  avatarAnatomy.keys,
);

export const AvatarTheme = defineMultiStyleConfig({
  sizes: {
    "2xs": {
      container: {
        width: 5,
        height: 5,
        fontSize: "0.625rem",
      },
      label: {
        fontSize: "0.625rem",
      },
    },
  },
});
