import { FeatureRolloutStatus } from "@bucketco/shared/featureAPI";

import dayjs from "@/common/utils/dayjs";

type LatestStatusReturn = {
  type: "check" | "feedback" | "usage";
  timestamp: string;
};
export function latestTimestampSingleStatus(
  status: FeatureRolloutStatus,
): LatestStatusReturn | null {
  const sortedList = [
    { type: "check" as const, timestamp: status.latestCheck },
    { type: "feedback" as const, timestamp: status.latestFeedback },
    { type: "usage" as const, timestamp: status.latestUsage },
  ]
    .filter((x): x is LatestStatusReturn => x.timestamp !== null)
    .sort((x, y) => (dayjs(x.timestamp).isAfter(y.timestamp) ? -1 : 1));

  return sortedList.length > 0 ? sortedList[0] : null;
}

export function latestTimestampAcrossAllStatuses(
  statuses: FeatureRolloutStatus[],
) {
  const sortedList = statuses
    .map((status) => {
      const latest = latestTimestampSingleStatus(status);
      return latest ? { ...latest, env: status.environment } : null;
    })
    .filter((x) => x !== null)
    .sort((x, y) => (dayjs(x.timestamp).isAfter(y.timestamp) ? -1 : 1));

  return sortedList?.length ? sortedList[0] : null;
}
