import { z } from "zod";

import { redirectUrlSchema } from "./schemas/redirectUrlSchema";
import { APIEmptyResponse, APIResponse } from "./api";

export interface SlackConnection {
  id: string;
  createdAt: string;
  updatedAt: string;
  team: string;
}

export const SlackChannelSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type SlackChannel = z.infer<typeof SlackChannelSchema>;

export const ConnectToSlackQuerySchema = z.object({
  appId: z.string().min(1),
  appName: z.string().min(1),
  redirect: redirectUrlSchema,
});

export type ConnectToSlackQueryType = z.infer<typeof ConnectToSlackQuerySchema>;

export const PostToSlackNowArgsSchema = z.discriminatedUnion("reportType", [
  z.object({
    reportType: z.literal("featureViewReport"),
    appId: z.string(),
    featureViewId: z.string(),
    channelId: z.string(),
  }),
]);

export type PostToSlackNowArgsType = z.infer<typeof PostToSlackNowArgsSchema>;

export const SlackReportTypeList = [
  "featureViewReport",
  "firstFeatureDataNotification",
] as const;
export const SlackReportTypeSchema = z.enum(SlackReportTypeList);
export type SlackReportType = z.infer<typeof SlackReportTypeSchema>;

export const PostToSlackNowParamsSchema = z
  .object({ orgId: z.string() })
  .strict();

export type PostToSlackNowParamsType = z.infer<
  typeof PostToSlackNowParamsSchema
>;

export interface SlackConnectionAPI {
  "/orgs/:orgId/slack/connection": {
    GET: {
      response: APIResponse<SlackConnection | null>;
      params: { orgId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { orgId: string };
    };
  };
  "/orgs/:orgId/slack/channels": {
    GET: {
      response: APIResponse<SlackChannel[] | null>;
      params: { orgId: string };
    };
  };
  "/orgs/:orgId/slack/post-now": {
    POST: {
      response: APIEmptyResponse;
      params: PostToSlackNowParamsType;
      body: PostToSlackNowArgsType;
    };
  };
  "/orgs/:orgId/connect-slack": {
    GET: {
      params: { orgId: string };
      query: ConnectToSlackQueryType;
    };
  };
  "/callback/slack": {
    GET: {
      response: void;
    };
  };
}
