import { UploadButton } from "react-uploader";
import { Uploader, UploaderOptions } from "uploader";

import { UPLOADIO_API_KEY, UPLOADIO_ORGLOGOS_PATH } from "@/common/utils/env";
import { theme } from "@/theme/theme";

// init upload.io client
const uploader = Uploader({
  apiKey: UPLOADIO_API_KEY,
});

const defaultOptions: UploaderOptions = {
  multi: false,
  styles: {
    colors: {
      primary: theme["colors"]["brand"]["500"],
      active: theme["colors"]["brand"]["600"],
    },
    fontSizes: {
      base: 14,
    },
  },
  editor: {
    images: {
      crop: true,
      cropRatio: 1 / 1,
      cropShape: "rect" as const,
    },
  },
  mimeTypes: ["image/jpeg", "image/png", "image/webp"],
  tags: ["orglogo"],
};

type UploadButtonChildrenType = Parameters<typeof UploadButton>[0]["children"];
type UploadLogoButton = {
  orgId: string;
  children: UploadButtonChildrenType;
  onChange: (fileUrl: string) => void;
};

export function UploadLogoButton({
  orgId,
  children,
  onChange,
}: UploadLogoButton) {
  const options = {
    ...defaultOptions,
    metadata: {
      orgId,
    },
    path: {
      fileName: "{UTC_DATE}-{UNIQUE_DIGITS_10}{ORIGINAL_FILE_EXT}",
      folderPath: `/${UPLOADIO_ORGLOGOS_PATH}/${orgId}`,
    },
  };

  return (
    <UploadButton
      options={options}
      uploader={uploader}
      onComplete={(files) => {
        if (files.length === 0 || !files[0].fileUrl) {
          return;
        }
        // prefer cropped (edited) version if available
        const file = files[0].editedFile || files[0].originalFile;
        onChange(file.fileUrl);
      }}
    >
      {children}
    </UploadButton>
  );
}
