import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";

export function SectionHeading({
  children,
  variant = "default",
  ...props
}: {
  children: React.ReactNode;
  variant?: "default" | "subtle";
} & HeadingProps) {
  return (
    <Heading
      as="h3"
      color={variant === "subtle" ? "dimmed" : undefined}
      fontSize={variant === "subtle" ? "sm" : "md"}
      fontWeight={variant === "subtle" ? "normal" : "medium"}
      {...props}
    >
      {children}
    </Heading>
  );
}
