import { MouseEvent, useState } from "react";
import {
  RiArrowRightLine,
  RiCheckLine,
  RiCloseLine,
  RiDeleteBinLine,
  RiPencilLine,
} from "react-icons/ri";
import { Link as RouterLink, To } from "react-router-dom";
import { IconButton, IconButtonProps, Tooltip } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";

import MotionBox from "@/common/components/MotionBox";

export function GoToIconButton({
  to,
  label,
  ...buttonProps
}: { to: string; label: string } & Omit<IconButtonProps, "aria-label">) {
  return (
    <Tooltip label={label}>
      <IconButton
        aria-label={label}
        as={RouterLink}
        icon={<RiArrowRightLine size={18} />}
        size="xs"
        to={to}
        variant="ghost"
        isRound
        {...buttonProps}
      />
    </Tooltip>
  );
}

export function EditIconButton({
  to,
  onClick,
  label,
  ...buttonProps
}: {
  to?: To;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  label: string;
} & Omit<IconButtonProps, "aria-label">) {
  return (
    <Tooltip label={label} openDelay={300} hasArrow>
      <IconButton
        aria-label={label}
        as={to ? RouterLink : undefined}
        icon={<RiPencilLine size={16} />}
        size="xs"
        to={to ? to : ""}
        variant="ghost"
        isRound
        onClick={onClick ? onClick : undefined}
        {...buttonProps}
      />
    </Tooltip>
  );
}

export function CloseIconButton({
  onClick,
  label,
  ...buttonProps
}: {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  label: string;
} & Omit<IconButtonProps, "aria-label">) {
  return (
    <Tooltip label={label} openDelay={300} hasArrow>
      <IconButton
        aria-label={label}
        icon={<RiCloseLine size={16} />}
        size="xs"
        variant="ghost"
        isRound
        onClick={onClick ? onClick : undefined}
        {...buttonProps}
      />
    </Tooltip>
  );
}

export function DeleteIconButton({
  onClick,
  label,
  ...buttonProps
}: { onClick: () => void; label: string } & Omit<
  IconButtonProps,
  "aria-label"
>) {
  return (
    <Tooltip label={label} openDelay={300} hasArrow>
      <IconButton
        aria-label={label}
        icon={<RiDeleteBinLine size={16} />}
        size="xs"
        variant="ghost"
        isRound
        onClick={onClick}
        {...buttonProps}
      />
    </Tooltip>
  );
}

const AnimatedDeleteCheckIcon = ({ check }: { check: boolean }) => {
  return (
    <AnimatePresence initial={false} mode="wait">
      {check ? (
        <MotionBox
          key="check"
          animate={{ scale: 1 }}
          exit={{ scale: 0.7 }}
          initial={{ scale: 0.7 }}
          transition={{ duration: "0.05" }}
        >
          <RiCheckLine size={16} />
        </MotionBox>
      ) : (
        <MotionBox
          key="delete"
          animate={{ scale: 1 }}
          exit={{ scale: 0.7 }}
          initial={{ scale: 0.7 }}
          transition={{ duration: "0.05" }}
        >
          <RiDeleteBinLine size={16} />
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export function DeleteConfirmIconButton({
  onClick,
  label,
  confirmLabel = "Click again to delete",
  ...buttonProps
}: {
  onClick: () => void;
  label: string;
  confirmLabel?: string;
} & Omit<IconButtonProps, "aria-label">) {
  const [confirming, setConfirming] = useState(false);
  return (
    <Tooltip
      label={confirming ? confirmLabel : undefined}
      placement="top"
      hasArrow
    >
      <IconButton
        aria-label={confirming ? confirmLabel : label}
        icon={<AnimatedDeleteCheckIcon check={confirming} />}
        size="xs"
        variant="ghost"
        isRound
        onClick={(e) => {
          if (confirming) {
            onClick(e);
          } else {
            setConfirming(true);
          }
        }}
        onMouseLeave={() => setConfirming(false)}
        {...buttonProps}
      />
    </Tooltip>
  );
}
