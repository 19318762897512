import { z } from "zod";

import { EnvironmentTypeSchema } from "../schemas/environmentTypeSchema";

export const API_URL = "http://localhost:3100/api";
export const TRACKING_DOGFOOD_KEY = "pub_loca_y7EAU00gyjmVriUQpIIHt7";
export const SEGMENT_WRITE_KEY = "taJPNosZVsm9AOTGtxhJPur4yd3fhPK3";
export const UPLOADIO_API_KEY = "public_kW15ayoXfXNvRVrGrrrXN46yvbhw";
export const UPLOADIO_ORGLOGOS_PATH = "orglogos-staging";
export const CLERK_PUBLISHABLE_KEY =
  "pk_test_dG9sZXJhbnQtY293LTE4LmNsZXJrLmFjY291bnRzLmRldiQ";

export const RuntimeVariablesSchema = z.object({
  ENVIRONMENT: EnvironmentTypeSchema,

  SEGMENT_WRITE_KEY: z.string().min(1).max(64).default(SEGMENT_WRITE_KEY),
  DOGFOOD_TRACKING_KEY: z
    .string()
    .min(12)
    .max(48)
    .default(TRACKING_DOGFOOD_KEY),

  HEROKU_SLUG_COMMIT: z.string().min(6).max(64).optional(),
  CLERK_PUBLISHABLE_KEY: z
    .string()
    .min(8)
    .max(128)
    .default(CLERK_PUBLISHABLE_KEY),

  API_URL: z.string().url().default(API_URL),
  UPLOADIO_API_KEY: z.string().min(1).max(64).default(UPLOADIO_API_KEY),
  UPLOADIO_ORGLOGOS_PATH: z
    .string()
    .min(1)
    .max(64)
    .default(UPLOADIO_ORGLOGOS_PATH),

  FEATURES: z
    .string()
    .optional()
    .transform((value) =>
      (value?.split(",").map((flag) => flag.trim()) || []).reduce(
        (acc, key) => {
          acc[key] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      ),
    ),
});

export type RuntimeVariablesInputType = z.input<typeof RuntimeVariablesSchema>;
export type RuntimeVariablesType = z.output<typeof RuntimeVariablesSchema>;
