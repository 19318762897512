import { Text, TextProps, useColorModeValue } from "@chakra-ui/react";

export default function NotAvailableCell(props: TextProps) {
  return (
    <Text
      aria-label="Not available"
      color={useColorModeValue("gray.300", "gray.700")}
      fontSize="md"
      {...props}
    >
      —
    </Text>
  );
}
