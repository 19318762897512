import { IconBaseProps } from "react-icons";
import { RiBracesLine } from "react-icons/ri";

import PointerPress from "@/common/assets/pointer-press.svg?react";

export function FeatureSourceIcon({
  source,
  ...props
}: {
  source: "event" | "attribute";
} & IconBaseProps) {
  return source === "event" ? (
    <PointerPress
      height={props.size ?? 16}
      width={props.size ?? 16}
      {...props}
    />
  ) : (
    <RiBracesLine size={16} {...props} />
  );
}
