import * as React from "react";
import Markdown, { Components } from "react-markdown";
import { Image } from "@chakra-ui/image";
import {
  Code,
  Divider,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/system";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";

type Props = {
  value?: string | null;
};

export const chakraComponents: Components = {
  p: ({ children }) => <Text mb={2}>{children}</Text>,
  em: ({ children }) => <Text as="em">{children}</Text>,
  blockquote: (props) => <Code as="blockquote" p={2} {...props} />,
  code: (props) => (
    <Code
      borderRadius="base"
      display="block"
      fontSize="xs"
      px={2}
      py={1}
      w="full"
      whiteSpace="break-spaces"
      {...props}
    />
  ),
  del: ({ children }) => <Text as="del">{children}</Text>,
  hr: Divider,
  a: Link,
  img: Image,
  text: ({ children }) => <Text as="span">{children}</Text>,
  ul: UnorderedList,
  ol: OrderedList,
  li: ListItem,
  h1: (props) => <Heading as="h1" {...props}></Heading>,
  h2: (props) => <Heading as="h2" {...props}></Heading>,
  h3: (props) => <Heading as="h3" {...props}></Heading>,
  h4: (props) => <Heading as="h4" {...props}></Heading>,
  h5: (props) => <Heading as="h5" {...props}></Heading>,
  h6: (props) => <Heading as="h6" {...props}></Heading>,
  pre: chakra.pre,
  table: Table,
  thead: Thead,
  tbody: Tbody,
  tr: Tr,
  td: Td,
  th: Th,
};

export function MarkdownDisplay({ value }: Props) {
  return (
    <Markdown components={chakraComponents} skipHtml>
      {value}
    </Markdown>
  );
}
