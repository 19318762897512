import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { ButtonGroup, Text, useToast, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import {
  EnvironmentPatchArgs,
  EnvironmentPatchSchema,
} from "@bucketco/shared/environmentAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { SlackChannelAutocompleteSelect } from "@/common/components/slack/SlackChannelAutocompleteSelect";
import SlackConnectionGuard from "@/common/components/slack/SlackConnectionGuard";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import useApiForm from "@/common/hooks/useApiForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import SlackOrgIntegration from "@/global-settings/components/SlackOrgIntegration";

function SlackEnvIntegrationForm() {
  const { currentApp, currentEnv, currentOrg } = useAuthContext();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    form,
    handleSubmit,
    mutation: { isPending },
  } = useApiForm(
    (data: EnvironmentPatchArgs) =>
      api
        .patch<"/apps/:appId/environments/:envId">(
          `/apps/${currentApp?.id}/environments/${currentEnv?.id}`,
          data,
        )
        .then((res) => res.data.environment),
    EnvironmentPatchSchema,
    {
      onSuccess: (data) => {
        segmentAnalytics.track("App Slack Settings Updated", {
          hasSetChannel: !!data.slackChannel,
        });

        toast({
          title: "Slack settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        queryClient.invalidateQueries({ queryKey: commonQueryKeys.bootstrap });

        form.reset({
          slackChannel: data.slackChannel || undefined,
        });
      },
    },
    {
      mode: "all",
      defaultValues: {
        slackChannel: currentEnv?.slackChannel || undefined,
      },
    },
  );

  useEffect(() => {
    segmentAnalytics.page("App Slack Integration Settings");
  }, []);

  // Quick access to do the right thing when there is no Slack connection on the org yet
  if (!currentOrg?.hasSlackConnection) {
    return <SlackOrgIntegration />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...form}>
        <VStack align="flex-start" maxW="compactForm" spacing={6} w="100%">
          <VStack align="flex-start" w="full">
            <ManagedFormControl
              isDisabled={isPending}
              label="Slack Channel"
              name="slackChannel"
              render={({ field }) => (
                <SlackChannelAutocompleteSelect
                  {...field}
                  includeDefaultChannel={false}
                />
              )}
            />

            <Text color="gray.500" fontSize="xs" mt={2}>
              All Slack reports will default to this channel unless a specific
              channel is selected
            </Text>
          </VStack>

          <FormRootError />

          <ButtonGroup>
            <FormSubmitLegacy />
            <FormReset />
          </ButtonGroup>
        </VStack>
      </FormProvider>
    </form>
  );
}

export default function SlackEnvIntegration() {
  return (
    <SlackConnectionGuard>
      <SlackEnvIntegrationForm />
    </SlackConnectionGuard>
  );
}
