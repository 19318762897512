import React, { forwardRef } from "react";
import { Box } from "@chakra-ui/react";

type Props = {
  enabled?: boolean;
  children?: React.ReactNode;
};

export const FillScrollWrapper = forwardRef<HTMLDivElement, Props>(
  ({ enabled, children }, ref) =>
    enabled ? (
      <Box flexGrow={1} overflow="hidden" position="relative">
        <Box
          ref={ref}
          bottom={0}
          left={0}
          overflow="auto"
          position="absolute"
          right={0}
          top={0}
        >
          {children}
        </Box>
      </Box>
    ) : (
      <>{children}</>
    ),
);

FillScrollWrapper.displayName = "FillScrollWrapper";
