import { FC } from "react";
import { Avatar, HStack, Text } from "@chakra-ui/react";

export type UserDisplayProps = {
  id: string;
  email: string | null;
  name?: string | null;
  avatar?: string | null;
};

export const UserDisplay: FC<{ user?: UserDisplayProps | null }> = ({
  user,
}) => {
  if (!user) {
    return null;
  }

  const displayText = user.name || user.email;

  return (
    <HStack as="span">
      <Avatar
        name={displayText || user.id}
        size="2xs"
        src={user.avatar ?? undefined}
      />
      <Text as="span" fontSize="md" fontWeight="500">
        {displayText}
      </Text>
    </HStack>
  );
};
