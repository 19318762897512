import { Link as RouterLink } from "react-router-dom";
import { Center, Image, Link, Text, useColorModeValue } from "@chakra-ui/react";

export default function InvalidRoute() {
  return (
    <Center flexDirection="column" minHeight="100vh" padding="4" width="100%">
      <Image alt="Not Found" marginBottom="4" src="/404.png" width={"10%"} />
      <Text
        color="gray.500"
        fontSize="xl"
        marginBottom="4"
        size="md"
        textAlign={"center"}
      >
        Oops! It seems like we couldn&apos;t find the page you were looking for.
        <br />
        But don&apos;t worry, we&apos;ve sent our best developers to find it!
      </Text>
      <Link
        _hover={{ color: useColorModeValue("gray.900", "white") }}
        as={RouterLink}
        color="dimmed"
        fontSize="lg"
        fontWeight="medium"
        px={2}
        textDecoration="none"
        to="/"
      >
        Go back home
      </Link>
    </Center>
  );
}
