import { ReactNode } from "react";
import { RiInformationLine } from "react-icons/ri";
import {
  Box,
  BoxProps,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  useColorModeValue,
} from "@chakra-ui/react";

import { PortalWrapper } from "@/common/components/PortalWrapper";

export type InfoIconTooltipProps = PopoverProps & {
  text: ReactNode;
  docsURL?: string;
  renderInPortal?: boolean;
  _content?: PopoverContentProps;
  _icon?: BoxProps;
};

export default function InfoIconTooltip({
  text,
  docsURL,
  renderInPortal = true,
  _content,
  _icon,
  ...rest
}: InfoIconTooltipProps) {
  const tooltipColor = useColorModeValue("gray.400", "gray.600");
  const tooltipHoverColor = useColorModeValue("gray.700", "gray.300");

  return (
    <Popover trigger="hover" variant="tooltip" {...rest}>
      <PopoverTrigger>
        <Box
          _hover={{ color: tooltipHoverColor }}
          as="span"
          color={tooltipColor}
          display="inline-block"
          role="button"
          {..._icon}
        >
          <RiInformationLine />
        </Box>
      </PopoverTrigger>
      <PortalWrapper usePortal={renderInPortal}>
        <PopoverContent {..._content}>
          <PopoverArrow />
          <PopoverBody fontSize="sm">
            <Box>{text}</Box>
            {docsURL && (
              <Link
                display="block"
                fontSize="sm"
                href={docsURL}
                mt={2}
                target="_blank"
              >
                Learn more
              </Link>
            )}
          </PopoverBody>
        </PopoverContent>
      </PortalWrapper>
    </Popover>
  );
}
