import { useParams } from "react-router-dom";

import { CompanyFeatureUsersTable } from "@/company/components/CompanyFeatureUsersTable";
import {
  useCompanyFeatureUsersData,
  useCompanyUsersData,
} from "@/company/data/useCompanyUsersData";
import {
  useCompanyFeatureUsersTableState,
  useCompanyUsersTableState,
} from "@/company/hooks/useCompanyTableStates";
import { CompanyUsersTable } from "../components/CompanyUsersTable";

const Users = ({ companyId }: { companyId: string }) => {
  const tableState = useCompanyUsersTableState();

  const { sorting, pageIndex } = tableState;
  // We only handle sorting one column for now
  const { id: sortBy, desc: sortDesc } = sorting[0] ?? {};
  const { data, isLoading, isFetching } = useCompanyUsersData(companyId, {
    sortBy,
    sortOrder:
      sortDesc === true ? "desc" : sortDesc === false ? "asc" : undefined,
    pageIndex,
  });

  return (
    <CompanyUsersTable
      data={data}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
};

const UsersByFeature = ({
  companyId,
  featureId,
}: {
  companyId: string;
  featureId: string;
}) => {
  const tableState = useCompanyFeatureUsersTableState();

  const { sorting, pageIndex } = tableState;
  // We only handle sorting one column for now
  const { id: sortBy, desc: sortDesc } = sorting[0] ?? {};
  const { data, isLoading, isFetching } = useCompanyFeatureUsersData(
    companyId,
    featureId,
    {
      sortBy,
      sortOrder:
        sortDesc === true ? "desc" : sortDesc === false ? "asc" : undefined,
      pageIndex,
    },
  );

  return (
    <CompanyFeatureUsersTable
      data={data}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
};

export default function CompanyUsers() {
  const { companyId } = useParams();
  const { featureFilter } = useCompanyFeatureUsersTableState();

  return featureFilter ? (
    <UsersByFeature companyId={companyId!} featureId={featureFilter} />
  ) : (
    <Users companyId={companyId!} />
  );
}
