import equal from "fast-deep-equal";

import { CompaniesColumn, CompaniesSortBy } from "@bucketco/shared/companyAPI";
import { UIFilter } from "@bucketco/shared/filter";
import { SegmentDTO } from "@bucketco/shared/segmentAPI";

import { useDataTableParamState } from "@/common/hooks/useDataTableParamState";
import { useFilterParam } from "@/company/hooks/useFilterParam";

export const useCompaniesTableState = (segment: SegmentDTO) => {
  // Load persisted sorting and column states from segment.
  const segmentSorting = segment.columnSortKey
    ? [
        {
          id: segment.columnSortKey,
          desc: segment.columnSortDesc ?? true,
        },
      ]
    : [
        {
          id: "name" as CompaniesSortBy,
          desc: false,
        },
      ];

  const [companyFilter, setCompanyFilter] = useFilterParam(
    segment.companyFilter,
  );

  // Create table state from feature view data and url params.
  const tableState = useDataTableParamState<CompaniesSortBy, CompaniesColumn>({
    sorting: segmentSorting,
    columnStates: segment.columns,
  });

  const isDirty =
    !equal(tableState.columnStates, segment.columns) ||
    !equal(tableState.sorting, segmentSorting) ||
    !equal(companyFilter, segment.companyFilter);

  return {
    ...tableState,
    companyFilter,
    onCompanyFilterChange: (filter: UIFilter | undefined) => {
      setCompanyFilter(
        filter && !equal(filter, segment.companyFilter) ? filter : undefined,
      );
    },
    onReset: () => {
      setCompanyFilter(undefined);
      tableState.onReset();
    },
    isDirty,
  };
};
