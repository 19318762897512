import { forwardRef } from "react";
import { HStack, Text } from "@chakra-ui/react";
import startCase from "lodash/startCase";

import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import SimpleSelect, {
  SimpleSelectProps,
} from "@/common/components/SimpleSelect";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";
import { StageFillIcon } from "@/stage/components/StagesIcon";
import { useStagesColor } from "@/stage/hooks/useStagesColor";

export type FormStageColorPickerProps = FormElementBaseProps &
  Omit<
    SimpleSelectProps,
    "options" | "valueAsNumber" | "value" | "onChange"
  > & {
    onChange?: (value: number) => void;
  };

export const FormStageColorPicker = forwardRef<
  HTMLSelectElement,
  FormStageColorPickerProps
>(({ _control, onChange, onBlur, ...rest }, ref) => {
  const [managedProps, inputProps] = useManagedFormControlProps(rest);
  const colorScale = useStagesColor();
  return (
    <ManagedFormControl
      {..._control}
      {...managedProps}
      render={({ field }) => (
        <SimpleSelect
          {...inputProps}
          {...field}
          ref={mergeRefs(field.ref, ref)}
          _menuList={{
            minW: 0,
          }}
          options={colorScale.map((value, index) => ({
            value: index,
            label: (
              <HStack>
                <StageFillIcon boxSize={3} color={value} flexShrink={0} />
                <Text>{startCase(value.split(".")[0])}</Text>
              </HStack>
            ),
          }))}
          width={14}
          valueAsNumber
          onBlur={mergeFunctions(field.onBlur, onBlur)}
          onChange={mergeFunctions(field.onChange, onChange)}
        />
      )}
      w="auto"
    />
  );
});
FormStageColorPicker.displayName = "FormStageColorPicker";
