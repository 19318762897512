import { Link } from "react-router-dom";
import { Avatar, HStack, Text } from "@chakra-ui/react";

import { CompanyUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";

type Props = {
  name: string | null;
  id: string;
  link?: boolean;
};

export default function CompanyDisplay(props: Props) {
  const { currentEnv } = useAuthContext();

  if (props.link) {
    return (
      <Text as={Link} to={CompanyUrl(currentEnv!, props.id)}>
        <CompanyDisplayInner {...props} />
      </Text>
    );
  }

  return <CompanyDisplayInner {...props} />;
}

function CompanyDisplayInner({ id, name }: Props) {
  return (
    <HStack>
      <Avatar name={name || id} size="2xs" />
      <Text fontSize="sm" noOfLines={1}>
        {name || id}
      </Text>
    </HStack>
  );
}
