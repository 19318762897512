import { useRef } from "react";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  UseFormReturn,
} from "react-hook-form";

import { useFormFieldUpdate } from "@/common/hooks/useFormFieldUpdate";

/**
 * Get a callback each time a form field value changes
 *
 * The callback gets the field name and old and new values as the first argument.
 *
 * @example
 * ```js
 * useFormFieldDiff(
 *  form,
 *  "my-field-name",
 *  ({oldValue, newValue}) => {
 *    console.log('triggers only on value updates of "my-field-name"')
 *  }
 * )
 * ```
 */
export function useFormFieldDiff<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  form: UseFormReturn<TFieldValues>,
  fieldName: TFieldName,
  fn: (diff: {
    oldValue: FieldPathValue<TFieldValues, TFieldName>;
    newValue: FieldPathValue<TFieldValues, TFieldName>;
  }) => void,
) {
  const ref = useRef(form.getValues(fieldName));

  useFormFieldUpdate(form, fieldName, (value) => {
    const oldValue = ref.current;

    if (oldValue !== value) {
      fn({
        oldValue,
        newValue: value,
      });

      ref.current = value;
    }
  });
}
