import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  ButtonGroup,
  FormErrorMessage,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { useQueryClient } from "@tanstack/react-query";

import {
  CreateAppArgsSchema,
  CreateAppArgsType,
} from "@bucketco/shared/appAPI";
import { EnvironmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormInput from "@/common/components/Form/FormInput";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import ModalCancelButton from "@/common/components/ModalCancelButton";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import useApiForm from "@/common/hooks/useApiForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export function NewApp({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentOrg } = useAuthContext();

  const { form, handleSubmit } = useApiForm(
    (data: CreateAppArgsType) =>
      api.post<"/apps">("/apps", data).then((res) => res.data.app),
    CreateAppArgsSchema,
    {
      onSuccess: async ({ environments }) => {
        segmentAnalytics.track("App Created");
        await queryClient.invalidateQueries(
          {
            queryKey: commonQueryKeys.bootstrap,
          },
          {
            throwOnError: true,
          },
        );
        onClose();
        navigate(EnvironmentUrl(environments[0]));
      },
    },
    {
      mode: "onChange",
      shouldUnregister: true,
    },
  );

  useEffect(() => {
    form.setValue("organizationId", currentOrg?.id ?? "");
  }, [currentOrg, form]);

  useEffect(() => {
    if (isOpen) {
      segmentAnalytics.track("New App Opened");
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} size="md" onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <FormProvider {...form}>
            <ModalHeader>
              <Heading size="lg">New app</Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack align="flex-start" spacing={6}>
                <FormInput
                  autoComplete="off"
                  label="App name"
                  name="name"
                  placeholder="Acme Production"
                  autoFocus
                  data-1p-ignore
                />

                <FormErrorMessage>
                  <ErrorMessage name="production" />
                </FormErrorMessage>

                <FormRootError />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <ModalCancelButton shouldNavigate={false} onClick={onClose} />
                <FormSubmitLegacy>Create</FormSubmitLegacy>
              </ButtonGroup>
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </form>
    </Modal>
  );
}
