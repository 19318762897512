import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { AdminPatchOrgType } from "@bucketco/shared/adminAPI";
import { ErrorResponse } from "@bucketco/shared/api";
import { Org } from "@bucketco/shared/organizationAPI";

import api from "@/common/utils/api";

export function useAdminOrgPatchMutation(orgId: string) {
  const queryClient = useQueryClient();

  return useMutation<
    Org,
    AxiosResponse<ErrorResponse>,
    AdminPatchOrgType,
    AdminPatchOrgType
  >({
    mutationKey: ["admin", "org", orgId],
    mutationFn: (body) =>
      api
        .patch<"/admin/:orgId">(`/admin/${orgId}`, body)
        .then((res) => res.data),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["orgs"],
      });
    },
  });
}
