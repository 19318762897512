import { forwardRef, ReactElement, useMemo } from "react";
import {
  RiArrowDownSLine,
  RiBracketsLine,
  RiFlag2Line,
  RiPieChartLine,
} from "react-icons/ri";
import {
  Box,
  Button,
  ButtonProps,
  chakra,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useFormControl,
} from "@chakra-ui/react";

import { UIFilterType } from "@bucketco/shared/filter";

import CompanyAttributeSvg from "@/common/assets/company-attribute-icon.svg?react";
import FeatureMetricSvg from "@/common/assets/feature-metric.svg?react";
import UserAttributeSvg from "@/common/assets/user-attribute-icon.svg?react";

const FeatureMetricIcon = chakra(FeatureMetricSvg);
const CompanyAttributeIcon = chakra(CompanyAttributeSvg);
const UserAttributeIcon = chakra(UserAttributeSvg);

const allTypes: readonly {
  type: UIFilterType;
  label: string;
  icon: ReactElement;
}[] = [
  {
    type: "segment",
    label: "Segment",
    icon: <RiPieChartLine size={14} />,
  },
  {
    type: "companyAttribute",
    label: "Company attribute",
    icon: <CompanyAttributeIcon boxSize={3.5} />,
  },
  {
    type: "userAttribute",
    label: "User attribute",
    icon: <UserAttributeIcon boxSize={3.5} />,
  },
  {
    type: "featureMetric",
    label: "Feature metric",
    icon: <FeatureMetricIcon boxSize={3.5} />,
  },
  {
    type: "featureTargeting",
    label: "Feature targeting",
    icon: <RiFlag2Line size={14} />,
  },
  {
    type: "otherContext",
    label: "Other context",
    icon: <RiBracketsLine size={14} />,
  },
] as const;

type RuleTypeProps = Omit<ButtonProps, "value" | "onChange"> & {
  value: UIFilterType | undefined;
  allowed?: UIFilterType[];
  onChange: (nextValue: UIFilterType) => void;
};

const RuleType = forwardRef<HTMLButtonElement, RuleTypeProps>(
  ({ allowed, value, onChange, ...rest }, ref) => {
    const formControlProps = useFormControl<HTMLButtonElement>(rest);
    const activeColor = useColorModeValue("brand.500", "brand.300");

    const usedTypes = useMemo(
      () => allTypes.filter(({ type }) => !allowed || allowed.includes(type)),
      [allowed],
    );

    const selectedOption = useMemo(() => {
      return usedTypes.find(({ type }) => type === value);
    }, [value, usedTypes]);

    return (
      <Menu size="xs">
        <MenuButton
          ref={ref}
          as={Button}
          background="appBackground"
          data-testid="rule-operator"
          isDisabled={formControlProps.disabled}
          px={3}
          rightIcon={
            <Box fontSize="xl" mr={-2}>
              <RiArrowDownSLine />
            </Box>
          }
          variant="input"
          {...formControlProps}
        >
          {selectedOption ? (
            <HStack spacing={2}>
              <Box color="dimmed" fontSize="14px">
                {selectedOption.icon}
              </Box>
              <Text display="block" noOfLines={1}>
                {selectedOption.label}
              </Text>
            </HStack>
          ) : (
            <Text color="dimmed" pl={1}>
              Filter type
            </Text>
          )}
        </MenuButton>
        <MenuList data-testid="rule-operator-list" maxH="sm" overflowY="auto">
          {usedTypes.map(({ type, label, icon }) => (
            <MenuItem
              key={type}
              color={value === type ? activeColor : undefined}
              icon={icon}
              onClick={() => onChange(type)}
            >
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  },
);

RuleType.displayName = "RuleType";

export default RuleType;
