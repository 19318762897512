import { useEffect } from "react";
import { useToken } from "@chakra-ui/react";
import { animate, MotionValue, useMotionValue } from "framer-motion";

const noShadow = "0 0 0 0 rgba(0, 0, 0, 0)";

export function useRaisedShadow(value: MotionValue<number>) {
  const boxShadow = useMotionValue(noShadow);
  const smallShadow = useToken("shadows", "md");

  useEffect(() => {
    let isActive = false;
    const handler = (latest: number) => {
      const wasActive = isActive;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(boxShadow, smallShadow);
        }
      } else {
        isActive = false;
        if (isActive !== wasActive) {
          animate(boxShadow, noShadow);
        }
      }
    };
    const unsubscribe = value.on("change", handler);
    return () => unsubscribe();
  }, [value, boxShadow, smallShadow]);

  return boxShadow;
}
