import { forwardRef, useEffect, useMemo, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { UserDTO, UsersQueryType } from "@bucketco/shared/userAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import AutocompleteSelect, {
  AutocompleteSelectProps,
  getStringFilterFunction,
  highlightStringMatch,
} from "@/common/components/AutocompleteSelect";
import { useUsers } from "@/common/data/useUsers";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

const filterFunctions = [
  getStringFilterFunction("name"),
  getStringFilterFunction("email"),
  getStringFilterFunction("id"),
];
const filterFunction = (user: UserDTO, search: string): boolean => {
  return filterFunctions.some((f) => f(user, search));
};

type UserAutocompleteSelectProps = {
  value?: string;
  query?: Omit<UsersQueryType, "idNameFilter" | "envId">;
  onChange: (value?: string) => void;
} & Omit<
  AutocompleteSelectProps<UserDTO>,
  | "value"
  | "onChange"
  | "isLoadingSuggestions"
  | "itemFilterFn"
  | "itemKeyProperty"
  | "itemRenderfn"
  | "suggestions"
  | "onClear"
  | "onSearchInput"
>;

export const UserAutocompleteSelect = forwardRef<
  HTMLButtonElement,
  UserAutocompleteSelectProps
>(function UserAutocompleteSelect(
  {
    value: valueProp,
    query,
    onChange,
    placeholder = "Select a user",
    ...autocompleteProps
  }: UserAutocompleteSelectProps,
  ref,
) {
  const fallbackValue: UserDTO | undefined = useMemo(
    () =>
      valueProp
        ? {
            id: valueProp,
            email: null,
            name: null,
          }
        : undefined,
    [valueProp],
  );

  const { currentApp } = useAuthContext();
  const queryClient = useQueryClient();
  const [userSearch, setUserSearch] = useState("");

  const { data: matchedUser } = useSingleUser(valueProp);
  const { data: searchUsers = [], isFetching } = useUsers(userSearch, query);

  const [selectedUser, setSelectedUser] = useState<UserDTO | undefined>(() =>
    fallbackValue?.id === matchedUser?.id ? matchedUser : fallbackValue,
  );

  const suggestions = selectedUser
    ? [selectedUser, ...searchUsers.filter((u) => u.id !== selectedUser.id)]
    : searchUsers;

  useEffect(() => {
    if (fallbackValue?.id === matchedUser?.id) {
      setSelectedUser(matchedUser);
    }
  }, [matchedUser, fallbackValue]);

  return (
    <AutocompleteSelect
      ref={ref}
      isLoadingSuggestions={isFetching}
      itemFilterFn={filterFunction}
      itemKeyProperty="id"
      itemRenderfn={(u, search) => (
        <Flex alignItems="center" gap={2} py={0.5} whiteSpace="nowrap">
          <Text>
            {search
              ? highlightStringMatch(u.name || u.id, search)
              : u.name || u.id}
          </Text>
          {u.email ? (
            <Text>
              &lt;
              {search ? highlightStringMatch(u.email, search) : u.email}
              &gt;
            </Text>
          ) : null}
          {u.name ? (
            <Text color="dimmedDisabled">
              ({search ? highlightStringMatch(u.id, search) : u.id})
            </Text>
          ) : null}
        </Flex>
      )}
      placeholder={placeholder}
      suggestions={suggestions}
      value={selectedUser}
      showClearButton
      onChange={(selected) => {
        // If a value is selected, pre-seed the single user query for immediate display
        if (selected) {
          queryClient.setQueryData(
            getSingleUserQueryKey(currentApp?.id, selected.id).queryKey,
            [selected],
          );
        }

        onChange(selected?.id ?? undefined);
        setUserSearch("");
      }}
      onClear={() => {
        setUserSearch("");
      }}
      onSearchInput={setUserSearch}
      {...autocompleteProps}
    />
  );
});

function getSingleUserQueryKey(
  appId?: string,
  envId?: string,
  search?: string,
) {
  const query: Omit<UsersQueryType, "envId"> = {
    idNameFilter: search,
  };
  return {
    query,
    queryKey: ["apps", appId, "environments", envId, "users", query],
  };
}

function useSingleUser(userId?: string) {
  const { appId, envId } = useCurrentEnv();

  const { query, queryKey } = getSingleUserQueryKey(appId, envId, userId);

  return useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return api
        .get<"/apps/:appId/users">(`/apps/${appId}/users`, {
          params: { ...query, envId: envId! },
        })
        .then((res) => res.data.data);
    },
    select: (users) => users.find((u) => u.id === userId),
    enabled: !!appId && !!envId && !!userId,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
  });
}
