import { FeatureSourceType } from "@bucketco/shared/featureAPI";

import {
  CodeExample,
  CodeExampleProps,
  CodeExampleType,
} from "@/common/components/CodeExample";
import {
  browserAttributeExample,
  browserEventExample,
  nodeAttributeExample,
  nodeEventExample,
  reactAttributeExample,
  reactEventExample,
} from "@/feature/data/codeExamples";

type SDK = "react" | "node" | "browser";

type Props = Omit<CodeExampleProps, "examples" | "initialExample"> & {
  flagKey: string;
  featureSource?: FeatureSourceType;
};

const sdkConfig: (Omit<CodeExampleType, "code"> & { key: SDK })[] = [
  {
    key: "react",
    label: "React SDK",
    highlighterLanguage: "jsx",
    docsLink: "https://docs.bucket.co/supported-languages/react-sdk",
  },
  {
    key: "browser",
    label: "Browser SDK",
    highlighterLanguage: "javascript",
    docsLink: "https://docs.bucket.co/supported-languages/browser-sdk",
  },
  {
    key: "node",
    label: "Node.js SDK",
    highlighterLanguage: "javascript",
    docsLink: "https://docs.bucket.co/supported-languages/node.js-sdk",
  },
];

export function FeatureCodeExample({
  flagKey,
  featureSource = "event",
  ...rest
}: Props) {
  return (
    <CodeExample
      examples={sdkConfig.map(({ key, ...rest }) => ({
        key,
        code: getCodeExample(flagKey, featureSource, key).trim(),
        ...rest,
      }))}
      initialExample="react"
      {...rest}
    />
  );
}

function getCodeExample(
  flagKey: string,
  featureSource: FeatureSourceType,
  output: SDK,
): string {
  const exampleMap = {
    event: {
      react: reactEventExample,
      browser: browserEventExample,
      node: nodeEventExample,
    },
    attribute: {
      react: reactAttributeExample,
      browser: browserAttributeExample,
      node: nodeAttributeExample,
    },
  };

  return exampleMap[featureSource][output](flagKey);
}
