import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";

export default function useSlackChannel(slackChannel: SlackChannel | null) {
  const { currentOrg, currentEnv, currentApp } = useAuthContext();

  if (!currentOrg || !currentEnv || !currentApp) {
    return undefined;
  }

  if (!currentOrg.hasSlackConnection) {
    return null;
  }

  return slackChannel
    ? slackChannel
    : currentEnv?.slackChannel ||
        currentApp?.environments.find((env) => env.isProduction)?.slackChannel;
}
