import { z } from "zod";

export const UpsertFormSchema = z.discriminatedUnion("action", [
  z.object({
    action: z.literal("create"),
    name: z
      .string({
        required_error: "You must supply a name",
      })
      .min(1, "You must supply a name"),
  }),
  z.object({
    action: z.literal("update"),
  }),
]);

export type UpsertForm = z.input<typeof UpsertFormSchema>;
