import React from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { ButtonGroup, Flex } from "@chakra-ui/react";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  WidgetDTO,
  WidgetSTARSFunnelConfiguration,
} from "@bucketco/shared/widgetAPI";

import { FeatureAutocompleteSelect } from "@/common/components/FeatureAutocompleteSelect";
import FormCancel from "@/common/components/Form/FormCancel";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { FormRootError } from "../../../common/components/Form/FormRootError";

export function WidgetForm({
  form,
  configuration,
  implicitConfiguration = {},
  submitLabel = "Save",
  widgetMutation,
  onDone,
}: {
  form: UseFormReturn<WidgetSTARSFunnelConfiguration>;
  configuration: WidgetSTARSFunnelConfiguration;
  implicitConfiguration?: Partial<WidgetSTARSFunnelConfiguration>;
  submitLabel?: string;
  widgetMutation: UseMutationResult<WidgetDTO, AxiosError<ErrorResponse>, any>;
  onDone: (configuration?: WidgetSTARSFunnelConfiguration) => void;
}) {
  const submitHandler = useFormMutationSubmitHandler(
    form,
    widgetMutation,
    (widget) => onDone(widget.configuration as WidgetSTARSFunnelConfiguration),
    {
      prepareVariables: (values) => ({ configuration: values }),
    },
  );

  return (
    <FormProvider {...form}>
      <Flex as="form" direction="column" gap={4} onSubmit={submitHandler}>
        {!implicitConfiguration.featureId && (
          <ManagedFormControl
            label="Feature"
            name="featureId"
            placeholder="Select a feature"
            render={({ field }) => (
              <FeatureAutocompleteSelect
                {...field}
                usePortal
                onChange={(featureName) => {
                  field.onChange(featureName?.id ?? null);
                }}
              />
            )}
          />
        )}

        <FormRootError />

        <ButtonGroup>
          <FormSubmitLegacy allowPristine>{submitLabel}</FormSubmitLegacy>
          <FormCancel color="dimmed" onClick={() => onDone(configuration)}>
            Cancel
          </FormCancel>
        </ButtonGroup>
      </Flex>
    </FormProvider>
  );
}
