import { progressAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  progressAnatomy.keys,
);

const filledTrack = defineStyle((props) => ({
  bg: props.color ? props.color : `${props.colorScheme}.500`,
  _dark: {
    bg: props.color ? props.color : `${props.colorScheme}.500`,
  },
}));

const baseStyle = defineStyle((props) => ({
  track: {
    bg: "gray.100",
    borderRadius: "full",
    _dark: {
      bg: "gray.700",
    },
  },
  filledTrack: filledTrack(props),
}));

// add highlight variant, used on feature metrics view
export const ProgressTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    transition: {
      filledTrack: {
        transition: "width 0.3s ease-in-out",
      },
    },
  },
  sizes: {
    xs: {
      track: { h: "0.4rem" },
    },
  },
});
