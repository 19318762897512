import { InputProps } from "@chakra-ui/react";

import { AttributeFilters } from "@/common/components/AttributeFilters";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { useEventAttributeNames } from "@/common/data/useEventAttributeNames";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";

export default function FormEventAttributeFilterPicker({
  name,
  eventName,
  size = "md",
  ...rest
}: {
  name: string;
  eventName: string;
  label?: string;
  isDisabled?: boolean;
  size?: InputProps["size"];
} & FormElementBaseProps &
  FormEventAttributeFilterPickerInputProps) {
  const [managedProps, inputProps] = useManagedFormControlProps(rest);
  return (
    <ManagedFormControl
      {...managedProps}
      name={name}
      render={({ field }) => (
        <FormEventAttributeFilterPickerInput
          {...field}
          {...inputProps}
          eventName={eventName}
          isDisabled={managedProps.isDisabled}
          name={name}
          size={size}
        />
      )}
      size={size}
    />
  );
}

export type FormEventAttributeFilterPickerInputProps = {
  name: string;
  eventName: string;
  size?: InputProps["size"];
  buttonText?: string;
  isDisabled?: boolean;
};

export function FormEventAttributeFilterPickerInput({
  name,
  eventName,
  size = "md",
  buttonText,
  isDisabled = false,
}: FormEventAttributeFilterPickerInputProps) {
  const { data: attributeNames = [] } = useEventAttributeNames(eventName);

  return (
    <AttributeFilters
      attributes={attributeNames}
      buttonText={buttonText}
      entityType="event"
      eventName={eventName}
      isDisabled={isDisabled}
      minFilterCount={0}
      name={name}
      size={size}
    />
  );
}
