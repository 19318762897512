import { useCallback, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { AppDTO } from "@bucketco/shared/appAPI";
import { EnvironmentDTO } from "@bucketco/shared/environmentAPI";
import { EnvironmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import DoubleArrowSelectIcon from "@/common/assets/double-arrow-select-icon.svg?react";
import EnvironmentSvg from "@/common/assets/environment-dot-circle-fill.svg?react";
import { NewApp } from "@/common/components/NewApp";
import OrganizationSwitcher from "@/common/components/OrganizationSwitcher";
import OrgLogo from "@/common/components/OrgLogo";
import { TreeVisualization } from "@/common/components/TreeVisualization";
import { IndentType } from "@/common/utils/tree";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { useEnvironmentColors } from "@/environment/hooks/useEnvironmentColors";

const EnvironmentIcon = chakra(EnvironmentSvg);

export function AppEnvironmentPicker() {
  const { currentOrg, currentApp, currentEnv } = useAuthContext();

  const [isNewAppOpen, setIsNewAppOpen] = useState(false);
  const colors = useEnvironmentColors();
  const match = useMatch("/envs/:envId/*");

  const onNewAppClose = useCallback(
    () => setIsNewAppOpen(false),
    [setIsNewAppOpen],
  );

  const [isOrgSwitcherOpen, setIsOrgSwitcherOpen] = useState(false);

  return (
    <>
      <Menu autoSelect={false} placement="bottom-start" size="md" closeOnSelect>
        <MenuButton
          _hover={{ bg: useColorModeValue("gray.100", "gray.800") }}
          as={Button}
          bg="transparent"
          borderRadius="lg"
          flexShrink={0}
          mt={1}
          px={2}
          py={6}
          rightIcon={
            <Box color="dimmed" ml={-2} mr={-2}>
              <DoubleArrowSelectIcon width={24} />
            </Box>
          }
          size="lg"
          textAlign="left"
          variant="ghost"
          w="auto"
        >
          <Flex align="center" as="span" gap={2.5} w="100%">
            <OrgLogo
              borderRadius="md"
              name={currentOrg!.name}
              size="sm"
              url={currentOrg!.logoUrl}
            />
            <Flex as="span" flexDirection="column" gap={1} overflow="hidden">
              <Text as="span" fontSize="sm" fontWeight="medium" isTruncated>
                {currentApp?.name}
              </Text>
              <EnvironmentDisplayName
                color="dimmed"
                environment={currentEnv!}
                fontSize="xs"
                fontWeight="normal"
                spacing={0.5}
              />
            </Flex>
          </Flex>
        </MenuButton>
        <Portal>
          <MenuList
            maxH="calc(100vh - 5rem)" // ensures that the menu doesn't go off the bottom of the screen
            minW="42"
            overflowY="auto"
            zIndex={3}
          >
            {currentOrg?.apps.map((app) => (
              <MenuGroup key={app.id} title={app.name}>
                {appEnvironmentMenuItems(app)?.map(
                  ({ app, environment, indents }) => {
                    const isSameApp = currentApp?.id === app.id;
                    const isSelected = currentEnv?.id === environment.id;
                    const envColor =
                      colors[
                        environment.isProduction
                          ? "production"
                          : "nonProduction"
                      ];
                    const iconColor = envColor.icon;
                    const envUrl = EnvironmentUrl(environment);
                    const fullEnvUrl = `${envUrl}/${
                      match ? match.params["*"] : ""
                    }`;

                    return (
                      <MenuItem
                        key={environment.id}
                        as={Link}
                        gap={0}
                        h={7}
                        paddingLeft={1}
                        py={0}
                        to={isSameApp ? fullEnvUrl : envUrl}
                      >
                        {!!indents.length && (
                          <TreeVisualization h="125%" indents={indents} />
                        )}
                        <HStack spacing={1}>
                          <EnvironmentIcon
                            boxSize={3}
                            color={iconColor}
                            ml={!indents.length ? 1 : undefined}
                          />
                          <Text>{environment.name}</Text>
                        </HStack>
                        <HStack ml={2} spacing={2}>
                          {isSelected ? (
                            <Badge
                              colorScheme="brand"
                              size="xs"
                              variant="subtle"
                            >
                              Current
                            </Badge>
                          ) : null}
                          {app.demo ? <Badge size="xs">Demo</Badge> : null}
                        </HStack>
                      </MenuItem>
                    );
                  },
                )}
              </MenuGroup>
            ))}
            <Box bg="popoverBackground" bottom={0} pos="sticky">
              <MenuDivider mb={0} />
              <Button
                m={2}
                size="xs"
                variant="outline"
                onClick={() => setIsNewAppOpen(true)}
              >
                New app
              </Button>
            </Box>
          </MenuList>
        </Portal>
        <NewApp isOpen={isNewAppOpen} onClose={onNewAppClose} />
      </Menu>
      <OrganizationSwitcher
        isOpen={isOrgSwitcherOpen}
        onClose={() => setIsOrgSwitcherOpen(false)}
      />
    </>
  );
}

type EnvironmentMenuItem = {
  app: AppDTO;
  environment: EnvironmentDTO;
  indents: Array<IndentType>;
};

function appEnvironmentMenuItems(app: AppDTO): EnvironmentMenuItem[] {
  const tree: Array<EnvironmentMenuItem> = [];

  // One iteration to establish the tree
  app.environments.forEach((environment, index) => {
    const node: EnvironmentMenuItem = {
      app,
      environment,
      indents: index !== app.environments.length - 1 ? ["├"] : ["└"],
    };

    tree.push(node);
  });

  return tree;
}
