import { ReactNode } from "react";
import {
  FormHelperText,
  FormHelperTextProps,
  Link,
  Text,
} from "@chakra-ui/react";

export type FormElementDescriptionProps = {
  text: ReactNode;
  docsURL?: string;
  isDisabled?: boolean;
} & FormHelperTextProps;

export function FormElementDescription({
  text,
  docsURL,
  isDisabled = false,
  ...textProps
}: FormElementDescriptionProps) {
  return (
    <FormHelperText
      color={isDisabled ? "dimmedDisabled" : "dimmed"}
      fontSize="sm"
      lineHeight="base"
      transition="color 200ms"
      {...textProps}
    >
      <Text as="span">{text}</Text>
      {docsURL && (
        <Link
          display="block"
          fontSize="sm"
          href={docsURL}
          mt={2}
          target="_blank"
        >
          Learn more
        </Link>
      )}
    </FormHelperText>
  );
}
