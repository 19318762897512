import { useToast } from "@chakra-ui/react";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  containsFeatureMetricFilter,
  getFilterCount,
} from "@bucketco/shared/filter";
import { SegmentDTO } from "@bucketco/shared/segmentAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { UpsertForm } from "@/common/types/upsertForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { segmentQueryKeys } from "@/company/data/segmentQueryKeys";
import { useCompaniesTableState } from "@/company/hooks/useCompaniesTableState";

export const useSegmentUpsertMutations = (
  segment: SegmentDTO,
  options?: Omit<
    UseMutationOptions<SegmentDTO, AxiosError<ErrorResponse>, UpsertForm>,
    "mutationFn"
  >,
) => {
  const { currentApp, currentEnv } = useAuthContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  const {
    companyFilter,
    onCompanyFilterChange,
    sorting,
    onSortingChange,
    columnStates,
    onColumnStatesChange,
  } = useCompaniesTableState(segment);

  return useMutation({
    ...options,
    mutationFn: async (values: UpsertForm) => {
      if (values.action === "update") {
        return api
          .patch<"/apps/:appId/segments/:segmentId">(
            `/apps/${currentApp?.id}/segments/${segment.id}`,
            {
              companyFilter: !segment.isAllSegment ? companyFilter : undefined,
              columns: columnStates,
              columnSortKey: sorting[0]?.id,
              columnSortDesc: sorting[0]?.desc,
            },
          )
          .then((res) => res.data);
      } else {
        // Create a new segment
        return api
          .post<"/apps/:appId/segments">(`/apps/${currentApp?.id}/segments`, {
            name: values.name,
            companyFilter,
            columns: columnStates,
            columnSortKey: sorting[0]?.id,
            columnSortDesc: sorting[0]?.desc,
          })
          .then((res) => res.data);
      }
    },
    onSuccess: async (data, variables, context) => {
      segmentAnalytics.track(
        variables.action === "update" ? "Segment Updated" : "Segment created",
        {
          filters_count: getFilterCount(data.companyFilter),
          feature_metric_filter_used: containsFeatureMetricFilter(
            data.companyFilter,
          ),
        },
      );

      toast({
        title:
          variables.action === "update"
            ? "Segment updated"
            : "New segment created",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onCompanyFilterChange(undefined);
      onSortingChange([]);
      onColumnStatesChange([]);

      // Wait for segments to be re-fetched
      await queryClient.invalidateQueries({
        queryKey: segmentQueryKeys.list(currentApp?.id, currentEnv?.id),
      });

      options?.onSuccess?.(data, variables, context);
    },
  });
};
