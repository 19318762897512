import {
  Box,
  Flex,
  FlexboxProps,
  FlexProps,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  FrequencyNumber,
  frequencyNumberToText,
  frequencyRoundNumber,
} from "@bucketco/shared/featureAPI";

type FrequencyProgressProps = {
  frequency: FrequencyNumber;
  direction?: FlexboxProps["flexDirection"];
  filledColor?: string;
  emptyColor?: string;
  showText?: boolean;
  isLoading?: boolean;
  size?: "2xs" | "xs" | "sm";
};

export function FrequencyProgress({
  frequency,
  filledColor,
  emptyColor,
  showText = true,
  direction = "row",
  isLoading,
  size = "sm",
  ...props
}: FrequencyProgressProps & FlexProps) {
  const defaultFilledColor = useColorModeValue("brand.500", "brand.400");
  const defaultEmptyColor = useColorModeValue("gray.200", "gray.700");
  const frequencyNumber =
    frequency !== null ? frequencyRoundNumber(frequency) + 1 : 0;
  const frequencyText = frequencyNumberToText(frequency);

  const sizes = {
    "2xs": {
      spacing: "1px",
      circle: 1,
    },
    xs: {
      spacing: 0.5,
      circle: 2,
    },
    sm: {
      spacing: 0.5,
      circle: 2.5,
    },
  } as const;

  return (
    <Flex flexDirection={direction} gap={2} {...props}>
      <HStack spacing={sizes[size].spacing}>
        {[...Array(4)].map((_, index) => {
          return (
            <Box
              key={index + 1}
              bg={
                index + 1 <= frequencyNumber
                  ? filledColor ?? defaultFilledColor
                  : emptyColor ?? defaultEmptyColor
              }
              borderRadius="full"
              h={sizes[size].circle}
              w={sizes[size].circle}
            />
          );
        })}
      </HStack>
      {!isLoading && showText && (
        <Text casing="capitalize" fontSize={size}>
          {frequencyText}
        </Text>
      )}
    </Flex>
  );
}
