const qk = {
  list: (appId?: string) => ["apps", appId, "featureViews"] as const,

  single: (appId?: string, viewId?: string | null) =>
    [...qk.list(appId), viewId] as const,

  singleFeatureRelationViews: (appId?: string) =>
    [...qk.list(appId), "feature", "views"] as const,
};

export { qk as featureViewQueryKeys };
