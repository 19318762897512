import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  DataExportHistoryItem,
  DataExportTypeType,
} from "@bucketco/shared/exportAPI";

import { appQueryKeys } from "@/app/data/appQueryKeys";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

export default function useDataExportHistoryData(type?: DataExportTypeType) {
  const { appId, envId } = useCurrentEnv();

  return useQuery<DataExportHistoryItem[], AxiosError<ErrorResponse>>({
    queryKey: appQueryKeys.dataExportHistory(appId, envId, type),

    queryFn: () =>
      api
        .get<"/apps/:appId/export/:type/history">(
          `/apps/${appId}/export/${type}/history`,
          { params: { envId: envId! } },
        )
        .then((res) => res.data),
    enabled: !!appId && !!envId && !!type,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
