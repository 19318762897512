import { FunctionComponent, SVGProps } from "react";
import { chakra, ChakraComponent, ChakraProps } from "@chakra-ui/react";

import { FunnelState } from "@bucketco/shared/featureAPI";

import StepChurned from "@/common/assets/stars-state-churned.svg?react";
import StepNeverSvg from "@/common/assets/stars-state-never.svg?react";
import StepNoneSvg from "@/common/assets/stars-state-none.svg?react";
import StepRetainedSvg from "@/common/assets/stars-state-retained.svg?react";
import StepTriedSvg from "@/common/assets/stars-state-tried.svg?react";

type State = FunnelState | "none";

const ICONS: Record<
  State,
  ChakraComponent<FunctionComponent<SVGProps<SVGSVGElement>>>
> = {
  none: chakra(StepNoneSvg),
  never: chakra(StepNeverSvg),
  tried: chakra(StepTriedSvg),
  retained: chakra(StepRetainedSvg),
  churned: chakra(StepChurned),
};

type Props = ChakraProps & { state: State };

export default function StarsStateIcon({ state, ...props }: Props) {
  const Icon = ICONS[state];
  return <Icon {...props} />;
}
