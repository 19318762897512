import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys,
);

const thStyle = {
  color: "dimmed",
  textTransform: "none",
  fontSize: "sm",
  fontWeight: "medium",
  letterSpacing: "inherit",
};

export const TableTheme = defineMultiStyleConfig({
  baseStyle: () => ({
    table: {
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: thStyle,
    thead: {
      th: thStyle,
    },
  }),
  sizes: {
    xs: {
      th: {
        paddingTop: 2,
        paddingBottom: 2,
      },
      thead: {
        "tr > *:first-of-type": { paddingLeft: 0 },
        "tr > *:last-of-type": { paddingRight: 0 },
      },
      tbody: {
        "tr > *:first-of-type": { paddingLeft: 0 },
        "tr > *:last-of-type": { paddingRight: 0 },
      },
    },
    sm: {
      th: {
        px: 2,
        py: 3,
      },
      td: {
        px: 2,
        py: 3,
      },
      thead: {
        "tr > *:first-of-type": { paddingLeft: 4 },
        "tr > *:last-of-type": { paddingRight: 4 },
      },
      tbody: {
        "tr > *:first-of-type": { paddingLeft: 4 },
        "tr > *:last-of-type": { paddingRight: 4 },
      },
    },
    md: {
      th: { px: 4 },
      td: {
        px: 4,
        py: 3.5,
      },
      tr: {
        "> *:first-of-type": { paddingLeft: 6 },
        "> *:last-of-type": { paddingRight: 6 },
      },
    },
  },
  variants: {
    simple: {
      th: {
        borderBottom: "1px solid",
        borderColor: "appBorder",
      },
      td: {
        borderBottom: "1px solid",
        borderColor: "appBorder",
      },
    },
    clickable: (props) => ({
      th: {
        borderBottom: "1px solid",
        borderColor: "appBorder",
      },
      td: {
        borderBottom: "1px solid",
        borderColor: "appBorder",
      },
      tr: {
        _hover: {
          td: {
            cursor: "pointer",
            bg: mode("gray.25", "gray.825")(props),
          },

          "[data-draghandle]": {
            opacity: 1,
          },
        },
        ":has([data-candrop='true'])": {
          td: {
            bg: `${mode("gray.25", "gray.825")(props)}`,
          },
        },
      },
    }),
  },
});
