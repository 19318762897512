import { forwardRef } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import {
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useFormControl,
} from "@chakra-ui/react";

import {
  AttributeFilterOperator,
  operatorDisplayName,
  OperatorsByType,
} from "@bucketco/shared/attributeFilter";

type RuleOperatorProps = Omit<ButtonProps, "value" | "onChange"> & {
  value: AttributeFilterOperator | undefined;
  onChange: (nextValue: AttributeFilterOperator) => void;
  operators: Partial<typeof OperatorsByType>;
};

const RuleOperator = forwardRef<HTMLButtonElement, RuleOperatorProps>(
  ({ value, onChange, operators, ...rest }, ref) => {
    const formControlProps = useFormControl<HTMLButtonElement>(rest);
    const activeColor = useColorModeValue("brand.500", "brand.300");

    const isCustomType =
      Object.entries(operators).filter(([_, ops]) => ops.length > 0).length > 1;

    return (
      <Menu size="xs">
        <MenuButton
          ref={ref}
          as={Button}
          background="appBackground"
          data-testid="rule-operator"
          isDisabled={formControlProps.disabled}
          rightIcon={
            <Box fontSize="xl" mr={-2}>
              <RiArrowDownSLine />
            </Box>
          }
          variant="input"
          {...formControlProps}
        >
          {value && (
            <Text display="block" noOfLines={1}>
              {operatorDisplayName[value]}
            </Text>
          )}
        </MenuButton>
        <MenuList data-testid="rule-operator-list" maxH="sm" overflowY="auto">
          {Object.entries(operators).map(([type, ops], index) => {
            if (ops.length === 0) return null;
            return (
              <MenuGroup
                key={index}
                borderTop="1px solid"
                borderTopColor="appBorder"
                color="dimmed"
                fontSize="xs"
                m={0}
                pb={1}
                pt={2}
                px={3}
                textTransform="capitalize"
                title={isCustomType ? type : undefined}
              >
                {ops.map((operator, index) => (
                  <MenuItem
                    key={index}
                    color={value === operator ? activeColor : undefined}
                    data-testid={`operator-${operator}`}
                    pl={6}
                    onClick={() => onChange(operator)}
                  >
                    {operatorDisplayName[operator]}
                  </MenuItem>
                ))}
              </MenuGroup>
            );
          })}
        </MenuList>
      </Menu>
    );
  },
);

RuleOperator.displayName = "RuleOperator";

export default RuleOperator;
