import { Text } from "@chakra-ui/react";

import { FeatureGoal } from "@bucketco/shared/featureAPI";
import { WidgetFeatureMetrics } from "@bucketco/shared/widgetAPI";

import { useGoalStatusColors } from "@/feature/hooks/useGoalStatusColors";
import { formatGoalValue } from "@/feature/utils/formatGoalValue";

export function GoalDescription({
  goal,
  colored = false,
  showThreshold = false,
  showLabel = true,
}: {
  goal: FeatureGoal;
  colored?: boolean;
  showThreshold?: boolean;
  showLabel?: boolean;
}) {
  const metric = WidgetFeatureMetrics[goal.metric];
  const isPercentage = metric?.type === "percentage";

  const colorSet = useGoalStatusColors(
    goal.currentValue >= goal.threshold ? "achieved" : "evaluating",
  );
  const color = colored ? colorSet.fg : undefined;

  return (
    <Text as="span" color={color} fontSize="sm">
      {showLabel ? `${metric?.label}: ` : undefined}
      {formatGoalValue(goal.currentValue ?? 0, isPercentage, goal.metric)}
      {showThreshold &&
        ` / ${formatGoalValue(goal.threshold ?? 0, isPercentage, goal.metric)}`}
    </Text>
  );
}
