import {
  forwardRef,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useId,
  useState,
} from "react";
import {
  Flex,
  Input,
  InputProps,
  SystemStyleObject,
  Tag,
  TagCloseButton,
  useColorModeValue,
  usePrevious,
  useUpdateEffect,
} from "@chakra-ui/react";

import { Group } from "@/common/components/Group";

type MultiValueInputProps<TValue = string> = {
  values: TValue[];
  onChange: (values: TValue[]) => void;
  valueRender?: (value: TValue) => ReactNode;
  onSearchInput?: (searchValue: string) => void;
} & Omit<InputProps, "value" | "onChange">;

export const MultiValueInput = forwardRef<
  HTMLInputElement,
  MultiValueInputProps
>(function MultiValueInput(
  {
    values = [],
    onChange,
    onSearchInput,
    valueRender = (v) => v,
    ...inputProps
  },
  ref,
) {
  const [inputValue, setInputValue] = useState("");

  const tagContainerStyles: SystemStyleObject = {
    borderWidth: 1,
    borderColor: useColorModeValue("gray.250", "gray.650"),
    borderRadius: "md",
    bgColor: "appBackground",
    fontSize: "sm",
  };

  const trimmedInputValue = inputValue.trim();
  useEffect(() => {
    if (onSearchInput) {
      onSearchInput(trimmedInputValue);
    }
  }, [trimmedInputValue, onSearchInput]);

  const prevValues = usePrevious(values);

  // One or more items have been appended to values, possibly from outside.
  // Reset the local search input
  useUpdateEffect(() => {
    if (
      values.length >= prevValues.length + 1 &&
      prevValues.every((i, idx) => i === values[idx])
    ) {
      setInputValue("");
    }
  }, [values, prevValues]);

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      const value = e.currentTarget.value.trim();

      if (value && !values.includes(value)) {
        e.preventDefault();
        onChange([...values, value]);
        setInputValue("");
      }
    }

    if (e.key === "Backspace" && e.currentTarget.value === "") {
      onChange(values.slice(0, -1));
    }
  }

  function handleRemove(value: string) {
    onChange(values.filter((v) => v !== value));
  }

  return (
    <Group direction="vertical" attached>
      {Array.isArray(values) && values.length > 0 && (
        <Flex
          flexWrap="wrap"
          gap={1.5}
          height="auto"
          p={1.5}
          sx={tagContainerStyles}
          onFocus={(e) => e.stopPropagation()} // Hack to avoid focus triggering auto complete popups
        >
          {values.map((v) => (
            <InputValue key={v} value={v} onRemove={handleRemove}>
              {valueRender(v)}
            </InputValue>
          ))}
        </Flex>
      )}

      <Input
        ref={ref}
        {...inputProps}
        bgColor="appBackground"
        value={inputValue}
        onChange={(e) => setInputValue(e.currentTarget.value)}
        onKeyDown={handleKeyDown}
      />
    </Group>
  );
});

function InputValue<TValue = string>({
  value,
  onRemove,
  children,
}: {
  value: TValue;
  onRemove: (value: TValue) => void;
  children: ReactNode;
}) {
  const tagId = useId();
  function handleKeyDown(e: KeyboardEvent) {
    if (["Backspace", "Delete"].includes(e.key)) {
      onRemove(value);
    }
  }

  return (
    <Tag colorScheme="gray" id={tagId} size="2xs" onKeyDown={handleKeyDown}>
      <div>{children}</div>
      <TagCloseButton
        aria-describedby={tagId}
        aria-label="Remove"
        ml={0.5}
        onClick={() => onRemove(value)}
      />
    </Tag>
  );
}
