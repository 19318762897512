import { ReactNode, useMemo } from "react";
import { Link, To, useParams } from "react-router-dom";
import {
  Box,
  GridItem,
  HStack,
  Progress,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
  useToken,
  VStack,
} from "@chakra-ui/react";

import {
  FeatureDetail,
  FeatureMetricsCurrent,
  FunnelState,
  FunnelStateToStepMap,
  STARSMetrics,
} from "@bucketco/shared/featureAPI";
import { buildFeatureFunnelFilters } from "@bucketco/shared/filter";
import { SatisfactionScore } from "@bucketco/shared/schemas/satisfactionScore";
import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";
import { CompaniesUrl } from "@bucketco/shared/urls";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import CompactNumber from "@/common/components/CompactNumber";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import MotionBox from "@/common/components/MotionBox";
import PercentageNumber from "@/common/components/PercentageNumber";
import { SectionHeading } from "@/common/components/SectionHeading";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import pluralize from "@/common/utils/pluralize";
import { SegmentDisplay } from "@/company/components/SegmentDisplay";
import useAllSegment from "@/company/data/useAllSegment";
import { starsFunnelStateDescriptions } from "@/feature/data/starsFunnelDescriptions";
import { useFeatureDescriptions } from "@/feature/hooks/useFeatureDescriptions";
import { useStarsFunnelColors } from "@/feature/hooks/useStarsFunnelColors";
import { useTargetingParam } from "@/feature/hooks/useTargetingParam";
import {
  RelevantMetrics,
  relevantMetricsForFeature,
} from "@/feature/utils/relevantMetricsForFeature";
import { useSatisfactionColors } from "@/feedback/components/FeedbackSatisfaction";

import { StarsMetricDescription } from "./StarsMetricDescription";
import { StarsRateLabel } from "./StarsRateLabel";

type AggregatedData = Record<
  Exclude<keyof STARSMetrics, "company">,
  { count: number; dropOff: number; rate: number; isDisabled?: boolean }
>;

type RateProps = {
  color: string;
  rate: number;
  label: string;
  isDisabled?: boolean;
  isAnimated?: boolean;
};

function Rate({
  color,
  rate,
  label,
  isDisabled,
  isAnimated = true,
}: RateProps) {
  const disabledColor = useColorModeValue("gray.200", "gray.700");
  return (
    <MotionBox
      alignItems="center"
      animate={{ opacity: 1, translateY: 0 }}
      display="flex"
      flexDirection="column"
      gap={1}
      initial={isAnimated && { opacity: 0, translateY: -4 }}
      transition={{
        type: "ease",
        duration: "0.2",
        delay: "0.6",
      }}
    >
      <Text fontSize="sm" variant="dimmed" whiteSpace="nowrap">
        {label}
      </Text>
      {!isDisabled && (
        <HStack>
          <Progress
            color={!isDisabled ? color : disabledColor}
            max={1}
            min={0}
            size="xs"
            value={rate}
            width={12}
          />
          <Text fontSize="sm" fontWeight="medium">
            <StarsRateLabel rate={rate} />
          </Text>
        </HStack>
      )}
    </MotionBox>
  );
}

type TooltipLinkProps = {
  link: To;
  tooltip: ReactNode;
  placement: "top" | "bottom";
  children: ReactNode;
  isDisabled?: boolean;
};

function TooltipLink({
  link,
  tooltip,
  placement,
  isDisabled,
  children,
}: TooltipLinkProps) {
  if (isDisabled) return <>{children}</>;
  return (
    <Link to={link}>
      <Tooltip
        label={tooltip}
        maxWidth={80}
        openDelay={300}
        placement={placement}
        hasArrow
      >
        {children}
      </Tooltip>
    </Link>
  );
}

type TooltipWithStatesProps = {
  states: FunnelState[];
  count: number;
  footer?: string;
};

function AnnotatedTooltipBody({
  states,
  count,
  footer,
}: TooltipWithStatesProps) {
  return (
    <VStack lineHeight={1}>
      <Text>{createTooltipText(count, states.length)}</Text>
      <HStack>
        {states.map((state) => {
          const description = starsFunnelStateDescriptions.find(
            ({ id }) => id === FunnelStateToStepMap[state],
          )!;
          return (
            <HStack key={state}>
              {description.visualization}
              <Text key={state}>{description.label}</Text>
            </HStack>
          );
        })}
      </HStack>
      {footer && <Text>{footer}</Text>}
    </VStack>
  );
}

type BarProps = {
  feature: FeatureDetail;
  step: keyof AggregatedData;
  stepCount: number;
  totalCount: number;
  dropOffCount: number;
  color: string;
  tooltip?: ReactNode;
  tooltipDropOff?: ReactNode;
  showMetric?: boolean;
  showCount?: boolean;
  minHeight?: number;
  isDisabled?: boolean;
  isAnimated?: boolean;
};

function createTooltipText(count: number, stateCount: number) {
  let message: string;
  if (count === 1) {
    message = "Show 1 company that is currently in";
  } else {
    message = `Show ${count} companies that are currently in`;
  }

  if (stateCount > 1) {
    message += " either";
  }

  return message;
}

function Bar({
  stepCount,
  totalCount,
  dropOffCount,
  color,
  showMetric = true,
  showCount = true,
  minHeight = 2,
  tooltip,
  tooltipDropOff,
  isDisabled,
  isAnimated = true,
  step,
  feature,
}: BarProps) {
  const { envId, envName } = useParams();
  const statsBgColor = useColorModeValue("white", "gray.700");
  const statsBorderColor = useColorModeValue("blackAlpha.100", "gray.650");
  const { disabledColor, dropOffColor } = useStarsFunnelColors();
  const [disabledColorToken, dropOffColorToken] = useToken("colors", [
    disabledColor,
    dropOffColor,
  ]);
  const stepHeight = getFraction(stepCount, totalCount) * 100;
  const dropOffHeight = getFraction(stepCount + dropOffCount, totalCount) * 100;
  const envUrlArg = { id: envId!, name: envName || "" };
  const [subsegments] = useSubsegmentParam(["Active"]);
  const [useTargeting] = useTargetingParam(true);
  const allSegment = useAllSegment();

  const [topFilter, bottomFilter] = useMemo(() => {
    return buildFeatureFunnelFilters(
      step,
      feature.id,
      useTargeting,
      subsegments,
    );
  }, [step, feature, useTargeting, subsegments]);

  return (
    <Box mt={2} position="relative">
      <TooltipLink
        isDisabled={isDisabled}
        link={CompaniesUrl(envUrlArg, {
          filter: topFilter,
          segmentId: allSegment?.id,
        })}
        placement="top"
        tooltip={tooltipDropOff}
      >
        <MotionBox
          animate={{
            scaleY: 1,
            backgroundColor: isDisabled
              ? disabledColorToken
              : dropOffColorToken,
          }}
          borderRadius="lg"
          bottom={0}
          height={`${dropOffHeight}%`}
          initial={
            isAnimated && {
              scaleY: 0,
              backgroundColor: isDisabled
                ? disabledColorToken
                : dropOffColorToken,
            }
          }
          minHeight={minHeight}
          position="absolute"
          style={{ originY: 1 }}
          transition={{ type: "ease", duration: "0.3" }}
          width="100%"
        />
      </TooltipLink>
      <TooltipLink
        isDisabled={isDisabled}
        link={CompaniesUrl(envUrlArg, {
          filter: bottomFilter,
          segmentId: allSegment?.id,
        })}
        placement="bottom"
        tooltip={tooltip}
      >
        <MotionBox
          animate={{ scaleY: !isDisabled ? 1 : 0 }}
          bg={color}
          borderRadius="lg"
          bottom={0}
          height={`${stepHeight}%`}
          initial={isAnimated && { scaleY: 0 }}
          minHeight={minHeight}
          position="absolute"
          style={{ originY: 1 }}
          transition={{ type: "ease", duration: "0.3" }}
          width="100%"
        />
      </TooltipLink>
      <MotionBox
        animate={{ opacity: showMetric ? 1 : 0 }}
        bg={statsBgColor}
        borderColor={statsBorderColor}
        borderRadius="lg"
        borderWidth={1}
        bottom={`max(var(--chakra-space-1), ${stepHeight}% - var(--chakra-space-16) + var(--chakra-space-5))`}
        boxShadow="md"
        display="flex"
        flexDirection="column"
        height={14}
        initial={isAnimated && { opacity: 0 }}
        justifyContent="center"
        left="50%"
        minWidth={16}
        position="absolute"
        px={5}
        textAlign="center"
        transform="translateX(-50%)"
        transition={{ type: "ease", duration: "0.2", delay: "0.3" }}
      >
        {showCount && (
          <Text fontSize="xl">
            <CompactNumber value={stepCount} />
          </Text>
        )}
        <Text color="gray.500" fontSize={!showCount ? "xl" : "sm"}>
          <PercentageNumber showdecimals={true} value={stepHeight / 100} />
        </Text>
      </MotionBox>
    </Box>
  );
}

type HeaderRatesProps = {
  feature: FeatureDetail;
  title?: string;
  steps: AggregatedData;
  isAnimated?: boolean;
};

function HeaderRates({
  feature,
  title,
  steps,
  isAnimated = true,
}: HeaderRatesProps) {
  const { adoptedColor, retainedColor } = useStarsFunnelColors();
  const satisfactionColors = useSatisfactionColors();
  const satisfiedColor = useMemo(
    () =>
      satisfactionColors[
        Math.min(
          5,
          Math.floor(steps.satisfied.rate * 5) + 1,
        ) as SatisfactionScore
      ],
    [satisfactionColors, steps.satisfied.rate],
  );

  return (
    <>
      <GridItem colSpan={feature.source === "event" ? 2 : 1}>
        {title && <SectionHeading>{title}</SectionHeading>}
      </GridItem>
      <Rate
        color={adoptedColor}
        isAnimated={isAnimated}
        label="Adoption rate"
        rate={steps.adopted.rate}
      />
      <Rate
        color={retainedColor}
        isAnimated={isAnimated}
        isDisabled={steps.retained.isDisabled}
        label={
          !steps.retained.isDisabled ? "Retention rate" : "No retention rate"
        }
        rate={steps.retained.rate}
      />
      <Rate
        color={satisfiedColor}
        isAnimated={isAnimated}
        isDisabled={steps.satisfied.isDisabled}
        label={
          !steps.satisfied.isDisabled
            ? "Satisfaction rate"
            : "No satisfaction rate"
        }
        rate={steps.satisfied.rate}
      />
    </>
  );
}

type BarsProps = {
  feature: FeatureDetail;
  steps: AggregatedData;
  isAnimated?: boolean;
};

function Bars({ feature, steps, isAnimated = true }: BarsProps) {
  const {
    segmentColor,
    triedColor,
    adoptedColor,
    retainedColor,
    satisfiedColor,
  } = useStarsFunnelColors();

  return (
    <>
      <Bar
        color={segmentColor}
        dropOffCount={0}
        feature={feature}
        isAnimated={isAnimated}
        step="segment"
        stepCount={steps.segment.count}
        tooltip={
          <AnnotatedTooltipBody
            count={steps.segment.count}
            states={["never", "tried", "retained", "churned"]}
          />
        }
        tooltipDropOff={
          <AnnotatedTooltipBody count={steps.tried.dropOff} states={[]} />
        }
        totalCount={steps.segment.count}
      />
      {feature.source === "event" && (
        <Bar
          color={triedColor}
          dropOffCount={steps.tried.dropOff}
          feature={feature}
          isAnimated={isAnimated}
          isDisabled={steps.tried.isDisabled}
          showMetric={!steps.tried.isDisabled}
          step="tried"
          stepCount={steps.tried.count}
          tooltip={
            <AnnotatedTooltipBody
              count={steps.tried.count}
              states={["tried", "retained", "churned"]}
            />
          }
          tooltipDropOff={
            <AnnotatedTooltipBody
              count={steps.tried.dropOff}
              states={["never"]}
            />
          }
          totalCount={steps.segment.count}
        />
      )}
      <Bar
        color={adoptedColor}
        dropOffCount={steps.adopted.dropOff}
        feature={feature}
        isAnimated={isAnimated}
        step="adopted"
        stepCount={steps.adopted.count}
        tooltip={
          <AnnotatedTooltipBody
            count={steps.adopted.count}
            states={["retained", "churned"]}
          />
        }
        tooltipDropOff={
          <AnnotatedTooltipBody
            count={steps.adopted.dropOff}
            states={["tried"]}
          />
        }
        totalCount={steps.segment.count}
      />
      <Bar
        color={retainedColor}
        dropOffCount={steps.retained.dropOff}
        feature={feature}
        isAnimated={isAnimated}
        isDisabled={steps.retained.isDisabled}
        step="retained"
        stepCount={steps.retained.count}
        tooltip={
          <AnnotatedTooltipBody
            count={steps.retained.count}
            states={["retained"]}
          />
        }
        tooltipDropOff={
          <AnnotatedTooltipBody
            count={steps.retained.dropOff}
            states={["churned"]}
          />
        }
        totalCount={steps.segment.count}
      />
      <Bar
        color={satisfiedColor}
        dropOffCount={steps.retained.count * (1 - steps.satisfied.rate)} // extrapolated value based on retained
        feature={feature}
        isAnimated={isAnimated}
        isDisabled={steps.satisfied.isDisabled}
        showCount={false}
        showMetric={!steps.satisfied.isDisabled}
        step="satisfied"
        stepCount={steps.retained.count * steps.satisfied.rate} // extrapolated value based on retained
        tooltip={
          <AnnotatedTooltipBody
            count={steps.satisfied.count}
            footer="and are satisfied with the feature"
            states={["retained"]}
          />
        }
        tooltipDropOff={
          <AnnotatedTooltipBody
            count={steps.satisfied.dropOff}
            footer="and are not satisfied with the feature"
            states={["retained"]}
          />
        }
        totalCount={steps.segment.count}
      />
    </>
  );
}

type LabelsProps = {
  feature: FeatureDetail;
  relevantMetrics: RelevantMetrics[];
  steps: AggregatedData;
  useTargeting: boolean;
  segment?: SegmentDTOWithTotalCompanies;
  isLoading?: boolean;
};

function Labels({
  feature,
  relevantMetrics,
  steps,
  useTargeting,
  segment,
  isLoading = true,
}: LabelsProps) {
  const {
    triedDescription,
    adoptedDescription,
    retainedDescription,
    satisfiedDescription,
  } = useFeatureDescriptions(feature, relevantMetrics, steps);
  const warningColor = useColorModeValue("yellow.600", "orange.300");
  const relevantFeedback = steps.satisfied.count + steps.satisfied.dropOff;

  return (
    <>
      <VStack spacing={1}>
        <HStack>
          <Text fontSize="lg" fontWeight="medium">
            Segment
          </Text>
          <InfoIconTooltip
            text={
              <p>
                The segment audience is determined by the feature&apos;s
                targeting rules. Optionally, you can apply a segment to this
                audience, such as &quot;Active&quot;.
              </p>
            }
          />
        </HStack>
        <StarsMetricDescription isLoading={isLoading}>
          {useTargeting && "Targeted"}
          {useTargeting && segment && " and "}
          {segment && <SegmentDisplay ml={1} segment={segment} />}
        </StarsMetricDescription>
      </VStack>
      {feature.source === "event" && (
        <VStack spacing={1}>
          <HStack>
            <Text fontSize="lg" fontWeight="medium">
              Tried
            </Text>
            <InfoIconTooltip
              text={
                <p>
                  Companies that have interacted with the feature at least once
                </p>
              }
            />
          </HStack>
          <StarsMetricDescription isLoading={isLoading}>
            {triedDescription}
          </StarsMetricDescription>
        </VStack>
      )}
      <VStack spacing={1}>
        <HStack>
          <Text fontSize="lg" fontWeight="medium">
            Adopted
          </Text>
          <InfoIconTooltip
            text={
              feature.source === "event" ? (
                <VStack spacing={1}>
                  <p>
                    {feature.adoptionEvaluationStrategy === "eventCount" && (
                      <>
                        Companies that have interacted with the feature{" "}
                        {feature.adoptionStrategyEventCountMinEventCount}+ times
                        over {feature.adoptionWindowSizeInDays / 7}{" "}
                        {pluralize(
                          "week",
                          feature.adoptionWindowSizeInDays / 7,
                        )}
                        .
                      </>
                    )}
                    {feature.adoptionEvaluationStrategy === "frequency" && (
                      <>
                        Companies that have interacted with the feature on{" "}
                        {feature.adoptionStrategyFrequencyMinDaysCount}+
                        separate days over{" "}
                        {feature.adoptionWindowSizeInDays / 7}{" "}
                        {pluralize(
                          "week",
                          feature.adoptionWindowSizeInDays / 7,
                        )}
                        .
                      </>
                    )}
                  </p>
                  <p>
                    Note: Churned companies from Retained will fall back to
                    Adopted.
                  </p>
                </VStack>
              ) : (
                <p>
                  Companies that have met the tracking criteria at some point.
                </p>
              )
            }
          />
        </HStack>
        {(feature.source !== "event" ||
          feature.adoptionEvaluationStrategy !== "legacy") && (
          <StarsMetricDescription isLoading={isLoading}>
            {adoptedDescription}
          </StarsMetricDescription>
        )}
      </VStack>
      <VStack spacing={1}>
        <HStack>
          <Text fontSize="lg" fontWeight="medium">
            Retained
          </Text>
          <InfoIconTooltip
            text={
              feature.source === "event" ? (
                <p>
                  {feature.adoptionEvaluationStrategy === "eventCount" && (
                    <>
                      Companies that have interacted with the feature{" "}
                      {feature.adoptionStrategyEventCountMinEventCount}+ times
                      over the last {feature.adoptionWindowSizeInDays / 7}{" "}
                      {pluralize("week", feature.adoptionWindowSizeInDays / 7)}.
                    </>
                  )}
                  {feature.adoptionEvaluationStrategy === "frequency" && (
                    <>
                      Companies that have interacted with the feature on{" "}
                      {feature.adoptionStrategyFrequencyMinDaysCount}+ separate
                      days over the last {feature.adoptionWindowSizeInDays / 7}{" "}
                      {pluralize("week", feature.adoptionWindowSizeInDays / 7)}.
                    </>
                  )}
                </p>
              ) : (
                <p>Companies that currently meet the tracking criteria.</p>
              )
            }
          />
        </HStack>
        {(feature.source !== "event" ||
          feature.adoptionEvaluationStrategy !== "legacy") && (
          <StarsMetricDescription isLoading={isLoading}>
            {retainedDescription}
          </StarsMetricDescription>
        )}
      </VStack>
      <VStack spacing={1}>
        <HStack>
          <Text fontSize="lg" fontWeight="medium">
            Satisfied
          </Text>
          <InfoIconTooltip
            text={
              feature.source === "event" ? (
                <VStack spacing={1}>
                  <p>
                    Retained companies which provided a positive satisfaction
                    score.
                  </p>
                  <p>
                    Note: Only the latest satisfaction score per company is
                    included.
                  </p>
                </VStack>
              ) : (
                <VStack spacing={1}>
                  <p>
                    Companies that currently meet the tracking criteria and have
                    provided a positive satisfaction score.
                  </p>
                  <p>
                    Note: Only the latest satisfaction score per company is
                    included.
                  </p>
                </VStack>
              )
            }
          />
        </HStack>
        <StarsMetricDescription
          color={
            relevantFeedback > 0 && relevantFeedback < 5
              ? warningColor
              : undefined
          }
          isLoading={isLoading}
        >
          {satisfiedDescription}
        </StarsMetricDescription>
      </VStack>
    </>
  );
}

type Props = {
  feature?: FeatureDetail;
  data?: FeatureMetricsCurrent;
  segment?: SegmentDTOWithTotalCompanies;
  isLoading: boolean;
  useTargeting: boolean;
  height: number;
  isInteractive?: boolean;
  isAnimated?: boolean;
};

export function StarsFunnel({
  feature,
  data,
  segment,
  isLoading,
  useTargeting,
  height,
  isInteractive = true,
  isAnimated = true,
}: Props) {
  const relevantMetrics = useMemo(
    () => relevantMetricsForFeature(feature),
    [feature],
  );

  const steps = useMemo<AggregatedData | undefined>(() => {
    if (!data || !feature) return undefined;

    const { segment, tried, adopted, retained, satisfied, unsatisfied } =
      data.metrics;
    const { adoptionRate, retentionRate, satisfactionRate } = data.rates;

    return {
      segment: {
        count: segment,
        dropOff: 0,
        rate: 1,
      },
      tried: {
        count: tried,
        dropOff: segment - tried,
        rate: getFraction(tried, segment),
        isDisabled: !relevantMetrics.includes("tried"),
      },
      adopted: {
        count: adopted,
        dropOff: tried - adopted,
        rate: adoptionRate,
      },
      retained: {
        count: retained,
        dropOff: adopted - retained,
        rate: retentionRate,
        isDisabled: !relevantMetrics.includes("retained"),
      },
      satisfied: {
        count: satisfied,
        dropOff: unsatisfied,
        rate: satisfactionRate,
        isDisabled: !satisfied && !unsatisfied,
      },
    };
  }, [data, feature, relevantMetrics]);

  if (!steps || !feature) return <Box height={`${height}px`}></Box>;

  return (
    <SimpleGrid
      columns={feature?.source === "event" ? 5 : 4}
      gridTemplateRows={`${
        isInteractive ? "min-content" : ""
      } auto min-content`}
      height={`${height}px`}
      mx={2}
      spacing={4}
    >
      {isInteractive && (
        <HeaderRates feature={feature} isAnimated={isAnimated} steps={steps} />
      )}
      <Bars feature={feature} isAnimated={isAnimated} steps={steps} />
      <Labels
        feature={feature}
        isLoading={isLoading}
        relevantMetrics={relevantMetrics}
        segment={segment}
        steps={steps}
        useTargeting={useTargeting}
      />
    </SimpleGrid>
  );
}
