import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { HStack, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  WidgetSTARSFunnelConfiguration,
  WidgetSTARSFunnelConfigurationSchema,
} from "@bucketco/shared/widgetAPI";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { useSubsegmentParam } from "@/common/hooks/useParam";
import { useSegment } from "@/company/data/useSegments";
import { FeatureStarsFunnelIcon } from "@/feature/components/FeatureWidgetCreator";
import { StarsFunnel } from "@/feature/components/StarsFunnel";
import { useCurrentMetricsData } from "@/feature/data/useCurrentMetricsData";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useTargetingParam } from "@/feature/hooks/useTargetingParam";
import { BaseWidgetProps } from "@/widget/components/types";
import { WidgetLayout } from "@/widget/components/WidgetLayout";
import { WidgetForm } from "@/widget/components/WidgetStarsFunnel/WidgetForm";
import { useWidgetUpdateMutation } from "@/widget/data/useWidgetMutations";

type Props = BaseWidgetProps<WidgetSTARSFunnelConfiguration>;
const WIDGET_HEIGHT = 270;

export const WidgetStarsFunnel = ({
  widgetId,
  configuration,
  implicitConfiguration,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const updateMutation = useWidgetUpdateMutation(widgetId);

  const form = useForm<WidgetSTARSFunnelConfiguration>({
    resolver: zodResolver(WidgetSTARSFunnelConfigurationSchema),
    mode: "onChange",
    defaultValues: {
      ...implicitConfiguration,
      ...configuration,
    },
  });

  const values = form.watch();

  const currentConfiguration = useMemo(
    () => (isEditing ? values : configuration),
    [configuration, isEditing, values],
  );

  const [subsegment] = useSubsegmentParam(["Active"]);
  const [useTargeting] = useTargetingParam(true);
  const { data: feature } = useFeatureData(currentConfiguration.featureId);
  const { data: segment } = useSegment(subsegment[0]);
  const { data, isLoading, isFetching } = useCurrentMetricsData(
    currentConfiguration.featureId,
    {
      subsegment: subsegment[0],
      useTargetingRules: useTargeting,
    },
  );

  return (
    <WidgetLayout
      canEdit={!implicitConfiguration?.featureId}
      form={
        <WidgetForm
          configuration={currentConfiguration}
          form={form}
          implicitConfiguration={implicitConfiguration}
          widgetMutation={updateMutation}
          onDone={(configuration) => {
            form.reset(configuration);
            setIsEditing(false);
          }}
        />
      }
      icon={<FeatureStarsFunnelIcon boxSize={4} color="dimmedDisabled" />}
      isEditing={isEditing}
      isLoading={isFetching}
      setIsEditing={(newIsEditing) => {
        if (!newIsEditing) form.reset(configuration);
        setIsEditing(newIsEditing);
      }}
      title="STARS funnel"
      toolbar={
        <HStack spacing={1}>
          <Text color="dimmed" fontSize="xs">
            Showing all time
          </Text>
          <InfoIconTooltip text="This widget is not affected by the chosen time period." />
        </HStack>
      }
      widgetId={widgetId}
    >
      <StarsFunnel
        data={data}
        feature={feature}
        height={WIDGET_HEIGHT}
        isLoading={isLoading}
        segment={segment}
        useTargeting={useTargeting}
      />
    </WidgetLayout>
  );
};
