import { useMemo } from "react";
import {
  Box,
  BoxProps,
  Flex,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";

import dayjs from "@/common/utils/dayjs";
import pluralize from "@/common/utils/pluralize";

type Activity = {
  day: string;
  eventCount: number;
};

type GroupedActivity<T extends Activity> = T & {
  end?: string;
  span: number;
};

type Props<T extends Activity> = BoxProps & {
  data: T[];
  height?: number;
  tickWidth?: number;
  tickGap?: number;
  showAmplitude?: boolean;
  showEventCount?: boolean;
  mergeActive?: boolean;
  mergeInactive?: boolean;
};

export function RecentActivity<T extends Activity>({
  data,
  height = 4,
  tickWidth = 4,
  tickGap = 2,
  showAmplitude = true,
  showEventCount = true,
  mergeActive = false,
  mergeInactive = false,
  ...rest
}: Props<T>) {
  const activeColor = useColorModeValue("brand.500", "brand.400");
  const inactiveColor = useColorModeValue("gray.200", "gray.700");

  const max = data.reduce(
    (acc, { eventCount }) => Math.max(acc, eventCount),
    0,
  );

  const groups = useMemo(
    () =>
      data.reduce<GroupedActivity<T>[]>((acc, point) => {
        const last = acc[acc.length - 1];
        if (
          mergeInactive &&
          last?.eventCount === 0 &&
          point?.eventCount === 0
        ) {
          last.end = point.day;
          last.span += 1;
          return acc;
        }
        if (mergeActive && last?.eventCount > 0 && point?.eventCount > 0) {
          last.eventCount += point.eventCount;
          last.end = point.day;
          last.span += 1;
          return acc;
        }
        acc.push({ ...point, span: 1 });
        return acc;
      }, []),
    [data, mergeActive, mergeInactive],
  );

  return (
    <Flex
      align="center"
      gap={"2px"}
      height={height}
      width={"min-content"}
      {...rest}
    >
      {groups.map(({ day, end, eventCount, span }) =>
        eventCount > 0 ? (
          <Tooltip
            key={day}
            label={
              <>
                <Text>
                  {dayjs(day).format("LL")}
                  {end ? ` - ${dayjs(end).format("LL")}` : ""}
                </Text>
                {showEventCount && (
                  <Text>
                    {eventCount} {pluralize("event", eventCount)}
                  </Text>
                )}
              </>
            }
            placement="top"
            hasArrow
          >
            <Flex
              align="center"
              height={height}
              width={`${tickWidth * span + tickGap * (span - 1)}px`}
            >
              <Box
                bg={activeColor}
                borderRadius={1}
                height={`${Math.floor(
                  25 + (showAmplitude ? 75 * (eventCount / max) : 0),
                )}%`}
                width="100%"
              />
            </Flex>
          </Tooltip>
        ) : (
          <Box
            key={day}
            bg={inactiveColor}
            borderRadius="1.5px"
            height="25%"
            width={`${tickWidth * span + tickGap * (span - 1)}px`}
          />
        ),
      )}
    </Flex>
  );
}
