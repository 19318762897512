import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  RiDatabase2Line,
  RiGroup2Line,
  RiHotelLine,
  RiLineChartLine,
  RiPieChartLine,
  RiSettingsLine,
  RiSlackLine,
} from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Heading,
  HeadingProps,
  HStack,
  Tab as ChakraTab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { GlobalSettingsSections } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AutoFeedbackSurveysIcon from "@/common/assets/auto-feedback-surveys-icon.svg?react";
import EnvironmentsIcon from "@/common/assets/environments-dots-circles-line.svg?react";
import FeatureViewsIcon from "@/common/assets/feature-views-icon.svg?react";
import ErrorBoundary from "@/common/components/ErrorBoundary";
import HeaderLayout from "@/common/components/HeaderLayout";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import Environments from "@/global-settings/components/Environments";
import FeatureViews from "@/global-settings/components/FeatureViews";
import FeedbackPrompting from "@/global-settings/components/FeedbackPrompting";
import GeneralApp from "@/global-settings/components/GeneralApp";
import GeneralOrganization from "@/global-settings/components/GeneralOrganization";
import OrganizationBilling from "@/global-settings/components/OrgBilling";
import Segments from "@/global-settings/components/Segments";
import SlackEnvIntegration from "@/global-settings/components/SlackEnvIntegration";
import SlackOrgIntegration from "@/global-settings/components/SlackOrgIntegration";
import Stages from "@/global-settings/components/Stages";
import Team from "@/global-settings/components/Team";
import { StagesLineIcon } from "@/stage/components/StagesIcon";
import AppDataExport from "../components/AppDataExport";

function TabSectionHeading({
  children,
  ...props
}: { children: React.ReactNode } & HeadingProps) {
  return (
    <Heading
      as="h3"
      fontWeight="medium"
      mb={3}
      mt={2}
      pl={3}
      size="xs"
      {...props}
    >
      {children}
    </Heading>
  );
}

function Tab({
  children,
  to,
  ...props
}: { children: React.ReactNode; to: string } & TabProps) {
  return (
    <ChakraTab as={Link} to={to} {...props}>
      {children}
    </ChakraTab>
  );
}

type Route = {
  to: GlobalSettingsSections;
  name: string;
  icon: JSX.Element;
  panel: JSX.Element;
  meta?: Record<string, any>;
};

const orgRoutes: Route[] = [
  {
    name: "General",
    to: "org-general",
    icon: <RiHotelLine size={18} />,
    panel: <GeneralOrganization />,
  },
  {
    name: "Team",
    to: "org-team",
    icon: <RiGroup2Line size={18} />,
    panel: <Team />,
  },
  {
    name: "Slack",
    to: "org-slack",
    icon: <RiSlackLine size={18} />,
    panel: <SlackOrgIntegration />,
  },
  {
    name: "Billing",
    to: "org-billing",
    icon: <RiLineChartLine size={18} />,
    panel: <OrganizationBilling />,
  },
];

const appRoutes: Route[] = [
  {
    name: "General",
    to: "app-general",
    icon: <RiSettingsLine size={18} />,
    panel: <GeneralApp />,
  },
  {
    name: "Feature views",
    to: "app-feature-views",
    icon: <FeatureViewsIcon height="18px" width="18px" />,
    panel: <FeatureViews />,
  },
  {
    name: "Company segments",
    to: "app-segments",
    icon: <RiPieChartLine size={18} />,
    panel: <Segments />,
  },
  {
    name: "Environments",
    to: "app-environments",
    icon: <EnvironmentsIcon height="18px" width="18px" />,
    panel: <Environments />,
  },
];

const environmentRoutes: Route[] = [
  {
    name: "Feedback",
    to: "app-feedback",
    icon: <AutoFeedbackSurveysIcon height={18} width={18} />,
    panel: <FeedbackPrompting />,
  },
  {
    name: "Data export",
    to: "app-data-export",
    icon: <RiDatabase2Line size={18} />,
    panel: <AppDataExport />,
  },
  {
    name: "Slack",
    to: "app-slack",
    icon: <RiSlackLine size={18} />,
    panel: <SlackEnvIntegration />,
    meta: { onlyProduction: true },
  },
];

export default function GlobalSettings() {
  const navigate = useNavigate();
  const { isEnabled: isStagesEnabled } = useFeature("stages");
  useEffect(() => {
    segmentAnalytics.track("Global Settings Opened");
  }, []);

  const { currentApp, currentEnv } = useAuthContext();
  const { section = "org-general" } = useParams() as {
    section: GlobalSettingsSections;
  };

  const flaggedAppRoutes: Route[] = [...appRoutes];
  if (isStagesEnabled) {
    flaggedAppRoutes.push({
      name: "Release stages",
      to: "app-stages",
      icon: <StagesLineIcon boxSize="18px" />,
      panel: <Stages />,
    });
  }

  const allRoutes = [...orgRoutes, ...flaggedAppRoutes, ...environmentRoutes];
  const sections = allRoutes.map((r) => r.to);

  const currentSectionIndex = sections.indexOf(section);
  const currentRoute = allRoutes.find((r) => r.to === section);

  let sectionGroup = "Organization";
  if (currentRoute && flaggedAppRoutes.includes(currentRoute)) {
    sectionGroup = "App";
  } else if (currentRoute && environmentRoutes.includes(currentRoute)) {
    sectionGroup = "Environment";
    if (currentRoute.meta?.onlyProduction && !currentEnv?.isProduction) {
      navigate(`../app-feedback`);
    }
  }

  return (
    <HeaderLayout title="Settings">
      <Helmet>
        <title>
          Settings › {sectionGroup} › {currentRoute?.name ?? ""}
        </title>
      </Helmet>
      {/*
        This extra wrapper is needed to have Safari not be confused about the height.
        If you put the `flexGrow` on the `Tabs` container, Safari will size Tabs grid-children
        taller than the Tabs grid-container, causing higher than viewport content.
      */}
      <Box display="flex" flexGrow={1}>
        <Tabs
          index={currentSectionIndex}
          orientation="vertical"
          variant="sideMenu"
          w="100%"
          isLazy
          isManual
        >
          <TabList>
            <TabSectionHeading>Organization</TabSectionHeading>
            {orgRoutes.map((route) => (
              <Tab key={route.to} to={`../${route.to}`}>
                <HStack>
                  {route.icon}
                  <Text>{route.name}</Text>
                </HStack>
              </Tab>
            ))}
            <Divider my={2} />
            <TabSectionHeading mt={4}>
              App: {currentApp?.name}
            </TabSectionHeading>
            {flaggedAppRoutes.map((route) => (
              <Tab key={route.to} to={`../${route.to}`}>
                <HStack>
                  {route.icon}
                  <Text>{route.name}</Text>
                </HStack>
              </Tab>
            ))}
            <Divider my={2} />
            <TabSectionHeading mt={4}>
              Environment: {currentEnv?.name}
            </TabSectionHeading>
            {environmentRoutes.map((route) => (
              <Tab
                key={route.to}
                isDisabled={
                  route.meta?.onlyProduction && !currentEnv?.isProduction
                }
                to={`../${route.to}`}
              >
                <HStack>
                  {route.icon}
                  <Text>{route.name}</Text>
                </HStack>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {allRoutes.map((route) => (
              <TabPanel key={route.to}>
                <ErrorBoundary>{route.panel}</ErrorBoundary>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </HeaderLayout>
  );
}
