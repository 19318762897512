import { forwardRef, ReactElement, useMemo } from "react";
import { RiArrowDownSLine, RiCalendarLine, RiPulseFill } from "react-icons/ri";
import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useFormControl,
} from "@chakra-ui/react";

import { FeatureMetric } from "@bucketco/shared/filter";

import SatisfactionIcon from "@/common/assets/satisfaction.svg?react";
import STARSIcon from "@/common/assets/stars-icon.svg?react";
import { FeatureSourceIcon } from "@/feature/components/FeatureSourceIcon";

export const featureMetricOptions: readonly {
  metric: FeatureMetric;
  label: string;
  icon: ReactElement;
}[] = [
  {
    metric: "funnelStep",
    label: "STARS",
    icon: <STARSIcon height={14} width={14} />,
  },
  {
    metric: "frequency",
    label: "Frequency",
    icon: <RiPulseFill size={14} />,
  },
  {
    metric: "satisfaction",
    label: "Satisfaction",
    icon: <SatisfactionIcon height={14} width={14} />,
  },
  {
    metric: "eventCount",
    label: "Event count",
    icon: <FeatureSourceIcon size={14} source="event" />,
  },
  {
    metric: "firstUsed",
    label: "First used",
    icon: <RiCalendarLine size={14} />,
  },
  {
    metric: "lastUsed",
    label: "Last used",
    icon: <RiCalendarLine size={14} />,
  },
] as const;

type RuleMetricProps = Omit<ButtonProps, "value" | "onChange"> & {
  value: FeatureMetric | undefined;
  onChange: (nextValue: FeatureMetric) => void;
  availableMetrics?: readonly FeatureMetric[] | FeatureMetric[];
  placeholder?: string;
  emptyPlaceholder?: string;
};

const RuleMetric = forwardRef<HTMLButtonElement, RuleMetricProps>(
  (
    {
      value,
      onChange,
      availableMetrics,
      placeholder = "Select metric",
      emptyPlaceholder = "No metrics available",
      ...rest
    },
    ref,
  ) => {
    const formControlProps = useFormControl<HTMLButtonElement>(rest);
    const activeColor = useColorModeValue("brand.500", "brand.300");

    const selectedOption = useMemo(() => {
      return featureMetricOptions.find(({ metric }) => metric === value);
    }, [value]);

    const options = useMemo(() => {
      if (!availableMetrics) return featureMetricOptions;
      return featureMetricOptions.filter(({ metric }) =>
        availableMetrics.includes(metric),
      );
    }, [availableMetrics]);

    return (
      <Menu size="xs">
        <MenuButton
          ref={ref}
          as={Button}
          background="appBackground"
          data-testid="rule-metric"
          isDisabled={formControlProps.disabled || !options.length}
          px={3}
          rightIcon={
            <Box fontSize="xl" mr={-2}>
              <RiArrowDownSLine />
            </Box>
          }
          variant="input"
          {...formControlProps}
        >
          {selectedOption ? (
            <HStack spacing={2}>
              <Box color="dimmed" fontSize="14px">
                {selectedOption.icon}
              </Box>
              <Text display="block" noOfLines={1}>
                {selectedOption.label}
              </Text>
            </HStack>
          ) : options.length ? (
            <Text color="dimmed">{placeholder}</Text>
          ) : (
            <Text color="dimmed">{emptyPlaceholder}</Text>
          )}
        </MenuButton>
        <MenuList data-testid="rule-metric-list" maxH="sm" overflowY="auto">
          {options.map(({ metric, label, icon }) => (
            <MenuItem
              key={metric}
              color={value === metric ? activeColor : undefined}
              icon={icon}
              onClick={() => onChange(metric)}
            >
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  },
);

RuleMetric.displayName = "RuleMetric";

export default RuleMetric;
