import { ReactNode } from "react";
import { ReactNodeArray } from "prop-types";

import { FeatureName } from "@bucketco/shared/featureAPI";

export enum DragType {
  FeatureDisplay = "DRAG.FEATURE_DISPLAY",
  MenuReorderOption = "DRAG.MENU_REORDER_OPTION",
}

export type DragTypeItemMap = {
  [DragType.FeatureDisplay]: FeatureName;
  [DragType.MenuReorderOption]: {
    id: string;
    index: number;
    children: ReactNode | ReactNodeArray;
    isChecked: boolean;
  };
};
