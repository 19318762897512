import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { RunAppScenario, ScenarioOptions } from "@bucketco/shared/adminAPI";
import { ErrorResponse } from "@bucketco/shared/api";

import api from "@/common/utils/api";

export function useAdminRunAppScenarioMutation<
  TName extends RunAppScenario,
  TVariables extends ScenarioOptions[TName],
>(orgId: string, appId: string, scenarioId: TName, variables: TVariables) {
  return useMutation<{ success: boolean }, AxiosResponse<ErrorResponse>>({
    mutationKey: [
      "admin",
      "org",
      orgId,
      "app",
      appId,
      "scenario",
      scenarioId,
      variables,
    ],
    mutationFn: () =>
      api
        .post<"/admin/:orgId/apps/:appId/scenarios">(
          `/admin/${orgId}/apps/${appId}/scenarios`,
          variables,
          { params: { scenario: scenarioId } },
        )
        .then((res) => res.data),
  });
}
