import uniqBy from "lodash/uniqBy";
import { z } from "zod";

import {
  AttributeField,
  AttributeFilter as OldAttributeFilter,
  AttributeFilterOperator,
  attributeFilterOperatorsList,
  AttributeFilterType,
  FIRST_SEEN_FIELD_KEY,
  isNumeric,
  LAST_SEEN_FIELD_KEY,
  listOperators,
  numberOperators,
  operatorsWithoutValue,
  relevantOperators,
} from "./attributeFilter";
import { FunnelStep, FunnelStepList } from "./featureAPI";

export type AdjustAttributeFieldsCallback = (
  filterType: UIFilterType,
  fields: AttributeField[],
) => AttributeField[];

export const COMPANY_ID_CONTEXT_FIELD = "company.id";
export const MAX_ROLLOUT_THRESHOLD = 100000;

export const featureMetrics = [
  "funnelStep",
  "frequency",
  "satisfaction",
  "eventCount",
  "firstUsed",
  "lastUsed",
] as const;
export const FeatureMetricSchema = z.enum(featureMetrics);
export type FeatureMetric = z.infer<typeof FeatureMetricSchema>;

export const featureMetricOperators: Record<
  FeatureMetric,
  readonly AttributeFilterOperator[]
> = {
  funnelStep: ["IS", "IS_NOT", "ANY_OF", "NOT_ANY_OF", "GT", "LT"],
  frequency: ["IS", "IS_NOT", "GT", "LT", "ANY_OF", "NOT_ANY_OF"],
  satisfaction: ["IS", "IS_NOT", "GT", "LT", "ANY_OF", "NOT_ANY_OF"],
  eventCount: ["IS", "IS_NOT", "GT", "LT"],
  firstUsed: ["AFTER", "BEFORE"],
  lastUsed: ["AFTER", "BEFORE"],
} as const;

export const featureMetricOperatorRefinement = (
  {
    metric,
    operator,
  }: {
    metric?: FeatureMetric | undefined;
    operator?: AttributeFilterOperator | undefined;
  },
  ctx: z.RefinementCtx,
) => {
  if (
    metric &&
    operator &&
    !featureMetricOperators[metric].includes(operator)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `The operator is not supported`,
      path: ["operator"],
    });
  }
};

export const operatorRefinement = (
  {
    type,
    operator,
    values,
  }: {
    type: UIFilterType;
    operator?: AttributeFilterOperator | "and" | "or" | undefined;
    values?: string[] | undefined;
  },
  ctx: z.RefinementCtx,
) => {
  // Ignore group operators
  if (operator === "and" || operator === "or") {
    return;
  }

  if (type === "segment" || type === "featureTargeting") {
    return;
  }

  if (operator && listOperators.includes(operator) && !values?.length) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "The list must not be empty",
      path: ["values"],
    });
  }

  const value = values?.[0];

  if (operator && numberOperators.includes(operator) && !isNumeric(value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "The value must be a number",
      path: ["values", 0],
    });
  }

  if (
    operator &&
    (value === "" || typeof value === "undefined") &&
    !operatorsWithoutValue.includes(operator)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "A value must be set",
      path: ["values", 0],
    });
  }
};

/** Internal use before superRefine */
export const BaseCompanyAttributeFilterSchema = z.object({
  type: z.literal("companyAttribute"),
  field: z
    .string({ required_error: "An attribute must be selected" })
    .min(1, "An attribute must be selected"),
  operator: z.enum(attributeFilterOperatorsList),
  values: z.array(z.string()).optional(),
});

export type CompanyAttributeFilter = z.infer<
  typeof BaseCompanyAttributeFilterSchema
>;

/** Internal use before superRefine */
export const BaseCompanyFeatureMetricFilterSchema = z.object({
  type: z.literal("featureMetric"),
  featureId: z
    .string({ required_error: "A feature must be selected" })
    .min(1, "A feature must be selected"),
  metric: FeatureMetricSchema,
  operator: z.enum(attributeFilterOperatorsList),
  values: z.array(z.string()),
});

export type CompanyFeatureMetricFilter = z.infer<
  typeof BaseCompanyFeatureMetricFilterSchema
>;

// Special case as infer is not allowed for recursive schemas
export type FilterGroup<T extends FilterClass> = {
  type: "group";
  operator: "and" | "or";
  filters?: FilterTree<T>[];
};

export const FilterGroupSchema = z.object({
  type: z.literal("group"),
  filters: z.lazy(() => z.array(UIFilterSchema)).optional(),
  operator: z.enum(["and", "or"]),
});

export const FeatureTargetingFilterSchema = z.object({
  type: z.literal("featureTargeting"),
  operator: z.enum(["FLAG", "NOT_FLAG"]),
  featureId: z.string().min(1),
});

export type FeatureTargetingFilter = z.infer<
  typeof FeatureTargetingFilterSchema
>;

export const SegmentFilterSchema = z.object({
  type: z.literal("segment"),
  operator: z.enum(["SEGMENT", "NOT_SEGMENT"]),
  segmentId: z.string().min(1),
});

export type SegmentFilter = z.infer<typeof SegmentFilterSchema>;

export const FeatureFlagRolloutFilterSchema = z.object({
  type: z.literal("featureFlagRolloutPercentage"),
  flagKey: z.string(),
  percentage: z.number(),
});

export type FeatureFlagRolloutPercentageFilter = z.infer<
  typeof FeatureFlagRolloutFilterSchema
>;

export const OtherContextFilterSchema = BaseCompanyAttributeFilterSchema.merge(
  z.object({
    type: z.literal("otherContext"),
  }),
);

export type OtherContextFilter = z.infer<typeof OtherContextFilterSchema>;

export const UserAttributeFilterSchema = BaseCompanyAttributeFilterSchema.merge(
  z.object({
    type: z.literal("userAttribute"),
  }),
);

export type UserAttributeFilter = z.infer<typeof UserAttributeFilterSchema>;

export type FilterClass =
  | SegmentFilter
  | CompanyAttributeFilter
  | CompanyFeatureMetricFilter
  | FilterGroup<any>
  | FilterNegation<any>
  | FilterConstant
  | FeatureFlagRolloutPercentageFilter
  | OtherContextFilter
  | UserAttributeFilter
  | FeatureTargetingFilter;

export type FilterNegation<T extends FilterClass> = {
  type: "negation";
  filter: FilterTree<T>;
};

export type FilterConstant = {
  type: "constant";
  value: boolean;
};

export type FilterTree<T extends FilterClass> =
  | FilterGroup<T>
  | FilterNegation<T>
  | T;

// These are recognized by the UI
export type SingleUIFilter =
  | CompanyAttributeFilter
  | UserAttributeFilter
  | OtherContextFilter
  | CompanyFeatureMetricFilter
  | SegmentFilter
  | FeatureTargetingFilter;

export type UIFilter = FilterTree<SingleUIFilter>;

// These are recognized by realtime
export type SingleRealtimeFilter =
  | CompanyAttributeFilter
  | CompanyFeatureMetricFilter
  | FeatureFlagRolloutPercentageFilter
  | FilterConstant;

export type RealtimeFilter = FilterTree<SingleRealtimeFilter>;

export type UIFilterType = UIFilter["type"];

// Other types

export const UIFilterSchema: z.ZodType<UIFilter> = z
  .discriminatedUnion("type", [
    BaseCompanyAttributeFilterSchema,
    BaseCompanyFeatureMetricFilterSchema,
    SegmentFilterSchema,
    OtherContextFilterSchema,
    UserAttributeFilterSchema,
    FilterGroupSchema,
    FeatureTargetingFilterSchema,
  ])
  .superRefine(featureMetricOperatorRefinement as any)
  .superRefine(operatorRefinement);

export const SingleUIFilterSchema: z.ZodType<SingleUIFilter> = z
  .discriminatedUnion("type", [
    BaseCompanyAttributeFilterSchema,
    BaseCompanyFeatureMetricFilterSchema,
    UserAttributeFilterSchema,
    OtherContextFilterSchema,
    SegmentFilterSchema,
    FeatureTargetingFilterSchema,
  ])
  .superRefine(featureMetricOperatorRefinement as any)
  .superRefine(operatorRefinement);

export const emptyFilter: FilterTree<never> = {
  type: "group" as const,
  operator: "and" as const,
  filters: [],
};

export const customerSegmentFilter = {
  type: "group" as const,
  operator: "and" as const,
  filters: [
    {
      type: "companyAttribute" as const,
      field: "customer",
      operator: "IS_TRUE" as const,
      values: [""],
    },
    {
      type: "companyAttribute" as const,
      field: "$last_seen",
      operator: "AFTER" as const,
      values: ["30"],
    },
  ],
};

export const activeSegmentFilter = {
  type: "group" as const,
  operator: "and" as const,
  filters: [
    {
      type: "companyAttribute",
      field: "$last_seen",
      operator: "AFTER",
      values: ["30"],
    },
  ],
};

export function createCompanyFilterFromOldAttributeFilter(
  oldAttributeFilter: OldAttributeFilter,
): FilterGroup<CompanyAttributeFilter> {
  return {
    type: "group" as const,
    operator: "and" as const,
    filters: oldAttributeFilter.map((filter) => ({
      type: "companyAttribute" as const,
      field: filter.field,
      operator: filter.operator,
      values: filter.values,
    })),
  };
}

export function getFilters<T extends UIFilterType>(
  filter: UIFilter | null | undefined,
  types: T[],
): Extract<UIFilter, { type: T }>[] {
  if (!filter) return [];
  if (filter.type === "group" && filter.filters?.length) {
    return filter.filters.flatMap((f) => getFilters(f, types));
  }
  return (types.includes(filter.type as T) ? [filter] : []) as Extract<
    UIFilter,
    { type: T }
  >[];
}

export function getFilterCount(filter: UIFilter): number {
  if (filter.type === "group") {
    return filter.filters?.reduce((acc, f) => acc + getFilterCount(f), 0) ?? 0;
  }
  return 1;
}

export function containsFeatureMetricFilter(companyFilter: UIFilter): boolean {
  return getFilters(companyFilter, ["featureMetric"]).length > 0;
}

export function operatorsByFilterType(type: UIFilterType): {
  [key in AttributeFilterType]: AttributeFilterOperator[];
} {
  switch (type) {
    case "companyAttribute":
    case "userAttribute":
    case "otherContext":
      return relevantOperators([
        "any",
        "date",
        "text",
        "number",
        "list",
        "boolean",
      ]);
    case "featureMetric":
      return relevantOperators([
        "any",
        "date",
        "text",
        "number",
        "list",
        "boolean",
      ]);
    case "segment":
      return relevantOperators(["segment"]);
    case "featureTargeting":
      return relevantOperators(["featureTargeting"]);
    default:
      return relevantOperators([]);
  }
}

export function getNewOperatorOnTypeChange(
  newType: UIFilterType,
  oldOperator: AttributeFilterOperator,
): AttributeFilterOperator {
  const allowedOperators = operatorsByFilterType(newType);
  const flatOperators = Object.values(allowedOperators).flat();

  return flatOperators.includes(oldOperator) ? oldOperator : flatOperators[0];
}

export function wrapWithFilterGroup<T extends FilterClass>(
  filter: T | T[] | null | undefined,
): FilterGroup<T> {
  if (!filter) {
    return emptyFilter as FilterGroup<T>;
  } else if (Array.isArray(filter)) {
    return {
      type: "group",
      operator: "and",
      filters: filter,
    };
  } else if (filter.type === "group") {
    return filter;
  } else {
    return {
      type: "group",
      operator: "and",
      filters: [filter],
    };
  }
}

export function group<T extends FilterClass>(
  type: "and" | "or",
  ...filters: (FilterTree<T> | null | undefined)[]
): FilterGroup<T> {
  if (!filters.length) {
    return emptyFilter as FilterGroup<T>;
  }

  const actualFilters = filters.filter((f) => f) as T[];

  if (
    actualFilters.length == 1 &&
    actualFilters[0].type === "group" &&
    actualFilters[0].operator === type
  ) {
    return actualFilters[0];
  }

  return {
    type: "group",
    operator: type,
    filters: actualFilters,
  };
}

export function reduce<T extends FilterClass>(
  filter: FilterTree<T> | FilterTree<T>[] | null | undefined,
): FilterTree<T> {
  // undefined and null are treated as empty filters
  if (!filter) {
    return emptyFilter;
  }

  // arrays are treated as AND groups
  if (Array.isArray(filter)) {
    return reduce<T>({ type: "group", operator: "and", filters: filter });
  }

  // negation is kept as is unless the reduction simplifies it to an empty filter
  // otherwise, we simplify the negated filter
  if (filter.type === "negation") {
    const simplified = reduce(filter.filter);
    return simplified.type === "group" && !simplified.filters?.length
      ? simplified
      : { ...filter, filter: simplified };
  }

  // groups are simplified by flattening them if possible
  if (filter.type === "group") {
    const reducedChildren = filter.filters?.map(reduce<T>) ?? [];

    if (reducedChildren.length === 0) {
      return emptyFilter;
    }
    if (reducedChildren.length === 1) {
      return reducedChildren[0];
    }

    const canFlatten = reducedChildren.every(
      (f) =>
        (f.type === "group" && f.operator === filter.operator) ||
        f.type !== "group",
    );

    if (canFlatten) {
      return {
        ...filter,
        filters: reducedChildren.flatMap((f) =>
          f.type === "group" ? f.filters! : [f],
        ),
      };
    }

    return { ...filter, filters: reducedChildren };
  }

  return filter;
}

export type Dependee = Omit<DependentEntity, "filter" | "name" | "key">;

export type DependentEntity = {
  id: string;
  name: string;
  filter: UIFilter;
  type: "segment" | "feature";
};

const cycleDepthLimit = 10;
export class CycleError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "CycleError";
  }
}

export function getDependentEntities(
  dependee: Dependee,
  dependencies: Record<string, DependentEntity>,
): DependentEntity[] {
  const result: DependentEntity[] = [];

  function unrollFilterRecursive(filter: UIFilter, path: DependentEntity[]) {
    if (path.length > cycleDepthLimit) {
      throw new CycleError("Cycle detected in filter dependencies.");
    }

    if (filter.type === "segment") {
      if (filter.segmentId === dependee.id) {
        result.push(...path);
      }

      const segment = dependencies[filter.segmentId];
      if (segment) {
        unrollFilterRecursive(segment.filter, [...path, segment]);
      }
    } else if (filter.type === "featureTargeting") {
      if (filter.featureId === dependee.id) {
        result.push(...path);
      }

      const featureTargeting = dependencies[filter.featureId];
      if (featureTargeting) {
        unrollFilterRecursive(featureTargeting.filter, [
          ...path,
          featureTargeting,
        ]);
      }
    } else if (filter.type === "featureMetric") {
      if (filter.featureId === dependee.id) {
        result.push(...path);
      }

      const feature = dependencies[filter.featureId];
      if (feature) {
        unrollFilterRecursive(feature.filter, [...path, feature]);
      }
    } else if (filter.type === "group" && filter.filters?.length) {
      filter.filters.flat().forEach((f) => unrollFilterRecursive(f, path));
    }
  }

  Object.values(dependencies)
    .flat()
    .forEach((dep) => unrollFilterRecursive(dep.filter, [dep]));

  return uniqBy(
    result.filter((dep) => dep.id != dependee.id),
    (d) => d.id,
  );
}

const nonSimpleCompanyAttributes = [FIRST_SEEN_FIELD_KEY, LAST_SEEN_FIELD_KEY];

type GenericFilter =
  | {
      type: Exclude<
        RealtimeFilter["type"] | UIFilter["type"],
        "group" | "companyAttribute" | "segment" | "featureTargeting"
      >;
    }
  | {
      type: "companyAttribute";
      field: string;
    }
  | {
      type: "segment";
      segmentId: string;
    }
  | {
      type: "featureTargeting";
      featureId: string;
    }
  | { type: "group"; filters?: GenericFilter[] };

export function isStatelessFilter(
  filter: GenericFilter,
  dependencies?: Record<string, DependentEntity>,
  depth = 0,
): boolean {
  if (!filter) {
    return true;
  }

  if (depth > cycleDepthLimit) {
    throw new CycleError("Cycle detected in filter.");
  }

  if (filter.type === "companyAttribute") {
    return !nonSimpleCompanyAttributes.includes(filter.field);
  } else if (filter.type === "segment") {
    const segment = dependencies?.[filter.segmentId];
    if (segment) {
      return isStatelessFilter(segment.filter, dependencies, depth + 1);
    }
  } else if (filter.type === "featureTargeting") {
    const featureTargeting = dependencies?.[filter.featureId];
    if (featureTargeting) {
      return isStatelessFilter(
        featureTargeting.filter,
        dependencies,
        depth + 1,
      );
    }
  } else if (filter.type === "group") {
    if (!filter.filters?.length) {
      return true;
    }

    return filter.filters.every((f) =>
      isStatelessFilter(f, dependencies, depth + 1),
    );
  }

  return filter.type !== "featureMetric";
}

const funnelStepToIndex = FunnelStepList.reduce(
  (acc, cur) => {
    acc[cur] = FunnelStepList.indexOf(cur);
    return acc;
  },
  {} as Record<FunnelStep, number>,
);

function mapFunnelStepsToValues(steps: FunnelStep[]) {
  return steps.map((s) => funnelStepToIndex[s].toString());
}

export function buildFeatureFunnelFilters(
  step: FunnelStep | "satisfied",
  featureId: string,
  useTargeting: boolean,
  segments?: string[],
) {
  let exclFilter: UIFilter;
  let inclFilter: UIFilter;

  const starsFilterBase = {
    type: "featureMetric" as const,
    featureId: featureId,
    operator: "ANY_OF" as const,
    metric: "funnelStep" as const,
  };

  switch (step) {
    case "company":
      return [emptyFilter, emptyFilter];
    case "segment":
      exclFilter = emptyFilter;
      inclFilter = {
        ...starsFilterBase,
        values: mapFunnelStepsToValues([
          "company",
          "segment",
          "tried",
          "adopted",
          "retained",
        ]),
      };

      break;
    case "tried":
      exclFilter = {
        ...starsFilterBase,
        values: mapFunnelStepsToValues(["company"]),
      };
      inclFilter = {
        ...starsFilterBase,
        values: mapFunnelStepsToValues([
          "segment",
          "tried",
          "adopted",
          "retained",
        ]),
      };

      break;
    case "adopted":
      exclFilter = {
        ...starsFilterBase,
        values: mapFunnelStepsToValues(["tried"]),
      };
      inclFilter = {
        ...starsFilterBase,
        values: mapFunnelStepsToValues(["adopted", "retained"]),
      };
      break;
    case "retained":
      exclFilter = {
        ...starsFilterBase,
        values: mapFunnelStepsToValues(["adopted"]),
      };
      inclFilter = {
        ...starsFilterBase,
        values: mapFunnelStepsToValues(["retained"]),
      };
      break;
    case "satisfied":
      exclFilter = {
        ...starsFilterBase,
        values: mapFunnelStepsToValues(["retained"]),
      };

      inclFilter = {
        type: "group",
        operator: "and",
        filters: [
          {
            ...starsFilterBase,
            values: mapFunnelStepsToValues(["retained"]),
          },
          {
            type: "featureMetric",
            featureId: featureId,
            operator: "GT",
            metric: "satisfaction",
            values: ["3"],
          },
        ],
      };

      break;
  }

  if (segments?.length) {
    const subsegmentFilters = segments.map(
      (s) =>
        ({
          type: "segment",
          operator: "SEGMENT",
          segmentId: s,
        }) satisfies UIFilter,
    );

    exclFilter = {
      type: "group",
      operator: "and",
      filters: [exclFilter, ...subsegmentFilters],
    };

    inclFilter = {
      type: "group",
      operator: "and",
      filters: [inclFilter, ...subsegmentFilters],
    };
  }

  if (useTargeting) {
    const targetingFilter = {
      type: "featureTargeting" as const,
      featureId: featureId,
      operator: "FLAG" as const,
    };

    exclFilter = {
      type: "group",
      operator: "and",
      filters: [exclFilter, targetingFilter],
    };

    inclFilter = {
      type: "group",
      operator: "and",
      filters: [inclFilter, targetingFilter],
    };
  }
  return [reduce(exclFilter), reduce(inclFilter)];
}
