import { z } from "zod";

import { UPLOADIO_ALLOWED_HOST } from "../constants";

export const uploadioUrlSchema = z.preprocess(
  (val) => {
    try {
      // parses the value as an URL to process this:
      // "https://upcdn.io/kW15ayo/../my-spoof-account/raw/foo/bar.png"
      // into this:
      // "https://upcdn.io/my-spoof-account/raw/foo/bar.png"
      return new URL(String(val)).href;
    } catch {
      return val;
    }
  },
  z
    .string()
    .startsWith(UPLOADIO_ALLOWED_HOST, "Invalid hostname used in the URL"),
);
