/* ================================= */
/*            Event Based            */
/* ================================= */

export const reactEventExample = (flagKey: string) => /*jsx*/ `
import { useFeature } from "@bucketco/react-sdk";

// component should be wrapped with the BucketProvider
const MyFeature = () => {
  const { isEnabled, track } = useFeature("${flagKey}");
  
  // check if feature is enabled, then track feature usage
  if (!isEnabled) return null;
  return <button onClick={() => track()}>Track usage!</button>;
}
`;

export const browserEventExample = (flagKey: string) => /*js*/ `
// import an initialized BucketClient
import { bucketClient } from "./bucketClient";

const { isEnabled, track } = bucketClient.getFeature("${flagKey}");

if (isEnabled) { // check if feature is enabled
  track(); // track feature usage
}

 `;

export const nodeEventExample = (flagKey: string) => /*js*/ `
// import an initialized BucketClient
import { bucketClient } from "./bucketClient";

const boundClient = bucketClient.bindClient({ user, company });
const { isEnabled, track } = boundClient.getFeature("${flagKey}");

if (isEnabled) { // check if feature is enabled
  track(); // track feature usage
}

`;

/* ================================= */
/*          Attribute Based          */
/* ================================= */

export const reactAttributeExample = (flagKey: string) => /*tsx*/ `
import { BucketProvider, useFeature } from "@bucketco/react-sdk";

const App = ({ company, ...rest }) => (
  <BucketProvider company={{ ...company, attributeMatchingCriteria: true }} {...rest}>
    <Feature />
  </BucketProvider>
)

const Feature = () => {
  const { isEnabled } = useFeature("${flagKey}");

  if (!isEnabled) return null;
  return <p>Feature is enabled!</p>;
}
`;

export const browserAttributeExample = (flagKey: string) => /*js*/ `
import { BucketClient } from "@bucketco/browser-sdk";

// supply company attributes either in the BucketClient constructor
const bucketClient = new BucketClient({
  company: {
    attributeMatchingCriteria: true
    ...
  },
  ...
})

const { isEnabled } = bucketClient.getFeature("${flagKey}");
if (isEnabled) {
  // feature is enabled
}
`;

export const nodeAttributeExample = (flagKey: string) => /*js*/ `
// import an initialized BucketClient
import { bucketClient } from "./bucketClient";

// supply company attributes either in the BucketClient constructor
// or by passing them to an initialized bucketClient.company()
await bucketClient.updateCompany({ attributes: { attributeMatchingCriteria: true }});

const { isEnabled } = bucketClient.getFeature("${flagKey}");
if (isEnabled) {
  // feature is enabled
}
`;
