import { useMemo } from "react";
import { Link, To } from "react-router-dom";
import {
  Box,
  chakra,
  Divider,
  HStack,
  StackProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { FeatureRolloutStatus } from "@bucketco/shared/featureAPI";
import { StageNameDTO } from "@bucketco/shared/stageAPI";

import EnvironmentSvg from "@/common/assets/environment-dot-circle-fill.svg?react";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { RulesSummary } from "@/feature/components/RolloutStatus";
import { useFeatureRolloutStatusData } from "@/feature/data/useFeatureRolloutStatusData";
import { StageDisplayName } from "@/stage/components/StageDisplayName";
import { StageFillIcon } from "@/stage/components/StagesIcon";

const EnvironmentIcon = chakra(EnvironmentSvg);

type RolloutStatusLabelProps = {
  status?: FeatureRolloutStatus;
  showRules?: boolean;
};

export const RolloutStatusLabel = ({
  status,
  showRules = true,
}: RolloutStatusLabelProps) => {
  const dividerColor = useColorModeValue("gray.300", "gray.600");

  if (!status) {
    return (
      <Box alignItems="center" display="inline-flex" gap={1}>
        <NoDataYet />
      </Box>
    );
  }

  return (
    <Box alignItems="center" display="inline-flex" gap={2}>
      <EnvironmentDisplayName as="span" environment={status.environment} />
      {showRules && (
        <>
          <Divider
            alignSelf="stretch"
            borderColor={dividerColor}
            h="auto"
            orientation="vertical"
          />
          <RulesSummary rules={status.targetingRules} />
        </>
      )}
    </Box>
  );
};

type RolloutStageLabelProps = {
  stage?: StageNameDTO | null;
};

export const RolloutStageLabel = ({ stage }: RolloutStageLabelProps) => {
  if (!stage) {
    return (
      <Box alignItems="center" display="inline-flex" gap={1}>
        <NoStage />
      </Box>
    );
  }

  return (
    <Box alignItems="center" display="inline-flex" gap={2}>
      <StageDisplayName stage={stage} />
    </Box>
  );
};

type RolloutStatusTagProps = {
  featureId?: string;
  link?: To;
};

export const RolloutStatusTag = ({
  featureId,
  link,
}: RolloutStatusTagProps) => {
  const { isEnabled: isStagesEnabled, isLoading: isStagesLoading } =
    useFeature("stages");
  const { data, isLoading } = useFeatureRolloutStatusData(featureId);

  const status = useMemo(() => {
    return data?.statuses?.find(
      // Just check if either is set to avoid different system time issues
      ({ latestCheck, latestUsage }) => latestCheck || latestUsage,
    );
  }, [data]);

  if (isLoading || isStagesLoading) {
    return null;
  }

  return (
    <Box as={link ? Link : undefined} fontSize="sm" to={link!}>
      {isStagesEnabled ? (
        <RolloutStageLabel stage={data?.stage} />
      ) : (
        <RolloutStatusLabel status={status} />
      )}
    </Box>
  );
};

export function NoDataYet(props: StackProps) {
  const iconColor = useColorModeValue("gray.400", "gray.500");
  return (
    <HStack {...props} spacing={1}>
      <EnvironmentIcon boxSize={3} color={iconColor} />
      <Text as="span" color="dimmed" fontWeight="medium" whiteSpace="nowrap">
        No data yet
      </Text>
    </HStack>
  );
}

export function NoStage(props: StackProps) {
  const iconColor = useColorModeValue("gray.400", "gray.500");
  return (
    <HStack {...props} spacing={1}>
      <StageFillIcon boxSize={3} color={iconColor} flexShrink={0} />
      <Text as="span" color="dimmed" fontWeight="medium" whiteSpace="nowrap">
        No stage
      </Text>
    </HStack>
  );
}
