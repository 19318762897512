import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  TargetAudienceEstimateRequest,
  TargetAudienceEstimateResult,
} from "@bucketco/shared/filterAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";

import { commonQueryKeys } from "./commonQueryKeys";

export function useEstimateTargetAudience<TData = TargetAudienceEstimateResult>(
  args?: TargetAudienceEstimateRequest,
  options?: Omit<
    UseQueryOptions<
      TargetAudienceEstimateResult,
      AxiosError<ErrorResponse>,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) {
  const { currentApp } = useAuthContext();

  return useQuery<
    TargetAudienceEstimateResult,
    AxiosError<ErrorResponse>,
    TData
  >({
    queryKey: commonQueryKeys.estimateTargetAudience(currentApp?.id, args),
    queryFn: () =>
      api
        .post<"/apps/:appId/filters/estimate">(
          `/apps/${currentApp?.id}/filters/estimate`,
          args,
        )
        .then((res) => res.data),
    ...options,
    enabled:
      options?.enabled ??
      (!!currentApp && !!args && !!Object.keys(args).length),
  });
}
