import { useMemo } from "react";
import { RiArrowDownSLine, RiTimeLine } from "react-icons/ri";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Text,
} from "@chakra-ui/react";

import MenuDescription from "@/common/components/MenuDescription";

export const periodList = ["past30days", "past3months", "past6months"] as const;
export type Period = (typeof periodList)[number];

export type PeriodPickerProps = Omit<MenuProps, "children"> & {
  value: Period;
  onChange: (value: Period) => void;
};

export default function PeriodPicker({
  value,
  onChange,
  ...rest
}: PeriodPickerProps) {
  const satisfactionFilterOptions: Record<Period, JSX.Element> = useMemo(
    () => ({
      past30days: (
        <Flex alignItems="center" gap={2}>
          <Box>Past 30 days</Box>
        </Flex>
      ),
      past3months: (
        <Flex alignItems="center" gap={2}>
          <Box>Past 3 months</Box>
        </Flex>
      ),
      past6months: (
        <Flex alignItems="center" gap={2}>
          <Box>Past 6 months</Box>
        </Flex>
      ),
    }),
    [],
  );

  return (
    <Menu {...rest}>
      <MenuButton
        as={Button}
        leftIcon={
          <Text fontSize="lg" variant="dimmed">
            <RiTimeLine />
          </Text>
        }
        rightIcon={
          <Box fontSize="xl" mr={-2}>
            <RiArrowDownSLine />
          </Box>
        }
        size="sm"
        variant="input"
        {...rest}
      >
        {satisfactionFilterOptions[value]}
      </MenuButton>
      <MenuList>
        <MenuDescription color="dimmed">Select time period</MenuDescription>
        <MenuOptionGroup
          value={value ?? "any"}
          onChange={(value) => onChange(value as Period)}
        >
          {periodList.map((period) => (
            <MenuItemOption key={period} value={period}>
              {satisfactionFilterOptions[period]}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
