import { forwardRef } from "react";
import { Input, InputProps } from "@chakra-ui/react";

export function isoDateToInputDate(isoDateString: string | undefined | null) {
  if (isoDateString) {
    return isoDateString.slice(0, 10);
  }
  return "";
}

export type DateInputProps = Omit<
  InputProps,
  "type" | "value" | "defaultValue"
> & {
  value?: string;
  defaultValue?: string;
  asIsoString?: boolean;
};

export const DateInput = forwardRef(function DateInput(
  { asIsoString = false, sx, value, onChange, ...rest }: DateInputProps,
  ref,
) {
  const hasValue = Boolean(value || rest.defaultValue);

  return (
    <Input
      ref={ref}
      sx={{
        // Safari-only selector
        "@supports (background: -webkit-named-image(i))": {
          // Target internal shadow dom, outermost container
          "::-webkit-datetime-edit": {
            // Set opacity low instead of setting a color.
            // Safari seems to override the color dynamically,
            // which causes inconsistent coloring
            opacity: hasValue ? 1 : 0.4,
          },
        },

        // Inverse Safari selector. All other browsers can use color
        "@supports not (background: -webkit-named-image(i))": {
          color: hasValue ? "inherit" : "inputPlaceholder",
        },
        ...sx,
      }}
      type="date"
      {...rest}
      value={asIsoString ? isoDateToInputDate(value) : value}
      onChange={(e) => {
        if (asIsoString) {
          e.target = {
            ...e.target,
            value: e.target.valueAsDate?.toISOString() ?? "",
          };
        }

        onChange?.(e);
      }}
    />
  );
});
