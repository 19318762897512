import { forwardRef } from "react";
import { chakra, HTMLChakraProps, useColorModeValue } from "@chakra-ui/react";

const FieldSet = chakra.fieldset;
const Legend = chakra.legend;

type FieldSetProps = HTMLChakraProps<"fieldset"> & {
  title?: string;
  _title?: HTMLChakraProps<"legend">;
};

export const FormFieldSet = forwardRef<HTMLFieldSetElement, FieldSetProps>(
  ({ title, children, _title }, ref) => {
    const legendColor = useColorModeValue("gray.50", "gray.900");

    return (
      <FieldSet ref={ref} style={{ display: "contents" }}>
        {title && (
          <Legend
            bgColor={legendColor}
            borderRadius="md"
            boxSizing="content-box"
            display="block"
            mt={4}
            mx={-3}
            px={3}
            py={2}
            {..._title}
          >
            {title}
          </Legend>
        )}
        {children}
      </FieldSet>
    );
  },
);

FormFieldSet.displayName = "FormFieldSet";
