import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import { EnvironmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import navigateToLogin from "@/common/utils/navigateToLogin";

export default function RequireEnvironment({
  children,
}: {
  children: ReactNode;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { envId, envName } = useParams();
  const { currentOrg, currentApp, currentEnv } = useAuthContext();
  const errorToast = useErrorToast();

  useEffect(() => {
    // If an app was deleted, the user might log in with
    // an url to the deleted app. Detect this and redirect
    // to the new currentApp
    if (currentApp && currentEnv && envId && envId !== currentEnv.id) {
      const toastId = "environment-not-found-redirect";

      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          position: "top",
          variant: "subtle",
          status: "warning",
          description: `The environment you wanted to access was not available. Redirecting you to: ${currentApp.name} (${currentEnv.name})`,
          duration: 5000,
        });
      }

      navigate(EnvironmentUrl(currentEnv), { state: { from: location } });
    }
  }, [currentEnv, envId, toast, navigate, location, currentApp]);

  useEffect(() => {
    if (!currentOrg)
      errorToast({
        description: `You don't have access to the environment "${envName}" (${envId})`,
        duration: 5000,
      });
  }, [errorToast, currentOrg, envId, envName]);

  if (!currentOrg) {
    return navigateToLogin();
  }
  return <>{children}</>;
}
