import { FC } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { FeatureName } from "@bucketco/shared/featureAPI";
import { FeatureUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import { TreeVisualization } from "@/common/components/TreeVisualization";
import pluralize from "@/common/utils/pluralize";
import { assignIndents, flattenTree, IndentType } from "@/common/utils/tree";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";

type FeatureTreeNavigationProps = {
  rootFeatureId?: string;
};

export const FeatureTreeNavigation: FC<FeatureTreeNavigationProps> = ({
  rootFeatureId,
}) => {
  const { currentEnv } = useAuthContext();
  const tree = useFeatureNamesTree(rootFeatureId);
  const list = flattenTree(tree);
  const currentFeatureNode = list.find((n) => n.feature.id === rootFeatureId);
  const currentPageColor = useColorModeValue("brand.500", "brand.300");

  const childrenCount = flattenTree(currentFeatureNode?.children ?? []).length;

  // do not show the tree picker if there's no children nor parents
  const shouldShowTree =
    currentFeatureNode?.parent !== null ||
    currentFeatureNode?.children.length > 0;

  if (currentFeatureNode && !shouldShowTree) {
    return (
      <FeatureDisplay
        feature={currentFeatureNode.feature}
        fontWeight="medium"
        iconColor="inherit"
        includeParents={true}
        ml={3}
        size="lg"
      />
    );
  }

  return (
    <HStack spacing={1.5}>
      <Menu>
        <MenuButton
          aria-label="Switch feature"
          as={Button}
          display="flex"
          pl={3}
          pr={2}
          rightIcon={
            <Box fontSize="lg" ml={-0.5}>
              <RiArrowDownSLine />
            </Box>
          }
          size="md"
          variant="outlineOnHover"
        >
          {currentFeatureNode && (
            <FeatureDisplay
              feature={currentFeatureNode.feature}
              fontWeight="medium"
              iconColor="inherit"
              includeParents={true}
              size="lg"
            />
          )}
        </MenuButton>
        <MenuList>
          {list.map((i) => {
            const isCurrentPage = i.feature.id === rootFeatureId;

            return (
              <MenuItem
                key={i.feature.id}
                alignItems="center"
                aria-current={isCurrentPage ? "page" : undefined}
                as={Link}
                bg={isCurrentPage ? currentPageColor : undefined}
                color={isCurrentPage ? currentPageColor : undefined}
                display="flex"
                py={0}
                sx={{
                  bg: isCurrentPage ? "gray.50" : undefined,
                  _dark: {
                    bg: isCurrentPage ? "gray.750" : undefined,
                  },
                }}
                to={FeatureUrl(currentEnv!, i.feature)}
              >
                <TreeVisualization
                  expandedDescenderHeight={"6px"}
                  h="32px"
                  indents={i.indents}
                />
                <FeatureDisplay
                  feature={i.feature}
                  iconColor={isCurrentPage ? currentPageColor : undefined}
                  includeParents={false}
                ></FeatureDisplay>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      {childrenCount > 0 && (
        <Text color="dimmed" fontSize="sm" fontWeight="normal">
          {childrenCount} {pluralize("subfeature", childrenCount)}
        </Text>
      )}
    </HStack>
  );
};

type FeatureNameTreeNode = {
  feature: FeatureName;
  parent: FeatureNameTreeNode | null;
  children: Array<FeatureNameTreeNode>;
  indents: Array<IndentType>;
  depth: number;
};

/**
 *
 * @param featureId Optionally filter the full list of feature trees to only the tree containing this featureId
 */
function useFeatureNamesTree(featureId?: string): FeatureNameTreeNode[] {
  const { data: featureNames = [] } = useFeatureNamesData();

  const tree: Array<FeatureNameTreeNode> = [];
  const lookup: Record<string, FeatureNameTreeNode | undefined> = {};

  // One iteration to establish the tree
  for (const f of featureNames) {
    const parent = f.parentFeatureId ? lookup[f.parentFeatureId] : null;
    const depth = parent ? parent.depth + 1 : 0;

    const node: FeatureNameTreeNode = {
      feature: f,
      parent: parent ?? null,
      children: [],
      indents: [],
      depth,
    };
    lookup[f.id] = node;

    if (node.parent) {
      node.parent.children.push(node);
    } else {
      tree.push(node);
    }
  }

  // Second iteration to establish the indent types.
  assignIndents(tree);

  // Filter down to relevant feature tree
  if (featureId) {
    let currentNode = lookup[featureId];

    while (currentNode?.parent) {
      currentNode = currentNode.parent;
    }

    if (currentNode) {
      return [currentNode];
    } else {
      return [];
    }
  }

  return tree;
}
