import { BoxProps } from "@chakra-ui/react";

import MotionBox from "@/common/components/MotionBox";

function MotionFadeIn({
  children,
  ...props
}: { children: React.ReactNode } & BoxProps) {
  return (
    <MotionBox
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      transition={{ duration: "0.15", ease: "easeInOut" }}
      {...props}
    >
      {children}
    </MotionBox>
  );
}

export default MotionFadeIn;
