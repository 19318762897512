import { ReactNode } from "react";
import { Text, TextProps } from "@chakra-ui/react";

import { AttributeType } from "@bucketco/shared/companyAPI";
import { FunnelStep } from "@bucketco/shared/featureAPI";

import BooleanCell from "./BooleanCell";
import FunnelStateCell from "./FunnelStateCell";
import NotAvailableCell from "./NotAvailableCell";
import NumberCell from "./NumberCell";
import { OverflowTooltip } from "./OverflowTooltip";
import TimestampCell from "./TimestampCell";

type Props = {
  value: string | number | boolean | Date | null | undefined;
  compact?: boolean;
  type: AttributeType | "funnelStep";
};

export default function DataCell({
  value,
  type,
  compact,
  ...props
}: Props & TextProps) {
  let component: ReactNode;
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    value === "null"
  ) {
    component = <NotAvailableCell {...props} />;
  } else if (type === "funnelStep" && typeof value === "string") {
    component = <FunnelStateCell value={value as FunnelStep} {...props} />;
  } else if (type === "boolean") {
    component = <BooleanCell value={Boolean(value)} {...props} />;
  } else if (type === "date" && value instanceof Date) {
    component = <TimestampCell value={value.toISOString()} leftAlign />;
  } else if (type === "date" && typeof value === "string") {
    component = <TimestampCell value={value} leftAlign />;
  } else if (type === "number") {
    component = (
      <NumberCell compact={compact} value={Number(value)} {...props} />
    );
  } else {
    component = (
      <OverflowTooltip
        label={value.toString()}
        openDelay={300}
        placement="bottom-start"
        hasArrow
      >
        <Text {...props}>
          {value instanceof Date ? value.toISOString() : value.toString()}
        </Text>
      </OverflowTooltip>
    );
  }

  return component;
}
