import { Box, Grid, GridItem, Text } from "@chakra-ui/react";

import { ErrorResponse } from "@bucketco/shared/api";

export function ErrorResponseDisplay({
  response,
}: {
  response?: ErrorResponse;
}) {
  if (!response) {
    return null;
  }

  return (
    <Box>
      <Text>Error: {response.error.message ?? response.error.code}</Text>
      {response.validationErrors && (
        <Grid as="dl" gap={2} gridTemplateColumns={"max-content 1fr"}>
          {response.validationErrors.map((error, idx) => (
            <>
              <GridItem key={`${idx}_path`} as="dt">
                {error.path.join(".")}
              </GridItem>
              <GridItem key={`${idx}_message`} as="dd">
                {error.message}
              </GridItem>
            </>
          ))}
        </Grid>
      )}
    </Box>
  );
}
