import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { StageDTO, StagesQuery } from "@bucketco/shared/stageAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { stageQueryKeys } from "./stageQueryKeys";

export const useStagesData = <TData = StageDTO[]>(
  searchParams?: StagesQuery,
  queryOptions?: Omit<
    UseQueryOptions<StageDTO[], AxiosError<ErrorResponse>, TData>,
    "queryKey" | "queryFn" | "initialData"
  > & { initialData?: () => undefined },
) => {
  const { appId } = useCurrentEnv();

  // Avoid literal undefined as it becomes the string 'undefined' in search params
  const cleanSearchParams: StagesQuery | undefined =
    searchParams &&
    Object.fromEntries(
      Object.entries(searchParams).filter(([_key, value]) => {
        return typeof value !== "undefined";
      }),
    );

  const dataUrl = `/apps/${appId}/stages?${new URLSearchParams(
    cleanSearchParams as Record<string, string>,
  ).toString()}`;

  return useQuery({
    queryKey: stageQueryKeys.list(appId, cleanSearchParams),

    queryFn: () =>
      api.get<"/apps/:appId/stages">(dataUrl).then((res) => res.data.stages),
    enabled: !!appId,
    ...queryOptions,
  });
};
