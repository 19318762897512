import { Alert, AlertDescription, Text } from "@chakra-ui/react";

import { Dependee, UIFilter } from "@bucketco/shared/filter";

import { AndOrList } from "@/common/components/AndOrList";
import pluralize from "@/common/utils/pluralize";
import { SegmentDisplay } from "@/company/components/SegmentDisplay";
import { DeleteAffectEnvironments } from "@/environment/components/EnvironmentCallouts";
import { useFilterCheck } from "../data/useFilterCheck";

type AffectedDependenciesProps = {
  dependee: Dependee;
  filter?: UIFilter;
  changeType: "delete" | "update";
  showAffectEnvironments?: boolean;
};

const AffectedDependencies = ({
  dependee,
  filter,
  changeType,
  showAffectEnvironments,
}: AffectedDependenciesProps) => {
  const { data } = useFilterCheck(dependee, filter);

  if (!data) {
    return null;
  }

  const { features, segments } = data;
  if (!features.length && !segments.length) {
    if (showAffectEnvironments) {
      return <DeleteAffectEnvironments entity={dependee.type} mt={8} />;
    } else {
      return null;
    }
  }

  if (changeType === "update" && data.cycle) {
    return (
      <Alert alignItems="flex-start" maxW="sm" status="error">
        <AlertDescription>
          <Text lineHeight={1.5}>
            Changes to the filter of this {dependee.type} would create a{" "}
            <strong>cycle</strong>. Modify the filter to remove the cycle.
          </Text>
        </AlertDescription>
      </Alert>
    );
  }

  const hasFeatures = features.length > 0;
  const hasSegments = segments.length > 0;

  return (
    <Alert
      alignItems="flex-start"
      maxW="sm"
      mt={2}
      status={changeType === "delete" ? "error" : "warning"}
    >
      <AlertDescription lineHeight={1.5}>
        {changeType === "delete" && (
          <Text>
            Deleting this {dependee.type} is <strong>not allowed</strong>{" "}
            because it is a dependency of:{" "}
          </Text>
        )}
        {changeType === "update" && (
          <Text>
            Changes to these filters will affect the segment/features that
            depend on this {dependee.type}:{" "}
          </Text>
        )}
        {hasSegments && (
          <>
            {pluralize("segment", segments.length)}{" "}
            <AndOrList
              conjunction="and"
              direction="horizontal"
              display="inline-flex"
              verticalAlign="bottom"
            >
              {segments.map((s) => (
                <SegmentDisplay
                  key={s.id}
                  display="inline-flex"
                  segment={s}
                  link
                />
              ))}
            </AndOrList>
          </>
        )}
        {hasFeatures && (
          <>
            {hasSegments && ", and "}
            {pluralize("feature", features.length)}{" "}
            <AndOrList
              conjunction="and"
              direction="horizontal"
              display="inline-flex"
              verticalAlign="bottom"
            >
              {features.map((s) => (
                <SegmentDisplay
                  key={s.id}
                  display="inline-flex"
                  segment={s}
                  link
                />
              ))}
            </AndOrList>
          </>
        )}
      </AlertDescription>
    </Alert>
  );
};

export default AffectedDependencies;
